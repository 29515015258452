import {connect} from "react-redux";
import React, {useCallback, useEffect} from "react";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import {useHistory, withRouter} from "react-router-dom";
import {shortUpperCaseFirstChars, useQuery} from "../services/helpers";
import Avatar from "@material-ui/core/Avatar";
import {makeStyles} from "@material-ui/styles";
import Tooltip from "@material-ui/core/Tooltip";
import clsx from "clsx";
import Skeleton from "@material-ui/lab/Skeleton";


const useStyles = makeStyles((theme) => ({
    small: {
        width: theme.spacing(4),
        height: theme.spacing(4),
        backgroundColor: '#10B16E',
        margin:'auto',
    },
    notices:{
        borderRight:'3px solid red'
    }
}));

function ProjectsListItems(props) {
    let history   = useHistory();
    let query     = useQuery();
    const classes = useStyles();

    const handleChangeProject = (value) => {

        const active = findProject(value);

        if (!!active) {
            let params = {};
            for (const [key, value] of query.entries()) {
                params = {...params, ...{[key]:value}};
            }

            props.onChange && history.push(`/app/${active.path}/${props.match?.params?.page || 'daily'}`);

            props.updateProject({active});
            props.getCountriesList({projectId:active.id, error: null, errorCode: null,});
            props.updateReports({data:null, summary:null, uniquePayments:[], uniqueSubscriptions:[], error: null, errorCode: null,});
            props.updateAbTests({abTests:null, abTest: null,actionLog:null,histogram:null, error: null, errorCode: null,});
            props.projectTestsUpdateAbTests({letters:null, list: null, error: null, errorCode: null,});
            props.updateProducts({helpers:null, products: null, error: null, errorCode: null,});
            props.updateSettings({data:null, error: null, errorCode: null,});
            props.updateDeeplink({deeplinks:null, error: null, errorCode: null,});
            props.updateCreateAbTest({versions:null, error: null, errorCode: null,});
            props.getVersions({projectId: active.id, error: null, errorCode: null,});

            props.updateCalculator({data:null, error: null, errorCode: null,});
            props.updateRenewals({ periods: null });

            props.updateRenewalsCalculator({
                max_num_payments:0,
                error: null,
                errorCode: null,
                renewals: null,
            });
            props.homeUpdateCalculator({title:null,
                budget:null,
                country:"*",
                registered: {
                    after: null,
                    before: null
                },

                data: null,});
            props.calcPredictiveUpdate({data:null, error: null, errorCode: null,});

            if (params?.version)
                props.getVersionLog({projectId: active.id, version: params?.version});

            props.updateReport({
                renewals: null,
            });

            props.onChange && props.onChange();
        }
    };

    const findProject = useCallback((value, field = 'path') => {
        return (props.projects.projects||[]).find(project => project[field] === value)
    }, [props.projects.projects]);

    useEffect(() => {

        if (props.projects.projects === null && props.projects.pending === false && props.projects.error === null){
            props.getProjects();
        } else if (props.project.active === null && props.project.pending === false &&
            props.projects.projects?.length > 0 && props.projects.pending === false && props.projects.error === null){

            const active = findProject(props.match.params?.projectPath);

            if (!!active) {
                props.updateProject({active});
            }
        }

        if (props.project?.active?.name) {
            document.getElementsByTagName('title')[0].innerText = `BiTable - ${props.project?.active?.name}`
        }

    }, [props, findProject]);


    if (props.projects.projects === null && props.projects.pending === true)
        return (new Array(10)).fill(1).map((_, index) =>
            <ListItem key={index}>
                <Skeleton variant="circle" width={40} height={24} />
            </ListItem>
        );


    return (
        props.projects.projects||[]).map((item, i) =>
        <ListItem key={i}
                  disableGutters={true}
                  className={clsx(item.notices.length > 0 ? classes.notices: null)}
                  button
                  onClick={handleChangeProject.bind(this, item.path)}
                  selected={item.id === props.project?.active?.id} >
            <ListItemIcon>
                <Tooltip title={item.name} placement="right-end" arrow>
                    <Avatar sizes={'small'} className={classes.small}  src={item?.icon} >
                        {shortUpperCaseFirstChars(item.name)}
                    </Avatar>
                </Tooltip>
            </ListItemIcon>
            <ListItemText primary={item.name} />
        </ListItem>
    );

}



const mapState = state => ({
    profile:      state.profile,
    projects:     state.projects,
    project:      state.project,
    abtests:      state.abtests,
    createAbTest: state.createAbTest,
    calculator:   state.calculator,
    report:       state.report,

    renewalcaluator:      state.renewalcaluator,
    calculatorhome:       state.calculatorhome,
    calculatorpredictive: state.calculatorpredictive,
});

const mapDispatch = ({
                         renewals,
                         projects: {
                             projects,
                             updateProjects
                         },
                         project: {
                             updateProject,
                             getCountriesList
                         },
                         settings:{
                             updateSettings
                         },
                         reports:{
                             updateReports
                         },
                         products:{
                             updateProducts
                         },
                         projectTests:{
                             updateAbTests : projectTestsUpdateAbTests

                         },
                         deeplink:{
                             updateDeeplink,
                         },
                         abtests:{
                             updateAbTests
                         },
                         createAbTest:{
                             updateCreateAbTest
                         },
                         versions:{
                             getVersions
                         },
                         versionLog:{
                             getVersionLog
                         },
                         calculator:{
                             updateCalculator
                         },
                         report: {
                             updateReport
                         },
                         renewalcaluator,
                         calculatorhome,
                         calculatorpredictive,

                     }) => ({
    // getProjects: (...data) => projects(...data),
    getProjects: projects,
    updateProjects: (...data) => updateProjects(...data),
    updateSettings: (...data) => updateSettings(...data),
    updateProject: (...data) => updateProject(...data),
    getCountriesList: (...data) => getCountriesList(...data),
    updateReports: (...data) => updateReports(...data),
    updateAbTests: (...data) => updateAbTests(...data),
    updateProducts: (...data) => updateProducts(...data),
    projectTestsUpdateAbTests: (data) => projectTestsUpdateAbTests(data),
    getVersions,
    getVersionLog,
    updateDeeplink,
    updateCreateAbTest,
    updateCalculator,
    updateReport,

    updateRenewalsCalculator: (...data) => renewalcaluator.updateRenewals(...data),
    updateRenewals: (...data)           => renewals.updateRenewals(...data),
    homeUpdateCalculator:     (...data) => calculatorhome.updateCalculator(...data),
    calcPredictiveUpdate:     (...data) => calculatorpredictive.update(...data),
});

export default withRouter(connect(mapState, mapDispatch)(ProjectsListItems))