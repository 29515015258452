import ApiService from "../services/api";
import {checkResponseError} from './helpers'

const ACCESS_TOKEN = 'access_token';

export default {
    state: {
        pending: false,
        error: null,
        errorCode: null,
        access_token: localStorage.getItem(ACCESS_TOKEN),

        isAuthUser:() => !!localStorage.getItem(ACCESS_TOKEN),

        userInfo:{
            email: ''
        },
    },
    reducers: {
        authStart(state) {
            return {
                ...state,
                pending: true
            }
        },
        updateError(state, data) {
            return {
                ...state,
                ...data,
                pending: false
            }
        },
        updateProfile(state, data){
            return {
                ...state,
                ...data,
                pending: false
            }
        }
    },

    effects: (dispatch) => ({
        async login(data) {
            try {
                dispatch.profile.authStart();

                let responseData = await ApiService.login(data);

                dispatch.profile.updateProfile({userInfo:{email: data.email}});

                checkResponseError(dispatch.profile, responseData);

                if (responseData.hasOwnProperty(ACCESS_TOKEN) === true) {
                    localStorage.setItem(ACCESS_TOKEN, responseData[ACCESS_TOKEN]);

                    dispatch.profile.updateProfile({
                        access_token:responseData[ACCESS_TOKEN],
                        // isAuthUser: true
                    });
                }

                if (responseData.hasOwnProperty('projects') === true) {
                    dispatch.projects.updateProjects({projects: responseData.projects});
                }

                return responseData;

            } catch (e) {
                console.log('Catch login', e);
                dispatch.profile.updateProfile({error: e.message})
            }
        },

        logOut(){
            localStorage.removeItem(ACCESS_TOKEN);

            dispatch.profile.updateProfile({
                access_token: null
            });
        },

        async renewPassword(data){
            try {
                dispatch.profile.authStart();

                let responseData = await ApiService.renewPassword(data);

                checkResponseError(dispatch.profile, responseData);

                if (responseData.hasOwnProperty(ACCESS_TOKEN) === true) {
                    localStorage.setItem(ACCESS_TOKEN, responseData[ACCESS_TOKEN]);

                    dispatch.profile.updateProfile({access_token:responseData[ACCESS_TOKEN]});
                }

                if (responseData.hasOwnProperty('projects') === true) {
                    dispatch.projects.updateProjects({projects: responseData.projects});
                }

                return responseData;

            } catch (e) {
                dispatch.profile.updateProfile({error: e.message})
            }
        }
    })
};