

export function checkResponseError(dispatchModel, responseData) {
    if (responseData?.success === false){
        dispatchModel.updateError({
            errorCode: responseData?.error_code,
            error: responseData?.error,
            pending: false
        });
    } else if (responseData?.success === true){
        dispatchModel.updateError({
            errorCode: null,
            error: null,
        });
    }
}

export const round = (num, round= 0) => {
    num = num != null ? num : 0;
    const n = Math.pow(10, round);
    return Math.round(num * n)/n
};

export const saveDivide = (a, b) => {

    if (b === 0) return 0;

    return a / b;
};

export const percent = (a, b, isNumber = false) => {

    const percent = round(saveDivide(a, b)*100,0);

    if (isNumber) return percent;

    return `${percent}%`;
};

export const calcFields = (countUsers, {product_id, price, price_2pp, trials, purchases, lifetime}) => {

    const c1 = saveDivide(trials, countUsers);
    const c2 = saveDivide(purchases, trials);
    const paidCVR = saveDivide(purchases, countUsers);
    const userLTV = (price * lifetime + price_2pp * (lifetime - 1)) * paidCVR;

    // (30*0.7*105)+(30*0.85*(105*0.3)

    return { product_id,
        price,
        trials,
        purchases,
        lifetime,
        c1: round(c1 * 100, 2) ,
        c2: round(c2 * 100, 2) ,
        paidCVR: round(paidCVR * 100, 2),
        userLTV: userLTV,

        priceOnPaid:        round(price * purchases, 2),
        priceOnPaidLifetime: `${product_id}`.includes('year') ?
            round(price * purchases + price_2pp * purchases * (lifetime - 1), 2) :
            round(price * purchases * lifetime, 2),
    }
};