import * as Column from "react-base-table";
import {reduce} from 'lodash';
import moment from 'moment'
import Box from "@mui/material/Box";
import React from "react";
import Tooltip from "@mui/material/Tooltip";

const columns = [
    {
        align: Column.Alignment.LEFT,
        frozen: Column.FrozenDirection.LEFT,
        dataKey: 'rowName',
        key: 'rowName',
        title: 'Финансы',
        width: 150,
        style: {
            fontWeight: 600
        }
    }
];

const prepareColumns = weeklyData => {
    return reduce(weeklyData, (acc, {title, tp: {diffFinanceFinish, weekFinanceStart, weekFinanceFinish}}) => {
        return [
            ...acc,
            {
                align: Column.Alignment.RIGHT,
                dataKey: `week${acc.length}`,
                key: `week${acc.length}`,
                title: (
                    <Tooltip
                        arrow
                        title={[
                            moment(weekFinanceStart, 'DD.MM.YYYY HH:mm:ss')?.format('YYYY-MM-DD HH:mm'),
                            moment(weekFinanceFinish, 'DD.MM.YYYY HH:mm:ss')?.format('YYYY-MM-DD HH:mm')
                        ].join(' ')}
                    >
                        <Box>
                            <Box>{title}</Box>
                            <Box>{diffFinanceFinish}</Box>
                        </Box>
                    </Tooltip>
                ),
                width: 120
            }
        ]
    }, columns)
};

export default prepareColumns;
