import React from "react";
import 'react-base-table/styles.css';
import { reduce, orderBy, replace, isEmpty, isObject, round, has, trim, values, upperFirst, get } from 'lodash'
import moment from 'moment'
import Box from "@material-ui/core/Box";
import {CellFormatRender} from "./utils";
import { BaseGridTable } from "../../../components";
import {Typography} from "@material-ui/core";
import CustomPopper from "../../../components/CustomPopover";
import {HoveredBox} from "./styled";
import {GridToolbarContainer} from "@mui/x-data-grid";
import LTVDistribution from "../../../components/Home/LTVDistribution";

const PERIOD_ROWS = ['users_payed', 'payments', 'revenue', 'subrate', 'profit', 'ROMI', 'CAC', 'trials', 'CAC_trials', 'LTV', 'ARPU'];

const getDataInfo = ({ data = {} }) => {
    return reduce(data, (acc, value, key) => [
        ...acc,
        <HoveredBox p={0.5} key={key} display="flex" justifyContent="space-between">
            <Typography mr={1}>{key}</Typography>
            <Typography ml={1}>&nbsp;&nbsp;{value?.toLocaleString('en-US')}</Typography>
        </HoveredBox>
    ], []);
};


const prepareColumns = (data) => {
    const dateNow = moment();

    data = reduce(data, (acc, item) => {
        item.dateTime = moment(trim(`${item.date} ${item?.time}`), 'DD.MM.YYYY HH:mm:ss');
        return [
            ...acc,
            item
        ];
    }, []);

    return [
        {
            field: 'period',
            headerName: 'Period',
            minWidth:   100,
            headerAlign: 'left',
            align: 'left',
            sortable: false,
            flex: 1,
            renderCell: ({ value }) => replace(upperFirst(value), '_', ' ')
        },
        ...reduce(
            orderBy(data, ['dateTime'], ['asc']), (acc, item) => {
                const key = item.dateTime.format( 'DD-MM-YYYY-HH-mm-ss');
                return [
                    ...acc,
                    {
                        field: key,
                        headerName:   item.name || item.date,
                        minWidth:   130,
                        headerAlign: 'right',
                        align: 'right',
                        sortable: false,
                        flex: 1,
                        renderCell: ({ value,  id, row }) => {
                            if (dateNow.diff(item.dateTime) < 0) return '';

                            let more = null;
                            if ((id === 'payments' || id === 'trials') && !isEmpty(value?.data)) {
                                const dataInfo = getDataInfo({ data: value?.data});
                                more = isEmpty(dataInfo)
                                    ? null
                                    : (
                                        <CustomPopper tooltipText={`Show ${id} details`}>
                                            <Box mx={2}>{dataInfo}</Box>
                                        </CustomPopper>
                                    )
                            }
                            else if (id ===  'LTV') {
                                more = (
                                    <CustomPopper tooltipText={`Show ${id} details`}>
                                        <LTVDistribution data={orderBy(value?.data, ['count'], ['desc'])} />
                                    </CustomPopper>
                                );
                            }

                            if (has(CellFormatRender, id))
                                return <>{CellFormatRender[id](value.value)}{more}</>;

                            return isObject(value.value) ?
                                '' :
                                <>{round(value.value, 2)?.toLocaleString('en-US')}{more}</>
                        }
                    }
                ]
        }, [])
    ];
};

const prepareTableRows = (data) => {
    return reduce(PERIOD_ROWS, (acc, field) => ([
        ...acc,
        {
            ...reduce(data, (acc2, item) => {
                item.dateTime = moment(trim(`${item.date} ${item?.time}`), 'DD.MM.YYYY HH:mm:ss');

                let data = null;
                if (['payments', 'trials'].includes(field))
                    data = get(item, `data.${field}`);
                else if (field === 'LTV')
                    data = get(item, `data.LTV_distribution`);

                return {
                    ...acc2,
                    [item.dateTime.format('DD-MM-YYYY-HH-mm-ss')]: {
                        value: item[field],
                        data
                    },
            }}, {
                'period': field,
                'id': field
            })
        }
    ]), [])
};


const ActualTable = ({ data, loading, spend, firstOpen }) => {
    const columns = prepareColumns(data);
    const rows = prepareTableRows(data);

    return (
        <BaseGridTable
            components={{
                Toolbar: () => (
                    <GridToolbarContainer style={{ borderBottom: '1px solid rgba(224,224,224,1)' }}>
                        <Box p={1} display="flex" alignItems="center">
                            <Typography p={1} component={Box} variant={'h4'} color={'inherit'}>Actual Table</Typography>
                            <Box px={1}>Spend: $ {spend?.toLocaleString('en-US')}</Box>
                            <Box px={1}>First Open: {firstOpen?.toLocaleString('en-US')}</Box>
                        </Box>
                    </GridToolbarContainer>
                )
            }}
            loading={loading}
            rows={isEmpty(data) ? [] : values(rows)}
            columns={columns}
        />
    )
};

export default ActualTable;