import React, { useEffect } from "react";
import { connect, useSelector } from 'react-redux'
import Box from "@material-ui/core/Box";
import ActualTableComponent from "./Home/ActualTableComponent";
import ManualTableComponent from "./Home/ManualTableComponent";
import { isEmpty } from 'lodash';
import WarningPopper from "./WarningPopper";

const HomeShortReport = ({ getVeryGoodReportData, project_id, registered_after, registered_before }) => {
    const { warnings } = useSelector(state => state.veryGood);

    useEffect(() => {
        if (project_id && !isEmpty(registered_after) && !isEmpty((registered_before)))
            getVeryGoodReportData({ project_id, registered_after, registered_before });
    }, [project_id, registered_after, registered_before, getVeryGoodReportData]);

    return (
        <Box pt={2}>
            <WarningPopper data={warnings} />

            <Box py={1}>
                <ActualTableComponent />
            </Box>

            {/*<Box py={1}>*/}
                {/*<PredictiveTableComponent />*/}
            {/*</Box>*/}

            <Box py={1}>
                <ManualTableComponent />
            </Box>
        </Box>
    );
};

const mapDispatch = ({ veryGood: { getVeryGoodReportData } }) => ({
    getVeryGoodReportData: (data) => getVeryGoodReportData(data),
});

export default connect(null, mapDispatch)(HomeShortReport);