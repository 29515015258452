import {connect} from "react-redux";
import Box from "@material-ui/core/Box";
import React, {useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Popper from '@material-ui/core/Popper';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Paper from "@material-ui/core/Paper";
import {Button, alpha, InputLabel, OutlinedInput, Typography} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import theme from "../../theme";
import Dialog from "@material-ui/core/Dialog";
import withStyles from "@material-ui/styles/withStyles";
import DialogContentText from '@material-ui/core/DialogContentText';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import TextField from "@material-ui/core/TextField";
import clsx from "clsx";
import Tooltip from "@material-ui/core/Tooltip";
import CircularProgress from "@material-ui/core/CircularProgress";
import InlineEdit from "../../components/InlineEdit";
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import UnfoldLessIcon from '@material-ui/icons/UnfoldLess';
import Collapse from "@material-ui/core/Collapse";
import buildQueryString, {addNZeroAtEnd, getLocaleDateString} from "../../services/helpers";
import * as moment from "moment";
import {round, saveDivide} from "../../models/helpers";
import * as echarts from 'echarts';
import {
    TitleComponent,
    ToolboxComponent,
    TooltipComponent,
    GridComponent,
    DataZoomComponent
} from 'echarts/components';
import {
    LineChart
} from 'echarts/charts';
import {
    CanvasRenderer
} from 'echarts/renderers';
import {useWindowSize, useHistory, useLocation} from "../../hooks";
import {useQuery} from "../../services/helpers";
import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";
import {CountryFlag} from "../../components";
import Chip from "@material-ui/core/Chip";

echarts.use(
    [TitleComponent, ToolboxComponent, TooltipComponent, GridComponent, DataZoomComponent, LineChart, CanvasRenderer]
);


const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    header: {
        background: 'rgba(246, 249, 255, 1)',
        '& > :first-child':{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
        }
    },
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',

    },
    table: {
        borderRadius: 7,
        '&>thead.MuiTableHead-root': {
            // '&>tr::first-child>th':{
            color: `${theme.palette.black} !important`,
            boxShadow: `0px 5px 7px ${alpha(theme.palette.black, 0.03)}`,
            // }
        }
    },
    tableHeader:{
        backgroundColor: '#fff',
    },
    odd: {
        backgroundColor: '#FBFCFF',
    },
    even: {
        backgroundColor:'#EAEDF3',
    },
    headerPercentGroup:{
        backgroundColor: '#D8F3FC',
    },
    ceilPercentGroup:{
        backgroundColor: '#EBF7FB',
        '&.even':{
            backgroundColor: '#e1eaed',
        }
    },
    headerLifetime:{
        backgroundColor: '#D8FFDA',
    },
    ceilLifetime:{
        backgroundColor: '#EBFBEC',
        '&.even':{
            backgroundColor: '#dbebdc',
        }
    },
    ltvPositive:{
        backgroundColor: '#E7F5EF',
        '&.even':{
            backgroundColor: '#d8e6e0',
        }
    },
    ltvNegative:{
        backgroundColor: '#FBEBEB',
        '&.even':{
            backgroundColor: '#e7d7d7',
        }
    },
    padding:{
        '& > td.MuiTableCell-root':{
            paddingTop:4,
            paddingBottom:4,
        }
    },
    totalRow:{
        '& > td':{
            fontWeight: 600,
            color: '#000',
        }
    },

    testSection:{
        '& > div' : {
            borderRadius: 7
        }
    },

    chooseTest:{
        cursor:'pointer',
        borderBottom:'11px solid rgba(16, 177, 110, 1)',
        height: 'calc(100% - 62px)',
        borderBottomRightRadius:'inherit',
        borderBottomLeftRadius:'inherit',
        '&::after':{
            color: '#FFFFFF',
            content: "'∨'",
            position: 'relative',
            top: '23px',
            textAlign: 'center',
            display: 'block',
        }
    },
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    debugCell:{
        textAlign: 'left',
        paddingLeft: 24,
    },
    foldIcon:{
        float: 'left',
        marginTop: -24,
    }
}));

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: `${theme.spacing(2)}px ${theme.spacing(3)+4}px`,
        backgroundColor: '#F0F3F9',
        position: 'sticky',
        top: 0,
        zIndex: 1,
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: 3,
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h4">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: `${theme.spacing(1)}px ${theme.spacing(2)+4}px`,
    },
}))(MuiDialogContent);


const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        paddingLeft: theme.spacing(3)+4,
        paddingRight: theme.spacing(3)+4,
        justifyContent: 'start',
        position: 'sticky',
        bottom: 0,
        zIndex: 1,
        backgroundColor: '#fff',
        boxShadow: '0px -5px 8px 0px rgba(0, 0, 0, 0.03)',
    },
}))(MuiDialogActions);


const TOOLTIP_TEXTS = {
    segment:    'Варианты теста',
    users:      'Кол-во юзеров в каждой когорте',
    productId:  'Список всех покупок на эту когорту (ID)',
    price:      'Цена покупки (берется из данных)',
    trial:      'Если в покупке есть триал (кол-во триалок)',
    paid:       'Тут либо прямая покупка, либо конверсия',
    c1:         'кол-во триалок деленное на кол-во юзеров в когорте',
    c2:         'кол-во покупок деленное на кол-во триалок 30/300. если покупка без триала - ставим ноль',
    paidCVR:    'кол-во покупок деленное на кол-во юзеров в когорте',
    lifetime:   'Данные берем из настроек',
    userLTV:    'Цена покупки * лайфтайм * Paid CVR ()',
    LTV:        'Сумма всех LTV по когорте',
    total:      'Сумма money в кагорте',
    priceOnPaidLifetime: '`Цена за первый год` * `кол-во` + `Цена для второго года` * `кол-во` * (`лайфтайм` - 1)'
};



function Report(props) {

    const format = getLocaleDateString('ru-RU').replace('dd', 'DD');

    const classes = useStyles();
    let history = useHistory();
    let location = useLocation();
    const [_width, ] = useWindowSize();
    let query = useQuery();

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [openAB, setOpenAB] = React.useState(false);
    const [attrDays, setAttrDays] = React.useState(query.get('attr_days') || '');
    const [countries, setCountries] = React.useState(query.getAll('countries[]') || []);

    const pendingValue = props.abtests.abTest;

    const chartRef = React.createRef();

    const abTestId = query.get('ab_test_id');

    useEffect(() => {

        if (props.abtests.abTests === null && props.abtests.pending === false && props.project?.active?.id && props.project.pending === false) {

            props.getABTests({projectId: props.project.active.id});

        } else if (abTestId && props.abtests.filterAbTests === null &&
            props.abtests.abTests?.length > 0 &&
             props.abtests.pending === false &&
            (countries.length > 0 ||  attrDays !== '')){

            props.getABTest({abTestId, attrDays, countries})

        } else if (abTestId && props.abtests.abTest === null && props.abtests.pending === false ){

            props.getABTest({abTestId});

        }
        // else if (abTestId && props.abtests.abTest !== null && props.abtests.abTest?.id !== parseInt(abTestId)){
        //
        //     props.getABTest({abTestId, attrDays, countries})
        //
        // }
    },[props, abTestId, attrDays, countries]);

    useEffect(() => {

        if ( props.abtests?.histogram?.historyForChart){

            let {date, users, payments} = props.abtests?.histogram?.historyForChart;

            if (chartRef.current) {

                let myChart = echarts.init(chartRef.current);
                let option;

                option = {
                    tooltip: {
                        trigger: 'axis',
                        position: function (pt) {
                            return [pt[0], '10%'];
                        },
                        axisPointer: {
                            type: 'cross'
                        }
                    },
                    title: {
                        left: 'center',
                        text: 'User Count',
                    },
                    xAxis: {
                        type: 'category',
                        data: date || []
                    },
                    yAxis: {
                        type: 'value',
                        axisLabel: {
                            formatter: '{value}'
                        },
                        axisPointer: {
                            snap: true
                        }
                    },
                    dataZoom: [{
                        type: 'inside',
                        start: 0,
                        end: 10
                    }, {
                        start: 0,
                        end: 10
                    }],
                    series: [
                        {
                            name: 'Users',
                            type: 'line',
                            smooth: true,
                            itemStyle: {
                                color: 'rgb(255, 70, 131)'
                            },
                            data: users || [],
                            //  раскрасить области
                            // markArea: {
                            //     itemStyle: {
                            //         color: 'rgba(255, 173, 177, 0.4)'
                            //     },
                            //     data: [ [{
                            //         name: '早高峰',
                            //         xAxis: '07:30'
                            //     }, {
                            //         xAxis: '10:00'
                            //     }], [{
                            //         name: '晚高峰',
                            //         xAxis: '17:30'
                            //     }, {
                            //         xAxis: '21:15'
                            //     }] ]
                            // }
                        },
                        {
                            name: 'Payments',
                            type: 'bar',
                            // itemStyle: {
                            //     color: 'rgb(75,255,65)'
                            // },
                            data: payments || []
                        }
                    ]
                };

                option && myChart.setOption(option);
            }

        }
    }, [props, chartRef]);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (event, reason) => {

        if (reason === 'toggleInput') {
            return;
        }
        if (anchorEl) {
            anchorEl.focus();
        }
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'github-label' : undefined;



    // find index where total is max
    const indexOfMaxValueARPU = props.abtests.abTest?.variants.reduce((iMax, x, i, arr) => x.total > arr[iMax]['total'] ? i : iMax, 0);
    const indexOfMaxValueTotal = props.abtests.abTest?.variants.reduce((iMax, x, i, arr) => x.sumPriceToPaid > arr[iMax]['sumPriceToPaid'] ? i : iMax, 0);

    const handleOpenAB = () => {
        setOpenAB(true)
    };

    const handleCloseAB = () => {
        setOpenAB(false)
    };

    const timer = React.useRef(null);

    const handleChange = (event) => {

        if (timer.current) clearTimeout(timer.current);

        setAttrDays(event.target.value);

        timer.current = setTimeout(() => {

            history.push(window.location.pathname + buildQueryString({abTestId, attrDays: event.target.value, countries }));

            updateTable({attrDays: event.target.value, isUseAttrDays:true, countries });
        }, 1200);
    };

    const updateTable = (data) => {
        const { isUseAttrDays, ...other} = {...data, ...{abTestId}};

        if (other['attrDays'] === '' || isUseAttrDays === false)
            delete other['attrDays'];

        if (other['countries'] === '' || other['countries']?.length === 0)
            delete other['countries'];

        // делаем новый запрос
        const index = props.abtests.abTests.findIndex(item => item.id === parseInt(abTestId));

        if (index !== -1) props.getABTest && props.getABTest(other)
    };

    const handleChangeCountries = (_, value) => {

        if (timer.current) clearTimeout(timer.current);

        setCountries(value);

        timer.current = setTimeout(() => {

            history.push(window.location.pathname + buildQueryString({abTestId, attrDays, countries: value  }));

            updateTable({attrDays, isUseAttrDays:true, countries: value });
        }, 1200);
    };

    const renderOption = (isoCode) => {

        const item = props?.abtests?.countries?.find(({value}) => value === isoCode);
        if (props?.abtests?.countriesIsoCodes && item) {

            return <React.Fragment>

                <Checkbox checked={countries?.indexOf(isoCode) > -1} />

                <CountryFlag countryCode={isoCode} style={{fontSize: '2em', paddingRight: 8}}/>


                <ListItemText primary={item.name}
                              secondary={
                                  <React.Fragment>
                                      <Typography component={'span'}>Users: {item.users}</Typography><br/>
                                      <Typography component={'span'}>Payments: {item.payments}</Typography><br/>
                                      <Typography component={'span'}>Revenue: {item.revenue}</Typography>
                                  </React.Fragment>
                              }/>
            </React.Fragment>
        }

        return null;
    };

    const renderTags = (value, getTagProps) =>
        value.map((option, index) => (
            <Chip
                avatar={
                    <CountryFlag countryCode={option} style={{fontSize: '2em', paddingRight: 8}}/>
                  }
                  size="small"
                  variant="outlined"
                  label={`${option} `}
                  {...getTagProps({ index })} />
        ));


    const descriptionElementRef = React.useRef(null);

    const selectTest = (event, newValue) => {
        if (newValue?.id) {

            setCountries([]);
            setAttrDays('');

            history.push(location.pathname + buildQueryString({abTestId:newValue?.id, countries:null, attrDays:null}))
            props.getABTest({abTestId: newValue.id, countries:null, attrDays:null});
        }
    };

    React.useEffect(() => {
        if (openAB) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [openAB]);


    const getDate = (timestamp) => {
        if (!timestamp) return 'Not set';

        return moment.unix(timestamp).locale('ru').format(format)
    };


    return (
        <React.Fragment>

            <Dialog onClose={handleCloseAB}
                    aria-labelledby="scroll-dialog-title"
                    aria-describedby="scroll-dialog-description"
                    open={openAB}
                    scroll={'paper'}
            >
                <form>
                    <DialogTitle id="customized-dialog-title" onClose={handleCloseAB}>
                        Modal title
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="scroll-dialog-description"
                                           ref={descriptionElementRef}>
                            <Box
                                display={{xs: 'block', sm: 'block', md: 'flex', lg: 'flex'}}
                                alignItems="flex-start"
                                flexDirection="row"
                            >
                                <Box p={1} mx={{xs: 0, sm: 'auto', md: 'auto', lg: 'auto'}}>
                                    <InputLabel htmlFor='budget' component={'span'}>
                                        Test name
                                    </InputLabel>
                                    <OutlinedInput
                                        fullWidth
                                        margin="dense"
                                        id='budget'
                                        type='budget'
                                        name='budget'
                                        required={true}
                                        autoComplete="budget"
                                        autoFocus
                                    />

                                    <InputLabel htmlFor='budget' component={'span'}>
                                        Start date
                                    </InputLabel>
                                    <OutlinedInput
                                        fullWidth
                                        margin="dense"
                                        id='budget'
                                        type='budget'
                                        name='budget'
                                        required={true}
                                        autoComplete="budget"
                                        autoFocus
                                    />

                                    <InputLabel htmlFor='budget' component={'span'}>
                                        Version
                                    </InputLabel>
                                    <OutlinedInput
                                        fullWidth
                                        margin="dense"
                                        id='budget'
                                        type='budget'
                                        name='budget'
                                        required={true}
                                        autoComplete="budget"
                                        autoFocus
                                    />

                                    <InputLabel htmlFor='budget' component={'span'}>
                                        Upload file
                                    </InputLabel>

                                    <input
                                        style={{ display: 'none' }}
                                        accept="image/*"
                                        className={classes.input}
                                        id="contained-button-file"
                                        multiple
                                        type="file"
                                    />
                                    <label htmlFor="contained-button-file">
                                        <Button className={'MuiInputBase-root MuiOutlinedInput-root MuiInputBase-fullWidth MuiInputBase-marginDense MuiOutlinedInput-marginDense'} variant="outlined" color="primary" component="span">
                                            Upload
                                        </Button>
                                    </label>
                                </Box>

                                <Box p={1} mx={{xs: 0, sm: 'auto', md: 'auto', lg: 'auto'}} style={{height: 330}}>
                                    <InputLabel htmlFor='budget' component={'span'}>
                                        Description
                                    </InputLabel>
                                    <TextField
                                        style={{height: '100%', width: '100%'}}
                                        id="outlined-multiline-static"
                                        multiline
                                        variant="outlined"
                                        InputProps={{
                                            style:{height: '100%', width: '100%'}
                                        }}

                                    />
                                </Box>

                            </Box>

                        </DialogContentText>

                    </DialogContent>

                    <DialogActions>
                        <Button autoFocus onClick={handleCloseAB} color="primary" variant={'contained'}>
                            Save changes
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>

            <Box style={{ width: '100%' }} display={'flex'} >
                <Box flexGrow={1}>

                    <Popper
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        placement="bottom-start"
                        className={classes.root}
                        style={{width:360}}
                    >
                        <Autocomplete
                            style={{width:360}}
                            open
                            onClose={handleClose}
                            classes={{
                                paper: classes.paper,
                                option: classes.option,
                                popperDisablePortal: classes.popperDisablePortal,
                            }}
                            onChange={selectTest}
                            renderTags={() => null}
                            noOptionsText="Tests not found"
                            renderOption={(option, { selected }) => (
                                <React.Fragment>
                                    <ListItem component={Box} style={{width:360, borderRight:`4px solid ${option.is_active ? '#04A842': '#e50a1d'}`}} selected={option?.id === pendingValue?.id} >
                                        <ListItemText primary={option.test_name} secondary={`Users: ${option?.users}`} />
                                    </ListItem>
                                </React.Fragment>
                            )}
                            options={ props.abtests.abTests || []}
                            getOptionLabel={(option) => (typeof option === 'string' || option === undefined) ? option||'' : `${option.test_name}`}
                            renderInput={(params) => (
                                <OutlinedInput
                                    style={{width:360}}
                                    ref={params.InputProps.ref}
                                    inputProps={params.inputProps}
                                    autoFocus
                                    name='search'
                                    id='search'
                                />
                            )}
                        />
                    </Popper>
                </Box>

                <Box m={1} textAlign={'end'}>
                    <Typography component={'a'}
                                color="primary"
                                onClick={handleOpenAB}
                                style={{
                                    borderBottom: `2.2px dotted ${theme.palette.primary.main}`,
                                }}>
                        Create A/B
                    </Typography>
                </Box>

            </Box>

            <Box pt={1} display={{xs: 'block', sm: 'block', md: 'flex', lg: 'flex'}} flexDirection="row">

                <Paper width={'100%'}
                       component={Box}
                       p={0}
                       m={0}
                       style={{maxWidth: 366,}}
                       rounded={'true'}
                       elevation={1} >

                    <Box p={1} className={classes.header} display={'flex'}>
                        <Tooltip title={pendingValue?.test_name || ''} placement="top-start" arrow>
                            <Typography component={Box} p={1} variant={'subtitle1'} width={'100%'}>
                                {pendingValue?.test_name}
                            </Typography>
                        </Tooltip>

                        <Box p={1} flexShrink={1} className={clsx(pendingValue && 'testIndicator', pendingValue?.is_active ? 'active':'inactive')} >
                            { pendingValue?.is_active ? 'Active':'Inactive'}
                        </Box>
                    </Box>

                    <Box px={2} onClick={handleClick} className={classes.chooseTest}>
                        <Box display="flex" pt={1}>
                            <Typography component={Box} variant={'subtitle1'} width={80}>Start</Typography>
                            <Box flexShrink={1}>
                                {getDate(props.abtests?.histogram?.dates?.user_first)}
                                &nbsp;-&nbsp;
                                {props.abtests?.abTest?.is_active ? 'running' : getDate(props.abtests?.histogram?.dates?.user_last)}
                            </Box>
                        </Box>

                        <Box display="flex" py={1}>
                            <Typography component={Box} variant={'subtitle1'} width={80}>Users</Typography>
                            <Box flexShrink={1}>{pendingValue?.users}</Box>
                        </Box>

                    </Box>

                </Paper>

                <Paper width={'100%'}
                       component={Box}
                       p={0}
                       mx={{xs: 0, sm: 0, md: 2, lg: 2}}
                       my={{xs: 2, sm: 2, md: 0, lg: 0}}
                       rounded={'true'}
                       elevation={1}
                       style={{minHeight:151}}>

                    <Box p={2} className={classes.header}>
                        <Typography component={'div'} variant={'subtitle1'}>
                            Description
                        </Typography>
                    </Box>

                    <Box px={2} py={1}>
                        {props.abtests?.abTest !== null &&
                        <InlineEdit text={props.abtests.abTest?.description}
                                    multiline={true}
                                    type={'textarea'}
                                    onSetText={value => {
                                        if (value !== props.abtests.abTest?.description) {
                                            props.postABTest({
                                                abTestId: props.abtests.abTest?.id,
                                                field: 'description',
                                                value: value || ''
                                            });
                                        }
                                    }}
                        />
                        }
                    </Box>
                </Paper>

                <Paper width={'100%'}
                       component={Box}
                       p={0}
                       m={0}
                       rounded={'true'}
                       elevation={1}
                       style={{minHeight:151}}>

                    <Box p={2} className={classes.header}>
                        <Typography component={'div'} variant={'subtitle1'}>
                            Segment Description
                        </Typography>
                    </Box>

                    <Box px={2} py={1}>
                        <table>
                            <tbody>
                            {props.abtests.abTest?.variants.map(({description, letter}) =>
                                <tr key={letter} style={{verticalAlign: 'top'}}>
                                    <td>{letter.toUpperCase()}</td>
                                    <td style={{display: 'inline-block', paddingLeft: 8, width:'100%'}}>
                                        <InlineEdit text={description}
                                                    multiline={true}
                                                    type={'text'}
                                                    onSetText={value => {
                                                        if (value !== pendingValue?.description) {
                                                            props.postABTest({
                                                                abTestId: pendingValue?.id,
                                                                field: `variant_${letter.toLowerCase()}_description`,
                                                                value
                                                            });
                                                        }
                                                    }}
                                        />
                                    </td>
                                </tr>
                            )}
                            </tbody>
                        </table>
                    </Box>

                </Paper>

            </Box>

            {props.abtests.pending && <CircularProgress className={classes.buttonProgress}/>}


            <Grid container spacing={3}>
                <Grid item xs>
                    <InputLabel htmlFor='attr'>
                        Days attributes
                    </InputLabel>

                    <OutlinedInput id={'attr'} inputProps={{min:0}} disabled={props.abtests.pending} value={attrDays} type={'number'} onChange={handleChange} />
                </Grid>
                <Grid item xs>
                    <InputLabel htmlFor='country'>
                        Country
                    </InputLabel>

                    {/*<Select*/}
                    {/*    labelId="country"*/}
                    {/*    id="country"*/}
                    {/*    variant={"outlined"}*/}
                    {/*    fullWidth*/}
                    {/*    multiple*/}
                    {/*    name={'countries'}*/}
                    {/*    value={countries}*/}
                    {/*    onChange={handleChangeCountries}*/}
                    {/*    renderValue={(selected) => selected.join(', ')}*/}
                    {/*>*/}
                    {/*    {props?.abtests?.countries?.map(({value, name, users, payments, revenue}) => (*/}
                    {/*        <MenuItem key={value} value={value}>*/}
                    {/*            <Checkbox checked={countries.indexOf(value) > -1} />*/}
                    {/*            <ListItemText primary={name} secondary={users} />*/}
                    {/*        </MenuItem>*/}
                    {/*    ))}*/}
                    {/*</Select>*/}

                    <Autocomplete
                        id="size-small-standard"
                        multiple
                        disableCloseOnSelect
                        limitTags={3}
                        onChange={handleChangeCountries}
                        value={countries}
                        options={props?.abtests?.countriesIsoCodes || []}
                        getOptionLabel={(option) => option}
                        renderTags={renderTags}
                        renderOption={renderOption}

                        renderInput={(params) => (
                            <TextField {...params}
                                       variant="outlined"
                            />
                        )}
                    />
                </Grid>
            </Grid>
            
            <TableContainer component={Box} mt={3} style={{borderRadius: 7, boxShadow: '0px 5px 7px rgba(0, 0, 0, 0.03)'}}>
                <Table>
                    <TableHeader />

                    <TableBody>

                        {(props.abtests.filterAbTests || props.abtests.abTest)?.variants?.map((item, i) => <TableBodyRow key={`${item.letter}_${item.users}`} i={i} {...item} indexOfMaxValueTotal={indexOfMaxValueTotal} indexOfMaxValueARPU={indexOfMaxValueARPU}/> )}

                    </TableBody>
                </Table>
            </TableContainer>


            <Box ref={chartRef} id={'main'} width={_width - 100} height={400} display={'block'}/>

        </React.Fragment>
    )
}


function TableHeader(){

    const classes = useStyles();

    return (
        <Box className={classes.tableHeader} component={TableHead}>
            <TableRow>
                <Tooltip title={TOOLTIP_TEXTS.segment} placement="top-end" arrow>
                    <TableCell>Segment</TableCell>
                </Tooltip>

                <Tooltip title={TOOLTIP_TEXTS.users} placement="top-end" arrow>
                    <TableCell align={'right'}>Users</TableCell>
                </Tooltip>

                <Tooltip title={TOOLTIP_TEXTS.productId} placement="top-end" arrow>
                    <TableCell align={'right'}>Product ID</TableCell>
                </Tooltip>

                <Tooltip title={TOOLTIP_TEXTS.price} placement="top-end" arrow>
                    <TableCell align={'right'}>Price</TableCell>
                </Tooltip>

                <Tooltip title={TOOLTIP_TEXTS.trial} placement="top-end" arrow>
                    <TableCell align={'right'}>Trial</TableCell>
                </Tooltip>

                <Tooltip title={TOOLTIP_TEXTS.paid} placement="top-end" arrow>
                    <TableCell align={'right'}>Paid</TableCell>
                </Tooltip>

                <Tooltip title={TOOLTIP_TEXTS.c1} placement="top-end" arrow>
                    <TableCell className={classes.headerPercentGroup} align={'right'}>C1</TableCell>
                </Tooltip>

                <Tooltip title={TOOLTIP_TEXTS.c2} placement="top-end" arrow>
                    <TableCell className={classes.headerPercentGroup} align={'right'}>C2</TableCell>
                </Tooltip>

                <Tooltip title={TOOLTIP_TEXTS.paidCVR} placement="top-end" arrow>
                    <TableCell className={classes.headerPercentGroup} align={'right'}>Paid CVR</TableCell>
                </Tooltip>

                <TableCell className={classes.priceOnPaid} align={'right'}>Price On Paid</TableCell>

                <Tooltip title={TOOLTIP_TEXTS.lifetime} placement="top-end" arrow>
                    <TableCell className={classes.headerLifetime} align={'right'}>Lifetime</TableCell>
                </Tooltip>

                <Tooltip title={TOOLTIP_TEXTS.priceOnPaidLifetime} placement="top-end" arrow>
                    <TableCell className={classes.priceOnPaidLifetime} align={'right'}>Price On Paid Lifetime</TableCell>
                </Tooltip>

                <Tooltip title={TOOLTIP_TEXTS.userLTV} placement="top-end" arrow>
                    <TableCell align={'right'}>User LTV</TableCell>
                </Tooltip>

                <Tooltip title={TOOLTIP_TEXTS.LTV} placement="top-end" arrow>
                    <TableCell align={'center'}>ARPU<br/>ARPPU</TableCell>
                </Tooltip>

                <Tooltip title={TOOLTIP_TEXTS.total} placement="top-end" arrow>
                    <TableCell align={'center'}>Total</TableCell>
                </Tooltip>
            </TableRow>
        </Box>
    )
}

function TableBodyRow({letter, users, summary, total, sumPriceToPaid, B2F_letters, i, indexOfMaxValueTotal, indexOfMaxValueARPU}) {

    const classes = useStyles();

    const [openDebugRows, setOpenDebugRows] = React.useState(false);

    const lastRowIndex = summary?.length - 1 ?? 0;

    return summary?.map(({product_id, price, trials, purchases, lifetime, c1, c2, paidCVR, userLTV, uid, priceOnPaid, priceOnPaidLifetime}, j) => {

            const oddEvenClassName = (i % 2 ? classes.even : classes.odd);

            return (
                <React.Fragment key={`${i}-${j}-${uid}`}>
                    <TableRow  className={clsx(oddEvenClassName, classes.padding, (lastRowIndex !== 0 && lastRowIndex === j) ? classes.totalRow : '')}>
                        <React.Fragment>
                            {/*{lastRowIndex !== 0 && lastRowIndex === j ? <TableCell colSpan={2} ><UnfoldMoreIcon style={{position: 'relative', top: 21}} onClick={() => setOpenDebugRows(!openDebugRows)} /></TableCell> : null}*/}
                            {
                                j === 0 &&
                                <React.Fragment>
                                    {/*Segment*/}
                                    <TableCell rowSpan={summary.length} align={'center'}>
                                        {letter?.toUpperCase()}
                                    </TableCell>

                                    {/*Users*/}
                                    <TableCell rowSpan={summary.length}>{users?.toLocaleString('en-US')}</TableCell>
                                </React.Fragment>
                            }

                            {/*Product ID*/}
                            <TableCell>{product_id}</TableCell>

                            {/*Price*/}
                            <TableCell >$&nbsp;{price?.toLocaleString('en-US')}</TableCell>

                            {/*Trial*/}
                            <TableCell >{trials?.toLocaleString('en-US')}</TableCell>

                            {/*Paid*/}
                            <TableCell >{purchases?.toLocaleString('en-US')}</TableCell>

                            {/*C1*/}
                            <TableCell className={clsx(classes.ceilPercentGroup, (i % 2 ? 'even' : ''))}>{c1?.toLocaleString('en-US')}&nbsp;%</TableCell>

                            {/*C2*/}
                            <TableCell className={clsx(classes.ceilPercentGroup, (i % 2 ? 'even' : ''))}>{c2?.toLocaleString('en-US')}&nbsp;%</TableCell>

                            {/*Paid CVR*/}
                            <TableCell className={clsx(classes.ceilPercentGroup, (i % 2 ? 'even' : ''))}>{paidCVR?.toLocaleString('en-US')}&nbsp;%</TableCell>

                            {/*Price On Paid*/}
                            <TableCell>$&nbsp;{priceOnPaid?.toLocaleString('en-US')}</TableCell>

                            {/*Lifetime*/}
                            <TableCell className={clsx(classes.ceilLifetime, (i % 2 ? 'even' : ''))} align={'center'}>{lifetime?.toLocaleString('en-US')}</TableCell>

                            {/*Price On Paid Lifetime*/}
                            <TableCell >$&nbsp;{priceOnPaidLifetime?.toLocaleString('en-US')}</TableCell>

                            {/*UserLTV*/}
                            <TableCell>$&nbsp;{addNZeroAtEnd(userLTV, 3)}</TableCell>
                            {
                                0 === j &&
                                <React.Fragment>
                                    <TableCell  align={'center'}
                                                className={clsx(i === indexOfMaxValueARPU ?
                                                    classes.ltvPositive:
                                                    classes.ltvNegative,
                                                    (i % 2 ? 'even' : '')
                                                )}
                                                rowSpan={summary.length}>

                                        $&nbsp;{addNZeroAtEnd(round(saveDivide(sumPriceToPaid, users),3)?.toLocaleString('en-US'), 3)}
                                        {/*$&nbsp;{addNZeroAtEnd(total.toLocaleString('en-US'), 2)} */}
                                        <br/>
                                        $&nbsp;{addNZeroAtEnd(round(saveDivide(sumPriceToPaid, summary[lastRowIndex]['purchases']), 2)?.toLocaleString('en-US'), 2)}
                                    </TableCell>
                                    <TableCell rowSpan={summary.length}
                                               className={clsx(i === indexOfMaxValueTotal ?
                                                   classes.ltvPositive:
                                                   classes.ltvNegative,
                                                   (i % 2 ? 'even' : '')
                                               )}>

                                        $&nbsp;{addNZeroAtEnd(sumPriceToPaid?.toLocaleString('en-US'), 2)}
                                    </TableCell>

                                </React.Fragment>
                            }
                        </React.Fragment>
                    </TableRow>

                    {lastRowIndex !== 0 && lastRowIndex === j ? (
                        <TableRow>
                            <TableCell colSpan={15} padding={'none'} className={classes.debugCell}>
                                <Collapse in={openDebugRows} timeout="auto" unmountOnExit style={{padding:0}}>
                                    <Table style={{border:'1px solid #c0c0c0'}}>
                                        <TableHeader />

                                        <TableBody >
                                            {B2F_letters?.map(({summary, back, front, total, sumPriceToPaid, users, is_active}) => {
                                                const lastRowIndex = summary?.length - 1 ?? 0;
                                                // const indexOfMaxValue = props.abtests.abTest?.variants.reduce((iMax, x, i, arr) => x.total > arr[iMax]['total'] ? i : iMax, 0);
                                                return summary?.map(({product_id, price, trials, purchases, lifetime, c1, c2, paidCVR, userLTV, uid}, k) => {

                                                        const oddEvenClassName = (i % 2 ? classes.even : classes.odd);

                                                        return (
                                                            <TableRow key={`b2f-${k}`}
                                                                      className={clsx(oddEvenClassName, classes.padding, (lastRowIndex !== 0 && lastRowIndex === k) ? classes.totalRow : '')}>
                                                                {
                                                                    k === 0 &&
                                                                    <React.Fragment>
                                                                        <TableCell rowSpan={summary.length}
                                                                                   align={'center'}>
                                                                            {front?.toUpperCase()} ({back}) <span className={clsx("dot", is_active ? 'active' : 'inactive'  )} />
                                                                        </TableCell>
                                                                        <TableCell
                                                                            rowSpan={summary.length}>{users?.toLocaleString('en-US')}</TableCell>
                                                                    </React.Fragment>
                                                                }
                                                                <TableCell>{product_id}</TableCell>
                                                                <TableCell>$&nbsp;{price.toLocaleString('en-US')}</TableCell>
                                                                <TableCell>{trials.toLocaleString('en-US')}</TableCell>
                                                                <TableCell>{purchases.toLocaleString('en-US')}</TableCell>
                                                                <TableCell
                                                                    className={clsx(classes.ceilPercentGroup, (i % 2 ? 'even' : ''))}>{c1?.toLocaleString('en-US')}&nbsp;%</TableCell>
                                                                <TableCell
                                                                    className={clsx(classes.ceilPercentGroup, (i % 2 ? 'even' : ''))}>{c2?.toLocaleString('en-US')}&nbsp;%</TableCell>
                                                                <TableCell
                                                                    className={clsx(classes.ceilPercentGroup, (i % 2 ? 'even' : ''))}>{paidCVR?.toLocaleString('en-US')}&nbsp;%</TableCell>
                                                                <TableCell>&nbsp;</TableCell>
                                                                <TableCell
                                                                    className={clsx(classes.ceilLifetime, (i % 2 ? 'even' : ''))}
                                                                    align={'center'}>{lifetime?.toLocaleString('en-US')}</TableCell>
                                                                <TableCell>&nbsp;</TableCell>
                                                                <TableCell>$&nbsp;{userLTV}</TableCell>
                                                                {
                                                                    0 === k &&
                                                                    <React.Fragment>
                                                                        <TableCell align={'center'}
                                                                                   className={clsx(i === indexOfMaxValueARPU ?
                                                                                       classes.ltvPositive :
                                                                                       classes.ltvNegative,
                                                                                       (i % 2 ? 'even' : '')
                                                                                   )}
                                                                                   rowSpan={summary.length}>
                                                                            $&nbsp;{total?.toLocaleString('en-US')}
                                                                        </TableCell>
                                                                        <TableCell rowSpan={summary.length}>
                                                                            $&nbsp;{sumPriceToPaid?.toLocaleString('en-US')}
                                                                        </TableCell>
                                                                    </React.Fragment>
                                                                }
                                                            </TableRow>
                                                        )
                                                    })
                                            } )}
                                        </TableBody>
                                    </Table>
                                </Collapse>

                                {openDebugRows ? <UnfoldLessIcon onClick={() => setOpenDebugRows(!openDebugRows)} />
                                :
                                <UnfoldMoreIcon className={classes.foldIcon} onClick={() => setOpenDebugRows(!openDebugRows)} />}

                            </TableCell>
                        </TableRow>
                    ) : null }

                </React.Fragment>
            )
        }
    )
}

const mapState = state => ({
    abTestsReport: state.abTestsReport,
    projectTests:  state.projectTests,
    project:       state.project,
    abtests:       state.abtests,
});

const mapDispatch = ({
                         abTestsReport:{
                             getReportForSegments,
                             setSegments
                         },
                         projectTests:{
                             getAllProjectTests
                         },
                         abtests:{
                             getABTests,
                             getABTest,
                             postABTest,
                         },
                     }) => ({
    getReportForSegments: (data) => getReportForSegments(data),
    setSegments,
    getAllProjectTests,
    getABTests,
    getABTest,
    postABTest,
});

export default connect(mapState, mapDispatch)(Report)