import {Redirect, Route} from "react-router-dom";
import profile from "../models/profile";
import React from "react";


function PrivateRoute({children, match, history, location, path, computedMatch, ...otherProps}) {

    return (
        <Route {...{ match, history, location, path, computedMatch }}
               render={prop => {
                   return profile.state.isAuthUser() ?
                       <children.type {...otherProps} />
                       :
                       <Redirect to={{
                           pathname: "/start/login/",
                           state: {from: prop.location}
                       }}
                       />
               }}
        />
    );
}

export default PrivateRoute;