import {uniq, get, reduce, compact, isEmpty} from "lodash";
import {CellFormatRender} from "../../../views/home2/utils";


export const getAllEvents = ({weeklyData, key = null}) => {
    if (isEmpty(key)) return [];

    return uniq(reduce(weeklyData, (acc, weekData) => ([
        ...acc,
        ...Object.keys(
            get(weekData, key, ['1'])
        )
    ]), []));
};
/**
 *
 * @param {Array<Object>} weeklyData
 */
export const getRetentionEvents = ({weeklyData}) =>
    getAllEvents({weeklyData, key: 'events.retention.list'});


export const getRetentionEventRows = ({events = [], listKey = 'list'}) => {
    return reduce(events, (acc, name) => ([
        ...acc,
        {
            name,
            key: `events.retention.${listKey}.${name}.count`,
            renderValue: CellFormatRender.percent,
        }
    ]), [])
};

export const getRetentionChildrenRows = ({weeksObject, rows, columnKeys}) => {
    return reduce(rows, (acc, {name, key, renderValue}) => ([
        ...acc,
        {
            rowName: name,
            rowKey: key,
            ...reduce(columnKeys, (acc, columnKey) => ({
                ...acc,
                [columnKey]: {
                    value: renderValue(get(weeksObject, compact([columnKey, key]).join('.'), null))
                }
            }), {})
        }
    ]), [])
};


export const getCreatedEvents = ({weeklyData}) =>
    getAllEvents({weeklyData, key: 'events.created.list'});

export const getCreatedEventRows = ({events = [], listKey = 'list'}) => {
    return reduce(events, (acc, name) => ([
        ...acc,
        {
            name,
            key: [`events.created.${listKey}.${name}.count`, `events.created.${listKey}.${name}.users`],
            renderValue: CellFormatRender.count,
        }
    ]), [])
};

export const getCreatedChildrenRows = ({weeksObject, rows, columnKeys}) => {
    return reduce(rows, (acc, {name, key, title, renderValue}) => ([
        ...acc,
        {
            rowName: name,
            rowKey: key,
            ...reduce(columnKeys, (acc, columnKey) => {

                const eventsCount = get(weeksObject, compact([columnKey, key[0]]).join('.'), 0);
                const usersCount = get(weeksObject, compact([columnKey, key[1]]).join('.'), 0);

                return {
                    ...acc,
                    [columnKey]: {
                        value: [
                            CellFormatRender.count(eventsCount),
                            // CellFormatRender.count(usersCount),
                            parseInt(usersCount) !== 0 ? Math.round(eventsCount / usersCount * 100) / 100 : 0

                        ].join(' / ')
                    }
                }
            }, {})
        }
    ]), [])
};


export const getViewedEvents = ({weeklyData}) =>
    getAllEvents({weeklyData, key: 'events.viewed.list'});

export const getViewedEventRows = ({events = [], listKey = 'list'}) => {
    return reduce(events, (acc, name) => ([
        ...acc,
        {
            name,
            key: [`events.viewed.${listKey}.${name}.count`, `events.viewed.${listKey}.${name}.users`],
            renderValue: CellFormatRender.count,
        }
    ]), [])
};

export const getViewedChildrenRows = ({weeksObject, rows, columnKeys}) => {
    return reduce(rows, (acc, {name, key}) => ([
        ...acc,
        {
            rowName: name,
            rowKey: key,
            ...reduce(columnKeys, (acc, columnKey) => {

                const eventsCount = get(weeksObject, compact([columnKey, key[0]]).join('.'), 0);
                const usersCount = get(weeksObject, compact([columnKey, key[1]]).join('.'), 0);
                return {
                    ...acc,
                    [columnKey]: {
                        value:
                            [
                                CellFormatRender.count(eventsCount),
                                // CellFormatRender.count(usersCount),
                                parseInt(usersCount) !== 0 ? Math.round(eventsCount / usersCount * 100) / 100 : 0
                            ].join(' / ')
                    }
                }
            }, {})
        }
    ]), [])
};

export const getAccViewedEvents = ({weeklyData}) => getAllEvents({weeklyData, key: 'events.viewed.list+'});
export const getAccCreatedEvents = ({weeklyData}) => getAllEvents({weeklyData, key: 'events.created.list+'});