import React from "react";
import useOnClickOutside from "../hooks/useOnClickOutside";
import Popover from "@material-ui/core/Popover";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Tooltip from "@material-ui/core/Tooltip";

const CustomPopper = ({ children, tooltipText }) =>  {
    const [anchorEl, setAnchorEl] = React.useState(null);

    useOnClickOutside(anchorEl, () => {
        setAnchorEl(null);
    });

    const handleClick = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };
    const handlePopoverClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    const id = open ? 'warnings-popper' : undefined;

    return (
        <>
            <Tooltip title={tooltipText} aria-label="tooltipText">
                <MoreVertIcon fontSize="small" onClick={handleClick}/>
            </Tooltip>

            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
            >
                {children}
            </Popover>
        </>
    );
};

export default CustomPopper;