import React, { memo } from 'react';
import { ResponsiveBar } from '@nivo/bar'
// import {ResponsiveBarCanvas} from '@nivo/bar'
// import {useTheme} from '@nivo/core'
import Card from '@mui/material/Card';
import { sum, round, values, reduce } from 'lodash';
import { shallowEqual, useSelector } from 'react-redux';
import Stack from '@mui/material/Stack';
// import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';

const getProductsRealName = state => state.recurringRevenue.productsRealName;

const BarTooltip = memo(({ id, color, data }) => {
  const productsRealName = useSelector(getProductsRealName, shallowEqual);

  const { period, ...periodProducts } = data;
  const totalSum = round(sum(values(periodProducts)), 2);

  return (
    <Card
      sx={{
        padding: 1,
        fontSize: 10,
        color: '#fff',
        background: '#222222',
        zIndex: 20,
      }}
    >
      <table>
        <thead>
        <tr>
          <td colSpan={2}>{period}</td>
        </tr>
        </thead>
        <tbody>
        {reduce(periodProducts, (acc, value, key) => ([
          ...acc,
          <tr key={key} style={{ ...(id === key && { color }) }}>
            <td>{productsRealName[key]}</td>
            <td>$ {value}</td>
          </tr>
        ]), [])}

        </tbody>
        <tfoot>
        <tr>
          <td>Total</td>
          <td>$ {totalSum}</td>
        </tr>
        </tfoot>
      </table>

    </Card>
  )
});

const BarChart = ({ data = [], products = [], title = null }) => {
  const barData = data?.slice() || [];
  const barKeys = products?.slice() || [];

  // const [checked, setChecked] = useState(false);
  // const handleChange = () => setChecked(!checked);

  return (
    <Card sx={{ mt: 2, height: 650, width: '100%' }}>
      <Stack direction="row" spacing={1}>
        {/*<Switch*/}
        {/*  inputProps={{ 'aria-label': 'Group Mode' }}*/}
        {/*  checked={checked}*/}
        {/*  onChange={handleChange}*/}
        {/*/>*/}
        {title && <Typography p={1}>{title}</Typography>}
      </Stack>
      <ResponsiveBar
        animate={false}
        data={barData}
        keys={barKeys}
        groupMode='stacked'
        height={650}
        margin={{ top: 80, right: 110, bottom: 150, left: 80 }}
        pixelRatio={2}
        indexBy="period"
        valueScale={{ type: 'linear' }}
        indexScale={{ type: 'band', round: true }}
        padding={0.3}
        colors={{ scheme: 'paired' }}
        borderWidth={0}
        borderRadius={0}
        labelSkipWidth={16}
        labelSkipHeight={16}
        tooltip={BarTooltip}

      />
    </Card>
  );
};

export default BarChart