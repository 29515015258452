import {withStyles} from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";

export const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: 'transparent', // theme.palette.secondary.light,
        color: theme.palette.black,
    },
    body: {
        fontSize: 14,
        width:100,
        position: 'relative',
    },
}))(TableCell);
