import ApiService from "../services/api";
import {checkResponseError} from './helpers'
import camelcaseKeys from 'camelcase-keys';
import moment from "moment";


export default {
    state: {
        pending: false,
        error: null,
        errorCode: null,

        data: null,
        controllers: {},

        subtractMonths: 1,
        countMonthLoaded: 0,
        loadedMonthsData: {},
        loadingMonth: {}
    },
    reducers: {
        authStart(state, data) {
            return {
                ...state,
                ...data,
                pending: true
            }
        },
        updateError(state, data) {
            return {
                ...state,
                ...data,
                pending: false
            }
        },
        updateReports(state, data) {
            return {
                ...state,
                ...data,
                pending: false
            }
        },
        updateLoadedMonthsData(state, { loadedMonthsData, period}) {
            return {
                controllers: {
                    ...state.controllers,
                    [period]: null,
                },
                countMonthLoaded: state.countMonthLoaded + (state.loadedMonthsData?.[period] ? 0: 1),
                loadedMonthsData: {
                    ...state.loadedMonthsData,
                    [period]: loadedMonthsData
                }
            }
        }
    },

    effects: (dispatch) => ({

        async getData(data, rootState) {
            try {
                const {registeredAfter, registeredBefore, monthNumber} = data;
                const period = `${registeredAfter} - ${registeredBefore}`;

                if (rootState.monthly.controllers?.[period] !== null) {
                    const contollerForPeriod = rootState.monthly.controllers?.[period];
                    contollerForPeriod?.abort();
                }

                const controller = new AbortController();
                dispatch.monthly.authStart({
                    controllers: {
                        ...rootState.monthly.controllers,
                        [period]: controller
                    }
                });

                const responseData = await ApiService.getMonthlyData({...data, signal: controller.signal});

                checkResponseError(dispatch.reports, responseData);

                dispatch.monthly.updateLoadedMonthsData({
                    period,
                    loadedMonthsData: {
                        period: {
                            monthNumber,
                            text: period,
                            registeredAfter: moment(registeredAfter, 'YYYY-MM-DD'),
                            registeredBefore: moment(registeredBefore, 'YYYY-MM-DD')
                        },
                        ...camelcaseKeys(responseData, {
                            deep: true,
                            stopPaths: [
                                'versions.first',
                                'versions.last'
                            ]
                        })
                    }
                })
            } catch (e) {
                process.env.NODE_ENV === 'development' && console.log('Catch getReports', e);
                dispatch.reports.updateReports({error: e.message})
            }
        },
    })
};
