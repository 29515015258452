import Box from "@material-ui/core/Box";
import styled from 'styled-components';

export const TableName = styled(Box)`
    && {
        padding: 4px;
        font-weight: 600;
        font-size: 18px;
        font-family: monospace;
    }
`;

export const HoveredBox = styled(Box)`
    && {
        :hover {
            background: rgba(0,0,0,0.04);
        }
    }
`;
