import React, {useEffect, useRef, useState} from "react";
import {connect} from "react-redux";
import Checkbox from "@material-ui/core/Checkbox";
import CountryFlag from "../../components/CountryFlag";
import {AutoSizer, CellMeasurer, CellMeasurerCache, List} from "react-virtualized";
import Box from "@material-ui/core/Box";
import {makeStyles} from "@material-ui/core/styles";
import SearchIcon from '@material-ui/icons/Search';
import OutlinedInput from "@material-ui/core/OutlinedInput";
import {Button} from "@material-ui/core";
import ButtonGroup from "@material-ui/core/ButtonGroup";


const useStyles = makeStyles({
    List: {
        // width: '100%',
        // border: '1px solid #DDD',
        // marginTop: 15
        // padding: 10,
    },
});

function Countries(props) {

    const classes = useStyles();
    const [isActive, setIsActive] = useState(true);
    const [search, setSearch] = useState('');

    const cache = useRef(
        new CellMeasurerCache({
            defaultHeight: 30,
            defaultWidth: 275,
        })
    );


    useEffect(() => {
        if (props.project.active !== null && props.project.pending === false &&
            props.settings.pending === false && props.settings.data === null
        ) {

            props.getCountries({projectId: props.project.active.id, order: props.settings.order});
        }
    }, [props, isActive]);


    const handleChangeOrder = (order) => () => {

        if ('active' === order) {
            setIsActive(!isActive);
            return;
        }

        props.getCountries({
            projectId: props.project.active.id,
            order
        })
            .then(() => setIsActive(null));
    };

    const handleSearch = (event) => {
        setSearch(event.target.value);
    };

    const handleChangeCountry = (country) => (event) => {
        const data = {
            projectId: props.project.active.id,
            country
        };
        event.target.checked ?
            props.putCountry(data)
            :
            props.delCountry(data)
    };

    const countries = props.settings.data?.countries?.filter(item =>
        search.length < 2 ||
        item.name.toLowerCase().includes(search.toLowerCase()) ||
        item.code.toLowerCase().includes(search.toLowerCase()))
        .sort((a, b) => {
            if (isActive) return b.is_active - a.is_active;
            if (isActive === false) return a.is_active - b.is_active;
            return 0;
        })
        || [];

    return (<Box style={{maxWidth: 400}}>
        <Box>
            <OutlinedInput startAdornment={<SearchIcon />}
                           onChange={handleSearch}
                           fullWidth
                           type={'text'} />
        </Box>
        <Box display={'flex'}>
            <Box flexGrow={1} pl={1}>
            Order by:
            </Box>
            <ButtonGroup color="primary" component={Box}>
                <Button onClick={handleChangeOrder('alpha')}
                        variant={props.settings.order === 'alpha' ? 'contained' : ''}>
                    Alpha
                </Button>
                <Button onClick={handleChangeOrder('users')}
                        variant={props.settings.order === 'users' ? 'contained' : ''}>
                    Users
                </Button>
                {/*<Button onClick={handleChangeOrder('active')}*/}
                {/*    variant={props.settings.order === 'active' ? 'contained' : ''}>*/}
                {/*    Checked*/}
                {/*</Button>*/}
            </ButtonGroup>
        </Box>
            <AutoSizer disableHeight>
                {({width}) => (
                    <List
                        ref="List"
                        className={classes.List}
                        width={width}
                        height={310}
                        rowCount={countries.length}
                        rowHeight={cache.current.rowHeight}
                        deferredMeasurementCache={cache.current}
                        overscanRowCount={5}
                        noRowsRenderer={() => {
                            if (props.settings.pending === true && props.settings.data === null)
                                return <Box>Loading...</Box>;
                            if (countries.length === 0)
                                return (countries.length === 0) && <Box>Nothing</Box>;
                        }}
                        rowRenderer={({key, index, isScrolling, isVisible, style, parent}) => {

                            const item = countries[index];

                            return <CellMeasurer key={key}
                                                 cache={cache.current}
                                                 parent={parent}
                                                 columnIndex={0}
                                                 rowIndex={index}>
                                <Box style={{...style, ...{padding: 10, minWidth:cache.columnWidth}}} display={'flex'}>
                                    <Box flexGrow={1}>
                                        <CountryFlag countryCode={item?.code} style={{fontSize:'2em'}} />
                                        {item?.name}
                                    </Box>
                                    <Box style={{paddingRight:16}}>
                                        ({item?.users})
                                        <Checkbox checked={item.is_active}
                                                  style={{padding:0}}
                                                  color={'primary'}
                                                  onChange={handleChangeCountry(item.code)}/>
                                    </Box>
                                </Box>
                            </CellMeasurer>;
                        }}
                    />
                )}
            </AutoSizer>
        </Box>
    )
}

const mapState = state => ({
    settings: state.settings,
    project: state.project,
});

const mapDispatch = ({
                         settings: {
                             getCountries,
                             putCountry,
                             delCountry,
                             updateSettings
                         }
                     }) => ({
    getCountries: (...data) => getCountries(...data),
    putCountry: (...data) => putCountry(...data),
    delCountry: (...data) => delCountry(...data),
    updateSettings
});

export default connect(mapState, mapDispatch)(Countries);