import React from 'react';
import { useSelector } from "react-redux";
import { isNil } from "lodash";
import BaseTable from "./BaseTable";
import {preparePaymentsData} from "./utils";

const rows = [
    { field: 'users_payed', format: (value) => isNil(value) ? '' : `${value.toLocaleString('en-US')}` },
    { field: 'subrate', format: (value) => isNil(value) ? '' : `${value.toLocaleString('en-US')}%` },
    { field: 'CAC', format: (value) => isNil(value) ? '' : `$ ${value.toLocaleString('en-US')}` },
    { field: 'ROMI', format: (value) => isNil(value) ? '' : `${value.toLocaleString('en-US')}%` },
    { field: 'profit', format: (value) => isNil(value) ? '' : `$ ${value.toLocaleString('en-US')}` },
    { field: 'LTV', format: (value) => isNil(value) ? '' : `$ ${value.toLocaleString('en-US')}` },
    { field: 'revenue', format: (value) => isNil(value) ? '' : `$ ${value.toLocaleString('en-US')}` }
];

const ActualTableComponent = () => {
    const {
        pending = false,
        payments : pData = [],
    } = useSelector(state => state.veryGood);

    const data = preparePaymentsData(pData);

    return (
        <BaseTable tableName="Actual Payments" data={data} rows={rows} loading={pending} />
    )
};

export default ActualTableComponent;