import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import 'react-base-table/styles.css';
import {Box} from "@mui/material";
import prepareColumns from "./components/prepareColumns";
import moment from './../../core/moment';
import {isEqual, get, orderBy} from "lodash";
import prepareRows from "./components/prepareRows";
import AppVersions from "./components/AppVersions/AppVersions";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import useWindowSize from "../../hooks/useWindowSize";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {Collapse, Typography} from "@mui/material";
import RefreshIcon from '@mui/icons-material/Refresh';
import Popover from "@mui/material/Popover";


const CustomPopover = ({key, children, content = null}) => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? `simple-popover-${key}` : undefined;

    return (
        <>
            <Box
                component={'div'}
                sx={{
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    display: 'flex',
                    width: '100%',
                    height: '100%',
                    ...(open && content && { background: 'lightgray' })
                }}
                 onClick={handleClick}
            >
                {children}
            </Box>
            {content && (
                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                >
                    <Box p={1}>
                        {content}
                    </Box>
                </Popover>
            )}
        </>
    );
};

const Row = ({row, columns}) => {
    const [open, setOpen] = React.useState(false);

    return (
        <React.Fragment>
            <TableRow sx={{'& > *': {borderBottom: 'unset', ...get(row, `rowStyle`, {})}}}>
                {columns.map(({dataKey, key, width}, index) => (
                    <TableCell
                        key={key}
                        padding="none"
                        {...(index === 0 && {
                            title: get(row, `${dataKey}.value`, get(row, dataKey, null))
                        })}
                        {...(index !== 0 && {
                            title: get(row, `${dataKey}.title`, null)
                        })}
                        {...(row?.children?.length > 0 && {onClick: () => setOpen(!open)})}
                        sx={{
                            fontSize: '12px',
                            ...(index === 0 && get(row, `style`, {})),
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            width,
                            maxWidth: width,
                            minWidth: width,
                            paddingX: '3px',
                            paddingY: '1px',
                        }}
                    >
                        <CustomPopover key={key} content={get(row, `${dataKey}.popover`)}>
                            {(row?.children?.length > 0 && index === 0) && (
                                <>
                                    {open
                                        ? <KeyboardArrowUpIcon fontSize="small"/>
                                        : <KeyboardArrowDownIcon fontSize="small"/>}
                                </>
                            )}
                            {get(row, `${dataKey}.value`, get(row, dataKey, null))}
                        </CustomPopover>
                    </TableCell>
                ))}
            </TableRow>
            {row?.children?.length > 0 && (
                <TableRow>
                    <TableCell colSpan={columns.length} sx={{p: 0}}>
                        <Collapse in={open} timeout="auto" unmountOnExit style={{paddingRight: 0}}>
                            <Table
                                size="small"
                                sx={{
                                    background: 'lightgray'
                                }}
                            >
                                <TableBody>
                                    {row?.children?.map(newRow =>
                                        <Row key={`${newRow.rowKey}-child`} row={newRow} columns={columns}/>
                                    )}
                                </TableBody>
                            </Table>
                        </Collapse>
                    </TableCell>
                </TableRow>
            )}
        </React.Fragment>
    );
};

const WeeklyPage = () => {
    const [width,] = useWindowSize();
    const dispatch = useDispatch();
    const {
        pending,
        countWeekLoaded,
        loadedWeeksData,
        controllers
    } = useSelector(state => state.weekly);
    const { active } = useSelector(state => state.project);

    const projectId = useSelector(state => state.project.active, isEqual)?.id;

    useEffect(() => {
        if (projectId > 0) {
            loadFromWeek(1);
            if (process.env.NODE_ENV === 'production') {
                loadFromWeek(2);
                loadFromWeek(3);
                loadFromWeek(4);
                loadFromWeek(5);
            }
        }
        // eslint-disable-next-line
    }, [active, projectId]);

    const loadFromWeek = (weekNumber = 0) => {
        const startDate = moment().subtract(weekNumber, 'weeks').startOf('week');
        const endDate = moment().subtract(weekNumber, 'weeks').endOf('week');
        dispatch({
            type: 'weekly/getData',
            payload: {
                projectId,
                weekNumber,
                registeredAfter: moment(startDate)?.format('YYYY-MM-DD'),
                registeredBefore: moment(endDate)?.format('YYYY-MM-DD')
            }
        });
    };

    const handleLoadPrevWeek = (count = false) => {
        loadFromWeek(countWeekLoaded + 1);
        if (process.env.NODE_ENV === 'production') {
            loadFromWeek(countWeekLoaded + 2);
            loadFromWeek(countWeekLoaded + 3);
            loadFromWeek(countWeekLoaded + 4);
            loadFromWeek(countWeekLoaded + 5);
        }
    };

    const weeksData = orderBy(Object.values(loadedWeeksData) || [], ['period.registeredAfter'], ['desc']);

    return (
        <Box>
            <Stack spacing={2}>
                {weeksData.map(({period, weeks, users, spent, versions, products}) => {
                    const dataRow = prepareRows({
                        weeklyData: weeks || [],
                        products
                    });
                    const columns = prepareColumns(weeks || []);

                    // console.log('dataRow', {dataRow});

                    return (
                        <Paper key={period?.text}>
                            <Box display="flex" sx={{borderBottom: '1px solid #000'}}>
                                <Box sx={{flexShrink: 0}} p={1}>
                                    <Button
                                        disabled={controllers?.[period.text] !== null}
                                        size="small"
                                        onClick={() => loadFromWeek(period.weekNumber)}
                                        variant="contained" endIcon={<RefreshIcon/>}>
                                        Обновить неделю
                                    </Button>
                                    <Box p={1}>
                                        <Box fontWeight="900">Неделя</Box>
                                        <Typography variant="body1">{period?.text}</Typography>
                                    </Box>

                                    <Box p={1}>
                                        <Box fontWeight="900">Размер</Box>
                                        <Typography variant="body1" component={Box} display="flex"
                                                    justifyContent="space-between">
                                            <Box>Users:</Box>
                                            <Box variant="body1">{users?.count?.toLocaleString('en-US')}</Box>
                                        </Typography>

                                        <Typography variant="body1" component={Box} display="flex"
                                                    justifyContent="space-between">
                                            <Box>Payed:</Box>
                                            <Box variant="body1">{users?.payed?.toLocaleString('en-US')}</Box>
                                        </Typography>
                                    </Box>
                                    <Box p={1}>
                                        <Box fontWeight="900">Spend</Box>
                                        <Typography
                                            variant="body1">$ {spent?.amount?.toLocaleString('en-US')}</Typography>
                                    </Box>
                                    <Box p={1}>
                                        <Box fontWeight={900}>Версия приложения <br/>в момент first open </Box>
                                        <AppVersions period={period.text} versions={versions?.first}/>
                                    </Box>
                                    <Box p={1}>
                                        <Box fontWeight={900}>Версия приложения <br/>в момент last open </Box>
                                        <AppVersions period={period.text} versions={versions?.last}/>
                                    </Box>
                                </Box>
                                <Box sx={{overflow: 'scroll'}}>
                                    <TableContainer component={Paper} width={width - 210}>
                                        <Table
                                            aria-label="collapsible table"
                                            size="small"
                                        >
                                            <TableHead style={{background: '#ffff02'}}>
                                                <TableRow>
                                                    {columns.map(({key, title, width, style = {}}) => (
                                                        <TableCell
                                                            key={key}
                                                            padding="none"
                                                            py="1px"
                                                            px="2px"
                                                            sx={{
                                                                ...style,
                                                                width,
                                                                minWidth: width,
                                                                maxWidth: width,

                                                            }}>
                                                            {title}
                                                        </TableCell>
                                                    ))}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {dataRow.map((row) => (
                                                    <Row key={row.rowKey || row.rowName} row={row} columns={columns}/>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Box>
                            </Box>
                        </Paper>
                    )
                })}
            </Stack>
            <Box p={2} justifyContent="center" display="flex">
                <Button
                    variant='contained'
                    disabled={pending || loadedWeeksData?.length === 0 || !projectId}
                    onClick={handleLoadPrevWeek}
                >
                    {pending ? 'Loading ...' : 'Load more'}
                </Button>
            </Box>
        </Box>
    );
};

export default WeeklyPage;