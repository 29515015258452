import {Column} from "../../components/table";
import React, {memo} from "react";
import Box from "@material-ui/core/Box";
import {lightGrey} from "../../theme/palette";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import styled from 'styled-components';
import {sum, get} from "lodash";


const TruncatedText = styled.div`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
`;

const CellRender = memo(({cellData}) => {

    let data = <Box width={'100%'} height={'100%'} >
        <Box>{cellData.value}</Box>
        <Typography variant={'overline'}>{cellData.percent}</Typography>
    </Box>;

    const title = cellData?.tooltip;
    if (title) {

        const {
            subscriptions_active_and_cancelled,
            subscriptions_active_and_renewable,
            subscriptions_expired_and_renewable,
            subscriptions_active_and_converted
        } = title;

        const toolTipText =  <div>
            {subscriptions_active_and_converted>=0 ? <div>Active+Converted: {subscriptions_active_and_converted}</div> : null}
            {subscriptions_active_and_cancelled>=0 ? <div>Active+Canceled: {subscriptions_active_and_cancelled}</div> : null}
            {subscriptions_active_and_renewable>=0 ? <div>Active+Renewable: {subscriptions_active_and_renewable}</div>: null}
            {subscriptions_expired_and_renewable>=0 ? <div>Expired+Renewable: {subscriptions_expired_and_renewable}</div>: null}
        </div>;

        data = (<Tooltip title={toolTipText} arrow>
            {data}
        </Tooltip>)
    }

    return (
        data
    )
});


const percentWithTotal =  ({ cellData, column, container }) => {

    if (['cancelled_first_hour', 'cancelled_first_day', 'cancelled_first_30d', 'cancelled_unknown'].includes(column['dataKey'])) {
        container.columnManager._columnStyles[column['dataKey']] = {
            ...container.columnManager._columnStyles[column['dataKey']],
            ...{backgroundColor: lightGrey},
        }
    }

    if (cellData instanceof Object) {
        return <CellRender cellData={cellData} />
    }

    return  cellData
};



const ByMinutes = [
    {
        align: Column.Alignment.CENTER,
        dataKey: 'cancelled_minutes_5',
        key: 'cancelled_minutes_5',
        title: '00-05',
        width: 50,
        cellRenderer: percentWithTotal
    },
    {
        align: Column.Alignment.CENTER,
        dataKey: 'cancelled_minutes_10',
        key: 'cancelled_minutes_10',
        title: '05-10',
        width: 50,
        cellRenderer: percentWithTotal
    },
    {
        align:   Column.Alignment.CENTER,
        dataKey: 'cancelled_minutes_20',
        key:     'cancelled_minutes_20',
        title:   '10-20',
        width:   50,
        cellRenderer: percentWithTotal
    },
    {
        align: Column.Alignment.CENTER,
        dataKey: 'cancelled_minutes_30',
        key: 'cancelled_minutes_30',
        title: '20-30',
        width: 50,
        cellRenderer: percentWithTotal
    },
    {
        align:   Column.Alignment.CENTER,
        dataKey: 'cancelled_minutes_45',
        key:     'cancelled_minutes_45',
        title:   '30-45',
        width:   50,
        cellRenderer: percentWithTotal
    },
    {
        align:   Column.Alignment.CENTER,
        dataKey: 'cancelled_minutes_60',
        key:     'cancelled_minutes_60',
        title:   '45-60',
        width:   60,

        cellRenderer: percentWithTotal
    },
];

const ByHours = [
    {
        align: Column.Alignment.CENTER,
        dataKey: 'cancelled_hours_2',
        key: 'cancelled_hours_2',
        title: '01-02',
        width: 60,
        cellRenderer: percentWithTotal
    },
    {
        align: Column.Alignment.CENTER,
        dataKey: 'cancelled_hours_4',
        key: 'cancelled_hours_4',
        title: '02-04',
        width: 60,
        cellRenderer: percentWithTotal
    },
    {
        align: Column.Alignment.CENTER,
        dataKey: 'cancelled_hours_8',
        key: 'cancelled_hours_8',
        title: '04-08',
        width: 60,
        cellRenderer: percentWithTotal
    },
    {
        align: Column.Alignment.CENTER,
        dataKey: 'cancelled_hours_12',
        key: 'cancelled_hours_12',
        title: '08-12',
        width: 60,
        cellRenderer: percentWithTotal
    },
    {
        align: Column.Alignment.CENTER,
        dataKey: 'cancelled_hours_18',
        key: 'cancelled_hours_18',
        title: '12-18',
        width: 60,
        cellRenderer: percentWithTotal
    },
    {
        align: Column.Alignment.CENTER,
        dataKey: 'cancelled_hours_24',
        key: 'cancelled_hours_24',
        title: '18-24',
        width: 60,
        cellRenderer: percentWithTotal
    },
];

const ByDays = [
    {
        align: Column.Alignment.CENTER,
        dataKey: 'cancelled_days_2',
        key: 'cancelled_days_2',
        title: '01-02',
        width: 60,
        cellRenderer: percentWithTotal
    },
    {
        align: Column.Alignment.CENTER,
        dataKey: 'cancelled_days_3',
        key: 'cancelled_days_3',
        title: '02-03',
        width: 60,
        cellRenderer: percentWithTotal
    },
    {
        align: Column.Alignment.CENTER,
        dataKey: 'cancelled_days_4',
        key: 'cancelled_days_4',
        title: '03-04',
        width: 60,
        cellRenderer: percentWithTotal
    },
    {
        align: Column.Alignment.CENTER,
        dataKey: 'cancelled_days_6',
        key: 'cancelled_days_6',
        title: '04-06',
        width: 60,
        cellRenderer: percentWithTotal
    },
    {
        align: Column.Alignment.CENTER,
        dataKey: 'cancelled_days_8',
        key: 'cancelled_days_8',
        title: '06-08',
        width: 60,
        cellRenderer: percentWithTotal
    },
    {
        align: Column.Alignment.CENTER,
        dataKey: 'cancelled_days_10',
        key: 'cancelled_days_10',
        title: '08-10',
        width: 60,
        cellRenderer: percentWithTotal
    },
    {
        align: Column.Alignment.CENTER,
        dataKey: 'cancelled_days_15',
        key: 'cancelled_days_15',
        title: '10-15',
        width: 60,
        cellRenderer: percentWithTotal
    },
    {
        align: Column.Alignment.CENTER,
        dataKey: 'cancelled_days_20',
        key: 'cancelled_days_20',
        title: '15-20',
        width: 60,
        cellRenderer: percentWithTotal
    },
    {
        align: Column.Alignment.CENTER,
        dataKey: 'cancelled_days_25',
        key: 'cancelled_days_25',
        title: '20-25',
        width: 60,
        cellRenderer: percentWithTotal
    },
    {
        align: Column.Alignment.CENTER,
        dataKey: 'cancelled_days_30',
        key: 'cancelled_days_30',
        title: '25-30',
        width: 60,
        cellRenderer: percentWithTotal
    },
];


export default [
    ...[
        {
            align:   Column.Alignment.RIGHT,
            dataKey: 'period',
            key:     'period',
            title:   'Period',
            width:   100,
            frozen:  Column.FrozenDirection.LEFT,
            rowSpan: 2,
            cellRenderer: ({ cellData }) => <TruncatedText title={cellData}>{cellData}</TruncatedText>,
        },
        {
            align:   Column.Alignment.RIGHT,
            dataKey: 'users',
            key:     'users',
            title:   'Users',
            width:   60,
            frozen:  Column.FrozenDirection.LEFT,
            rowSpan: 2,
        },
        {
            align:   Column.Alignment.LEFT,
            dataKey: 'alias',
            key:     'alias',
            title:   'Product',
            width:   180,
            frozen:  Column.FrozenDirection.LEFT,
            rowSpan: 2,
            cellRenderer: ({ cellData }) => <TruncatedText title={cellData}>{cellData}</TruncatedText>,
        },
        {
            align:   Column.Alignment.CENTER,
            dataKey: 'subscriptions_total',
            key:     'subscriptions_total',
            title:   'Total',
            width:   60,
        },
        {
            align:   Column.Alignment.CENTER,
            dataKey: 'subscriptions_active_and_trials',
            key:     'subscriptions_active_and_trials',
            title:   'InTrial',
            width:   60,
        },
        {
            align:   Column.Alignment.CENTER,
            dataKey: 'subscriptions_active',
            key:     'subscriptions_active',
            title:   'Active',
            width:   60,
            cellRenderer: percentWithTotal
        },
        {
            align:   Column.Alignment.CENTER,
            dataKey: 'subscriptions_renewable',
            key:     'subscriptions_renewable',
            title:   'Renewable',
            width:   80,
            cellRenderer: percentWithTotal
        },
        {
            align:   Column.Alignment.CENTER,
            dataKey: 'cancelled_before_first_payment',
            key:     'cancelled_before_first_payment',
            title:   'Cancelled before FP',
            width:   100,
            cellRenderer: percentWithTotal
        },
        {
            align: Column.Alignment.CENTER,
            dataKey: 'total_canceled',
            key: 'total_canceled',
            title: 'Total cancelled',
            width: 80,
            cellRenderer: ({ rowData }) => {
                const value = sum([
                    get(rowData, 'cancelled_after_30d.value', 0),
                    get(rowData, 'cancelled_unknown.value', 0)
                ]);

                const subscriptions_total = get(rowData, 'subscriptions_total', 0);

                return (
                    <CellRender
                        cellData={{
                            value,
                            percent: `${Math.ceil((value/subscriptions_total || 0)*10000)/100} %`,
                        }}
                    />)
            }
        },
    ],
    ...ByMinutes,
    ...[
        {
            align:   Column.Alignment.CENTER,
            dataKey: 'cancelled_first_hour',
            key:     'cancelled_first_hour',
            title:   'First hour',
            width:   60,

            cellRenderer: percentWithTotal
        },
    ],
    ...ByHours,
    ...[
        {
            align:   Column.Alignment.CENTER,
            dataKey: 'cancelled_first_day',
            key:     'cancelled_first_day',
            title:   'First day',
            width:   60,
            cellRenderer: percentWithTotal
        },
    ],
    ...ByDays,
    ...[
        {
            align: Column.Alignment.CENTER,
            dataKey: 'cancelled_first_30d',
            key: 'cancelled_first_30d',
            title: 'First 30d',
            width: 60,
            cellRenderer: percentWithTotal
        },
        {
            align: Column.Alignment.CENTER,
            dataKey: 'cancelled_after_30d',
            key: 'cancelled_after_30d',
            title: 'After 30d',
            width: 60,
            cellRenderer: percentWithTotal
        },
        {
            align: Column.Alignment.CENTER,
            dataKey: 'cancelled_unknown',
            key: 'cancelled_unknown',
            title: 'Unknown',
            width: 80,
            cellRenderer: percentWithTotal
        }
    ]
]