import Box from "@material-ui/core/Box";
import React, {useCallback, useEffect} from "react";
import Grid from "@material-ui/core/Grid";
import {Button, alpha, InputLabel, OutlinedInput} from "@material-ui/core";
import {makeStyles,} from "@material-ui/core/styles";
import clsx from "clsx";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import {connect} from "react-redux";
import {useHistory} from "react-router-dom";
import buildQueryString, {useQuery} from "../../../services/helpers";
import {format} from 'date-fns';
import CircularProgress from "@material-ui/core/CircularProgress";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import CountryFlag from "../../../components/CountryFlag";
import LinearProgress from "@material-ui/core/LinearProgress";
import IOSSwitch from "../../../components/IOSSwitch";
import styled from 'styled-components'


const StyledListItemText = styled(ListItemText)`
  word-break: break-all;
`;

const styles = makeStyles((theme) => ({
    form:{
        // width: '100%', // Fix IE 11 issue.
        padding: theme.spacing(3) - 2,
        border: `1px solid #E0E4EF`,
        boxShadow: `0px 5px 7px ${alpha(theme.palette.black, 0.03)}, 0px 30px 50px rgba(1, 11, 99, 0.05)`,
        borderRadius: 7,
        backgroundColor:theme.palette.secondary.dark,
        maxWidth:1062,
    },
    checkboxFilter:{
        paddingLeft: theme.spacing(3) - 4,
        paddingRight: theme.spacing(3) - 4,
        backgroundColor: '#E0E4EF',
        borderRadius: 5,
    },
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
        zIndex: 1,
    },
}));

const GROUP_OBJECT_USER     = 'users';
const GROUP_OBJECT_PAYMENTS = 'payments';

const GROUP_PERIOD_DAY   = 'day';
const GROUP_PERIOD_MONTH = 'month';


function FromReport(props) {

    const classes = styles();

    let history = useHistory();
    let query = useQuery();

    const [formData, setFormData] = React.useState({

        periodNumber: query.get('period_number') || 0,
        periodLength: query.get('period_length') || 1,
        groupPeriod: (query.get('group_period')  || GROUP_PERIOD_MONTH ) === GROUP_PERIOD_MONTH,
        groupObject: (query.get('group_object')  || GROUP_OBJECT_PAYMENTS) === GROUP_OBJECT_PAYMENTS,
        periodFinish: query.get('period_finish') || ((query.get('group_period') || GROUP_PERIOD_MONTH ) === GROUP_PERIOD_MONTH ? format(Date.now(), "yyyy-MM") : format(Date.now(), "yyyy-MM--dd")),

        products:       query.getAll('products[]') || [],
        sources:        query.getAll('sources[]') || [],
        countries:      query.getAll('countries[]') || [],
        languages:      query.getAll('languages[]') || [],
        versions_app:   query.getAll('versions_app[]') || [],
        versions_test:  query.getAll('versions_test[]') || [],
        use_first_payment_for_trial:  query.get('use_first_payment_for_trial') || 0,
    });

    const handleSubmit = useCallback(() => {

        const {groupPeriod, groupObject, products } = formData;

        const filterData = {
            ...formData,
            ...{
                groupPeriod: groupPeriod ? GROUP_PERIOD_MONTH : GROUP_PERIOD_DAY,
                groupObject: groupObject ? GROUP_OBJECT_PAYMENTS : GROUP_OBJECT_USER,
                products: products,
            },
        };

        Object.keys(filterData).forEach(item => {
            if ( filterData[item]instanceof Array && filterData[item].length === 0)
                delete filterData[item];
        });

        history.push(window.location.pathname + buildQueryString(filterData));

        props.onChange(filterData);

    }, [props, formData, history]);

    const handleChange = (event) => {

        const {products, groupPeriod, groupObject, ...other} = formData;
        let data = {};

        if ( event.target.name === 'products') {
            const index = products.indexOf(event.target.value);
            if (event.target.checked)
            {
                products.push(event.target.value);

            } else if( index > -1) {
                products.splice(index, 1);
            }
            data = {...data, ...{products}};
        }
        else if ( ['sources','countries','languages','versions_app','versions_test'].includes(event.target.name)) {
            data = {...data, ...{[event.target.name]:event.target.value}}
        }
        else if (event.target.name === 'groupPeriod'){
            const _format = event.target.checked ? "yyyy-MM" : "yyyy-MM-dd";
            data = {...data, ...{
                [event.target.name]: event.target?.checked,
                periodFinish: format(Date.parse(formData.periodFinish) || Date.now(), _format)
            }}
        } else if (event.target.name === 'groupObject') {
            data = {...data, ...{[event.target.name]: event.target?.checked}}
        }
        else {
            data = {...data,...{[event.target.name]: event.target?.value}};
        }

        setFormData(prevState=> ({...prevState, ...data}));

        const filterData = {
            ...other,
            ...data,
            ...{
                groupPeriod: groupPeriod ? GROUP_PERIOD_MONTH : GROUP_PERIOD_DAY,
                groupObject: groupObject ? GROUP_OBJECT_PAYMENTS : GROUP_OBJECT_USER,
            },
        };

        history.push(window.location.pathname + buildQueryString(filterData));
    };

    useEffect(() => {

        if (props.products.pending === false && props.products.products === null &&
            props.project?.active?.id && props.project.pending === false) {
            props.getProducts({projectId: props.project.active.id});
        }
        else if (props.calculator.pending === false && props.calculator.data === null &&
            props.project?.active?.id && props.project.pending === false) {

            props.getCalculator({projectId: props.project.active.id})
        }
        else if (props.products.pending === false && props.products.products === null &&
            props.project?.active?.id && props.project.pending === false &&
            props.products.pending === false && props.renewalcaluator.renewals === null){

            handleSubmit()
        }

    }, [props, handleSubmit]);


    return (
        <Box className={clsx(classes.form)}>

            {(props.renewalcaluator.pending || props.products.pending) && <CircularProgress className={classes.buttonProgress}/>}
            <Grid container spacing={3}>
                <Grid item xs>
                    <InputLabel htmlFor='budget' component={'span'}>
                        Source:
                    </InputLabel>

                    <Select labelId="label"
                            id="sources"
                            value={formData?.sources || []}
                            variant={"outlined"}
                            multiple
                            input={
                                <OutlinedInput fullWidth disabled={props.calculator.pending}/>
                            }
                            inputProps={{
                                name: 'sources'
                            }}
                            renderValue={(selected) => selected.join(', ')}
                            onChange={handleChange}
                    >
                        {props.calculator.data?.sources?.map(item =>
                            <MenuItem key={item.name} value={item.value}>
                                <Checkbox checked={formData?.sources?.indexOf(item.value) > -1} />
                                <ListItemText primary={item.name || '*'} secondary={`Users: ${item.count}`}  />
                            </MenuItem>
                        )}
                    </Select>
                    {props.calculator.pending && <LinearProgress />}
                </Grid>
                <Grid item xs>
                    <InputLabel htmlFor='budget' component={'span'}>
                        Version&nbsp;App:
                    </InputLabel>

                    <Select labelId="label"
                            id="versions_app"
                            value={formData?.versions_app || []}
                            variant={"outlined"}
                            multiple
                            input={
                                <OutlinedInput fullWidth disabled={props.calculator.pending}/>
                            }
                            inputProps={{
                                name: 'versions_app'
                            }}
                            renderValue={(selected) => selected.join(', ')}
                            onChange={handleChange}
                    >
                        {props.calculator.data?.versions_app?.map(item =>
                            <MenuItem key={item.name} value={item.value} >
                                <Checkbox checked={formData?.versions_app?.indexOf(item.value) > -1} />
                                <ListItemText primary={item.name || '*'} secondary={`Users: ${item.count}`}  />
                            </MenuItem>
                        )}
                    </Select>
                    {props.calculator.pending && <LinearProgress />}
                </Grid>
                <Grid item xs>
                    <InputLabel htmlFor='budget' component={'span'}>
                        Version,&nbsp;A/B:
                    </InputLabel>

                    <Select labelId="label"
                            id="versions_test"
                            value={formData?.versions_test}
                            variant={"outlined"}
                            multiple
                            input={
                                <OutlinedInput fullWidth disabled={props.calculator.pending}/>
                            }
                            inputProps={{
                                name: 'versions_test'
                            }}
                            renderValue={(selected) => selected.join(', ')}
                            onChange={handleChange}
                    >
                        {props.calculator.data?.versions_test?.map(item =>
                            <MenuItem key={item.name} value={item.value}>
                                <Checkbox checked={formData?.versions_test?.indexOf(item.value) > -1} />
                                <ListItemText primary={item.name || '*'} secondary={`Users: ${item.count}`}  />
                            </MenuItem>
                        )}
                    </Select>
                    {props.calculator.pending && <LinearProgress />}
                </Grid>
            </Grid>

            <Grid container spacing={3}>
                <Grid item xs>
                    <InputLabel htmlFor='budget' component={'span'}>
                        Country:
                    </InputLabel>

                    <Select labelId="label"
                            id="countries"
                            value={formData?.countries}
                            variant={"outlined"}
                            multiple
                            input={
                                <OutlinedInput fullWidth disabled={props.calculator.pending}/>
                            }
                            inputProps={{
                                name: 'countries'
                            }}
                            onChange={handleChange}
                            renderValue={(selected) => selected.join(', ')}
                            // renderValue={renderCountry.bind(this)}
                    >
                        <MenuItem value={''}>All</MenuItem>
                        {props.calculator.data?.countries?.map(item =>
                            <MenuItem key={item.name} value={item.value}>
                                <CountryFlag countryCode={item.value} style={{fontSize:'2em',paddingRight:8}} />
                                <Checkbox checked={formData?.countries?.indexOf(item.value) > -1} />
                                <ListItemText primary={item.name || '*'} secondary={`Users: ${item.count}`}  />
                            </MenuItem>
                        )}
                    </Select>
                    {props.calculator.pending && <LinearProgress />}
                </Grid>

                <Grid item xs>
                    <InputLabel htmlFor='budget' component={'span'}>
                        Language:
                    </InputLabel>

                    <Select labelId="label"
                            id="languages"
                            value={formData?.languages}
                            variant={"outlined"}
                            input={
                                <OutlinedInput fullWidth disabled={props.calculator.pending}/>
                            }
                            multiple
                            inputProps={{
                                name: 'languages'
                            }}
                            onChange={handleChange.bind(this)}
                            renderValue={(selected) => selected.join(', ')}
                    >
                        {props.calculator.data?.languages?.map(item =>
                            <MenuItem key={item.name} value={item.value}>
                                <Checkbox checked={formData?.languages?.indexOf(item.value) > -1} />
                                <ListItemText primary={item.name || '*'} secondary={`Users: ${item.count}`}  />
                            </MenuItem>
                        )}
                    </Select>
                    {props.calculator.pending && <LinearProgress />}
                </Grid>
                <Grid item xs>
                    <InputLabel htmlFor='budget' component={'span'}>
                        Group&nbsp;by&nbsp;Period:
                    </InputLabel>

                    <Grid component={Box} container alignItems="center" spacing={0} >
                        <Grid item>days</Grid>
                        <Grid item>
                            {/*<AntSwitch checked={state.checkedC} onChange={handleChange} name="checkedC" />*/}
                            <IOSSwitch checked={formData.groupPeriod}
                                       disabled={props.renewalcaluator.pending}
                                       onChange={handleChange.bind(this)} name="groupPeriod"/>
                        </Grid>
                        <Grid item>months</Grid>
                    </Grid>
                </Grid>

                <Grid item xs>
                    <InputLabel htmlFor='budget' component={'span'}>
                        Group&nbsp;by&nbsp;type:
                    </InputLabel>

                    <Grid component={Box} container alignItems="center" spacing={0} >
                        <Grid item>user</Grid>
                        <Grid item>
                            {/*<AntSwitch checked={state.checkedC} onChange={handleChange} name="checkedC" />*/}
                            <IOSSwitch checked={formData.groupObject}
                                       disabled={props.renewalcaluator.pending}
                                       onChange={handleChange.bind(this)} name="groupObject"/>
                        </Grid>
                        <Grid item>payments</Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Grid container spacing={3}>
                <Grid item xs>
                    <InputLabel htmlFor='budget' component={'span'}>
                        End&nbsp;of&nbsp;month:
                    </InputLabel>

                    <OutlinedInput
                        disabled={props.renewalcaluator.pending}
                        fullWidth
                        margin="dense"
                        type={ formData.groupPeriod? "month":"date" }

                        inputProps={{
                            max: format(Date.now(), "yyyy-MM-dd")
                        }}
                        value={formData.periodFinish}
                        name={'periodFinish'}
                        onChange={handleChange.bind(this)}
                    />

                </Grid>
                <Grid item xs>
                    <InputLabel htmlFor='budget' component={'span'}>
                        Count&nbsp;of&nbsp;periods:
                    </InputLabel>
                    <OutlinedInput
                        disabled={props.renewalcaluator.pending}
                        fullWidth
                        margin="dense"
                        type='number'
                        inputProps={{
                            min:0
                        }}
                        value={formData.periodNumber}
                        name={'periodNumber'}
                        onChange={handleChange.bind(this)}
                    />
                </Grid>
                <Grid item xs>
                    <InputLabel htmlFor='budget' component={'span'}>
                        Period&nbsp;length:
                    </InputLabel>

                    <OutlinedInput
                        disabled={props.renewalcaluator.pending}
                        fullWidth
                        margin="dense"
                        type='number'
                        name='periodLength'
                        inputProps={{
                            min:1
                        }}
                        value={formData.periodLength}
                        onChange={handleChange.bind(this)}
                    />
                </Grid>
            </Grid>
            <Grid>
                <FormControlLabel
                    value={parseInt(formData.use_first_payment_for_trial) === 1 ? 0 : 1}
                    control={<Checkbox checked={parseInt(formData.use_first_payment_for_trial) === 1} color="primary" onChange={handleChange.bind(this)} name="use_first_payment_for_trial"/>}
                    label="Дата отмены триалки отсчитывать от первого платежа."
                    labelPlacement="start"
                />
            </Grid>

            <hr style={{border: '1px solid #D8DBE3'}} />


            <Grid container>

                {props.products.products?.filter(({params:{trials}}) => {
                    if (props.showTrials && trials === 0) return false;
                    return true;
                } )?.map(({alias, product_id, odds}, i) =>

                    <Grid component={FormControlLabel} item xs={4} md={3} key={alias} control={
                        <ListItem role={undefined} dense >
                            <ListItemIcon>
                                <Checkbox
                                    edge="start"
                                    tabIndex={-1}
                                    disableRipple
                                    disabled={props.renewalcaluator.pending}
                                    checked={formData.products?.indexOf(product_id) > -1 }
                                    value={product_id}
                                    name={'products'}
                                    onClick={handleChange}
                                    inputProps={{'aria-labelledby': `label-product-${i}`}}
                                />
                            </ListItemIcon>
                            <StyledListItemText color={'body1'} id={`label-product-${i}`} primary={alias} secondary={odds.payments_total}/>
                        </ListItem>
                    } />
                )}

            </Grid>

            <Button variant={'contained'}
                    color={'primary'}
                    disabled={formData?.products?.length === 0}
                    onClick={handleSubmit.bind(this)}
                    endIcon={props.cancelled.pending ? <CircularProgress size={'small'} /> : null}

            >
                Apply
            </Button>

        </Box>
    )
}

const mapState = ({project, products, renewalcaluator, calculator, cancelled}) => ({
    project,
    products,
    renewalcaluator, 
    calculator,
    cancelled,
});

const mapDispatch = ({
                        products: {
                            getProducts,
                        },
                         calculator:{
                             getCalculator
                         }
                     }) => ({
    getProducts,
    getCalculator: (data) => getCalculator(data)
});

FromReport.defaultProps = {
    showTrials: false,
};

export default connect(mapState, mapDispatch)(FromReport)