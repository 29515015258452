import palette from "../palette";
import { createTheme } from '@material-ui/core/styles'

const theme = createTheme();

export default {
    root:{
        margin: theme.spacing(0.5),
        color: palette.darkGrey,
        fontWeight: 900,
        marginTop: theme.spacing(2)
    }
};
