import {Column} from "./../../components/table";

const columns = [
    {
        align:   Column.Alignment.RIGHT,
        dataKey: 'period',
        key:     'period',
        title:   'Период',
        width:   90,
        frozen:  Column.FrozenDirection.LEFT,
    },
    // {
    //     align:   Column.Alignment.RIGHT,
    //     dataKey: 'product_id',
    //     key:     'product_id',
    //     title:   'Продукт',
    //     width:   100,
    //     resizable: true,
    //     frozen:  Column.FrozenDirection.LEFT,
    // },
    {
        align:   Column.Alignment.CENTER,
        dataKey: 'trials',
        key:     'trials',
        title:   'Триалки',
        width:   70,
        frozen:  Column.FrozenDirection.LEFT,
    },
    {
        align:   Column.Alignment.CENTER,
        dataKey: 'conversions',
        key:     'conversions',
        title:   'Конверт',
        width:   90,
    },
    {
        align:   Column.Alignment.CENTER,
        dataKey: 'billing',
        key:     'billing',
        title:   'Billing',
        width:   90,
    },
    {
        align:   Column.Alignment.CENTER,
        dataKey: 'waiting',
        key:     'waiting',
        title:   'Waiting',
        width:   90,
    },
    {
        align:   Column.Alignment.CENTER,
        dataKey: 'cancelled',
        key:     'cancelled',
        title:   'Отменено',
        width:   85,
    },
    {
        align:   Column.Alignment.CENTER,
        dataKey: 'change_product',
        key:     'change_product',
        title:   'Смена ТП*',
        width:   90,
        tooltip: 'Первая реальная покупка отличалась от подписки',
    },
    {
        align:   Column.Alignment.CENTER,
        dataKey: 'refunded',
        key:     'refunded',
        title:   'Refunded',
        width:   90,
    },
    {
        align:   Column.Alignment.CENTER,
        dataKey: 'dist_days.0',
        key:     'dist_days_0',
        title:   '0',
        width:   70,
    },
    {
        align:   Column.Alignment.CENTER,
        dataKey: 'dist_days.1',
        key:     'dist_days_1',
        title:   '1',
        width:   70,
    },
    {
        align:   Column.Alignment.CENTER,
        dataKey: 'dist_days.2',
        key:     'dist_days_2',
        title:   '2',
        width:   70,
    },
    {
        align:   Column.Alignment.CENTER,
        dataKey: 'dist_days.3',
        key:     'dist_days_3',
        title:   '3',
        width:   70,
    },
    {
        align:   Column.Alignment.CENTER,
        dataKey: 'dist_days.4',
        key:     'dist_days_4',
        title:   '4',
        width:   70,
    },
    {
        align:   Column.Alignment.CENTER,
        dataKey: 'dist_days.5',
        key:     'dist_days_5',
        title:   '5',
        width:   70,
    },
    {
        align:   Column.Alignment.CENTER,
        dataKey: 'dist_days.6',
        key:     'dist_days_6',
        title:   '6',
        width:   70,
    },
    {
        align:   Column.Alignment.CENTER,
        dataKey: 'dist_days.7',
        key:     'dist_days_7',
        title:   '7',
        width:   70,
    },
    {
        align:   Column.Alignment.CENTER,
        dataKey: 'dist_days.8',
        key:     'dist_days_8',
        title:   '8',
        width:   70,
    },
    {
        align:   Column.Alignment.CENTER,
        dataKey: 'dist_days.9',
        key:     'dist_days_9',
        title:   '9',
        width:   70,
    },
    {
        align:   Column.Alignment.CENTER,
        dataKey: 'dist_days.10-19',
        key:     'dist_days_10-19',
        title:   '10-19',
        width:   70,
    },
    {
        align:   Column.Alignment.CENTER,
        dataKey: 'dist_days.20-29',
        key:     'dist_days_20-29',
        title:   '20-29',
        width:   70,
    },
    {
        align:   Column.Alignment.CENTER,
        dataKey: 'dist_days.30-39',
        key:     'dist_days_30-39',
        title:   '30-39',
        width:   70,
    },
    {
        align:   Column.Alignment.CENTER,
        dataKey: 'dist_days.40-49',
        key:     'dist_days_40-49',
        title:   '40-49',
        width:   70,
    },
    {
        align:   Column.Alignment.CENTER,
        dataKey: 'dist_days.50-59',
        key:     'dist_days_50-59',
        title:   '50-59',
        width:   70,
    },
    {
        align:   Column.Alignment.CENTER,
        dataKey: 'dist_days.60-69',
        key:     'dist_days_60-69',
        title:   '60-69',
        width:   70,
    },
    {
        align:   Column.Alignment.CENTER,
        dataKey: 'dist_days.70-79',
        key:     'dist_days_70-79',
        title:   '70-79',
        width:   70,
    },
    {
        align:   Column.Alignment.CENTER,
        dataKey: 'dist_days.80-89',
        key:     'dist_days_80-89',
        title:   '80-89',
        width:   70,
    },
    {
        align:   Column.Alignment.CENTER,
        dataKey: 'dist_days.90+',
        key:     'dist_days_90+',
        title:   '90+',
        width:   70,
    },
];

export default columns;