import React from "react";
import Box from "@material-ui/core/Box";
// import InfoOutlined from "@material-ui/icons/InfoOutlined";
// import Tooltip from "@material-ui/core/Tooltip";

const TextWithIcon = ({ children = null, iconPosition = 'right' }) => {
    // const icon = (
    //     <Tooltip title="Отображается общее число подписок, через слеш указано число конверсий для триальных подписок">
    //         <InfoOutlined fontSize="small" />
    //     </Tooltip>
    // );

    return (
        <Box display="flex" >
            {/*{iconPosition === 'left' && icon}*/}
            <Box flexGrow={1}>
                {children}
            </Box>
            {/*{iconPosition === 'right' && icon}*/}
        </Box>
    )
};

export default TextWithIcon
