import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import moment from '../../core/moment';
import { isEqual, compact, isEmpty } from 'lodash';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Stack from '@mui/material/Stack';
import StatusCheckboxes from './components/StatusCheckboxes';
import SubscriptionTypes from './components/SubscriptionTypes';
import GroupingSelect from './components/GroupingSelect';
import prepareBarData from './utils/prepareBarData';
import { createSelector } from 'reselect'
import LineChart from './components/LineChart';
import BarChart from './components/BarChart';

const getProducts = state => state.recurringRevenue.products;
const getData = state => state.recurringRevenue.data;
const selectData = createSelector(getData, items => items);

const RecurringRevenuePage = () => {
  const dispatch = useDispatch();
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [checkedStatus, setCheckedStatus] = useState({
    renewable: true,
    canceled: true,
  });
  const [grouping, setGrouping] = useState('day');
  const { active } = useSelector(state => state.project);

  const [startDate, setStartDate] = useState(moment().subtract(2, 'month').startOf('month').toDate());
  const [endDate, setEndDate] = useState(moment().endOf('month').toDate());

  const projectId = useSelector(state => state.project.active, isEqual)?.id;
  const products = useSelector(getProducts, shallowEqual);

  const data = useSelector(selectData);
  const pending = useSelector(state => state.recurringRevenue.pending || false);

  useEffect(() => {
    if (projectId && startDate && startDate) {
      dispatch({
        type: 'recurringRevenue/getData',
        payload: {
          projectId,
          dateAfter: moment(startDate)?.format('YYYY-MM-DD'),
          dateBefore: moment(endDate)?.format('YYYY-MM-DD')
        }
      });
    }
    // eslint-disable-next-line
  }, [active, projectId, startDate, endDate]);

  const {
    canceledData,
    renewableData,
    canceledSeries,
    renewableSeries
  } = prepareBarData({
    data,
    selectedProducts: isEmpty(selectedProducts) ? products : selectedProducts,
    // selectedProducts: products,
    grouping
  });

  return (
    <Box>
      {pending && <Box>Loading....</Box>}

      <Box display="flex">
        <Box display="flex">
          <DatePicker
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            selectsStart
            startDate={startDate}
            endDate={endDate}
            dateFormat="dd.MM.yyyy"
          />
          <DatePicker
            selected={endDate}
            onChange={(date) => setEndDate(date)}
            selectsEnd
            startDate={startDate}
            endDate={endDate}
            minDate={startDate}
            dateFormat="dd.MM.yyyy"
          />
        </Box>

        <Box display="flex" justifyContent="end" flex="1" sx={{ p: 1 }}>
          <Stack direction="row" spacing={1}>
            <GroupingSelect grouping={grouping} setGrouping={setGrouping} />
            <SubscriptionTypes onChange={setSelectedProducts} />
            <StatusCheckboxes checked={checkedStatus} setChecked={setCheckedStatus} />
          </Stack>
        </Box>
      </Box>

      {!pending && !isEmpty(data) && (
        <>
          <LineChart
            data={compact([
              (checkedStatus.canceled && {
                id: 'Canceled',
                data: canceledSeries || [],
              }),
              (checkedStatus.renewable && {
                id: 'Renewable',
                data: renewableSeries || [],
              }),
            ])}
          />
          {checkedStatus.renewable && grouping === 'day' && (
            <BarChart
              title="Renewable"
              data={renewableData || []}
              products={isEmpty(selectedProducts) ? products : selectedProducts}
            />
          )}
          {checkedStatus.canceled && grouping === 'day' && (
            <BarChart
              title="Canceled"
              data={canceledData}
              products={isEmpty(selectedProducts) ? products : selectedProducts}
            />
          )}


          {checkedStatus.renewable && grouping === 'week' && (
            <BarChart
              title="Renewable"
              data={renewableData || []}
              products={isEmpty(selectedProducts) ? products : selectedProducts}
            />
          )}
          {checkedStatus.canceled && grouping === 'week' && (
            <BarChart
              title="Canceled"
              data={canceledData}
              products={isEmpty(selectedProducts) ? products : selectedProducts}
            />
          )}


          {checkedStatus.renewable && grouping === 'month' && (
            <BarChart
              title="Renewable"
              data={renewableData || []}
              products={isEmpty(selectedProducts) ? products : selectedProducts}
            />
          )}
          {checkedStatus.canceled && grouping === 'month' && (
            <BarChart
              title="Canceled"
              data={canceledData}
              products={isEmpty(selectedProducts) ? products : selectedProducts}
            />
          )}
        </>
      )}
    </Box>
  );
};

export default RecurringRevenuePage