import ApiService from "../services/api";
import {checkResponseError, round} from "./helpers";
import ReportService from "../services/ReportService";


export default {
    state: {
        pending: false,
        error: null,
        errorCode: null,

        deeplinks: null,

        countDetails: null,
    },

    reducers:{
        authStart(state) {
            return {
                ...state,
                pending: true
            }
        },
        updateError(state, data) {
            return {
                ...state,
                ...data,
                pending: false
            }
        },
        updateDeeplink(state, data){
            return {
                ...state,
                ...data,
                pending: data?.pending || false
            }
        }
    },

    effects: (dispatch) => ({

        async getDeeplinks(data) {
            try {
                dispatch.deeplink.authStart();

                const responseData = await ApiService.getDeeplinks(data);

                checkResponseError(dispatch.deeplink, responseData.versions);

                const {deeplinks} = responseData;

                dispatch.deeplink.updateDeeplink({
                    deeplinks
                })

            }
            catch (e) {
                console.log('Catch deeplinks', e);
                dispatch.deeplink.updateError({error: e.message})
            }
        },

        async getDeeplinkSourceDetails(data, state) {

            dispatch.deeplink.authStart();

            const {source}  = data;
            const {deeplinks} = state.deeplink;

            const deeplinkSourceIndex = deeplinks.findIndex(deeplink => deeplink.source.toLowerCase() === source.toLowerCase());
            if (deeplinkSourceIndex !== -1) {

                let countDetails = deeplinks[deeplinkSourceIndex].links?.length;

                dispatch.deeplink.updateDeeplink({
                    countDetails,
                });

                /*eslint-disable no-unused-expressions*/
                deeplinks[deeplinkSourceIndex]?.links?.forEach(({id}, index) => {

                    ApiService.getDeeplinkDetails({deeplinkId: id})
                        .then(responseData => {

                            // const {count} = responseData;
                            responseData.summary = responseData.details;
                            responseData.users = responseData.count.users;
                            responseData = ReportService.abTestSummary(responseData);

                            deeplinks[deeplinkSourceIndex].links[index].summary        = responseData.summary;
                            deeplinks[deeplinkSourceIndex].links[index].sumPriceToPaid = responseData.sumPriceToPaid;
                            deeplinks[deeplinkSourceIndex].links[index].users          = responseData.users;
                            deeplinks[deeplinkSourceIndex].links[index]['total'] = round(responseData.total, 2);
                            countDetails--;

                            if (countDetails === 0) {
                                countDetails = null;
                            }

                            dispatch.deeplink.updateDeeplink({
                                deeplinks,
                                pending: countDetails !== null,
                                countDetails,
                            })
                        })
                })

            }

        }
        // async getDetails(data) {
        //     try {
        //         dispatch.projectTests.authStart();
        //
        //         const responseData = await ApiService.getDeeplinkDetails(data);
        //
        //         checkResponseError(dispatch.deeplink, responseData.versions);
        //
        //         const {deeplinks} = responseData;
        //     }
        //     catch (e) {
        //         console.log('Catch deeplinks', e);
        //         dispatch.deeplink.updateError({error: e.message})
        //     }
        // }
    })
}