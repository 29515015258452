import firebase from 'firebase/compat/app';
import 'firebase/compat/messaging';
import ApiService from './services/api';


if (!firebase.apps.length) {
    firebase.initializeApp({
        // Project Settings => Add Firebase to your web app
        apiKey: "AIzaSyAi5IfKyIA7-Ehqan_oL5_6wKW7SEmY480",
        authDomain: "bi-tables-99b49.firebaseapp.com",
        databaseURL: "https://bi-tables-99b49.firebaseio.com",
        projectId: "bi-tables-99b49",
        storageBucket: "bi-tables-99b49.appspot.com",
        messagingSenderId: "679790008456",
        appId: "1:679790008456:web:02a0f6dba7184a0e19673d",
        measurementId: "G-NQXEHT95W4"
    });
}

export default firebase





//ToDO add remove token
/**
 * @see https://github.com/firebase/quickstart-js/tree/master/messaging
 */

export const fireBaseInit = () => {

    if (firebase.messaging.isSupported() === false ) return;

    const messaging = firebase.messaging();

    function urlBase64ToUint8Array(base64String) {
        const padding = '='.repeat((4 - base64String.length % 4) % 4);
        const base64 = (base64String + padding)
            .replace(/-/g, '+')
            .replace(/_/g, '/');

        const rawData = window.atob(base64);
        const outputArray = new Uint8Array(rawData.length);

        for (let i = 0; i < rawData.length; ++i) {
            outputArray[i] = rawData.charCodeAt(i);
        }
        return outputArray;
    }

    const sendTokenToServer = ()  => {
        if (('Notification' in window && Notification.permission !== 'denied') || Notification.permission === "default") {
            messaging.getToken()
                .then((pushToken) => {

                    console.log('Push Token', pushToken);


                    if (pushToken) {
                        ApiService.postPushToken({pushToken})
                            .then((data) => {
                                console.log('Update push token', data);
                            })
                            .catch(err => {
                                console.log('Error update push token', err);
                            });
                    } else {
                        // Show permission request.
                        console.log('No Instance ID token available. Request permission to generate one.');
                        // Show permission UI.
                    }
                })
                .catch((err) => {
                    console.log('An error occurred while retrieving token. ', err);
                });
        }
    };

    const vapidPublicKey = 'BHjNY2CaQ3osMserkzHDyFLmfR_YKsBGv7HXET4reCnR1gqdiFee2rZz8jQavgPyUaH8hKkCosHiWAsdDInHIyI';

    navigator.serviceWorker.register(`${process.env.PUBLIC_URL}/firebase-messaging-sw.js`)
        .then((registration) => {
            console.log("Registration successful, firebase-messaging-sw scope, is:", registration);
            navigator.serviceWorker.ready
                .then(function(registration) {
                    messaging.requestPermission()
                        .then(() => {
                            sendTokenToServer();
                        });
                    return registration.pushManager.getSubscription()
                        .then(async function(subscription) {
                            if (subscription) {
                                return subscription;
                            }

                            const convertedVapidKey = urlBase64ToUint8Array(vapidPublicKey);

                            return registration.pushManager.subscribe({
                                userVisibleOnly: true,
                                applicationServerKey: convertedVapidKey
                            });
                        });
                })
                .then(function(subscription) {
                    console.log('subscription', subscription);
                })
                .catch(err => {
                    console.log('error', err);
                })
        })
        .catch((error) => {
            console.log('error register firebase-messaging-sw.js', error);
        });

    messaging.onTokenRefresh(() => {
        sendTokenToServer();
    });

    messaging.onMessage((payload) => {

        console.log('Message received. ', payload);

        // // регистрируем пустой ServiceWorker каждый раз
        // navigator.serviceWorker.register(`${process.env.PUBLIC_URL}/firebase-messaging-sw.js`).then(()=>{});
        //
        // navigator.serviceWorker.ready.then((registration) => {
        //
        //     let {data} = payload;
        //     if (data?.notification){
        //         data = JSON.parse(data.notification);
        //     }
        //
        //     // теперь мы можем показать уведомление
        //     return registration.showNotification(data.title, data);
        // }).catch(function(error) {
        //     console.log('ServiceWorker registration failed', error);
        // });
    })
};
