import {connect, useSelector} from "react-redux";
import Box from "@material-ui/core/Box";
import React, {useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Popper from '@material-ui/core/Popper';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ImageIcon from '@material-ui/icons/Image';
import Paper from "@material-ui/core/Paper";
import {alpha, OutlinedInput} from "@material-ui/core";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import LinearProgress from "@material-ui/core/LinearProgress";
import clsx from "clsx";
import Tooltip from "@material-ui/core/Tooltip";
import buildQueryString, {addNZeroAtEnd, useQuery} from "../../services/helpers";
import {useHistory, useLocation} from "../../hooks";



const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',

    },
    table: {
        borderRadius: 7,
        '&>thead.MuiTableHead-root': {
            color: `${theme.palette.black} !important`,
            boxShadow: `0px 5px 7px ${alpha(theme.palette.black, 0.03)}`,
        }
    },
    tableHeader:{
        backgroundColor: '#fff',
    },
    odd: {
        backgroundColor: '#FBFCFF',
    },
    even: {
        backgroundColor:'#EAEDF3',
    },
    headerPercentGroup:{
        backgroundColor: '#D8F3FC',
    },
    ceilPercentGroup:{
        backgroundColor: '#EBF7FB',
        '&.even':{
            backgroundColor: '#e1eaed',
        }
    },
    headerLifetime:{
        backgroundColor: '#D8FFDA',
    },
    ceilLifetime:{
        backgroundColor: '#EBFBEC',
        '&.even':{
            backgroundColor: '#dbebdc',
        }
    },
    ltvPositive:{
        backgroundColor: '#E7F5EF',
        '&.even':{
            backgroundColor: '#d8e6e0',
        }
    },
    ltvNegative:{
        backgroundColor: '#FBEBEB',
        '&.even':{
            backgroundColor: '#e7d7d7',
        }
    },
    padding:{
        '& > td.MuiTableCell-root':{
            paddingTop:4,
            paddingBottom:4,
        }
    },
    totalRow:{
        '& > td':{
            fontWeight: 600,
            color: '#000',
        }
    }
}));


const TOOLTIP_TEXTS = {
    segment:    'Варианты теста',
    users:      'Кол-во юзеров в каждой когорте',
    productId:  'Список всех покупок на эту когорту (ID)',
    price:      'Цена покупки (берется из данных)',
    trial:      'Если в покупке есть триал (кол-во триалок)',
    paid:       'Тут либо прямая покупка, либо конверсия',
    c1:         'кол-во триалок деленное на кол-во юзеров в когорте',
    c2:         'кол-во покупок деленное на кол-во триалок 30/300. если покупка без триала - ставим ноль',
    paidCVR:    'кол-во покупок деленное на кол-во юзеров в когорте',
    lifetime:   'Данные берем из настроек',
    userLTV:    'Цена покупки * лайфтайм * Paid CVR',
    LTV:        'Сумма всех LTV по когорте',
    total:      'Сумма money в кагорте',
};



function Report(props) {

    const {deeplink} = props;

    const classes = useStyles();

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [pendingValue, setPendingValue] = React.useState(null);
    const { active } = useSelector(state => state.project);

    const open = Boolean(anchorEl);
    const id = open ? 'github-label' : undefined;

    let history  = useHistory();
    let location = useLocation();
    let query    = useQuery();

    const activeSource = query.get('source');


    useEffect(() => {

        if (props.deeplink.deeplinks === null && props.deeplink.pending === false
            && props.project?.active?.id && props.project.pending === false)
        {
            props.getDeeplinks({projectId: props.project.active.id});
        }

        if (pendingValue?.source?.toLowerCase() !== activeSource?.toLowerCase() && props.deeplink.deeplinks !== null
            && props.deeplink.pending === false){

            setPendingValue({source:activeSource});
            props.getDeeplinkSourceDetails({source:activeSource});
        }

    },[active, props, activeSource, pendingValue]);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (event, reason) => {

        if (reason === 'toggleInput') {
            return;
        }
        if (anchorEl) {
            anchorEl.focus();
        }
        setAnchorEl(null);
    };

    const listDeeplink = props.deeplink.deeplinks?.find(({source}) => source === pendingValue?.source ) || [];
    const totalLetters = listDeeplink?.links?.length ?? 0;
    // find index where total is max
    const indexOfMaxValueARPU = listDeeplink?.links?.reduce((iMax, x, i, arr) => x.total > arr[iMax]['total'] ? i : iMax, 0);
    const indexOfMaxValueTotal = listDeeplink?.links?.reduce((iMax, x, i, arr) => x.sumPriceToPaid > arr[iMax]['sumPriceToPaid'] ? i : iMax, 0);

    // const indexOfMaxValueARPU = props.abtests.abTest?.variants.reduce((iMax, x, i, arr) => x.total > arr[iMax]['total'] ? i : iMax, 0);
    // const indexOfMaxValueTotal = props.abtests.abTest?.variants.reduce((iMax, x, i, arr) => x.sumPriceToPaid > arr[iMax]['sumPriceToPaid'] ? i : iMax, 0);

    const selectTest = (event, newValue) => {

        const {source} = newValue;

        props.getDeeplinkSourceDetails({source});

        setPendingValue(newValue);

        history.push(location.pathname + buildQueryString({source}))
    };


    const TableBodyRow = ({alias, users, count, description, sumPriceToPaid, summary, id, total}, i) => {

        const lastRowIndex = summary?.length - 1 ?? 0;

        return summary?.map(({product_id, price, trials, purchases, lifetime, c1, c2, paidCVR, userLTV}, j) => {

                return <TableRow key={`${i}-${j}`} className={clsx(i % 2 ? classes.even : classes.odd, classes.padding, (lastRowIndex !== 0 && lastRowIndex === j) ? classes.totalRow : '')}>
                    {
                        j === 0 &&
                        <React.Fragment>
                            <TableCell rowSpan={summary.length} align={'center'}>
                                {alias}
                            </TableCell>
                            <TableCell rowSpan={summary.length}>{count.users.toLocaleString('en-US')}</TableCell>
                        </React.Fragment>
                    }
                    <TableCell>{product_id}</TableCell>
                    <TableCell >$&nbsp;{price?.toLocaleString('en-US') ?? price}</TableCell>
                    <TableCell >{trials?.toLocaleString('en-US') ?? trials}</TableCell>
                    <TableCell >{purchases?.toLocaleString('en-US') ?? purchases}</TableCell>
                    <TableCell className={clsx(classes.ceilPercentGroup, (i % 2 ? 'even' : ''))}>{c1}%</TableCell>
                    <TableCell className={clsx(classes.ceilPercentGroup, (i % 2 ? 'even' : ''))}>{c2}%</TableCell>
                    <TableCell className={clsx(classes.ceilPercentGroup, (i % 2 ? 'even' : ''))}>{paidCVR}%</TableCell>
                    <TableCell className={clsx(classes.ceilLifetime, (i % 2 ? 'even' : ''))} align={'center'}>{lifetime?.toLocaleString('en-US') ?? lifetime}</TableCell>
                    <TableCell>$&nbsp;{userLTV}</TableCell>
                    {
                        0 === j &&
                        <React.Fragment>
                            <TableCell  align={'center'}
                                        className={clsx(i === indexOfMaxValueARPU ?
                                            classes.ltvPositive:
                                            classes.ltvNegative,
                                            (i % 2 ? 'even' : '')
                                        )}
                                        rowSpan={summary.length}>
                                $&nbsp;{total?.toLocaleString('en-US') ?? total}
                            </TableCell>
                            <TableCell rowSpan={summary.length}
                                       className={clsx(i === indexOfMaxValueTotal ?
                                           classes.ltvPositive:
                                           classes.ltvNegative,
                                           (i % 2 ? 'even' : '')
                                       )}>
                            $&nbsp;{addNZeroAtEnd(sumPriceToPaid?.toLocaleString('en-US'), 2)}
                            </TableCell>

                        </React.Fragment>
                    }
                </TableRow>
            }
        )};

    return (
        <React.Fragment>

            <Box style={{ width: '100%' }} display={'flex'} >
                <Box  flexGrow={1}>
                    <Box display="flex" className={classes.root} component={Paper} >
                        <Box p={3} flexGrow={1} onClick={handleClick}>
                            <Box pb={1}>{pendingValue?.source ?? 'Choose source'}</Box>
                        </Box>
                    </Box>

                    <Popper
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        placement="bottom-start"
                        className={classes.root}
                        style={{width:360}}
                    >
                        <Autocomplete
                            style={{width:360}}
                            open
                            onClose={handleClose}
                            classes={{
                                paper: classes.paper,
                                option: classes.option,
                                popperDisablePortal: classes.popperDisablePortal,
                            }}
                            onChange={selectTest}
                            renderTags={() => null}
                            noOptionsText="Tests not found"
                            renderOption={(option, { selected }) => (
                                <React.Fragment>
                                    <ListItem component={Box} style={{width:360}}>
                                        <ListItemAvatar>
                                            <Avatar>
                                                <ImageIcon />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText primary={option.source} />
                                    </ListItem>
                                </React.Fragment>
                            )}
                            options={ props.deeplink.deeplinks || []}
                            getOptionLabel={(option) => (typeof option === 'string' || option === undefined) ? option||'' : `${option.source}`}
                            renderInput={(params) => (
                                <OutlinedInput
                                    style={{width:360}}
                                    ref={params.InputProps.ref}
                                    inputProps={params.inputProps}
                                    autoFocus
                                    name='search'
                                    id='search'
                                />
                            )}
                        />
                    </Popper>
                </Box>

            </Box>

            <TableContainer component={Box} mt={3} style={{borderRadius: 7, boxShadow: '0px 5px 7px rgba(0, 0, 0, 0.03)'}}>
                {deeplink.countDetails !== null && <LinearProgress variant="determinate" value={100/totalLetters * (totalLetters - deeplink.countDetails)} />}
                <Table>
                    <Box className={classes.tableHeader} component={TableHead}>
                        <TableRow>
                            <Tooltip title={TOOLTIP_TEXTS.segment} placement="top-end" arrow>
                                <TableCell>Segment</TableCell>
                            </Tooltip>

                            <Tooltip title={TOOLTIP_TEXTS.users} placement="top-end" arrow>
                                <TableCell align={'right'}>Users</TableCell>
                            </Tooltip>

                            <Tooltip title={TOOLTIP_TEXTS.productId} placement="top-end" arrow>
                                <TableCell align={'right'}>Product ID</TableCell>
                            </Tooltip>

                            <Tooltip title={TOOLTIP_TEXTS.price} placement="top-end" arrow>
                                <TableCell align={'right'}>Price</TableCell>
                            </Tooltip>

                            <Tooltip title={TOOLTIP_TEXTS.trial} placement="top-end" arrow>
                                <TableCell align={'right'}>Trial</TableCell>
                            </Tooltip>

                            <Tooltip title={TOOLTIP_TEXTS.paid} placement="top-end" arrow>
                                <TableCell align={'right'}>Paid</TableCell>
                            </Tooltip>

                            <Tooltip title={TOOLTIP_TEXTS.c1} placement="top-end" arrow>
                                <TableCell className={classes.headerPercentGroup} align={'right'}>C1</TableCell>
                            </Tooltip>

                            <Tooltip title={TOOLTIP_TEXTS.c2} placement="top-end" arrow>
                                <TableCell className={classes.headerPercentGroup} align={'right'}>C2</TableCell>
                            </Tooltip>

                            <Tooltip title={TOOLTIP_TEXTS.paidCVR} placement="top-end" arrow>
                                <TableCell className={classes.headerPercentGroup} align={'right'}>Paid CVR</TableCell>
                            </Tooltip>

                            <Tooltip title={TOOLTIP_TEXTS.lifetime} placement="top-end" arrow>
                                <TableCell className={classes.headerLifetime} align={'right'}>Lifetime</TableCell>
                            </Tooltip>

                            <Tooltip title={TOOLTIP_TEXTS.userLTV} placement="top-end" arrow>
                                <TableCell align={'right'}>User LTV</TableCell>
                            </Tooltip>

                            <Tooltip title={TOOLTIP_TEXTS.LTV} placement="top-end" arrow>
                                <TableCell align={'center'}>ARPU</TableCell>
                            </Tooltip>

                            <Tooltip title={TOOLTIP_TEXTS.total} placement="top-end" arrow>
                                <TableCell align={'center'}>Total</TableCell>
                            </Tooltip>
                        </TableRow>
                    </Box>
                    <TableBody>

                        {listDeeplink?.links?.map(TableBodyRow)}

                    </TableBody>
                </Table>
            </TableContainer>

        </React.Fragment>
    )
}

const mapState = state => ({
    deeplink: state.deeplink,
    abTestsReport: state.abTestsReport,
    projectTests: state.projectTests,
    project: state.project,
});

const mapDispatch = ({
                         deeplink:{
                             getDeeplinks,
                             getDeeplinkSourceDetails,
                         },
                         abTestsReport:{
                             getReportForSegments,
                             setSegments
                         },
                         projectTests:{
                             getAllProjectTests
                         }
                     }) => ({
    getReportForSegments: (data) => getReportForSegments(data),
    getDeeplinks,
    setSegments,
    getAllProjectTests,
    getDeeplinkSourceDetails
});

export default connect(mapState, mapDispatch)(Report)