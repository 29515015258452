import React, {useState} from "react";
import DatePicker from "react-datepicker";
import moment from 'moment'

import "react-datepicker/dist/react-datepicker.css";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Popover from "@mui/material/Popover";
import Stack from "@mui/material/Stack";


const BasicDateRangePicker = ({dateStart = null, dateEnd = new Date(), onChange}) => {
  const [startDate, setStartDate] = useState(dateStart);
  const [endDate, setEndDate] = useState(dateEnd);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => {
    setAnchorEl(null);
    onChange && onChange({
      startDate,
      endDate
    })
  };
  const open = Boolean(anchorEl);
  const id = open ? 'date-range-popover' : undefined;

  return (
    <Box>
      <Button
        disableElevation
        aria-describedby={id}
        variant="outlined"
        color="inherit"
        sx={{
          borderColor: 'rgba(0, 0, 0, 0.23)'
        }}
        onClick={handleClick}
      >
        <Stack direction="row" spacing={4 / 8} alignItems="center">
          <Box>{startDate && moment(startDate, 'YYYY-mm-DD')?.format('YYYY-MM-DD')}</Box>
          <Box>&rarr;</Box>
          <Box>{endDate && moment(endDate, 'YYYY-mm-DD')?.format('YYYY-MM-DD')}</Box>
        </Stack>
      </Button>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box
          display="inline-flex"
          sx={{
            '& .react-datepicker': {
              borderRadius: 0,
              border: 'none',
            }
          }}
        >
          <DatePicker
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            selected={startDate}
            onChange={date => setStartDate(date)}
            selectsStart
            startDate={startDate}
            endDate={endDate}
            maxDate={new Date()}
            inline
          />
          <DatePicker
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            selected={endDate}
            onChange={date => setEndDate(date)}
            selectsEnd
            startDate={startDate}
            endDate={endDate}
            minDate={startDate}
            maxDate={new Date()}
            peekNextMonth
            inline
          />
        </Box>
      </Popover>
    </Box>
  );
};

export default BasicDateRangePicker
