import React from "react";
import BaseTable from 'react-base-table'
import * as Column from 'react-base-table'
import 'react-base-table/styles.css';
import {get, isEmpty, sum, values, reduce, isNil, filter, sumBy, intersection} from 'lodash'
import { CellFormatRender, emptyRenderer } from "./utils";
import { TableName } from "./styled";
import CustomPopper from "../../../components/CustomPopover";
import BaseGridTable from "../../../components/BaseGridTable";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import {GridToolbarContainer, GridPreferencesPanel } from "@mui/x-data-grid";
import styled from 'styled-components'

const StyledSup = styled.sup`
    padding-left: 4px;
    color: gray;
    font-size: 10px;
`;

const purchasesDetailsColumns = [
    {
        align:   Column.Alignment.LEFT,
        frozen:  Column.FrozenDirection.LEFT,
        dataKey: 'product',
        key:     'product',
        title: 'Product',
        width:   250,
    },
    {
        align:   Column.Alignment.RIGHT,
        dataKey: 'count',
        key:     'count',
        title: 'Count',
        width:   90,
        cellRenderer: ({ cellData }) => cellData?.toLocaleString('en-US')
    },
    {
        align:   Column.Alignment.RIGHT,
        dataKey: 'count_refunded',
        key:     'count_refunded',
        title: 'Count refunded',
        width:   90,
        cellRenderer: ({ cellData }) => cellData?.toLocaleString('en-US')
    },
    {
        align:   Column.Alignment.RIGHT,
        dataKey: 'payments_refunded',
        key:     'payments_refunded',
        title:   'Payments refunded',
        width:   90,
        cellRenderer: ({ cellData }) => cellData?.toLocaleString('en-US')
    },
    {
        align:   Column.Alignment.RIGHT,
        dataKey: 'revenue',
        key:     'revenue',
        title: 'Revenue',
        width:   90,
        cellRenderer: ({ cellData }) => CellFormatRender.money(cellData)
    },
    {
        align:   Column.Alignment.RIGHT,
        dataKey: 'revenue_predictive',
        key:     'revenue_predictive',
        title: 'Revenue predictive',
        width:   90,
        cellRenderer: ({ cellData }) => CellFormatRender.money(cellData)
    },
    {
        align:   Column.Alignment.RIGHT,
        dataKey: 'revenue_refunded',
        key:     'revenue_refunded',
        title: 'Revenue refunded',
        width:   90,
        cellRenderer: ({ cellData }) => CellFormatRender.money(cellData)
    },
    {
        align:   Column.Alignment.RIGHT,
        dataKey: 'payments_fy',
        key:     'payments_fy',
        title: 'Payments FY',
        width:   90,
        cellRenderer: ({ cellData }) => cellData?.toLocaleString('en-US')
    },
    {
        align:   Column.Alignment.RIGHT,
        dataKey: 'payments_sy',
        key:     'payments_sy',
        title: 'Payments SY',
        width:   90,
        cellRenderer: ({ cellData }) => cellData?.toLocaleString('en-US')
    },
    {
        align:   Column.Alignment.RIGHT,
        dataKey: 'converted',
        key:     'converted',
        title: 'Converted',
        width:   90,
        cellRenderer: ({ cellData }) => cellData?.toLocaleString('en-US')
    },
    {
        align:   Column.Alignment.RIGHT,
        dataKey: 'payments',
        key:     'payments',
        title: 'Payments',
        width:   90,
        cellRenderer: ({ cellData }) => cellData?.toLocaleString('en-US')
    },
    {
        align:   Column.Alignment.RIGHT,
        dataKey: 'revenue_first',
        key:     'revenue_first',
        title: 'Revenue first',
        width:   90,
        cellRenderer: ({ cellData }) => CellFormatRender.money(cellData)
    },
    {
        align:   Column.Alignment.RIGHT,
        dataKey: 'revenue_total',
        key:     'revenue_total',
        title: 'Revenue total',
        width:   90,
        cellRenderer: ({ cellData }) => CellFormatRender.money(cellData)
    },
    {
        align:   Column.Alignment.RIGHT,
        dataKey: 'count_renewable',
        key:     'count_renewable',
        title: 'Count Revewable',
        width:   90,
        cellRenderer: ({ cellData }) => cellData?.toLocaleString('en-US')
    },
    {
        align:   Column.Alignment.RIGHT,
        dataKey: 'count_cancelled',
        key:     'count_cancelled',
        title: 'Count Cancelled',
        width:   90,
        cellRenderer: ({ cellData }) => cellData?.toLocaleString('en-US')
    },
    {
        align:   Column.Alignment.RIGHT,
        dataKey: 'users_refunded',
        key:     'users_refunded',
        title: 'Users refunded',
        width:   90,
        cellRenderer: ({ cellData }) => cellData?.toLocaleString('en-US')
    }
];

export const getColumns = (columns = []) => {
    const filteredColumns = filter(purchasesDetailsColumns, ({dataKey}) => columns.includes(dataKey));

    if (filteredColumns?.length > 1)
        filteredColumns[0].align = Column.Alignment.LEFT;

    return filteredColumns;
};


export const showFieldDetails = ({ data, columns, tooltipText, headerText, ignoreFieldsWithZeroValue = [] }) => {
    // const hideWithZeroValue = ['count_refunded', 'payments_refunded', 'payments_refunded'];

    const tableData = values(data).reduce((acc, item) => {
        if (intersection(columns, ignoreFieldsWithZeroValue).length > 0) {
            if (item['count_refunded'] === 0) return acc;
            if (item['payments_refunded'] === 0) return acc;
            if (item['payments_refunded'] === 0) return acc;
        }

        return [
            ...acc,
            item
        ]
    }, []);
    // console.log('showFieldDetails', { data });

    if (isEmpty(tableData)) return  null;

    const tableColumns = getColumns(columns);
    const width = sumBy(tableColumns, 'width');

    return (
        <CustomPopper tooltipText={tooltipText}>
            {!isEmpty(headerText) && <TableName>{headerText}</TableName>}
            <BaseTable
                fixed
                ignoreFunctionInColumnCompare={true}
                rowKey="product"
                width={width > 630 ? 630 : width}
                height={tableData?.length * 49 + 60}
                headerHeight={49}
                rowHeight={49}
                data={tableData}
                columns={tableColumns}
                emptyRenderer={emptyRenderer({ data: tableData })}
            />
        </CustomPopper>
    );
};


const columns = [
    {
        align: 'left',
        field: 'day_afo',
        key:   'day_afo',
        headerName: <Box lineHeight="18px">Day<br/>AFO</Box>,
        width:   90,
        sortable: false,
    },
    {
        align: 'right',
        field: 'paid_users',
        key:   'paid_users',
        headerName: <Box lineHeight="18px">Paid <br/>users</Box>,
        width:   90,
        sortable: false,
        renderCell: ({ row, field }) => {
            const paid_users_grows = get(row, 'paid_users_grows');
            return (
                <Box display="flex" flexDirection="column">
                    <Box>{get(row, field)?.toLocaleString('en-US')}</Box>
                    {paid_users_grows && (
                        <Box component={Typography} variant="overline">
                            {paid_users_grows}
                        </Box>
                    )}
                </Box>
            )
        }
    },
    {
        align: 'right',
        field: 'total.payments',
        headerName: <Box lineHeight="18px">F. Purchases, Total <br/>(прямые, инапы, <br/>конверсии из триала) (Count)</Box>,
        width:   150,
        sortable: false,
        renderCell: ({ row, field }) => get(row, field)?.toLocaleString('en-US')
    },
    {
        align: 'right',
        field: 'subscriptions.count',
        headerName: <Box lineHeight="18px">G. Subscriptions <br/>without trial (Count)</Box>,
        width:   150,
        sortable: false,
        renderCell: ({ row, field }) => {
            const data = values(
                get(row, 'subscriptions.details')
            );

            return <>
                {get(row, field)?.toLocaleString('en-US')}

                {showFieldDetails({
                    data,
                    columns: ['product', 'count', 'count_renewable', 'count_cancelled'],
                    tooltipText: 'Show details',
                    headerText: 'Subscriptions without trial'
                })}
                </>
        }
    },
    {
        align: 'right',
        field: 'trials.count',
        headerName: 'H. Trials (Count)',
        width:   150,
        sortable: false,
        renderCell: ({ row, field }) => {
            const data = values(get(row, 'trials.details'));
            return (
                <>
                    {get(row, field)?.toLocaleString('en-US')}

                    {showFieldDetails({
                        data,
                        columns: ['product', 'count', 'count_renewable', 'count_cancelled'],
                        tooltipText: 'Show Trials details',
                        headerText: 'Trials details'
                    })}
                </>
            )
        }
    },
    {
        align: 'right',
        field: 'trials.converted',
        headerName: <Box lineHeight="18px">I. Trials<br/>Convert (Count)</Box>,
        width:   150,
        sortable: false,
        renderCell: ({ row, field, ...otherProps }) => {
            const trialsConvertedGrows = get(row, 'trialsConvertedGrows', 0);
            const trials_count = get(row, 'trials.count', null);
            const trials_converted_percent = get(row, field) / trials_count;
            const data = values(get(row, 'trials.details'));
            return (
                <>
                    <Box display="flex" flexDirection="column">
                        <Box>
                            {get(row, field)?.toLocaleString('en-US')}
                            <StyledSup>{trialsConvertedGrows}</StyledSup>
                        </Box>
                        {!isNil(trials_count) && (
                            <Box component={Typography} variant="overline">
                                {CellFormatRender.percent(trials_converted_percent * 100)}
                            </Box>
                        )}
                    </Box>

                    {showFieldDetails({
                        data,
                        columns: ['product', 'converted'],
                        tooltipText: 'Show Trials Convert details',
                        headerText: 'Trials Convert details'
                    })}
                </>
            );
        }
    },
    {
        align: 'right',
        field: 'purchases.count',
        key:   'purchases.count',
        headerName: 'J. In-app (Count)',
        width:   150,
        sortable: false,
        renderCell: ({ row, field }) => {
            const data = values(get(row, 'purchases.details'));
            return (
                <>
                    {get(row, field)?.toLocaleString('en-US')}

                    {showFieldDetails({
                        data,
                        columns: ['product', 'count'],
                        tooltipText: 'Show In-app details',
                        headerText: 'In-app details'
                    })}
                </>
            )}
    },
    {
        align: 'right',
        field: 'total.payments_refunded',
        key:   'total.payments_refunded',
        headerName: 'Payments refunded',
        width:   150,
        sortable: false,
        renderCell: ({ row, field }) => {
            const data = [
                ...values(get(row, 'purchases.details')),
                ...values(get(row, 'trials.details')),
                ...values(get(row, 'subscriptions.details')),
            ].reduce((acc, item) => {
                    item['count_refunded'] = item?.count_refunded || item?.payments_refunded || 0;
                    return [
                        ...acc,
                        item
                    ];
                }
            , []);

            return (
                <>
                    {get(row, field)?.toLocaleString('en-US')}

                    {showFieldDetails({
                        data,
                        columns: ['product', 'count_refunded', 'users_refunded'],
                        tooltipText: 'Show Payments refunded details',
                        headerText: 'Payments refunded details',
                        ignoreFieldsWithZeroValue: ['count_refunded', 'payments_refunded']
                    })}
                </>
            )
        }
    },
    {
        align: 'right',
        field: 'total.revenue_first',
        headerName: <Box lineHeight="18px">K. Purchases Total <br/>(прямые, инапы, конверсии из триала)<br/>(Revenue)</Box>,
        width:   150,
        sortable: false,
        renderCell: ({ row, field }) => {
            const data = [
                ...values(get(row, 'purchases.details')),
                ...values(get(row, 'trials.details')),
                ...values(get(row, 'subscriptions.details')),
            ].reduce((acc, item) => {
                // item['revenue'] = item?.revenue_first || item?.revenue;
                return [
                    ...acc,
                    {
                        ...item,
                        revenue: item?.revenue_first || item?.revenue
                    }
                ]
            }, []);

            return (
                <>
                    {CellFormatRender.money(get(row, field))}

                    {showFieldDetails({
                        data,
                        columns: ['product', 'count', 'revenue'],
                        tooltipText: 'Show Purchases Total details',
                        headerText: 'Purchases Total details',
                    })}
                </>
            )
        }
    },
    {
        align: 'right',
        field: 'subscriptions.revenue_first',
        headerName: <Box lineHeight="18px">L. Subscriptions <br/>without trial <br/>(revenue)</Box>,
        width: 150,
        sortable: false,
        renderCell: ({ row, field }) => {
            const data = values(get(row, 'subscriptions.details'));
            return (
                <>
                    {CellFormatRender.money(get(row, field))}

                    {showFieldDetails({
                        data,
                        columns: ['product', 'count', 'revenue_first'],
                        tooltipText: 'Show Subscriptions without trial details',
                        headerText: 'Subscriptions without trial details',
                    })}
                </>
            )
        }
    },
    {
        align: 'right',
        field: 'trials.revenue_first',
        headerName: <Box lineHeight="18px">M. Trials<br/> Convert Revenue</Box>,
        width: 150,
        sortable: false,
        renderCell: ({ row, field }) => {
            const data = values(get(row, 'trials.details'));

            return (<>
                    {CellFormatRender.money(get(row, field))}

                    {showFieldDetails({
                        data,
                        columns: ['product', 'count', 'revenue_first'],
                        tooltipText: 'Show Trials Convert Revenue details',
                        headerText: 'Trials Convert Revenue details',
                    })}
                </>
            );
        }
    },
    {
        align: 'right',
        field: 'purchases.revenue',
        headerName: <Box lineHeight="18px">N. In-app<br/>Revenue</Box>,
        width: 150,
        sortable: false,
        renderCell: ({ row, field }) => {
            const data = values(get(row, 'purchases.details'));
            return (
                <>
                    {CellFormatRender.money(get(row, field))}

                    {showFieldDetails({
                        data,
                        columns: ['product', 'revenue'],
                        tooltipText: 'Show In-app Revenue details',
                        headerText: 'In-app Revenue details',
                    })}
                </>
            )
        }
    },
    {
        align: 'right',
        field: 'total.profit_first',
        headerName: 'O. Profit',
        width: 150,
        sortable: false,
        renderCell: ({ row, field }) => CellFormatRender.money(get(row, field))
    },
    {
        align: 'right',
        field: 'total.revenue_refunded',
        key:   'total.revenue_refunded',
        headerName: 'Revenue refunded',
        width:   150,
        sortable: false,
        renderCell: ({ row, field }) => {
            const data = [
                ...values(get(row, 'purchases.details')),
                ...values(get(row, 'trials.details')),
                ...values(get(row, 'subscriptions.details')),
            ];

            return (
                <>
                    {CellFormatRender.money(get(row, field))}

                    {showFieldDetails({
                        data,
                        columns: ['product', 'revenue_refunded'],
                        tooltipText: 'Show Purchases revenue details',
                        headerText: 'Purchases revenue details',
                        ignoreFieldsWithZeroValue: ['revenue_refunded']
                    })}
                </>
            )
        }
    },
    {
        align: 'right',
        field: 'subscriptions.revenue_total',
        key:   'subscriptions.revenue_total', /// trials.revenue_first *
        headerName: <Box lineHeight="18px">P. Subscriptions without<br/> trial (Revenue * renewals)</Box>,
        width: 150,
        sortable: false,
        renderCell: ({ row, field }) => {
            const data = values(
                get(row, 'subscriptions.details')
            );

            return (
                <>
                    {CellFormatRender.money(get(row, field))}

                    {showFieldDetails({
                        data,
                        columns: ['product', 'revenue_total'],
                        tooltipText: 'Show Subscriptions without trial details',
                        headerText: 'Subscriptions without trial'
                    })}
                </>
            )
        }
    },
    {
        align: 'right',
        field: 'trials.revenue_total',
        key:   'trials.revenue_total',
        headerName: <Box lineHeight="18px">Q. Trials Convert<br/> (Revenue*renewals)</Box>,
        width: 150,
        sortable: false,
        renderCell: ({ row, field }) => {
            const data = values(
                get(row, 'trials.details')
            );

            return (
                <>
                    {CellFormatRender.money(get(row, field))}

                    {showFieldDetails({
                        data,
                        columns: ['product', 'revenue_total'],
                        tooltipText: 'Show Trials Convert details',
                        headerText: 'Trials Convert details'
                    })}
                </>
            )
        }
    },
    {
        align: 'right',
        field: 'R. purchases.revenue',
        headerName: 'R. In-app Revenue',
        width: 150,
        sortable: false,
        renderCell: ({ row, field }) => CellFormatRender.money(get(row, 'purchases.revenue'))
    },
    {
        align:   'right',
        headerName: <Box lineHeight="18px">S. Purchases Total <br/>(прямые, инапы, конверсии из триала)<br/> (Revenue*renewals)</Box>,
        field: 'custom_sum',
        width: 120,
        sortable: false,
        renderCell: ({ row }) => CellFormatRender.money(
            sum([
                get(row, 'subscriptions.revenue_total', 0),
                get(row, 'trials.revenue_total', 0),
                get(row, 'purchases.revenue', 0),
            ])
        )
    },
    // {
    //     align: 'right',
    //     field: 'total.profit_total',
    //     key:   'total.profit_total',
    //     headerName: <Box lineHeight="18px">T. Purchases Total<br/> (прямые, инапы, конверсии из триала)<br/> (Revenue*renewals)</Box>,
    //     width: 150,
    //     sortable: false,
    //     renderCell: ({ row, field }) => CellFormatRender.money(get(row, field))
    // },
    {
        align: 'right',
        field: 'total.revenue_predictive',
        key:   'total.revenue_predictive',
        headerName: <Box lineHeight="18px">Revenue predictive</Box>,
        width: 150,
        sortable: false,
        renderCell: ({ row, field }) => {
            const data = values(get(row, 'subscriptions.details'));

            return (<>
                {CellFormatRender.money(get(row, field))}

                {showFieldDetails({
                    data,
                    columns: ['product', 'revenue_predictive', 'payments_fy', 'payments_sy'],
                    tooltipText: 'Show Revenue predictive details',
                    headerText: 'Revenue predictive details'
                })}
            </>)
        }
    },
    {
        align: 'right',
        field: 'total.profit_predictive',
        key:   'total.profit_predictive',
        headerName: <Box lineHeight="18px">Profit predictive</Box>,
        width: 150,
        sortable: false,
        renderCell: ({ row, field }) => get(row, field)?.toLocaleString('en-US')
    }
];

const prepareData = ({ data }) => {
    const paidUsersZeroDay = get(data?.[0], 'paid_users', 0);
    const subscriptionsTrialsPurchasesCountZeroDay = sum([
        get(data?.[0], 'subscriptions.count', 0),
        get(data?.[0], 'trials.converted', 0),
        get(data?.[0], 'purchases.count', 0)
    ]);


    const trialsConvertedGrowsZeroDay = get(data?.[0], 'trials.converted', 0);

    return reduce(data, (acc, item) => {
        const subscriptions_trials_purchases_count_sum = sum([
            get(data?.[0], 'subscriptions.count', 0),
            get(data?.[0], 'trials.converted', 0),
            get(data?.[0], 'purchases.count', 0)
        ]);
        return [
            ...acc,
            {
                ...item,
                id: item.day_afo,
                trialsConvertedGrows:
                    get(item, 'trials.converted', 0) - trialsConvertedGrowsZeroDay,
                paid_users_grows: isNil(paidUsersZeroDay)
                    ? null
                    : CellFormatRender.percent((item.paid_users - paidUsersZeroDay)/paidUsersZeroDay * 100),

                subscriptions_trials_purchases_count_sum,
                subscriptions_trials_purchases_grows:
                    CellFormatRender.percent((subscriptions_trials_purchases_count_sum - subscriptionsTrialsPurchasesCountZeroDay)/subscriptionsTrialsPurchasesCountZeroDay * 100)
            }
        ];
    }, []);
};

const StatisticsTable = ({ data, loading }) => {

    data = prepareData({ data});

    return (
        <BaseGridTable
            components={{
                Toolbar: () => (
                    <GridToolbarContainer style={{ borderBottom: '1px solid rgba(224,224,224,1)' }}>
                        <Typography p={2} component={Box} variant={'h4'} color={'inherit'}>Statistics Table</Typography>
                    </GridToolbarContainer>
                ),
                // Header: ({ ...headerProps }) => {
                //     console.log('DataGrid Header props', { headerProps });
                //     return <GridHeader {...headerProps} >sdsddd</GridHeader>
                // },
                PreferencesPanel: ({ ...props }) => {
                    return (
                        <GridPreferencesPanel {...props}>
                            sdsss
                        </GridPreferencesPanel>
                    );
                }
            }}
            loading={loading}
            rows={isEmpty(data) ? [] : data}
            columns={columns}
        />
    )
};

export default StatisticsTable;