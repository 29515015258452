import ApiService from "../services/api";
import {checkResponseError} from './helpers'


export default {
    state: {
        pending: false,
        error: null,
        errorCode: null,

        data: null,
        controller: null
    },
    reducers: {
        authStart(state, data) {
            return {
                ...state,
                ...data,
                error: null,
                errorCode: null,
                pending: true
            }
        },
        updateError(state, data) {
            return {
                ...state,
                ...data,
                pending: false
            }
        },
        updateCalculator(state, data){
            return {
                ...state,
                ...data,
                pending: false
            }
        }
    },

    effects: (dispatch) => ({
        async getCalculator(data, rootState){
            try{

                if (rootState.calculator.controller !== null)
                    rootState.calculator.controller.abort();

                const controller = new AbortController();
                dispatch.calculator.authStart({ controller });

                const responseData = await ApiService.getCalculator(data);

                checkResponseError(dispatch.calculator, responseData);

                dispatch.calculator.updateCalculator({ data: responseData, controller: null });

                return responseData;
            }
            catch (e) {
                console.log('Catch calculator', e);
                dispatch.calculator.updateCalculator({error: e.message})
            }
        },

    })
};