import React, {useEffect, useRef} from "react";
import {connect} from "react-redux";
import Box from "@material-ui/core/Box";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import InlineEdit from "../../components/InlineEdit";
import useWindowSize from "../../hooks/useWindowSize";
import {makeStyles} from "@material-ui/core/styles";
import clsx from "clsx";
import Tooltip from "@material-ui/core/Tooltip";
import {useHistory, useLocation} from "react-router-dom";
import buildQueryString, {useQuery} from "../../services/helpers";
import TableContainer from "@material-ui/core/TableContainer";
import Loader from "../../components/table/loader";
import {StyledTable} from "../../components/styled";
import { round } from 'lodash';


const useStyles = makeStyles((theme) => ({
    root: {},
    ceilBorder: {
        border: '1px solid #c0c0c063',
    },
    ceilBorderLeft: {
        borderLeft: '1px solid #c0c0c063',
    },
    minWidth75: {
        minWidth: 41
    }
}));

function Renewals(props) {

    const classes = useStyles();
    const query = useQuery();
    const history = useHistory();
    const location = useLocation();

    const periods = props.renewals.periods || [];

    const [period, setPeriod] = React.useState(query.get('period') || 'week');

    const handleChangeTab = (event, newValue) => {
        setPeriod(newValue);

        history.push(location.pathname + buildQueryString({period: newValue}));
    };

    const bodyBlock = useRef(null);
    const [, _height] = useWindowSize();
    const maxHeight = (_height < 500 ? 500 : _height) - (bodyBlock?.current?.getBoundingClientRect()?.top || 100) - 100;


    const periodList = periods.find(({period: itemPeriod}) => itemPeriod === period)?.list || [];
    const maxRenewals = periodList.reduce((iMax, x, i, arr) => x.renewals.length > iMax ? x.renewals.length : iMax, 0);

    let a = (new Array(maxRenewals)).fill(1);

    const onChangeManual = (id, product_id, renewal) => {

        props.postProductRenewals({
            id,
            period,
            product_id,
            project_id: props.project?.active?.id,
            field: 'conversion_renewal',
            value: parseFloat(renewal),
            renewal: id,
        });

    };

    const projectId = props.project?.active?.id;

    useEffect(() => {
        if (props.renewals.pending === false && props.renewals.periods === null &&
            projectId && props.project.pending === false) {
            props.getProductRenewals({ projectId });
        }
    }, [props, projectId]);

    if (props.renewals.pending || props.project.pending ) return <Loader loading={true} />;

    return (
        <Box ref={bodyBlock}
             pb={3}
             height={maxHeight}
        >
            <Tabs value={period}
                  onChange={handleChangeTab}
                  indicatorColor="primary"
                  textColor="primary"
                  scrollButtons="on"
                  variant="scrollable"
                  component={Box}
                  pb={3}
            >
                {periods.map(({period, list}) =>
                    <Tab key={period} label={period} value={period} disabled={list.length === 0}/>
                )}
            </Tabs>

            <TableContainer style={{maxHeight, maxWidth: '100%', borderTop: '1px solid #c0c0c063'}}>
                <StyledTable size={'small'}>
                    <TableHead style={{position: 'sticky', top: 0, backgroundColor: '#fff', zIndex: 2}}>
                        <TableRow>
                            <TableCell
                                align="center"
                                rowSpan={2}
                                style={{
                                    position: 'sticky',
                                    left: 0,
                                    backgroundColor: '#fff',
                                    borderRight: '1px solid #c0c0c063',
                                    width: 30
                                }}
                            >
                                №
                            </TableCell>
                            {periodList.map(({alias}) => <TableCell colSpan={4} align="center">{alias}</TableCell>)}
                        </TableRow>
                        <TableRow>
                            {periodList.map((_, index) => ['factual', 'factualStep', 'manual', 'manualStep'].map(item =>
                                <TableCell align="right">{item}</TableCell>))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {a.map((_, id) =>

                            <TableRow key={id}>
                                <TableCell
                                    width={30}
                                    style={{
                                    position: 'sticky',
                                    left: 0,
                                    backgroundColor: '#fff',
                                    borderRight: '1px solid #c0c0c063'
                                }}>{id + 1}</TableCell>
                                {periodList.map(({renewals, product_id}, j) => {
                                    const {manual, factual, statistic, factualCalc, manualCalc} = renewals[id];
                                    return (
                                        <React.Fragment key={j}>
                                            <Tooltip title={`${statistic.completed} / ${statistic.active}`}>
                                                <TableCell
                                                    className={clsx(classes.ceilBorder, classes.minWidth75)}
                                                    variant={'body'}
                                                >
                                                    {factual}
                                                </TableCell>
                                            </Tooltip>
                                            <TableCell
                                              className={clsx(classes.ceilBorder, classes.minWidth75)}
                                              variant={'body'}
                                            >
                                                {round(factualCalc+1, 3)}
                                            </TableCell>
                                            <TableCell
                                                className={clsx(classes.ceilBorder, classes.minWidth75)}
                                                       variant={'body'}>
                                                <InlineEdit type={'text'}
                                                            currency={false}
                                                            text={manual}
                                                            onSetText={onChangeManual.bind(this, id + 1, product_id)}
                                                />
                                            </TableCell>
                                            <TableCell
                                              className={clsx(classes.ceilBorder, classes.minWidth75)}
                                              variant={'body'}
                                            >
                                                {round(manualCalc+1, 3)}
                                            </TableCell>
                                        </React.Fragment>
                                    )
                                })}
                            </TableRow>
                        )}
                    </TableBody>
                </StyledTable>
            </TableContainer>
        </Box>
    )
}


const mapState = state => ({
    renewals: state.renewals,
    project: state.project,
});

const mapDispatch = ({
                         renewals: {
                             getProductRenewals,
                             postProductRenewals,
                         },
                     }) => ({
    getProductRenewals: (data) => getProductRenewals(data),
    postProductRenewals: (data) => postProductRenewals(data),
});
export default connect(mapState, mapDispatch)(Renewals)