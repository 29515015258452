import palette from './palette';

export default {
    fontFamily: "Roboto, Helvetica, Montserrat, Arial, sans-serif",
    h1: {
        color: palette.text.primary,
        fontWeight: 800,
        fontSize: '96px',
        letterSpacing: '0.05em',
        // lineHeight: '80%',
        textShadow: '0px 4px 75px rgba(255, 255, 255, 0.3), 0px 4px 15px rgba(0, 0, 0, 0.5)'
    },
    h2: {
        color: palette.text.primary,
        fontWeight: 800,
        fontSize: '60px',
        letterSpacing: '0.05em',
        // lineHeight: '100%',
        textShadow: '0px 4px 75px rgba(255, 255, 255, 0.3), 0px 4px 15px rgba(0, 0, 0, 0.5)',
    },
    h3: {
        color: palette.text.primary,
        fontWeight: 800,
        fontSize: '26px',
        letterSpacing: '0.01em',
        lineHeight: 1.2,
    },
    h4: {
        color: palette.text.primary,
        fontWeight: 800,
        fontSize: '21px',
        letterSpacing: '0.01em',
        lineHeight: 1.2
    },
    h5: {
        color: palette.text.primary,
        fontWeight: 600,
        fontSize: '13px',
        letterSpacing: '0.03em',
        lineHeight: 1.2
    },
    h6: {
        color: palette.text.primary,
        fontWeight: 500,
        fontSize: '11px',
        letterSpacing: '0.03em',
        lineHeight: 1.2
    },
    subtitle1: {
        color: palette.text.primary,
        fontSize: '16px',
        fontWeight: 400,
        letterSpacing: '-0.05px',
        lineHeight: 1.2
    },
    subtitle2: {
        color: palette.text.secondary,
        fontWeight: 400,
        fontSize: '13px',
        letterSpacing: '-0.05px',
        lineHeight: '150%'
    },
    body1: {
        color: palette.text.primary,
        fontSize: '14px',
        letterSpacing: '-0.05px',
        lineHeight: '150%'
    },
    body2: {
        color: palette.darkGrey,
        fontSize: '13px',
        letterSpacing: '-0.05px',
        lineHeight: '150%'
    },
    button: {
        color: palette.text.primary,
        fontSize: '13px',
        letterSpacing: '0.05em',
    },
    caption: {
        color: `${palette.text.secondary} !important`,
        fontSize: '11px',
        letterSpacing: '0.33px',
        lineHeight: 1.2
    },
    overline: {
        color: palette.text.secondary,
        fontSize: '11px',
        fontWeight: 500,
        letterSpacing: '0.33px',
        lineHeight: 1.2,
        textTransform: 'uppercase'
    },
    // tableHeader:{
    //     fontStyle: 'normal',
    //     fontWeight: 500,
    //     fontSize: 15,
    //     lineHeight: 18,
    //     textAlign: 'center'
    // }
};
