import {connect} from "react-redux";
import Button from "@material-ui/core/Button";
import React from "react";
import {useMediaQuery} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { useTheme } from '@material-ui/core/styles';
import Box from "@material-ui/core/Box";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import Typography from "@material-ui/core/Typography";
import {FacebookIcon, getLocaleDateString, GoogleIcon} from "../../services/helpers";
import SuccessSnackbar from "../../components/SuccessSnackbar";
import {isCancel } from 'axios'
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import TikTokIcon from "./../../components/Icons/TiktokIcon";
import moment from "moment";
import {KeyboardDatePicker} from "@material-ui/pickers";
import Stack from "@mui/material/Stack";
import { isNil } from "lodash";

const format = getLocaleDateString('ru-RU');

const MIME_TYPE_FOR_SOURCE = {
    'facebook' : 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'google' : '.csv',
    'tiktok' : 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
};

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function UploadSpendFromFile(props) {

    const [open, setOpen] = React.useState(false);
    const [uploading, setUploading] = React.useState(false);
    const [date, setDate] = React.useState(null);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setIncrementing(null);
        setFileData(null);
        setUploading(false);
        setOpen(false);
    };


    const [incrementing, setIncrementing] = React.useState(null);
    const handleChange = (event) => {
        setIncrementing(event.target.value);
    };

    const [source, setSource] = React.useState(null);
    const handleChangeSource = (event) => {
        setSource(event.target.value);
    };

    const [fileData, setFileData] = React.useState(null);
    const onFileChange = event => {
        setFileData(event.target.files[0]);
    };

    const cbHandler = () => {
        setUploading(false);
    };


    const [showProgress, setShowProgress] = React.useState(false);
    const [progressValue, setProgressValue] = React.useState(0);

    const cancelFileUploadRef = React.useRef(null);
    const cancelUpload = () => {
        if (cancelFileUploadRef.current) {

            setShowProgress(false);
            setProgressValue(0);

            cancelFileUploadRef.current("User has canceled the file upload.");
        }
    };
    const onUploadProgress = progressEvent => {
        const { loaded, total } = progressEvent;

        let percent = Math.floor((loaded * 100) / total);

        if (percent < 100) {
            setProgressValue(percent);
        } else {
            setShowProgress(false);
            setProgressValue(0);

            props.updateReports({
                data:null,
                summary:null,
            })
        }
    };

    const onFileUpload = (e) => {
        let formData = new FormData();
        formData.append("report_file", fileData);
        formData.append("project_id", props.project?.active?.id);
        formData.append("incrementing", incrementing);
        if (source === 'tiktok' && date !== null) {
            formData.append('date', date?.format('YYYY-MM-DD'))
        }

        setShowProgress(true);
        props.uploadSpendsFromFile({
            formData,
            type: fileData.name.split('.').pop(),
            source,
            onUploadProgress,
            cancelFileUploadRef
        })
            .then(() => {
                setShowProgress(false);
                handleClose()
            })
            .catch(err => {
                setShowProgress(false);
                setProgressValue(0);

                if (isCancel(err)) {
                    alert(err.message);
                }
            });

    };


    const [uploadTabValue, setUploadTabValue] = React.useState('0');

    const handleChangeTab = (event, newValue) => {
        setUploadTabValue(newValue);
    };


    const onFileUploadRevenue = (e) => {
        let formData = new FormData();
        formData.append("report_file", fileData);
        formData.append("project_id", props.project?.active?.id);
        // formData.append("incrementing", incrementing);

        setShowProgress(true);
        props.uploadRevenueFromFile({
            formData,
            type: fileData.name.split('.').pop(),
            source,
            onUploadProgress,
            cancelFileUploadRef
        })
            .then(() => {
                setShowProgress(false);
                handleClose()
            })
            .catch(err => {
                setShowProgress(false);
                setProgressValue(0);

                if (isCancel(err)) {
                    alert(err.message);
                }
            });

    };

    return (
        <React.Fragment>

            <Snackbar open={showProgress} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                <SnackbarContent style={{backgroundColor:'gray'}}
                    message={
                        <React.Fragment>Uploading... {progressValue} %</React.Fragment>
                    }
                    action={
                        <Button color="secondary" size="small" onClick={cancelUpload}>
                            UNDO
                        </Button>
                    }
                />
            </Snackbar>

            <SuccessSnackbar open={uploading} text={'Upload'} severity={'success'} cb={cbHandler} />

            <Button disabled={!props.project?.active?.id || uploading}
                    color={'primary'}
                    variant={uploading ? 'outlined' : 'contained'}
                    onClick={handleClickOpen}
            >
                Upload
            </Button>

            <Dialog
                style={{minWidth:300}}
                fullScreen={fullScreen}
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
            >

                <Tabs value={uploadTabValue} onChange={handleChangeTab} aria-label="simple tabs example">
                    <Tab label="Spend" value={'0'}/>
                    <Tab label="Revenue" value={'1'} />
                </Tabs>

                <TabPanel value={uploadTabValue} index={'0'}>
                    <DialogContent>
                        <Stack spacing={1} direction='row'>

                            <Radio checked={source === 'facebook'}
                                   value={'facebook'}
                                   icon={<FacebookIcon />}
                                   checkedIcon={<FacebookIcon color='primary' />}
                                   onChange={handleChangeSource}
                            />

                            <Radio checked={source === 'google'}
                                   value={'google'}
                                   icon={<GoogleIcon />}
                                   checkedIcon={<GoogleIcon color='primary' />}
                                   onChange={handleChangeSource}
                            />

                            <Radio checked={source === 'tiktok'}
                                   value='tiktok'
                                   icon={<TikTokIcon />}
                                   checkedIcon={<TikTokIcon color='primary' />}
                                   onChange={handleChangeSource}
                            />
                        </Stack>

                        {source === 'tiktok' && <KeyboardDatePicker
                          id='date'
                          disableToolbar
                          variant="inline"
                          format={format}
                          inputVariant="outlined"
                          value={date}
                          onChange={value => setDate(moment(value))}
                          KeyboardButtonProps={{
                              'aria-label': 'change date',
                          }}
                        />}

                        <Box>
                            {source &&
                                <React.Fragment>
                                    <input
                                      disabled={source === 'tiktok' && isNil(date)}
                                        style={{display: 'none'}}
                                        accept={MIME_TYPE_FOR_SOURCE[source]}
                                        id="contained-button-file"
                                        type="file"
                                        onChange={onFileChange}
                                    />
                                    <label htmlFor="contained-button-file">
                                        <Button
                                            className={'MuiInputBase-root MuiOutlinedInput-root MuiInputBase-fullWidth MuiInputBase-marginDense MuiOutlinedInput-marginDense'}
                                            variant="outlined" color="primary" component="span">
                                            {fileData ? `(${fileData.name})` : 'Choose file'}
                                        </Button>
                                    </label>

                                </React.Fragment>
                            }

                            {fileData !== null && source &&
                                <RadioGroup aria-label="incrementing" name="incrementing" value={incrementing}
                                            onChange={handleChange}>
                                    <FormControlLabel value="1" control={<Radio/>} label="Append"/>
                                    <FormControlLabel value="0" control={<Radio/>} label="Rewrite"/>
                                </RadioGroup>
                            }
                        </Box>
                    </DialogContent>
                    {uploading === false && <DialogActions>
                        <Button autoFocus onClick={handleClose} color="primary">
                            Cancel
                        </Button>
                        { incrementing && fileData && source && <Button onClick={onFileUpload} color="primary" >
                            Upload
                        </Button>}
                    </DialogActions>}
                </TabPanel>

                <TabPanel value={uploadTabValue} index={'1'}>

                    <React.Fragment>
                        <input
                            style={{display: 'none'}}
                            accept={'.csv'}
                            id="contained-button-file"
                            type="file"
                            onChange={onFileChange}
                        />
                        <label htmlFor="contained-button-file">
                            <Button
                                className={'MuiInputBase-root MuiOutlinedInput-root MuiInputBase-fullWidth MuiInputBase-marginDense MuiOutlinedInput-marginDense'}
                                variant="outlined" color="primary" component="span">
                                {fileData ? `(${fileData.name})` : 'Choose file'}
                            </Button>
                        </label>

                    </React.Fragment>

                    {uploading === false && <DialogActions>
                        <Button autoFocus onClick={handleClose} color="primary">
                            Cancel
                        </Button>
                        { fileData && <Button onClick={onFileUploadRevenue} color="primary" >
                            Upload
                        </Button>}
                    </DialogActions>}
                </TabPanel>


            </Dialog>

        </React.Fragment>
    )

}


const mapState = state => ({
    reports: state.reports,
    project: state.project,
});

const mapDispatch = ({
                         reports:{
                             updateReports,
                             uploadSpendsFromFile,
                             uploadRevenueFromFile,
                         },
                     }) => ({
    uploadSpendsFromFile: (data) => uploadSpendsFromFile(data),
    uploadRevenueFromFile:(data) => uploadRevenueFromFile(data),
    updateReports:(data) => updateReports(data),
});

export default connect(mapState, mapDispatch)(UploadSpendFromFile);