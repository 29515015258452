import React from "react";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import {filter} from "lodash";
import FilterListIcon from "@mui/icons-material/FilterList";
import IconButton from "@mui/material/IconButton";
import Popover from "@mui/material/Popover";

const FilterColumns = ({tableColumns = [], columns, setColumns }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'cohort-columns-popover' : undefined;


  const filteredColumns = tableColumns.filter(item => item.dataKey !== 'date');

  const handleChangeColumn = ({target: {name, checked}}) => {
    if (checked)
      setColumns(prevState => [...filter(prevState, item => item !== name)]);
    else
      setColumns(prevState => ([...prevState, name]));
  };

  return (
    <Box>
      <IconButton aria-describedby={id} variant="contained" onClick={handleClick} size="small">
        <FilterListIcon fontSize="small"/>
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Stack component={Box} p={2}>
          {filteredColumns.map(product => (
            <FormControlLabel
              key={product}
              label={product.title}
              control={
                <Checkbox
                  sx={{py: 2 / 8}}
                  checked={!columns.includes(product.dataKey)}
                  name={product.dataKey}
                  onChange={handleChangeColumn}
                />
              }
            />
          ))}
        </Stack>
      </Popover>
    </Box>
  )
};

export default FilterColumns;