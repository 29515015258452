import 'react-app-polyfill/stable';
import 'react-app-polyfill/ie11';

import React from 'react';
import ReactDOM from 'react-dom';
import App from "./app";
import * as serviceWorker from './serviceWorker';
import './assets/css/base.css'
import moment from "./core/moment";

// const locale = window?.navigator?.language || "en-US";
// Set locale to moment
// moment.locale(locale);
const localeData = moment.localeData();
window.dateFormat = localeData.longDateFormat('L').replaceAll('D','d').replaceAll('Y', 'y');


ReactDOM.render(<App />,  document.getElementById('root'), () => {

});

serviceWorker.register({
    onUpdate: () => {
        console.log('serviceWorker.register onUpdate')
    },
    onSuccess: () => {
        console.log('serviceWorker.register onSuccess')
    },
});