import React from "react";
import {connect} from "react-redux";
import Box from "@material-ui/core/Box";


const data = [
    {
        dates: ['2020-01-01', '2020-01-02', '2020-01-03', '2020-01-03', '2020-01-05', '2020-01-06', '2020-01-07'],
        spend: {
            fb: [1, 2, 3, 4, 5, 6, 7],
            adNetworks: [11, 12, 13, 14, 15, 16, 17],
            unity: [21, 22, 23, 24, 25, 26, 27],
            google: [31, 32, 33, 34, 35, 36, 37],
            snapChat: [41, 42, 43, 44, 45, 46, 47],
            searchAds: [51, 52, 53, 54, 55, 56, 57],
        },
        conversions: {},
        profits: {}
    },
    {
        dates: ['2020-02-01', '2020-02-02', '2020-02-03', '2020-02-04', '2020-02-05', '2020-02-06', '2020-02-07'],
        spend: {
            fb: [1, 2, 3, 4, 5, 6, 7],
            adNetworks: [11, 12, 13, 14, 15, 16, 17],
            unity: [21, 22, 23, 24, 25, 26, 27],
            google: [31, 32, 33, 34, 35, 36, 37],
            snapChat: [41, 42, 43, 44, 45, 46, 47],
            searchAds: [51, 52, 53, 54, 55, 56, 57],
        },
        conversions: {},
        profits: {}
    },
];


data.reduce((prevValue, {dates, spend, conversions, profits}) => {
    let total = [0, 0, 0, 0, 0, 0, 0, 0];
    // add sum to each spend item
    for (const [key, value] of Object.entries(spend)) {
        value.push(value.reduce((a, b) => a + b, 0));
        spend[key] = value;

        value.forEach((v, i) => {
            total[i] += v;
        })
    }

    spend['total'] = total;

    prevValue.push({
        dates,
        spend,
        conversions,
        profits
    });

    return prevValue

}, []);


function DailyReport() {

    return (
        <Box>

dd
        </Box>
    );
}


export default connect(null, null)(DailyReport)