import React, {useState, useEffect, useRef, useCallback} from "react";
import useKeypress from "../hooks/useKeypress";
import useOnClickOutside from "../hooks/useOnClickOutside";
import DOMPurify from "dompurify";
import {ReactComponent as CreateIcon} from './../assets/images/icons/pen.svg';
import SaveIcon from "@material-ui/icons/Save";
import Box from "@material-ui/core/Box";
import {makeStyles} from "@material-ui/styles";
import clsx from "clsx";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Linkify from "react-linkify";


const styles =  makeStyles( theme => ({
    saveIcon:{
        margin: 'auto 0',
        position: 'absolute',
        right: -20,
        zIndex: 1,
        bottom: 2,
        '&:hover':{
            cursor:'pointer',
            '& path': {
                stroke: theme.palette.primary.main,
                fill: theme.palette.primary.main,
            }
        }
    },
    createIcon:{
        '&:hover':{
            cursor:'pointer',
            '& path': {
                stroke: theme.palette.primary.main,
                fill: theme.palette.primary.main,
            }
        }
    },
    textInput:{
        paddingTop:2,
        paddingBottom:2,
        paddingLeft:5, paddingRight:5,
    },
    parentTextContainer:{
        '& > .MuiInputBase-adornedEnd':{
            paddingRight: 0,
            height: 'auto',
        },
        '&>.MuiInputBase-multiline':{
            height:'100%'
        }
    }
}));

function InlineEdit(props) {

    const classes = styles();

    const [isInputActive, setIsInputActive] = useState(false);
    const [inputValue, setInputValue] = useState();

    const wrapperRef = useRef(null);
    const textRef = useRef(null);
    const inputRef = useRef(null);

    // const enter = useKeypress("Enter");
    const esc = useKeypress("Escape");

    const {onSetText} = props;
    const type = props?.type || 'text';

    // check to see if the user clicked outside of this component
    useOnClickOutside(wrapperRef, () => {
        if (isInputActive) {
            // onSetText(props.text );
            setIsInputActive(false);
        }
    });

    const onSave = (e) => {
        setIsInputActive(false);
        onSetText(inputValue);
    };

    const onCancel = (e) => {
        setIsInputActive(false);
        setInputValue(props.text);
    };

    // const onEnter = useCallback(() => {
    //     if (enter) {
    //         onSave();
    //     }
    //     // eslint-disable-next-line
    // }, [enter]);

    const onEsc = useCallback(() => {
        if (esc) {
            onCancel();
        }
        // eslint-disable-next-line
    }, [esc]);

    // focus the cursor in the input field on edit start
    useEffect(() => {
        if (isInputActive) {
            inputRef.current.focus();
        }
    }, [isInputActive]);

    useEffect(() => {
        if (isInputActive) {
            // if Enter is pressed, save the text and close the editor
            // eslint-disable-next-line
            // onEnter();
            // if Escape is pressed, revert the text and close the editor
            // eslint-disable-next-line
            onEsc();
        }
    }, [isInputActive, onEsc]); // watch the Enter and Escape key presses

    const handleInputChange = event => {
        // sanitize the input a little
        setInputValue(DOMPurify.sanitize(event.target.value));
    };

    // const setText = useCallback(() => {
    //
    //     console.log('setText', props.text );
    //
    //     setInputValue(props.text)
    // }, [props]);
    //
    // useEffect(() => {
    //     setText();
    // },[props]);

    const handleSpanClick =(e) => {
        // e?.stopPropagation();
        setInputValue(props.text);
        setIsInputActive(true)
    };

    return (
        <React.Fragment>
            <Box ref={wrapperRef} className={'edit'} display="flex">
                <Box component={'span'} width={'100%'} ref={textRef}

                      className={clsx(`inline-text_copy inline-text_copy--${
                        !isInputActive ? "" : "hidden"
                      }`, 'inline-text-multiline')}
                >
                    <Linkify properties={{target:'_blank'}}>
                    {props?.currency && `$` }
                    &nbsp;
                    {props.text}
                    </Linkify>
                </Box>

                {isInputActive && <TextField ref={inputRef}
                                             variant="outlined"
                                             fullWidth
                                             className={classes.parentTextContainer}
                                             multiline={props?.multiline || false}
                                             type={type}
                                             value={inputValue || ''}
                                             // style={{height: 24, paddingLeft:5, paddingRight:5}}
                                             onChange={handleInputChange.bind(this)}
                                             onFocus={e => e?.stopPropagation()}


                                             autoFocus
                                             inputProps={{
                                                 className:classes.textInput,
                                             }}
                                             InputProps={{
                                                 endAdornment: (
                                                     <InputAdornment position="end">
                                                         {isInputActive &&
                                                         <SaveIcon className={classes.saveIcon}
                                                                   fontSize={'small'}
                                                                   onClick={onSave.bind(this)}
                                                         />}
                                                     </InputAdornment>
                                                 )
                                             }}
                />}

                {
                    !isInputActive &&
                    <CreateIcon style={{
                        margin: 'auto 0',
                        flex: '1 0 auto',
                    }}
                                className={clsx('inline-text_copy--active', classes.createIcon )}
                                fontSize={'small'}
                                onClick={handleSpanClick.bind(this)}
                    />
                }
            </Box>

        </React.Fragment>
    );
}

export default InlineEdit;
