import React, {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import {useDispatch, useSelector} from "react-redux";
import moment from "../../core/moment";
import columns from './utils/columns'
import BasicDateRangePicker from "../../components/BasicDateRangePicker";
import Stack from "@mui/material/Stack";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import {useHistory, useWindowSize} from "../../hooks";
import {useQuery} from "../../services/helpers";
import {compact, reduce, uniq} from "lodash";
import Typography from "@mui/material/Typography";
import theme from "../../theme";
import Table, {Column} from "react-base-table";
import component from "../../views/calculator/TrialReportCompoment";
import {AutoSizer} from "react-virtualized";
import CountryFlag from "../../components/CountryFlag";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Chip from "@mui/material/Chip";
import Autocomplete from "@mui/material/Autocomplete";
import Popper from "@mui/material/Popper";
import CircularProgress from "@mui/material/CircularProgress";

// import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
// import CheckBoxIcon from '@mui/icons-material/CheckBox';

const styles = (theme) => ({
  popper: {
    maxWidth: "fit-content"
  }
});

const PopperMy = function (props) {
  return <Popper {...props} style={styles.popper} placement="bottom-start"/>;
};

const CalendarDashPage = () => {
  const dispatch = useDispatch();
  const [, height] = useWindowSize();
  const query = useQuery();
  const history = useHistory();
  const { active } = useSelector(state => state.project);

  const [isOpenCountries, setIsOpenCountries] = useState(false);

  const calculator = useSelector(state => state.calculator);
  const {data: calculatorData, pending: countriesPending} = calculator;

  const projectId = useSelector(state => state.project?.active?.id);
  const {pending, data} = useSelector(state => state.calendarDash);
  const tableData = reduce(data, (acc, item) => ([
    ...acc,
    {
      ...item,
      id: item.date,
      children: reduce(item.countries, (accCountry, country, countryCode) => ([
        ...accCountry,
        {
          ...country,
          countryCode,
          parentId: item.date,
          id: `${item.date}-${countryCode}`,
          date: countryCode
        }
      ]), [])
        .sort((a, b) => b.installs - a.installs)
    }
  ]), []);

  const dateBefore = moment().toDate();
  const dateAfter = moment().subtract(3, 'months').toDate();
  const country = uniq(compact(query.get('country[]')?.split(',') || []));

  const [filter, setFilter] = useState({
    dateAfter: query.get('date_after') ? moment(query.get('date_after')).toDate() : dateAfter,
    dateBefore: query.get('date_before') ? moment(query.get('date_before')).toDate() : dateBefore,
    groupBy: query.get('group_by') || 'week',
    country
  });

  const updateLocation = () => {
    history.push({
      search: '?' + compact([
        filter.groupBy && `groupBy=${filter.groupBy}`,
        filter.dateAfter && `date_after=${moment(filter.dateAfter)?.format('YYYY-MM-DD')}`,
        filter.dateBefore && `date_before=${moment(filter.dateBefore)?.format('YYYY-MM-DD')}`,
        filter.country && `country[]=${filter.country?.join(',')}`,
      ]).join('&')
    });
  };

  const handleChange = (event, value) => {
    setFilter(prev => ({
      ...prev,
      country: reduce(uniq(value), (acc, item) => ([...acc, item.value]), [])
    }));
  };


  const handleChangeData = ({startDate, endDate}) => {
    setFilter(prev => ({
      ...prev,
      dateAfter: startDate,
      dateBefore: endDate,
    }));
  };

  const handleGroupBy = event => {
    setFilter(prev => ({
      ...prev,
      groupBy: event.target.value,
    }));
  };

  useEffect(() => {
    if (filter.dateAfter && filter.dateBefore && projectId && !isOpenCountries) {
      const { country, ...otherFilterProps } = filter;
      dispatch({
        type: 'calendarDash/getData',
        payload: {
          projectId,
          ...otherFilterProps,
          dateAfter: moment(filter.dateAfter)?.format('YYYY-MM-DD'),
          dateBefore: moment(filter.dateBefore)?.format('YYYY-MM-DD'),
          ...(compact(country)?.length > 0 && { country: compact(country) })
        }
      });
    }
    // eslint-disable-next-line
  }, [active, filter, projectId, isOpenCountries]);

  // useEffect(() => {
  //   const country = compact(query.get('country[]')?.split(',') || []);
  //   const _countries = reduce(uniq(country), (acc, value) => ([
  //     ...acc,
  //     {
  //       value
  //     }
  //   ]), []);
  //
  //   setCountries(_countries)
  // }, []);

  // eslint-disable-next-line
  useEffect(updateLocation, [filter]);

  return (
    <Box>
      <Stack direction="row" component={Box} mb={2} spacing={1} alignItems="center">
        <Stack>
          <Typography>Period</Typography>
          <BasicDateRangePicker
            dateStart={filter.dateAfter}
            dateEnd={filter.dateBefore}
            onChange={handleChangeData}
          />
        </Stack>

        <Stack>
          <Typography>Group by</Typography>
          <Select size="small" defaultValue={filter.groupBy} onChange={handleGroupBy}>
            <MenuItem value="day">Day</MenuItem>
            <MenuItem value="week">Week</MenuItem>
            <MenuItem value="month">Month</MenuItem>
            <MenuItem value="line">Line</MenuItem>
          </Select>
        </Stack>

        <Stack>
          <Typography>Country</Typography>
          <Autocomplete
            size="small"
            disableClearable
            disableCloseOnSelect
            multiple
            fullWidth
            disabled={countriesPending}
            onChange={handleChange}
            filterSelectedOptions
            getOptionSelected={(option, value) => option.value === value}
            getOptionValue={(option) => option.value}
            value={reduce(country, (acc, item) => ([...acc, { value: item }]), [])}
            onOpen={() => setIsOpenCountries(prev => !prev)}
            onClose={() => setIsOpenCountries(prev => !prev)}
            options={
              (calculatorData?.countries || [])
                .sort((a, b) => {
                  if (b.value === '??' || b.value === '*') return -1;
                  if (a.value === '??' || a.value === '*') return 1;
                  return -b?.name?.localeCompare(a?.name)
                })
            }
            sx={{width: 200}}
            PopperComponent={PopperMy}
            renderOption={(props, option, { selected }) => {
              return <Stack direction='row' spacing={0.5} {...props} component={Box} px={1} py={0.5}>
                <CountryFlag countryCode={option.value} style={{fontSize: '1em'}}/>
                <Typography>{option.name}</Typography>
              </Stack>
            }}
            getOptionLabel={(option) => option?.name}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                fullWidth
                InputProps={{
                  ...params.InputProps
                }}
                sx={{
                  overflow: 'hidden',
                  flexFlow: 'nowrap',
                  textOverflow: 'ellipsis'
                }}
                {...(countriesPending && {
                  placeholder: 'Loading...'
                })}
              />
            )}
            limitTags={1}
            getLimitTagsText={more => `+${more}`}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Tooltip title={option.name || option.value}>
                  <Chip
                    size="small"
                    variant="outlined"
                    sx={{
                      alignItems: 'center',
                      display: 'flex'
                    }}
                    label={
                      <CountryFlag countryCode={option.value} style={{fontSize: '1em'}}/>
                    }
                    {...getTagProps({index})} />
                </Tooltip>
              ))
            }
          />
        </Stack>

        {pending && <Box pt={3}>Loading...</Box>}
      </Stack>

      <AutoSizer style={{padding: theme.spacing(3, 0)}}>
        {({width}) => (
          <Table fixed
                 style={{
                   position: 'absolute',
                 }}
                 ignoreFunctionInColumnCompare={true}
                 disabled={pending}
                 expandColumnKey='date'
                 width={width}
                 height={height - 200}
                 rowHeight={35}
                 overlayRenderer={
                   pending && (
                     <Box
                       width='100%'
                       height='100%'
                       display='flex'
                       justifyContent='center'
                       alignItems='center'
                       sx={{
                         ...(pending && {bgcolor: 'rgba(0,0,0,0.44)'})
                       }}
                     >
                       <CircularProgress color="inherit"/>
                     </Box>
                   )
                 }
                 data={pending ? []: tableData}
                 components={component}
          >
            {columns.map((column, index) => (
              <Column
                key={`${column.dataKey}-${index}`}
                {...column}
                filter={filter}
              />
            ))}
          </Table>
        )}
      </AutoSizer>
    </Box>
  )
};

export default CalendarDashPage;