import React from 'react';
import {VariableSizeGrid} from 'react-window';
import {
    COLUMN_WIDTH,
    HEADER_HEIGHT,
    PROFIT_COLUMNS_COUNT,
    SPEND_ALIASES,
    BORDER_SIZE,
    ROW_HEIGHT,
    WEEK_ROWS_STYLE,
    PROFIT_HEADER_COLUMNS, PROFIT_SECTION_COLUMNS_COUNT, PROFIT_TRIAL_SECTION_COLUMNS_COUNT, COLUMN_TIPS
} from "../Report";
import {makeStyles} from "@material-ui/styles";
import Box from "@material-ui/core/Box";
import clsx from "clsx";
import {Button} from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import memoizeOne from "memoize-one";
import Tooltip from "@material-ui/core/Tooltip";


export const styles =  makeStyles( theme => ({
    root:{
        backgroundColor: '#BAF0ED',
    },
    spendBGColor:{
        backgroundColor: '#F9ECCA',
    },
    profitRowCell:{
        backgroundColor: '#F0F9FC',
    },
    profitHeaderBG:{
        backgroundColor: '#BAF0ED',
    },
    revenueProfitHeaderBG:{
        backgroundColor: '#F0F9FC',
    },
    predictiveBGColor:{
        background: '#E6F3F7',
    },
    main:{
        overflowX: 'hidden',
        overflowY: 'hidden',
    },
    borderRight:{
        borderRight: `${BORDER_SIZE}px solid #c0c0c063`,
    },
    borderBottom:{
        borderBottom: `${BORDER_SIZE}px solid #c0c0c063`,
    },
    spendCells: {
        '&>div.MuiBox-root' : {
            ...WEEK_ROWS_STYLE,
            ...{
                fontWeight: 600,
                fontSize:13
            }
        }
    },
    divider:{
        backgroundColor: '#fff',
        position: 'absolute',
        width: 'inherit',
        top: '50%'
    },
    spendBtn:{
        '& > * > span.MuiButton-startIcon, span.MuiButton-endIcon':{
            marginLeft: 0,
            marginRight: 0,
            '&>svg.MuiSvgIcon-root': {
                fill: '#ffffff5c'
            }
        },
        paddingLeft: 1,
        paddingRight: 1,
    },
    conversionSubscriptionBGColor:{
        backgroundColor: '#BAECCD',
        fontWeight:600,
    },
    spendSubHeader:{
        fontSize: 13,
        fontWeight: 600,
    },
    paymentBGColor: {
        backgroundColor: '#BAF0ED',
        fontWeight:600,
    },
    subscriptionBGColor: {
        backgroundColor: '#D2EFDD'
    },
    trialSubHeaderSection:{
        backgroundColor: '#A4E3E0',
        fontWeight: 'bold',
        fontSize: 11,
    },
    boxSizing:{
        boxSizing: 'border-box',
    }
}));



const HeaderRowCells = React.forwardRef(({spends, reports:{uniquePayments, uniqueSubscriptions, summary}, columnCount, width, countSubscriptionColumns, countPaymentColumns, handleShowHideSpend }, ref) => {

    const classes = styles();

    const widthSubscriptionsHeaderBlock = uniqueSubscriptions.reduce((p, c) => c.columnCount + p, 0) * COLUMN_WIDTH;
    const widthPaymentsHeaderBlock = uniquePayments.reduce((p, c) => c.columnCount + p, 0) * COLUMN_WIDTH;

    const columnWidth = (index) => {
        return getWidthByIndex(index);
    };

    const getWidthByIndex = (index) => {

        let width = COLUMN_WIDTH;
        if (index === 0) width = COLUMN_WIDTH * spends.length - BORDER_SIZE;
        if (index === 1) width = COLUMN_WIDTH * PROFIT_COLUMNS_COUNT - BORDER_SIZE;
        if (index === 2) width = widthPaymentsHeaderBlock + widthSubscriptionsHeaderBlock;

        return width;
    };

    const HeaderSubscriptionProductName = ({product_id, columnCount}) => (
        <Box key={product_id}
             width={COLUMN_WIDTH * (columnCount || 1) - BORDER_SIZE}
             className={clsx(classes.borderRight, classes.spendCells, classes.conversionSubscriptionBGColor)}
        >
            <Box px={0.5} height={40}>{product_id}</Box>
        </Box>
    );

    const HeaderPaymentsProductName = ({product_id}) => (
        <Box key={product_id}
             width={COLUMN_WIDTH - BORDER_SIZE}
             className={clsx(classes.borderRight, classes.spendCells)}
        >
            <Box px={0.5} height={40}>{product_id}</Box>
        </Box>
    );

    const Cell = ({ columnIndex, style}) => {

        let left = 0;
        let backgroundColor = '#F9ECCA';
        const width = getWidthByIndex(columnIndex);

        if (columnIndex === 0) {
            return (
                <Box style={{...style, ...{left, width, backgroundColor}}} className={classes.borderRight} >
                    <Box justifyContent={'center'}
                         display="flex"
                         alignItems="center"
                         className={classes.spendSubHeader}
                         height={ROW_HEIGHT}
                    >
                        Spend, USD

                    </Box>

                    <Divider className={classes.divider} />

                    <Box style={{height: HEADER_HEIGHT - 2 * ROW_HEIGHT}} align="center">
                        <Button variant={'contained'}
                                className={classes.spendBtn}
                                startIcon={spends.length === 1 ? <ArrowLeftIcon/> : <ArrowRightIcon/>}
                                endIcon={spends.length === 1 ? <ArrowRightIcon/> : <ArrowLeftIcon/>}
                                color={'primary'}
                                onClick={handleShowHideSpend}
                        >
                            Show
                        </Button>

                    </Box>

                    <Box display="flex" height={ROW_HEIGHT} className={classes.spendCells}>
                        {spends.map((spendKey) => (
                            <Box key={spendKey} width={COLUMN_WIDTH}>
                                <Box key={spendKey}>{SPEND_ALIASES[spendKey] || 'Not set'}</Box>
                                <Box align="end" justifyContent={'center'}>
                                    $ {summary?.sources?.[spendKey]?.toLocaleString('en-US')}
                                </Box>
                            </Box>
                        ))}
                    </Box>

                </Box>
            )
        }
        else if (columnIndex === 1) {
            left = (getWidthByIndex(columnIndex-1) + BORDER_SIZE);

            return (
                <Box style={{...style, ...{left, width}}} className={classes.borderRight} >
                    <Box justifyContent={'center'}
                         display="flex"
                         alignItems="center"
                         className={clsx(classes.spendSubHeader, classes.profitHeaderBG, classes.borderBottom)}
                         height={ROW_HEIGHT}
                    >
                        Name of table
                    </Box>
                    <Box display={'flex'} className={clsx(classes.profitHeaderBG)}>
                        <Box width={(PROFIT_COLUMNS_COUNT - 7) * COLUMN_WIDTH}>
                            <Box display={'flex'} className={classes.revenueProfitHeaderBG}>
                                {PROFIT_HEADER_COLUMNS.slice(0, PROFIT_SECTION_COLUMNS_COUNT).map((item, i) => {

                                    let className = classes.spendCells;
                                    if ([2, 5, 7].includes(i))
                                        className = clsx(className, classes.borderRight);
                                    if ([3, 4, 5].includes(i))
                                        className = clsx(className, classes.predictiveBGColor);

                                    return (
                                        <Box key={item}
                                             width={COLUMN_WIDTH}
                                             className={className}
                                        >
                                            <Tooltip title={COLUMN_TIPS?.[item]} arrow placement="top-start">
                                                <Box px={0.5}
                                                     height={HEADER_HEIGHT - ROW_HEIGHT - BORDER_SIZE}
                                                >

                                                        {item}

                                                </Box>
                                            </Tooltip>
                                        </Box>
                                    )
                                })}
                            </Box>
                        </Box>

                        <Box width={3 * COLUMN_WIDTH} className={classes.borderRight}>
                            <Box display={'flex'}>
                                {PROFIT_HEADER_COLUMNS.slice(PROFIT_SECTION_COLUMNS_COUNT, PROFIT_COLUMNS_COUNT - PROFIT_TRIAL_SECTION_COLUMNS_COUNT).map((item, i) => (
                                    <Box key={item}
                                         width={COLUMN_WIDTH}
                                         className={classes.spendCells}
                                    >
                                        <Tooltip title={COLUMN_TIPS?.[item]} arrow placement="top-start">
                                            <Box px={0.5}
                                                 height={HEADER_HEIGHT - ROW_HEIGHT - BORDER_SIZE}
                                                 // className={i%2 ? classes.borderRight : ''}
                                                 className={clsx(classes.boxSizing)}
                                            >
                                                {item}
                                            </Box>
                                        </Tooltip>
                                    </Box>
                                ))}
                            </Box>
                        </Box>

                        <Box width={PROFIT_TRIAL_SECTION_COLUMNS_COUNT * COLUMN_WIDTH} className={clsx(classes.boxSizing)}>
                            <Box justifyContent={'center'}
                                 display="flex"
                                 alignItems="center"
                                 height={ROW_HEIGHT}
                                 className={clsx(classes.borderBottom, classes.borderRight, classes.trialSubHeaderSection)}
                            >
                                Trial section
                            </Box>

                            <Box display={'flex'}>
                                {PROFIT_HEADER_COLUMNS.slice(PROFIT_COLUMNS_COUNT - PROFIT_TRIAL_SECTION_COLUMNS_COUNT).map(item => (
                                    <Box key={item}
                                         width={COLUMN_WIDTH}
                                         className={classes.spendCells}
                                    >
                                        <Tooltip title={COLUMN_TIPS?.[item]} arrow placement="top-start">
                                            <Box px={0.5} height={40}>{item}</Box>
                                        </Tooltip>
                                    </Box>
                                ))}
                            </Box>
                        </Box>
                    </Box>
                </Box>
            )
        }
        else if (columnIndex === 2) {
            left = (getWidthByIndex(columnIndex - 2) + getWidthByIndex(columnIndex -1 ) + 2 * BORDER_SIZE);

            return (
                <Box style={{...style, ...{left, width}}} className={classes.borderRight} >
                    <Box justifyContent={'center'}
                         display="flex"
                         alignItems="center"
                         className={clsx(classes.spendSubHeader, classes.subscriptionBGColor, classes.borderBottom, classes.borderRight)}
                         height={ROW_HEIGHT}
                    >
                        Conversions
                    </Box>
                    <Box height={ROW_HEIGHT}
                         display={'flex'}>

                        <Box width={widthSubscriptionsHeaderBlock}
                             className={classes.subscriptionBGColor}
                        >
                            <Box justifyContent={'center'}
                                 display="flex"
                                 alignItems="center"
                                 height={ROW_HEIGHT}
                                 className={clsx(classes.borderRight, classes.borderBottom, classes.conversionSubscriptionBGColor)}
                            >
                                Subscriptions
                            </Box>

                            <Box display="flex" height={ROW_HEIGHT+10} className={clsx(classes.subscriptionBGColor)}>
                                {uniqueSubscriptions.map(HeaderSubscriptionProductName)}
                            </Box>
                        </Box>

                        {uniquePayments.length > 0 &&
                            <Box width={widthPaymentsHeaderBlock}
                                 className={classes.paymentBGColor}>

                                <Box justifyContent={'center'}
                                     display="flex"
                                     alignItems="center"
                                     height={ROW_HEIGHT}
                                     className={clsx(classes.borderBottom, classes.borderRight)}
                                >
                                    Payments
                                </Box>

                                <Box display="flex" height={ROW_HEIGHT + 10} className={clsx(classes.paymentBGColor)}>
                                    {uniquePayments.map(HeaderPaymentsProductName)}
                                </Box>

                            </Box>
                        }

                    </Box>
                </Box>
            )
        }

        return (
            <Box style={{...style, ...{left, width, backgroundColor}}} className={classes.borderRight} >Header {columnIndex}</Box>
        );

    };

    return(
        <VariableSizeGrid
            style={{
                overflowX: 'hidden',
                overflowY: 'hidden'
            }}
            ref={ref}
            columnCount={3}
            rowCount={1}
            columnWidth={memoizeOne(columnWidth)}
            rowHeight={() => HEADER_HEIGHT}
            height={HEADER_HEIGHT}
            width={width}
        >
            {React.memo(Cell)}
        </VariableSizeGrid>
    )
});

export default React.memo(HeaderRowCells);