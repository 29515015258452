import 'react-dates/initialize'
import React, {
    useEffect,
    useState
} from "react";
import {
    Box,
    InputLabel,
    makeStyles,
    OutlinedInput,
    Button,
    Typography,
    Table, TableCell, TableHead, TableBody, TableRow,
    Paper,
    Popover,
    TableContainer, alpha
} from "@material-ui/core";
import theme from "./../theme";
import {KeyboardDatePicker} from "@material-ui/pickers";
import {connect, useSelector} from "react-redux";
import {useQuery} from "../services/helpers";
import { useHistory, useLocation} from "react-router-dom";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import buildQueryString, {getLocaleDateString} from "../services/helpers";
import clsx from "clsx";
import CircularProgress from "@material-ui/core/CircularProgress";
import Divider from "@material-ui/core/Divider";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import CountryFlag from "./CountryFlag";
import ShortReport from "../views/calculator/ShortReport";
import {Skeleton} from "@material-ui/lab";
import ListItemText from "@material-ui/core/ListItemText";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import LinearProgress from "@material-ui/core/LinearProgress";
import Grid from "@material-ui/core/Grid";
import styled from 'styled-components'
import { differenceWith, isNil } from "lodash";
import TextWithIcon from "./TextWithIcon";
import HomeShortReport from "./HomeShortReport";



const CustomCellContainer = styled.div`
    && {
      display: flex;
      &> div:first-child{
        width: 100%;
        flex-direction: column;
        margin: auto 4px;
      }
      &> div:last-child{
        font-size: 10px;
        line-height: 12px;
        &> div.positive {
            color: ${props => props.theme.palette.primary.main};
        }
        & div.negative {
            color: ${props => props.theme.palette.error.main};
        }
      }
    }
`;

const getClassName = value => value < 0 ? 'negative' : 'positive';

const StyledTableCell = ({children, ...other}) => (
    <TableCell {...other}>
        <CustomCellContainer>
            {children}
            {/*<div>{value.toLocaleString('en-US')}</div>*/}
            {/*<div>*/}
            {/*    <div className={percentUp.className}>{percentUp.value}</div>*/}
            {/*    <div>{percentDown}</div>*/}
            {/*</div>*/}
        </CustomCellContainer>
    </TableCell>
);


const useStyles = makeStyles((theme) => ({
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.primary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        border: `1px solid #E0E4EF`,
        boxShadow: `0px 5px 7px ${alpha(theme.palette.black, 0.03)}, 0px 30px 50px rgba(1, 11, 99, 0.05)`,
        borderRadius: 7,
        backgroundColor: theme.palette.secondary.dark,

        '& > .MuiGrid-grid-xs-true': {
            minWidth: 180,
        }
    },
    title: {
        textAlign: 'center',
    },
    submit: {
        minWidth: 192,
    },
    section: {
        padding: `${theme.spacing(1)}px ${theme.spacing(3)}px`,
        display: 'flex',
        alignItems: 'baseline',
        '& > *': {
            padding: 0,
            margin: 0,
        },
    },
    span: {
        flex: 1,
        borderBottom: `2px dotted ${theme.palette.darkGrey}`,
        minWidth: 20,
    },
    total: {
        marginTop: theme.spacing(5),
        boxShadow: '0px 5px 7px rgba(0, 0, 0, 0.03)',
        paddingBottom: theme.spacing(0.5),
        borderRadius: 7,
        '&>div': {
            width: '100%',
            '&>div': {
                background: '#F0F3F9',
                padding: `${theme.spacing(2) + 2}px ${theme.spacing(3) + 2}px ${theme.spacing(2) - 2}px`,
                marginBottom: theme.spacing(0.5),
                [theme.breakpoints.down('md')]: {
                    borderRadius: 7,
                },
            },
            [theme.breakpoints.up('md')]: {
                '&:first-child': {
                    '&>div': {
                        '&:first-child': {
                            borderTopLeftRadius: 7,
                            borderBottomLeftRadius: 7,

                        }
                    }
                },
                '&:last-child': {
                    '&>div': {
                        '&:first-child': {
                            borderTopRightRadius: 7,
                            borderBottomRightRadius: 7,
                        },
                    }
                },
            }

        }
    },
    table: {
        borderRadius: 7,
        '&>thead.MuiTableHead-root': {
            // '&>tr::first-child>th':{
            color: `${theme.palette.black} !important`,
            boxShadow: `0px 5px 7px ${alpha(theme.palette.black, 0.03)}`,
            // }
        }
    },
    odd: {
        backgroundColor: theme.palette.secondary.light,
    },
    even: {
        backgroundColor: theme.palette.white,
    },
    profitCeil: {
        backgroundColor: theme.palette.secondary.dark
    },
    positive: {
        color: theme.palette.primary.main
    },
    negative: {
        color: theme.palette.error.main
    },
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    textAlignEnd: {
        textAlign: 'end'
    },
    textAlignCenter: {
        textAlign: 'center'
    },
    formControl: {
        // margin: theme.spacing(1),
        minWidth: 250,
        maxWidth: 300,
    },
}));


const FILTER_TYPE_ACTUAL     = 'actual';
const FILTER_TYPE_MANUAL     = 'manual';
const FILTER_TYPE_PREDICTIVE = 'predictive';

function FilterForm(props) {
    const classes = useStyles();
    let history   = useHistory();
    let location  = useLocation();
    let query     = useQuery();

    const { getCalculatorHome, getCountries, getCalculator, getCalculatorPredictive } = props;

    const project = useSelector(state => state.project);
    const calculatorhome = useSelector(state => state.calculatorhome);
    const settings = useSelector(state => state.settings);
    const calculator = useSelector(state => state.calculator);

    const [filters, setFilters] = useState({
        projectId:  query.get('project_id') || project.active?.id,
        dateAfter:  calculatorhome.registered.after || query.get('date_after'),
        dateBefore: calculatorhome.registered.before || query.get('date_before'),
        type:       FILTER_TYPE_MANUAL,

        sources:        query.getAll('sources[]') || [],
        countries:      query.getAll('countries[]') || [],
        languages:      query.getAll('languages[]') || [],
        versions_app:   query.getAll('versions_app[]') || [],
        versions_test:  query.getAll('versions_test[]') || [],
    });

    const handleSubmitFilterForm = (event) => {

        event.preventDefault();

        getCalculatorHome({...filters, ...{projectId: project.active?.id}});

        getCalculatorPredictive({...filters, ...{projectId: project.active?.id}});

        history.push(location.pathname + buildQueryString({...filters, ...{projectId: project.active?.id}}));
    };

    const handleChange = (prop, event) => {
        let value = (event?.target?.value) ? event?.target?.value : event;

        if ('SyntheticEvent' === value?.constructor?.name )
            value = '';

        setFilters({
            ...filters,
            [prop]: value
        });
    };

    function quantityGrowth(value) {
        if (value === 0) return ` 0.00`;
        if (value < 0) return value;
        return `+${value}`;
    }

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [anchorElMenu, setAnchorElMenu] = React.useState(null);
    const [info, setInfo] = React.useState([]);

    const handleShowPopover = (info) => (event) => {
        setInfo(info);
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setInfo([]);
        setAnchorEl(null);
    };

    const getColorClass = (value) => {
        if (value < 0) return classes.negative;

        return classes.positive;
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const getInAppValue = (amount_in_app) => {

        if (filters.type === FILTER_TYPE_MANUAL && amount_in_app?.value_manual)
            return Math.round(amount_in_app.value_manual + Number.EPSILON);

        if (filters.type === FILTER_TYPE_PREDICTIVE && amount_in_app?.value_predictive)
            return Math.round(amount_in_app.value_predictive + Number.EPSILON);

        return Math.round(amount_in_app.value + Number.EPSILON);
    };

    const getValues = (amount_in_app, amount_sub_first, amount_sub_total) => {
        const amountInAppValue = getInAppValue(amount_in_app);
        const {growth: growthInAppValue} = amount_in_app;

        const amountInSubFirst = Math.round((amount_sub_first.value + Number.EPSILON));
        const amountInSubFirstTotal = Math.round((amountInAppValue + amountInSubFirst));
        const {growth: growthInSubFirst} = amount_sub_first;

        const amountInSub = getInAppValue(amount_sub_total);
        const amountInSubTotal = Math.round((amountInAppValue + amountInSub));
        const {growth: growthInSub} = amount_sub_total;

        const profitInSubFirst = amountInSubFirstTotal - (calculatorhome.budget || 0);
        const profitInSubTotal = amountInSubTotal - (calculatorhome.budget || 0);

        return {
            amountInAppValue,
            amountInAppValueLocal: amountInAppValue.toLocaleString('en-US'),
            growthInAppValue,

            amountInSubFirst,
            amountInSubFirstLocal: amountInSubFirst.toLocaleString('en-US'),
            growthInSubFirst,

            amountInSubFirstTotal,
            amountInSubFirstTotalLocal: amountInSubFirstTotal.toLocaleString('en-US'),
            growthInSub,

            profitInSubFirst,
            profitInSubFirstLocal: profitInSubFirst.toLocaleString('en-US'),

            amountInSub,
            amountInSubLocal: amountInSub.toLocaleString('en-US'),
            amountInSubTotal,
            amountInSubTotalLocal: amountInSubTotal.toLocaleString('en-US'),
            profitInSubTotal,
            profitInSubTotalLocal: profitInSubTotal.toLocaleString('en-US'),
        };
    };

    useEffect(() => {
        if (project.active !== null && project.pending === false &&
            settings.pending === false && settings.data === null
        ) {
            getCountries({ projectId: project.active.id, order: 'users' });
        }
    }, [project, settings, getCountries]);

    useEffect(() => {
        if (calculator.pending === false && calculator.data === null &&
            calculator.error === null &&
            project?.active?.id && project.pending === false) {

            getCalculator({projectId: project.active.id})
        }
    }, [calculator, project, getCalculator]);

    useEffect(() => {
        if (calculatorhome.pending === false && calculatorhome.data === null &&
            calculatorhome.error === null &&
            project?.active?.id && project.pending === false &&
            filters.dateAfter !== null && filters.dateBefore !== null) {

            getCalculatorHome({...filters, ...{projectId: project.active.id}})
        }
    }, [project, calculatorhome, filters, getCalculatorHome]);


    const handleClickMenu = (event) => {
        setAnchorElMenu(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorElMenu(null);
    };

    const handleCloseType = (value) => {

        setFilters({
            ...filters,
            type: value
        });

        setAnchorElMenu(null);
    };

    const format = getLocaleDateString('ru-RU');

    function totalTableRow() {

        if (calculatorhome.data === null) return null;

        const {amount_in_app, amount_sub_first, amount_sub_total, quantity,
            trials:{
                converted:trialsConverted,
                value:trialsCount,
                growth:trialsGrowth,
                info: trialsInfo
        },
            amount_in_app: {
                info: amountInAppInfo
            }} = calculatorhome?.data?.total;

        const {
            amountInAppValueLocal,
            amountInSubFirstLocal,
            amountInSubFirstTotalLocal,
            profitInSubFirst,
            profitInSubFirstLocal,

            amountInSubLocal,
            amountInSubTotalLocal,

            profitInSubTotal,
            profitInSubTotalLocal,

            growthInAppValue,
            growthInSubFirst,
            growthInSub,

        } = getValues(amount_in_app, amount_sub_first, amount_sub_total);

        return (
            <TableRow>
                <TableCell className={classes.textAlignEnd}>Total</TableCell>

                <StyledTableCell className={classes.textAlignEnd}>
                    <div>{quantity.value.toLocaleString('en-US')}</div>
                    <div>
                        <div className={getColorClass(quantity.growth)}>
                            {quantityGrowth(quantity.growth)}%
                        </div>
                        <div />
                    </div>
                </StyledTableCell>


               <StyledTableCell onClick={handleShowPopover([...trialsInfo, ...amountInAppInfo])}>
                    <div>{trialsCount.toLocaleString('en-US')}</div>
                    <div>
                        <div className={getClassName(trialsGrowth)}>
                            {trialsGrowth}%
                        </div>
                        <div >
                            {trialsCount > 0
                                ? `${Math.ceil(trialsConverted/trialsCount*1000)/10}%`
                                : '0.00%'}
                        </div>
                    </div>
                </StyledTableCell>

                <StyledTableCell
                    className={classes.textAlignEnd}
                    onClick={handleShowPopover(amount_in_app?.info)}
                >
                    <div>$&nbsp;{amountInAppValueLocal.toLocaleString('en-US')}</div>
                    <div>
                        <div className={getColorClass(growthInAppValue)}>
                            {quantityGrowth(growthInAppValue)}%
                        </div>
                        <div />
                    </div>
                </StyledTableCell>

                <StyledTableCell
                    className={classes.textAlignEnd}
                    onClick={handleShowPopover(amount_sub_first?.info)}
                >
                    <div>$&nbsp;{amountInSubFirstLocal.toLocaleString('en-US')}</div>
                    <div>
                        <div className={getColorClass(growthInSubFirst)}>
                            {quantityGrowth(growthInSubFirst)}%
                        </div>
                        <div />
                    </div>
                </StyledTableCell>

                <StyledTableCell className={classes.textAlignEnd}>
                    <div>$&nbsp;{amountInSubFirstTotalLocal.toLocaleString('en-US')}</div>
                    <div>
                        <div className={getColorClass(growthInSub)}>
                            {quantityGrowth(growthInSub)}%
                        </div>
                        <div />
                    </div>
                </StyledTableCell>

                <TableCell
                    className={clsx(getColorClass(profitInSubFirst), classes.textAlignEnd)}
                >
                    $&nbsp;{profitInSubFirstLocal}
                </TableCell>


                <TableCell aria-describedby={id}
                           className={classes.textAlignEnd}
                           onClick={handleShowPopover(amount_in_app?.info)}>$&nbsp;{amountInAppValueLocal}</TableCell>
                <TableCell aria-describedby={id}
                           className={classes.textAlignEnd}
                           onClick={handleShowPopover(amount_sub_total?.info)}>$&nbsp;{amountInSubLocal}</TableCell>
                <TableCell className={classes.textAlignEnd}>$&nbsp;{amountInSubTotalLocal}</TableCell>
                <TableCell
                    className={clsx(getColorClass(profitInSubTotal), classes.textAlignEnd)}>$&nbsp;{profitInSubTotalLocal}</TableCell>

                <TableCell align={'left'} >
                    <Button
                        endIcon={
                            <KeyboardArrowDownIcon/>
                        }
                    >
                        All countries
                    </Button>
                </TableCell>

            </TableRow>
        )
    }


    const trialsInfo = calculatorhome.data?.total?.trials?.info || [];
    const subFirstInfo = calculatorhome.data?.total?.amount_sub_first?.info || [];

    const subFirstInfoExcludeTrials = differenceWith(
        subFirstInfo,
        trialsInfo,
        ({ product_id: subFirstProductId }, { product_id: trialsInfoProductId }) =>
            subFirstProductId === trialsInfoProductId
    );

    return (
        <React.Fragment>

            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Box px={1} py={2} style={{
                    background: '#5A677C',
                    boxShadow: '0px 10px 13px rgba(0, 0, 0, 0.12)',
                    color: '#fff'
                }}>
                    {info
                        ?.sort((a, b) => {
                            if (a.count > b.count) return -1;
                            if (a.count < b.count) return 1;
                            return 0;
                        })
                        ?.map((info) => {

                            const {product_id, count, amount: value, amount_manual:value_manual, amount_predictive:value_predictive} = info;
                            const val = getInAppValue({value, value_manual, value_predictive});

                            return (
                                <section key={product_id} className={classes.section}>
                                <Typography component={'div'} variant={'body2'}
                                            color={'inherit'}>{product_id}</Typography>
                                <span className={classes.span}/>
                                <Typography component={'h3'} color={'inherit'}>{count} ($&nbsp;{val.toLocaleString('en-US')})</Typography>
                            </section>)
                        })}
                </Box>

            </Popover>

            {/*<CssBaseline />*/}
            <div className={classes.paper}>
                <Box display="flex" style={{width: '100%'}} pb={1}>
                    <Box m={1} flexGrow={1}>
                        <Typography component={'h3'} variant={'h3'} color={'inherit'}>
                            {project?.active?.name}
                            {!project?.active?.name && <Skeleton width={90} height={45}  animation={'wave'} variant={'text'} />}
                        </Typography>
                    </Box>

                    <Box m={1} textAlign={'end'}>
                        <Typography component={'a'}
                                    color="primary"
                                    styleabtests={{
                                        borderBottom: `2.2px dotted ${theme.palette.primary.main}`,
                                    }}>
                            Advanced settings
                        </Typography>
                    </Box>
                </Box>
                <form method={'GET'} noValidate onSubmit={handleSubmitFilterForm} className={classes.form}>
                    <Grid container spacing={3}>

                        <Grid item xs className={classes.formControl}>
                            <InputLabel htmlFor='startDate'>
                                Start Date
                            </InputLabel>

                            <KeyboardDatePicker
                                id={'startDate'}
                                autoOk
                                variant="inline"
                                inputVariant="outlined"
                                format={format}
                                name={'dateAfter'}
                                value={filters.dateAfter}
                                InputAdornmentProps={{position: "end"}}
                                onChange={handleChange.bind(this, 'dateAfter')}
                            />
                        </Grid>

                        <Grid item xs className={classes.formControl}>
                            <InputLabel htmlFor='endDate'>
                                End Date
                            </InputLabel>

                            <KeyboardDatePicker
                                id={'endDate'}
                                autoOk
                                // open={isOpen}
                                variant="inline"
                                inputVariant="outlined"
                                format={format}
                                name={'dateBefore'}
                                value={filters.dateBefore}
                                InputAdornmentProps={{position: "end"}}
                                onChange={handleChange.bind(this, 'dateBefore')}
                            />

                        </Grid>

                        <Grid item xs className={classes.formControl}>
                            <InputLabel htmlFor='sources-label' component={'span'}>
                                Source:
                            </InputLabel>

                            <Select labelId="sources-label"
                                    id="sources"
                                    value={filters?.sources || []}
                                    variant={"outlined"}
                                    multiple
                                    input={
                                        <OutlinedInput fullWidth disabled={calculator.pending}/>
                                    }
                                    inputProps={{
                                        name: 'sources'
                                    }}
                                    renderValue={(selected) => selected.join(', ')}
                                    onChange={handleChange.bind(this, 'sources')}
                            >
                                {calculator.data?.sources?.map(item =>
                                    <MenuItem key={item.name} value={item.value}>
                                        <Checkbox checked={filters?.sources?.indexOf(item.value) > -1} />
                                        <ListItemText primary={item.name || '*'} secondary={`Users: ${item.count}`}  />
                                    </MenuItem>
                                )}
                            </Select>
                            {calculator.pending && <LinearProgress />}
                        </Grid>

                        <Grid item xs className={classes.formControl}>
                            <InputLabel htmlFor='versions_app-label' component={'span'}>
                                Version&nbsp;App:
                            </InputLabel>

                            <Select labelId="versions_app-label"
                                    id="versions_app"
                                    value={filters?.versions_app || []}
                                    variant={"outlined"}
                                    multiple
                                    input={
                                        <OutlinedInput fullWidth disabled={calculator.pending}/>
                                    }
                                    inputProps={{
                                        name: 'versions_app'
                                    }}
                                    renderValue={(selected) => selected.join(', ')}
                                    onChange={handleChange.bind(this, 'versions_app')}
                            >
                                {calculator.data?.versions_app?.map(item =>
                                    <MenuItem key={item.name} value={item.value} >
                                        <Checkbox checked={filters?.versions_app?.indexOf(item.value) > -1} />
                                        <ListItemText primary={item.name || '*'} secondary={`Users: ${item.count}`}  />
                                    </MenuItem>
                                )}
                            </Select>
                            {calculator.pending && <LinearProgress />}
                        </Grid>


                        <Grid item xs className={classes.formControl}>
                            <InputLabel htmlFor='versions_test-label' component={'span'}>
                                Version,&nbsp;A/B:
                            </InputLabel>

                            <Select labelId="versions_test-label"
                                    id="versions_test"
                                    value={filters?.versions_test}
                                    variant={"outlined"}
                                    multiple
                                    input={
                                        <OutlinedInput fullWidth disabled={calculator.pending}/>
                                    }
                                    inputProps={{
                                        name: 'versions_test'
                                    }}
                                    renderValue={(selected) => selected.join(', ')}
                                    onChange={handleChange.bind(this,'versions_test')}
                            >
                                {calculator.data?.versions_test?.map(item =>
                                    <MenuItem key={item.name} value={item.value}>
                                        <Checkbox checked={filters?.versions_test?.indexOf(item.value) > -1} />
                                        <ListItemText primary={item.name || '*'} secondary={`Users: ${item.count}`}  />
                                    </MenuItem>
                                )}
                            </Select>
                            {calculator.pending && <LinearProgress />}
                        </Grid>

                        <Grid item xs className={classes.formControl}>
                            <InputLabel htmlFor='countries-label' component={'span'}>
                                Country:
                            </InputLabel>

                            <Select labelId="countries-label"
                                    id="countries"
                                    value={filters?.countries}
                                    variant={"outlined"}
                                    multiple
                                    input={
                                        <OutlinedInput fullWidth disabled={calculator.pending}/>
                                    }
                                    inputProps={{
                                        name: 'countries'
                                    }}
                                    onChange={handleChange.bind(this,'countries')}
                                    renderValue={(selected) => selected.join(', ')}
                            >
                                <MenuItem value={''}>All</MenuItem>
                                {calculator.data?.countries?.map(item =>
                                    <MenuItem key={item.name} value={item.value}>
                                        <CountryFlag countryCode={item.value} style={{fontSize:'2em',paddingRight:8}} />
                                        <Checkbox checked={filters?.countries?.indexOf(item.value) > -1} />
                                        <ListItemText primary={item.name || '*'} secondary={`Users: ${item.count}`}  />
                                    </MenuItem>
                                )}
                            </Select>
                            {calculator.pending && <LinearProgress />}
                        </Grid>

                        <Grid item xs className={classes.formControl}>
                            <InputLabel htmlFor='languages-label' component={'span'}>
                                Language:
                            </InputLabel>

                            <Select labelId="languages-label"
                                    id="languages"
                                    value={filters?.languages}
                                    variant={"outlined"}
                                    input={
                                        <OutlinedInput fullWidth disabled={calculator.pending}/>
                                    }
                                    multiple
                                    inputProps={{
                                        name: 'languages'
                                    }}
                                    onChange={handleChange.bind(this, 'languages')}
                                    renderValue={(selected) => selected.join(', ')}
                            >
                                {calculator.data?.languages?.map(item =>
                                    <MenuItem key={item.name} value={item.value}>
                                        <Checkbox checked={filters?.languages?.indexOf(item.value) > -1} />
                                        <ListItemText primary={item.name || '*'} secondary={`Users: ${item.count}`}  />
                                    </MenuItem>
                                )}
                            </Select>
                            {calculator.pending && <LinearProgress />}
                        </Grid>

                    </Grid>

                    <Grid container spacing={3}>
                        <Grid item xs className={classes.formControl}>
                            <Button className={classes.submit}
                                    color={'primary'}
                                    type={'submit'}
                                    disabled={!(project.active?.id > 0)}
                                    variant={'contained'}>Apply</Button>
                        </Grid>
                    </Grid>

                </form>
            </div>

            <ShortReport {...props} filters={filters}/>

            <HomeShortReport
                project_id={project.active?.id}
                registered_after={filters.dateAfter}
                registered_before={filters.dateBefore} />


            <Box display={{xs: 'block', sm: 'block', md: 'flex', lg: 'flex'}}
                 flexDirection="row"
                 className={classes.total}
            >
                {calculatorhome.pending && <CircularProgress className={classes.buttonProgress}/>}

                <Box>
                    <Box>
                        <Typography component={'h4'} variant={'h4'} color={'inherit'}>
                            Total
                        </Typography>
                    </Box>

                    {calculatorhome.data?.count && <React.Fragment>

                        {['users', 'payments_in_app', 'payments_sub_first', 'payments_sub_total'].map(field => {

                                if (!calculatorhome.data?.count?.[field]) return null;

                                return (
                                    <section key={field} className={classes.section} style={{textTransform: 'capitalize',}}>
                                        <Typography component={'div'} variant={'body2'}>{field}</Typography>
                                        <span className={classes.span}/>
                                        <h4>{calculatorhome.data?.count?.[field]}</h4>
                                    </section>)
                            }
                        )}

                    </React.Fragment>}
                </Box>

                <Box>
                    <TextWithIcon>
                        <Typography component={'h4'} variant={'h4'} color={'inherit'}>Subsriptions</Typography>
                    </TextWithIcon>

                    {[...trialsInfo, ...subFirstInfoExcludeTrials]
                        ?.sort((a, b) => {
                            if (a.count < b.count) return 1;
                            if (a.count > b.count) return -1;
                            return 0;
                        })
                        ?.map(({product_id, count, converted = null}) =>
                            <section key={product_id} className={classes.section}>
                                <Typography component={'div'} variant={'body2'}>{product_id}</Typography>
                                <span className={classes.span}/>
                                <h4>{isNil(converted) ? count : `${count}/${converted * 1}`}</h4>
                            </section>
                        )}
                </Box>

                <Box>
                    <Box>
                        <Typography component={'h4'} variant={'h4'} color={'inherit'}>
                            Purchases
                        </Typography>
                    </Box>
                    {calculatorhome.data?.summary?.payments_in_app
                        .sort((a, b) => {
                            if (a.count < b.count) return 1;
                            if (a.count > b.count) return -1;
                            return 0;
                        })
                        .map(({product_id, count}) =>
                            <section key={product_id} className={classes.section}>
                                <Typography component={'div'} variant={'body2'}>{product_id}</Typography>
                                <span className={classes.span}/>
                                <h4>{count}</h4>
                            </section>
                        )}
                </Box>
            </Box>


            <TableContainer component={Box} mt={2}
                            style={{borderRadius: 7, boxShadow: '0px 5px 7px rgba(0, 0, 0, 0.03)'}}>
                <Table className={classes.table} size={'small'}>
                    <Paper component={TableHead}>
                        <TableRow>
                            <TableCell colSpan={3}/>
                            <TableCell className={classes.textAlignCenter} colSpan={4} style={{paddingBottom: 0}}>
                                Revenue, USD
                                <Divider style={{marginTop: 16}} variant="fullWidth" component="div"/>
                            </TableCell>
                            <TableCell className={classes.textAlignCenter} colSpan={4} style={{paddingBottom: 0}}>
                                <Box display={'flex'}>
                                    <Box flexGrow={1} >With the extension, USD</Box>
                                    <Box textAlign={'end'}>
                                        <Button component={'a'}
                                                color="inherit"
                                                endIcon={<ArrowDropDownIcon />}
                                                aria-controls="simple-menu"
                                                aria-haspopup="true"
                                                onClick={handleClickMenu}
                                                style={{
                                                    padding:0,
                                                    textTransform: 'capitalize',
                                                    borderBottom: `2.2px dotted ${theme.palette.darkGrey}`,
                                                }}>
                                            {filters.type}
                                        </Button>

                                        <Menu
                                            MenuListProps={{
                                                name: 'type',
                                                style:{
                                                    backgroundColor: '#5A677C',
                                                }
                                            }}
                                            id="simple-menu"
                                            anchorEl={anchorElMenu}
                                            keepMounted
                                            open={Boolean(anchorElMenu)}
                                            onClose={handleCloseMenu}
                                        >
                                            <MenuItem style={{color: theme.palette.getContrastText('#5A677C')}}
                                                      selected={FILTER_TYPE_ACTUAL === filters.type}
                                                      onClick={handleCloseType.bind(this, FILTER_TYPE_ACTUAL)}>actual</MenuItem>
                                            <MenuItem style={{color: theme.palette.getContrastText('#5A677C')}}
                                                      selected={FILTER_TYPE_MANUAL === filters.type}
                                                      onClick={handleCloseType.bind(this, FILTER_TYPE_MANUAL)}>manual</MenuItem>
                                            <MenuItem style={{color: theme.palette.getContrastText('#5A677C')}}
                                                      selected={FILTER_TYPE_PREDICTIVE === filters.type}
                                                      onClick={handleCloseType.bind(this, FILTER_TYPE_PREDICTIVE)}>predictive</MenuItem>
                                        </Menu>
                                    </Box>
                                </Box>
                                <Divider style={{marginTop: 16}} variant="fullWidth" component="div"/></TableCell>
                            <TableCell/>
                        </TableRow>

                        <TableRow>
                            <TableCell className={classes.textAlignEnd}>Day</TableCell>
                            <TableCell className={classes.textAlignEnd}>Qnt</TableCell>
                            <TableCell className={classes.textAlignEnd}>Trials</TableCell>
                            <TableCell className={classes.textAlignEnd}>In-app</TableCell>
                            <TableCell className={classes.textAlignEnd}>Subscription</TableCell>
                            <TableCell className={classes.textAlignEnd}>Total</TableCell>
                            <TableCell className={classes.textAlignEnd}>Profit</TableCell>

                            <TableCell className={classes.textAlignEnd}>In-app</TableCell>
                            <TableCell className={classes.textAlignEnd}>Subscription</TableCell>
                            <TableCell className={classes.textAlignEnd}>Total</TableCell>
                            <TableCell className={classes.textAlignEnd}>Profit</TableCell>

                            <TableCell align={'left'} >Country</TableCell>

                        </TableRow>

                        {totalTableRow()}

                    </Paper>

                    <TableBody>

                        {calculatorhome.data?.days.map(({
                                                                  day, quantity, amount_in_app, amount_sub_first, amount_sub_total,
                                                                  trials:{
                                                                      converted:trialsConverted,
                                                                      value:trialsCount,
                                                                      growth:trialsGrowth,
                                                                      info: trialsInfo
                                                                  }, amount_in_app: {
                                                                      info: amountInAppInfo
                            }}, index) => {

                            const {
                                amountInAppValueLocal,
                                amountInSubFirstLocal,
                                // amountInSubFirstTotalLocal,
                                profitInSubFirst,
                                profitInSubFirstLocal,

                                amountInSubLocal,
                                amountInSubTotalLocal,

                                profitInSubTotal,
                                profitInSubTotalLocal,

                                growthInAppValue,
                                growthInSubFirst,
                                growthInSub,

                            } = getValues(amount_in_app, amount_sub_first, amount_sub_total);


                            return <TableRow key={index} className={index % 2 ? classes.even : classes.odd}>
                                <TableCell>{day}</TableCell>

                                <StyledTableCell>
                                    <div>{quantity.value.toLocaleString('en-US')}</div>
                                    <div>
                                        <div className={getColorClass(quantity.growth)}>
                                            {quantityGrowth(quantity.growth)}%
                                        </div>
                                    </div>
                                </StyledTableCell>

                                <StyledTableCell onClick={handleShowPopover([...trialsInfo, ...amountInAppInfo])}>
                                    <div>{trialsCount.toLocaleString('en-US')}</div>
                                    <div>
                                        <div className={getClassName(trialsGrowth)}>
                                            {trialsGrowth}%
                                        </div>
                                        <div >
                                            {trialsCount > 0
                                                ? `${Math.ceil(trialsConverted/trialsCount*1000)/10}%`
                                                : '0.00%'}
                                        </div>
                                    </div>
                                </StyledTableCell>

                                <StyledTableCell>
                                    <div>$&nbsp;{amountInAppValueLocal.toLocaleString('en-US')}</div>
                                    <div>
                                        <div className={getColorClass(growthInAppValue)}>
                                            {quantityGrowth(growthInAppValue)}%
                                        </div>
                                        <div />
                                    </div>
                                </StyledTableCell>

                                <StyledTableCell onClick={handleShowPopover(amount_sub_first?.info)}>
                                    <div>$&nbsp;{amountInSubFirstLocal.toLocaleString('en-US')}</div>
                                    <div>
                                        <div className={ getColorClass(growthInSubFirst)}>
                                            {quantityGrowth(growthInSubFirst)}%
                                        </div>
                                        <div/>
                                    </div>
                                </StyledTableCell>

                                <StyledTableCell onClick={handleShowPopover(amount_sub_first?.info)}>
                                    <div>$&nbsp;{amountInSubFirstLocal.toLocaleString('en-US')}</div>
                                    <div>
                                        <div className={getColorClass(growthInSub)}>
                                            {quantityGrowth(growthInSub)}%
                                        </div>
                                        <div/>
                                    </div>
                                </StyledTableCell>

                                <TableCell className={clsx(classes.profitCeil, getColorClass(profitInSubFirst))}>
                                    $&nbsp;{profitInSubFirstLocal}
                                </TableCell>

                                <TableCell aria-describedby={id}
                                           onClick={handleShowPopover(amount_in_app?.info)}>$&nbsp;{amountInAppValueLocal}</TableCell>
                                <TableCell aria-describedby={id}
                                           onClick={handleShowPopover(amount_sub_total?.info)}>$&nbsp;{amountInSubLocal}</TableCell>
                                <TableCell>$&nbsp;{amountInSubTotalLocal}</TableCell>
                                <TableCell className={clsx(classes.profitCeil, getColorClass(profitInSubTotal))}>
                                    $&nbsp;{profitInSubTotalLocal}
                                </TableCell>

                                <TableCell align={'left'}>
                                    <Button
                                        endIcon={
                                            <KeyboardArrowDownIcon/>
                                        }
                                    >
                                        All countries
                                    </Button>
                                </TableCell>
                            </TableRow>
                        })}
                    </TableBody>

                </Table>
            </TableContainer>
        </React.Fragment>
    )
}

const mapDispatch = ({
                         calculatorhome: {
                             getCalculatorHome
                         },
                         calculatorpredictive: {
                             getCalculatorPredictive
                         },
                         settings: {
                             getCountries,
                         },
                         calculator: {
                             getCalculator,
                         }
                     }) => ({
    getCalculatorHome:       (...data) => getCalculatorHome(...data),
    getCountries:            (...data) => getCountries(...data),
    getCalculator:           (...data) => getCalculator(...data),
    getCalculatorPredictive: (...data) => getCalculatorPredictive(...data),
});

export default connect(null, mapDispatch)(FilterForm);