import {round, get} from "lodash";
import React from "react";
import Tooltip from "@mui/material/Tooltip";
import moment from "moment";
import {Column} from "../../../components/table";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CountryFlag from "../../../components/CountryFlag";

export default [
  {
    align: Column.Alignment.LEFT,
    dataKey: 'date',
    key: 'date',
    title: 'Date',
    width: 170,
    frozen: Column.FrozenDirection.LEFT,
    cellRenderer: ({rowData, column: {filter}}) => {
      if (rowData?.countryCode)
        return (
          <>
            {rowData?.countryCode && <CountryFlag countryCode={rowData?.countryCode} style={{ fontSize:'2em',paddingRight:8 }} />}
            {rowData?.countryCode?.toUpperCase()}
          </>
        );

      const startDate = moment(get(rowData, 'period.start'), 'DD.MM.YYYY HH:mm:ss');
      const endDate = moment(get(rowData, 'period.finish'), 'DD.MM.YYYY HH:mm:ss');
      return (
        <Tooltip
          title={
            filter?.groupBy === 'day'
              ? startDate.format('DD-MM-YYYY')
              : [startDate.format('DD-MM-YYYY'), endDate.format('DD-MM-YYYY')].join(' to ')
          }
          placement="top"
        >
          <span>
            {filter?.groupBy === 'month' && startDate.format('MMMM YYYY')}
            {filter?.groupBy === 'week' && `Week ${startDate.format('W YYYY')}`}
            {filter?.groupBy === 'day' && startDate.format('DD MMMM YYYY')}
          </span>
        </Tooltip>
      )
    }
  },
  // subscription.id
  {
    align: Column.Alignment.RIGHT,
    frozen: Column.FrozenDirection.LEFT,
    dataKey: 'subscription.id',
    key: 'subscription.id',
    title: 'SubscriptionId',
    width: 270,
    cellRenderer: ({cellData, rowData}) => {
      const alias = get(rowData, 'subscription.alias', null);

      return (
        <Tooltip title={
          <Stack>
            <Box>{alias || cellData}</Box>
            <Box>Price: {get(rowData, 'subscription.price')}</Box>
            <Box>Period: {get(rowData, 'subscription.period')}</Box>
            <Box>Trial type: {get(rowData, 'subscription.trialType')}</Box>
            <Box></Box>
          </Stack>
        }
        >
          <span>{cellData}</span>
        </Tooltip>
      )
    }
  },

  {
    align: Column.Alignment.RIGHT,
    dataKey: 'installs.2anySubscription',
    key: 'installs.2anySubscription',
    title: 'To any subscription',
    width: 100,
    cellRenderer: ({cellData}) => cellData
  },
  {
    align: Column.Alignment.RIGHT,
    dataKey: 'installs.2paidSubscription',
    key: 'installs.2paidSubscription',
    title: 'To paid subscription',
    width: 100,
    cellRenderer: ({cellData}) => cellData
  },
  {
    align: Column.Alignment.RIGHT,
    dataKey: 'installs.count',
    key: 'installs.count',
    title: 'Installs',
    width: 70,
    cellRenderer: ({cellData}) => cellData
  },

  //subscription.price
  {
    align: Column.Alignment.RIGHT,
    dataKey: 'subscription.price',
    key: 'subscription.price',
    title: 'Price',
    width: 100,
    cellRenderer: ({cellData}) => cellData && `$ ${cellData?.toLocaleString('en-US')}`
  },
  // subscription.period, Duration
  {
    align: Column.Alignment.RIGHT,
    dataKey: 'subscription.period',
    key: 'subscription.period',
    title: 'Duration',
    width: 100
  },
  // Subscription type
  {
    align: Column.Alignment.RIGHT,
    dataKey: 'subscription.trialType',
    key: 'subscription.trialType',
    title: 'Subscription type',
    width: 100
  },
  // Trial duration
  {
    align: Column.Alignment.RIGHT,
    dataKey: 'subscription.trialPeriod',
    key: 'subscription.trialPeriod',
    title: 'Trial duration',
    width: 100,
    cellRenderer: ({cellData}) => cellData > 0 ? cellData : ''
  },
  // subscription.count
  {
    align: Column.Alignment.RIGHT,
    dataKey: 'count',
    key: 'count',
    title: (
      <Stack direction="row" spacing={1} alignItems="center">
        <Box component="span">Trials <br/>или кол-во подписок</Box>
        <Tooltip
          arrow
          title="Все триалы, которые сделали по этой подписки юзеры, которые сделали first open в данный рендж или кол-во подписок"
          placement="top"
        >
          <InfoOutlinedIcon fontSize="small" sx={{'&:hover': {cursor: 'pointer'}}}/>
        </Tooltip>
      </Stack>
    ),
    width: 120,
    cellRenderer: ({cellData}) =>
      cellData > 0
        ? cellData?.toLocaleString('en-US')
        : ''
  },
  // Payers (trials convert count)
  {
    align: Column.Alignment.RIGHT,
    dataKey: 'trials.payers',
    key: 'trials.payers',
    title: (
      <Stack direction="row" spacing={1} alignItems="center">
        <Box component="span">Payers (trials convert count)</Box>
        <Tooltip
          arrow
          title="Кол-во всех юзеров, которые конвертнулись в платеж"
          placement="top"
        >
          <InfoOutlinedIcon fontSize="small" sx={{'&:hover': {cursor: 'pointer'}}}/>
        </Tooltip>
      </Stack>
    ),
    width: 120,
    cellRenderer: ({cellData}) => cellData > 0 ? cellData : ''
  },
  // pCVR
  {
    align: Column.Alignment.RIGHT,
    dataKey: 'trials.pCVR',
    key: 'trials.pCVR',
    title: (
      <Stack direction="row" spacing={1} alignItems="center">
        <Box component="span">pCVR</Box>
        <Tooltip
          arrow
          title="Конверсия из триала в покупку"
          placement="top"
        >
          <InfoOutlinedIcon fontSize="small" sx={{'&:hover': {cursor: 'pointer'}}}/>
        </Tooltip>
      </Stack>
    ),
    width: 120,
    cellRenderer: ({cellData}) => cellData > 0 ? cellData : ''
  },
  // subscription.renewable
  {
    align: Column.Alignment.RIGHT,
    dataKey: 'subscription.renewable',
    key: 'subscription.renewable',
    title: (
      <Stack direction="row" spacing={1} alignItems="center">
        <Box component="span">Active Subscribers</Box>
        <Tooltip
          arrow
          title="Кол-во юзеров у которых активно автопродление"
          placement="top"
        >
          <InfoOutlinedIcon fontSize="small" sx={{'&:hover': {cursor: 'pointer'}}}/>
        </Tooltip>
      </Stack>
    ),
    width: 120,
    cellRenderer: ({cellData}) => cellData > 0 ? cellData : ''
  },
  // trials.tCVR
  {
    align: Column.Alignment.RIGHT,
    dataKey: 'trials.tCVR',
    key: 'trials.tCVR',
    title: (
      <Stack direction="row" spacing={1} alignItems="center">
        <Box component="span">tCVR</Box>
        <Tooltip
          arrow
          title="Trials / first open за этот период"
          placement="top"
        >
          <InfoOutlinedIcon fontSize="small" sx={{'&:hover': {cursor: 'pointer'}}}/>
        </Tooltip>
      </Stack>
    ),
    width: 120,
    cellRenderer: ({cellData}) => cellData > 0 ? cellData : ''
  },
  // 2nd pCVR - 1
  // 2nd pCVR - 2
  // 2nd pCVR - 3

  // In billing retry subscribers (trial)
  {
    align: Column.Alignment.RIGHT,
    dataKey: 'billingRetry.trial',
    key: 'billingRetry.trial',
    title: (
      <Stack direction="row" spacing={1} alignItems="center">
        <Box component="span">
          In billing retry subscribers
        </Box>
        <Tooltip
          arrow
          title="Количество юзеров на данный момент, которые в биллинге между триалом и платежом"
          placement="top"
        >
          <InfoOutlinedIcon fontSize="small" sx={{'&:hover': {cursor: 'pointer'}}}/>
        </Tooltip>
      </Stack>
    ),
    width: 120,
    cellRenderer: ({cellData}) => cellData > 0 ? cellData : ''
  },
  // In billing retry subscribers (purchase)
  {
    align: Column.Alignment.RIGHT,
    dataKey: 'billingRetry.purchase',
    key: 'billingRetry.purchase',
    title: (
      <Stack direction="row" spacing={1} alignItems="center">
        <Box component="span">
          In billing retry subscribers
        </Box>
        <Tooltip
          arrow
          title="Количество юзеров на данный момент, которые в биллинге между платежом и платежом"
          placement="top"
        >
          <InfoOutlinedIcon fontSize="small" sx={{'&:hover': {cursor: 'pointer'}}}/>
        </Tooltip>
      </Stack>
    ),
    width: 120,
    cellRenderer: ({cellData}) => cellData > 0 ? cellData : ''
  },
  // %, in billing retry subscribers
  {
    align: Column.Alignment.RIGHT,
    dataKey: 'billingRetry.trial_%',
    key: 'billingRetry.trial_%',
    title: (
      <Stack direction="row" spacing={1} alignItems="center">
        <Box component="span">
          %, in billing retry subscribers
        </Box>
        <Tooltip
          arrow
          title="% юзеров на данный момент, которые в биллинге между триалом и платежом относительно всех триалов за этот период"
          placement="top"
        >
          <InfoOutlinedIcon fontSize="small" sx={{'&:hover': {cursor: 'pointer'}}}/>
        </Tooltip>
      </Stack>
    ),
    width: 120,
    cellRenderer: ({rowData}) => {
      const value = rowData?.billingRetry?.['trial_%'];

      return value > 0 ? value : '';
    }
  },
  //


  {
    align: Column.Alignment.RIGHT,
    dataKey: 'unsubscription.1hTrial_%',
    key: 'unsubscription.1hTrial_%',
    title: (
      <Stack direction="row" spacing={1} alignItems="center">
        <Box component="span">Unsubcription rate 1h, % (trial)</Box>
        <Tooltip
          arrow
          title="% юзеров, которые стопнули автопродление ДО платежа, и считаем их процент от всех триалов"
          placement="top"
        >
          <InfoOutlinedIcon fontSize="small" sx={{'&:hover': {cursor: 'pointer'}}}/>
        </Tooltip>
      </Stack>
    ),
    width: 160,
    cellRenderer: ({rowData}) => {
      const value = rowData?.unsubscription?.['1hTrial_%'];

      return value > 0 ? value : '';
    }
  },
  {
    align: Column.Alignment.RIGHT,
    dataKey: 'unsubscription.1hPurchase_%',
    key: 'unsubscription.1hPurchase_%',
    title: (
      <Stack direction="row" spacing={1} alignItems="center">
        <Box component="span">Unsubcription rate 1h, % (purchase)</Box>
        <Tooltip
          arrow
          title="% юзеров, которые стопнули автопродление ПОСЛЕ платежа, и считаем их процент от всех 1ых платежей"
          placement="top"
        >
          <InfoOutlinedIcon fontSize="small" sx={{'&:hover': {cursor: 'pointer'}}}/>
        </Tooltip>
      </Stack>
    ),
    width: 160,
    cellRenderer: ({rowData}) => {
      const value = rowData?.unsubscription?.['1hPurchase_%'];

      return value > 0 ? value : '';
    }
  },
  // ---
  {
    align: Column.Alignment.RIGHT,
    dataKey: 'unsubscription.1dTrial_%',
    key: 'unsubscription.1dTrial_%',
    title: (
      <Stack direction="row" spacing={1} alignItems="center">
        <Box component="span">Unsubcription rate 1d, % (trial)</Box>
        <Tooltip
          arrow
          title="% юзеров, которые стопнули автопродление ДО платежа, и считаем их процент от всех триалов"
          placement="top"
        >
          <InfoOutlinedIcon fontSize="small" sx={{'&:hover': {cursor: 'pointer'}}}/>
        </Tooltip>
      </Stack>
    ),
    width: 160,
    cellRenderer: ({rowData}) => {
      const value = rowData?.unsubscription?.['1dTrial_%'];

      return value > 0 ? value : '';
    }
  },
  {
    align: Column.Alignment.RIGHT,
    dataKey: 'unsubscription.1dPurchase_%',
    key: 'unsubscription.1dPurchase_%',
    title: (
      <Stack direction="row" spacing={1} alignItems="center">
        <Box component="span">Unsubcription rate 1d, % (purchase)</Box>
        <Tooltip
          arrow
          title="% юзеров, которые стопнули автопродление ПОСЛЕ платежа, и считаем их процент от всех 1ых платежей"
          placement="top"
        >
          <InfoOutlinedIcon fontSize="small" sx={{'&:hover': {cursor: 'pointer'}}}/>
        </Tooltip>
      </Stack>
    ),
    width: 160,
    cellRenderer: ({rowData}) => {
      const value = rowData?.unsubscription?.['1dPurchase_%'];

      return value > 0 ? value : '';
    }
  },


  // refunds.count
  {
    align: Column.Alignment.RIGHT,
    dataKey: 'refunds.count',
    key: 'refunds.count',
    title: (
      <Stack direction="row" spacing={1} alignItems="center">
        <Box component="span">Refunds (count)</Box>
        <Tooltip
          arrow
          title="Refunds (count)"
          placement="top"
        >
          <InfoOutlinedIcon fontSize="small" sx={{'&:hover': {cursor: 'pointer'}}}/>
        </Tooltip>
      </Stack>
    ),
    width: 160,
    cellRenderer: ({cellData}) => cellData > 0 ? cellData?.toLocaleString('en-US') : ''
  },
  // refunds.payments
  {
    align: Column.Alignment.RIGHT,
    dataKey: 'refunds.payments',
    key: 'refunds.payments',
    title: (
      <Stack direction="row" spacing={1} alignItems="center">
        <Box component="span">Refunds (payments)</Box>
        <Tooltip
          arrow
          title="Количество платежей на конец периода, которые в рфеанде"
          placement="top"
        >
          <InfoOutlinedIcon fontSize="small" sx={{'&:hover': {cursor: 'pointer'}}}/>
        </Tooltip>
      </Stack>
    ),
    width: 160,
    cellRenderer: ({cellData}) => cellData  > 0 ? cellData?.toLocaleString('en-US') : ''
  },
  // refunds.users
  {
    align: Column.Alignment.RIGHT,
    dataKey: 'refunds.users',
    key: 'refunds.users',
    title: (
      <Stack direction="row" spacing={1} alignItems="center">
        <Box component="span">Refunds Users</Box>
        <Tooltip
          arrow
          title="Количество уникальных юзеров на конец периода, которые сделали рефаед (чтобы понять сколько в среднем делает рефандов один юзер) "
          placement="top"
        >
          <InfoOutlinedIcon fontSize="small" sx={{'&:hover': {cursor: 'pointer'}}}/>
        </Tooltip>
      </Stack>
    ),
    width: 160,
    cellRenderer: ({cellData, rowData}) => cellData > 0 ? cellData?.toLocaleString('en-US') : ''
  },
  // refunds.revenue
  {
    align: Column.Alignment.RIGHT,
    dataKey: 'refunds.revenue',
    key: 'refunds.revenue',
    title: (
      <Stack direction="row" spacing={1} alignItems="center">
        <Box component="span">Refunds, $</Box>
        <Tooltip
          arrow
          title="Все рефанды в деньгах"
          placement="top"
        >
          <InfoOutlinedIcon fontSize="small" sx={{'&:hover': {cursor: 'pointer'}}}/>
        </Tooltip>
      </Stack>
    ),
    width: 160,
    cellRenderer: ({cellData}) => cellData ? `$ ${round(cellData, 2).toLocaleString('en-US')}` : ''
  },
  // revenue.total
  {
    align: Column.Alignment.RIGHT,
    dataKey: 'revenue.total',
    key: 'revenue.total',
    title: (
      <Stack direction="row" spacing={1} alignItems="center">
        <Box component="span">Revenue</Box>
        <Tooltip
          arrow
          title="Revenue - что по факту уже пришло"
          placement="top"
        >
          <InfoOutlinedIcon fontSize="small" sx={{'&:hover': {cursor: 'pointer'}}}/>
        </Tooltip>
      </Stack>
    ),
    width: 120,
    cellRenderer: ({cellData}) => cellData
      ? `$ ${round(cellData, 2).toLocaleString('en-US')}`
      : ''
  },
  // predictive.ltv
  {
    align: Column.Alignment.RIGHT,
    dataKey: 'predictive.ltv',
    key: 'predictive.ltv',
    title: (
      <Stack direction="row" spacing={1} alignItems="center">
        <Box component="span">LTV</Box>
        <Tooltip
          arrow
          title="Предиктив"
          placement="top"
        >
          <InfoOutlinedIcon fontSize="small" sx={{'&:hover': {cursor: 'pointer'}}}/>
        </Tooltip>
      </Stack>
    ),
    width: 120,
    cellRenderer: ({cellData}) =>
      cellData
        ? `$ ${round(cellData, 2)?.toLocaleString('en-US')}`
        : ''
  },
  // predictive.payments
  {
    align: Column.Alignment.RIGHT,
    dataKey: 'predictive.payments',
    key: 'predictive.payments',
    title: (
      <Stack direction="row" spacing={1} alignItems="center">
        <Box component="span">Payments</Box>
        <Tooltip
          arrow
          title="Кол-во платежей по предиктиву"
          placement="top"
        >
          <InfoOutlinedIcon fontSize="small" sx={{'&:hover': {cursor: 'pointer'}}}/>
        </Tooltip>
      </Stack>
    ),
    width: 120,
    cellRenderer: ({cellData}) => cellData > 0 ? cellData?.toLocaleString('en-US') : ''
  }
]