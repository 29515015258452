import Grid from "@material-ui/core/Grid";
import { Button, InputLabel, OutlinedInput } from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";
import LinearProgress from "@material-ui/core/LinearProgress";
import CountryFlag from "../../../components/CountryFlag";
import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { isEmpty, get} from "lodash";
import moment from "moment";
import { stringify, getLocaleDateString, parse } from "../../../services/helpers";
import { useHistory, useLocation } from "react-router-dom";
import useStyles from './styles';

const format = getLocaleDateString('ru-RU');

const Filters = props => {
    const classes = useStyles();
    const history = useHistory();
    const location = useLocation();

    const query = parse(useLocation().search);


    const registeredAfter = get(query, 'registeredAfter');
    const registeredBefore = get(query, 'registeredBefore');

    const { getCountries, getCalculator, getProjectReportHome } = props;


    const [filters, setFilters] = useState({
        registeredAfter: isEmpty(registeredAfter) ? null : moment(registeredAfter),
        registeredBefore: isEmpty(registeredBefore) ? null : moment(registeredBefore),

        // type:       FILTER_TYPE_MANUAL,

        sources:        get(query, 'sources', []),
        countries:      get(query, 'countries', []),
        languages:      get(query, 'languages', []),
        versionsApp:   get(query, 'versionsApp', []),
        versionsTest:  get(query, 'versionsTest', []),
    });

    const project = useSelector(state => state.project);
    const settings = useSelector(state => state.settings);
    const calculator = useSelector(state => state.calculator);

    const handleFilterChange = data => {
        const newFilters = { ...filters, ...data };

        history.replace({
            pathname: location.pathname,
            search: stringify({
                ...newFilters,
                ...(newFilters.registeredAfter &&
                    {registeredAfter: moment(newFilters.registeredAfter)?.format('YYYY-MM-DD')}),
                ...(newFilters.registeredBefore &&
                    {registeredBefore: moment(newFilters.registeredBefore)?.format('YYYY-MM-DD')}),
            })
        });

        setFilters(prevState => ({
            ...prevState,
            ...data
        }));
    };

    const loadReportHome =() => {
        if (parseInt(project?.active?.id) > 0 &&
            filters.registeredAfter && filters.registeredBefore &&
            filters.registeredAfter <= filters.registeredBefore
        ) {
            // eslint-disable-next-line
            getProjectReportHome({
                ...filters,
                registeredAfter: moment(filters.registeredAfter).format('YYYY-MM-DD'),
                registeredBefore: moment(filters.registeredBefore).format('YYYY-MM-DD'),
                projectId: project?.active?.id
            });
        }
    };

    const handleSubmitFilterForm = (event) => {
        event.preventDefault();

        loadReportHome();
    };


    useEffect(() => {
        if (!isEmpty(project?.active) && !project.pending && !settings.pending && isEmpty(settings.data)) {
            getCountries({ projectId: project.active.id, order: 'users' });
        }
    }, [project, settings, getCountries]);

    useEffect(() => {
        if (!isEmpty(project?.active) && !project.pending && !calculator.pending && isEmpty(calculator.data)) {
            getCalculator({ projectId: project.active.id })
        }
    }, [project, calculator, getCalculator]);

    useEffect(() => {
        loadReportHome();

        // eslint-disable-next-line
    }, [filters, project, location]);


    return (
        <form method={'GET'} noValidate onSubmit={handleSubmitFilterForm} className={classes.form}>
            <Grid container spacing={3}>
                <Grid item xs className={classes.formControl}>
                    <InputLabel htmlFor='startDate'>
                        Start Date
                    </InputLabel>

                    <KeyboardDatePicker
                        id={'startDate'}
                        disableToolbar
                        variant="inline"
                        format={format}
                        inputVariant="outlined"
                        value={filters.registeredAfter?.toDate() }
                        onChange={value => handleFilterChange({ registeredAfter: moment(value)})}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                    />
                </Grid>

                <Grid item xs className={classes.formControl}>
                    <InputLabel htmlFor='endDate'>
                        End Date
                    </InputLabel>

                    <KeyboardDatePicker
                        id={'endDate'}
                        disableToolbar
                        variant="inline"
                        format={format}
                        inputVariant="outlined"
                        value={filters.registeredBefore}
                        onChange={value => handleFilterChange({ registeredBefore: moment(value)})}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                    />

                </Grid>

                <Grid item xs className={classes.formControl}>
                    <InputLabel htmlFor='sources-label' component={'span'}>
                        Source:
                    </InputLabel>

                    <Select labelId="sources-label"
                            id="sources"
                            value={filters.sources}
                            variant={"outlined"}
                            multiple
                            input={
                                <OutlinedInput fullWidth disabled={calculator.pending}/>
                            }
                            inputProps={{
                                name: 'sources'
                            }}
                            renderValue={selected => selected?.map(item => item || '*')?.join(', ')}
                            onChange={e => handleFilterChange({ sources: e.target.value })}
                    >
                        {calculator.data?.sources?.map(item => (
                                <MenuItem key={item.name} value={item.value}>
                                    <Checkbox checked={filters.sources.indexOf(item.value) > -1} />
                                    <ListItemText primary={item.name || '*'} secondary={`Users: ${item.count}`}  />
                                </MenuItem>
                            )
                        )}
                    </Select>
                    {calculator.pending && <LinearProgress />}
                </Grid>

                <Grid item xs className={classes.formControl}>
                    <InputLabel htmlFor='versions_app-label' component={'span'}>
                        Version&nbsp;App:
                    </InputLabel>

                    <Select labelId="versions_app-label"
                            id="versions_app"
                            value={filters?.versionsApp || []}
                            variant={"outlined"}
                            multiple
                            input={
                                <OutlinedInput fullWidth disabled={calculator.pending}/>
                            }
                            inputProps={{
                                name: 'versionsApp'
                            }}
                            renderValue={(selected) => selected?.map(item => item || '*')?.join(', ')}
                            onChange={e => handleFilterChange({ versionsApp: e.target.value })}
                    >
                        {calculator.data?.versions_app?.map(item =>
                            <MenuItem key={item.name} value={item.value} >
                                <Checkbox checked={filters?.versionsApp?.indexOf(item.value) > -1} />
                                <ListItemText primary={item.name || '*'} secondary={`Users: ${item.count}`}  />
                            </MenuItem>
                        )}
                    </Select>
                    {calculator.pending && <LinearProgress />}
                </Grid>


                <Grid item xs className={classes.formControl}>
                    <InputLabel htmlFor='versions_test-label' component={'span'}>
                        Version,&nbsp;A/B:
                    </InputLabel>

                    <Select labelId="versions_test-label"
                            id="versions_test"
                            value={filters?.versionsTest}
                            variant={"outlined"}
                            multiple
                            input={
                                <OutlinedInput fullWidth disabled={calculator.pending}/>
                            }
                            inputProps={{
                                name: 'versionsTest'
                            }}
                            renderValue={(selected) => selected?.map(item => item || '*')?.join(', ')}
                            onChange={e => handleFilterChange({ versionsTest: e.target.value })}
                    >
                        {calculator.data?.versions_test?.map(item =>
                            <MenuItem key={item.name} value={item.value}>
                                <Checkbox checked={filters?.versionsTest?.indexOf(item.value) > -1} />
                                <ListItemText primary={item.name || '*'} secondary={`Users: ${item.count}`}  />
                            </MenuItem>
                        )}
                    </Select>
                    {calculator.pending && <LinearProgress />}
                </Grid>

                <Grid item xs className={classes.formControl}>
                    <InputLabel htmlFor='countries-label' component={'span'}>
                        Country:
                    </InputLabel>

                    <Select labelId="countries-label"
                            id="countries"
                            value={filters?.countries}
                            variant={"outlined"}
                            multiple
                            input={
                                <OutlinedInput fullWidth disabled={calculator.pending}/>
                            }
                            inputProps={{
                                name: 'countries'
                            }}
                            onChange={e => handleFilterChange({ countries: e?.target?.value || e})}
                            renderValue={(selected) => selected?.map(item => item || '*')?.join(', ')}
                    >
                        <MenuItem value={''}>All</MenuItem>
                        {calculator.data?.countries?.map(item =>
                            <MenuItem key={item.name} value={item.value}>
                                <CountryFlag countryCode={item.value} style={{fontSize:'2em',paddingRight:8}} />
                                <Checkbox checked={filters?.countries?.indexOf(item.value) > -1} />
                                <ListItemText primary={item.name || '*'} secondary={`Users: ${item.count}`}  />
                            </MenuItem>
                        )}
                    </Select>
                    {calculator.pending && <LinearProgress />}
                </Grid>

                <Grid item xs className={classes.formControl}>
                    <InputLabel htmlFor='languages-label' component={'span'}>
                        Language:
                    </InputLabel>

                    <Select labelId="languages-label"
                            id="languages"
                            value={filters?.languages}
                            variant={"outlined"}
                            input={
                                <OutlinedInput fullWidth disabled={calculator.pending}/>
                            }
                            multiple
                            inputProps={{
                                name: 'languages'
                            }}
                            onChange={e => handleFilterChange({ languages: e?.target?.value || e })}
                            renderValue={(selected) => selected?.map(item => item || '*')?.join(', ')}
                    >
                        {calculator.data?.languages?.map(item =>
                            <MenuItem key={item.name} value={item.value}>
                                <Checkbox checked={filters?.languages?.indexOf(item.value) > -1} />
                                <ListItemText primary={item.name || '*'} secondary={`Users: ${item.count}`}  />
                            </MenuItem>
                        )}
                    </Select>
                    {calculator.pending && <LinearProgress />}
                </Grid>

            </Grid>

            <Grid container spacing={3}>
                <Grid item xs className={classes.formControl}>
                    <Button className={classes.submit}
                            color="primary"
                            type="submit"
                            size="small"
                            disabled={!(project.active?.id > 0)}
                            variant="contained">Apply</Button>
                </Grid>
            </Grid>

        </form>
    );
};

const mapDispatch = ({
                         calculatorhome: {
                             getCalculatorHome
                         },
                         home: {
                             getProjectReportHome
                         },
                         calculatorpredictive: {
                             getCalculatorPredictive
                         },
                         settings: {
                             getCountries,
                         },
                         calculator: {
                             getCalculator,
                         }
                     }) => ({
    getCalculatorHome:       (...data) => getCalculatorHome(...data),
    getCountries:            (...data) => getCountries(...data),
    getCalculator:           (...data) => getCalculator(...data),
    getCalculatorPredictive: (...data) => getCalculatorPredictive(...data),
    getProjectReportHome: (...data) => getProjectReportHome(...data),
});

export default connect(null, mapDispatch)(Filters);