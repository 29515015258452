export default {
    contained:{
        // height:49,
        fontWeight:'bold',
        // margin: theme.spacing(0.5),
    },
    outlined:{
        // height:42
    }
};
