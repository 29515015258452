import ApiService from "../services/api";
import {checkResponseError} from './helpers'


export default {
    state: {
        pending: false,
        error: null,
        errorCode: null,

        title:null,
        budget:null,
        country:"*",
        registered: {
            after: null,
            before: null
        },

        data: null,
    },
    reducers: {
        authStart(state) {
            return {
                ...state,
                error: null,
                errorCode: null,
                pending: true
            }
        },
        updateError(state, data) {
            return {
                ...state,
                ...data,
                pending: false
            }
        },
        updateCalculator(state, data){
            return {
                ...state,
                ...data,
                pending: false
            }
        }
    },

    effects: (dispatch) => ({

        async getCalculatorHome(data){
            try{
                dispatch.calculatorhome.authStart();

                const responseData = await ApiService.getCalculatorHome(data);

                checkResponseError(dispatch.calculatorhome, responseData);

                const calculator = responseData?.calculator;

                dispatch.calculatorhome.updateCalculator(calculator);

                return responseData;
            }
            catch (e) {
                console.log('Catch calculator', e);
                dispatch.calculatorhome.updateCalculator({error: e.message})
            }
        },

    })
};