import React from "react";
import { DataGrid } from "@mui/x-data-grid";

const BaseGridTable = props => {
    return (
        <DataGrid
            autoHeight
            rowHeight={36}
            hideFooterPagination
            hideFooter
            disableColumnMenu={true}
            columnBuffer={6}
            rowBuffer={6}
            showColumnRightBorder
            showCellRightBorder
            disableSelectionOnClick
            disableColumnSelector
            {...props}
        />
    );
};

export default BaseGridTable;