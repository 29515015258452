import ApiService from "../services/api";
import {checkResponseError} from './helpers'
import camelcaseKeys from 'camelcase-keys';
import moment from "moment";


export default {
    state: {
        pending: false,
        error: null,
        errorCode: null,

        data: null,
        controllers: {},

        subtractWeeks: 1,
        countWeekLoaded: 0,
        loadedWeeksData: {},
        loadingWeek: {}
    },
    reducers: {
        authStart(state, data) {
            return {
                ...state,
                ...data,
                pending: true
            }
        },
        updateError(state, data) {
            return {
                ...state,
                ...data,
                pending: false
            }
        },
        updateReports(state, data) {
            return {
                ...state,
                ...data,
                pending: false
            }
        },
        updateLoadedWeeksData(state, { loadedWeeksData, period}) {
            return {
                controllers: {
                    ...state.controllers,
                    [period]: null,
                },
                countWeekLoaded: state.countWeekLoaded + (state.loadedWeeksData?.[period] ? 0: 1),
                loadedWeeksData: {
                    ...state.loadedWeeksData,
                    [period]: loadedWeeksData
                }
            }
        }
    },

    effects: (dispatch) => ({

        async getData(data, rootState) {
            try {
                const {registeredAfter, registeredBefore, weekNumber} = data;
                const period = `${registeredAfter} - ${registeredBefore}`;

                if (rootState.weekly.controllers?.[period] !== null) {
                    const contollerForPeriod = rootState.weekly.controllers?.[period];
                    contollerForPeriod?.abort();
                }

                const controller = new AbortController();
                dispatch.weekly.authStart({
                    controllers: {
                        ...rootState.weekly.controllers,
                        [period]: controller
                    }
                });

                const responseData = await ApiService.getWeeklyData({...data, signal: controller.signal});

                checkResponseError(dispatch.reports, responseData);


                dispatch.weekly.updateLoadedWeeksData({
                    period,
                    loadedWeeksData: {
                        period: {
                            weekNumber,
                            text: period,
                            registeredAfter: moment(registeredAfter, 'YYYY-MM-DD'),
                            registeredBefore: moment(registeredBefore, 'YYYY-MM-DD')
                        },
                        ...camelcaseKeys(responseData, {
                            deep: true,
                            stopPaths: [
                                'versions.first',
                                'versions.last'
                            ]
                        })
                    }
                })

                // const loadedWeeksData = {
                //     ...rootState.weekly.loadedWeeksData,
                //     [period]: {
                //         period: {
                //             text: period,
                //             registeredAfter: moment(registeredAfter, 'YYYY-MM-DD'),
                //             registeredBefore: moment(registeredBefore, 'YYYY-MM-DD')
                //         },
                //         ...camelcaseKeys(responseData, {
                //             deep: true,
                //             stopPaths: [
                //                 'versions.first',
                //                 'versions.last'
                //             ]
                //         })
                //     }
                // };
                //
                // console.log('dispatch', { dispatch });
                //
                // dispatch.weekly.updateReports({
                //     period,
                //     countWeekLoaded: rootState.weekly.countWeekLoaded + 1,
                //     loadedWeeksData,
                //     controllers: {
                //         ...rootState.weekly.controllers,
                //         [period]: null
                //     }
                // });

            } catch (e) {
                process.env.NODE_ENV === 'development' && console.log('Catch getReports', e);
                dispatch.reports.updateReports({error: e.message})
            }
        },
    })
};
