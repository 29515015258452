import ApiService from "../services/api";
import {checkResponseError} from './helpers'
import {versionCompare} from "../services/helpers";



const TEST_TEMPLATE = {
    can_change_in_use: true,
    can_change_variants: true,
    is_production: false,
    users:0,
    variant_in_use:null,
    variants:{},
};

export default {
    state: {
        pending: false,
        error: null,
        errorCode: null,

        versions: null,

    },
    reducers: {
        authStart(state) {
            return {
                ...state,
                pending: true
            }
        },
        updateError(state, data) {
            return {
                ...state,
                ...data,
                pending: false
            }
        },
        updateVersions(state, data){
            return {
                ...state,
                ...data,
                pending: false
            }
        }
    },

    effects: (dispatch) => ({

        async getVersions(data){
            try{

                dispatch.versions.authStart();

                const responseData = await ApiService.getVersions(data);

                checkResponseError(dispatch.versions, responseData);

                dispatch.versions.updateVersions({versions: responseData.versions});

                return responseData;
            }
            catch (e) {
                console.log('Catch versions', e);
                dispatch.versions.updateError({error: e.message})
            }
        },

        async postVersionNew(data, state){
            try{
                const {newVersion} = data;
                let {versions} = state.versions;
                dispatch.versions.authStart();

                const responseData = await ApiService.postVersionNew(data);

                checkResponseError(dispatch.versions, responseData);


                if (responseData?.success === true) {

                    versions = versions.reduce((versionP, versionC) => {

                        if (versionCompare(versionC.next, newVersion) === 0){
                            versionC.list.unshift({
                                ...TEST_TEMPLATE,
                                ...{
                                    next:`${newVersion}.1`,
                                    subs:[],
                                    version:newVersion,
                                }
                            });

                            let versionSplit = newVersion.split('.');

                            versionSplit[versionSplit.length-1] = parseInt(versionSplit[versionSplit.length-1])+1;

                            versionC.next = versionSplit.join('.');

                        } else {
                            versionC.list = versionC.list.reduce((itemP, itemC) => {

                                if (versionCompare(itemC.next, newVersion) === 0) {

                                    itemC.subs.push({
                                        ...TEST_TEMPLATE,
                                        ...{
                                            version:newVersion,
                                        }
                                    });

                                    let versionSplit = newVersion.split('.');

                                    versionSplit[versionSplit.length-1] = parseInt(versionSplit[versionSplit.length-1])+1;

                                    itemC.next = versionSplit.join('.');
                                }

                                itemP.push(itemC);
                                return itemP;
                            }, []);
                        }

                        versionP.push(versionC);
                        return versionP
                    }, []);

                    dispatch.versions.updateVersions({versions});

                }

                return responseData;
            }
            catch (e) {
                console.log('Catch versions', e);
                dispatch.versions.updateError({error: e.message})
            }
        },

        async postVersionFront(data, state){
            try{
                let {versions} = state.versions;
                dispatch.versions.authStart();

                const responseData = await ApiService.postVersionFront(data);

                checkResponseError(dispatch.versions, responseData);

                if (responseData?.success === true) {

                    versions = versions.reduce((versionP, versionC) => {
                        versionC.list = versionC.list.reduce((itemP, itemC) => {
                            if (versionCompare(itemC.version, data.version) === 0) {
                                itemC.variants = {...itemC.variants, ...{[data.letter]: data.description}};
                            } else if (itemC.subs.length > 0) {
                                itemC.subs = itemC.subs.reduce((subP, subC) => {
                                    if (subC.version === data.version) {
                                        subC.variants = {...subC.variants, ...{[data.letter]: data.description}};
                                    }

                                    subP.push(subC);
                                    return subP;
                                }, [])
                            }

                            itemP.push(itemC);
                            return itemP;
                        }, []);

                        versionP.push(versionC);
                        return versionP
                    }, []);

                    dispatch.versions.updateVersions({versions});
                }

                return responseData;
            }
            catch (e) {
                console.log('Catch versions', e);
                dispatch.versions.updateError({error: e.message})
            }
        },

        async deleteVersionFront(data, state){

            try{

                let {versions} = state.versions;
                dispatch.versions.authStart();

                const responseData = await ApiService.deleteVersionFront(data);

                checkResponseError(dispatch.versions, responseData);

                if (responseData?.success === true) {

                    versions = versions.reduce((versionP, versionC) => {
                        versionC.list = versionC.list.reduce((itemP, itemC) => {
                            if (versionCompare(itemC.version, data.version) === 0) {

                                if (itemC.variant_in_use?.toUpperCase() === data.letter.toUpperCase()){
                                    itemC.variant_in_use = null;
                                }

                                delete itemC.variants[data.letter];
                            } else if (itemC.subs.length > 0) {
                                itemC.subs = itemC.subs.reduce((subP, subC) => {
                                    if (versionCompare(subC.version, data.version) === 0) {

                                        if (subC.variant_in_use?.toUpperCase() === data.letter.toUpperCase()){
                                            subC.variant_in_use = null;
                                        }

                                        delete subC.variants[data.letter];

                                    }

                                    subP.push(subC);
                                    return subP;
                                }, [])
                            }

                            itemP.push(itemC);
                            return itemP;
                        }, []);

                        versionP.push(versionC);
                        return versionP
                    }, []);

                    dispatch.versions.updateVersions({versions});
                }

                return responseData;
            }
            catch (e) {
                console.log('Catch versions', e);
                dispatch.versions.updateError({error: e.message})
            }
        },

        async postVersionFrontApply(data, state){
            try{
                let {versions} = state.versions;

                dispatch.versions.authStart();

                const responseData = await ApiService.postVersionFrontApply(data);

                checkResponseError(dispatch.versions, responseData);

                if (responseData?.success === true) {

                    versions = versions.reduce((versionP, versionC) => {
                        versionC.list = versionC.list.reduce((itemP, itemC) => {

                            if (versionCompare(itemC.version, data.version) === 0 && itemC.can_change_in_use) {

                                itemC.variant_in_use = data.letter;

                            } else if (itemC.subs.length > 0) {

                                itemC.subs = itemC.subs.reduce((subP, subC) => {

                                    if (versionCompare(subC.version, data.version) === 0 && subC.can_change_in_use) {

                                        subC.variant_in_use = data.letter;
                                    }

                                    subP.push(subC);
                                    return subP;
                                }, [])
                            }

                            itemP.push(itemC);
                            return itemP;
                        }, []);

                        versionP.push(versionC);
                        return versionP
                    }, []);

                    dispatch.versions.updateVersions({versions});
                }


                return responseData;
            }
            catch (e) {
                console.log('Catch versions', e);
                dispatch.versions.updateError({error: e.message})
            }
        },

    })
};