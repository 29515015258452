import React from "react";
import Box from "@material-ui/core/Box";
import {styles as headerStyles} from "../Table/HeaderRowCells";
import {
    BORDER_SIZE,
    COLUMN_WIDTH,
    HEADER_HEIGHT,
    HEADER_ROW_HEIGHT,
    SPEND_ALIASES,
    CURRENT_PROFIT_COLUMNS,
    COST_PER_PURCHASE_COLUMNS,
    PREDICTIVE_6M_PROFIT_COLUMNS,
    PREDICTIVE_2Y_PROFIT_COLUMNS,
    ITUNES_COLUMNS,
    PREDICTIVE_1Y_PROFIT_COLUMNS,
    PREDICTIVE_MAX_PROFIT_COLUMNS
} from "../Report";
import {Button} from "@material-ui/core";
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import clsx from "clsx";
import {makeStyles} from "@material-ui/styles";
import {DEBUG} from './../../../constants/Constants'


const styles =  makeStyles( theme => ({
    conversionsBGColor:{
        backgroundColor: '#D2EFDD',
    },
    subscriptionsBGColor:{
        backgroundColor: '#BAECCD',
    },
    paymentsBGColor:{
        backgroundColor: '#BAF0ED',
    },
    center: {
        display: 'grid',
        alignItems: 'center',
        textAlign: 'center',
        borderBottom: `${BORDER_SIZE}px solid #c0c0c063`,
    },
    textEndCenter:{
        display: 'grid',
        textAlign: 'end',
        alignItems: 'center',
        wordBreak:' break-word',
        borderBottom: `${BORDER_SIZE}px solid #c0c0c063`,
        borderRight: `${BORDER_SIZE}px solid #c0c0c063`,
        // boxSizing: 'border-box',
    },
    boxSizing:{
        boxSizing: 'border-box',
    }
}));

function Header(props) {

    const classes = styles();

    const widthSubscriptions = props.reports.uniqueSubscriptions.reduce((p, c) => p + c.columnCount ,0) * COLUMN_WIDTH;
    const widthPayments = props.reports.uniquePayments.length * COLUMN_WIDTH;

    DEBUG && console.log('Header uniqueSubscriptions', props.reports.uniqueSubscriptions);

    return (
        <React.Fragment>
            <SpendsHeader {...props} spends={props.spends} handleShowHideSpend={props.handleShowHideSpend} />

            <ProfitHeader {...props} />

            {widthSubscriptions > 0 &&
                <Box className={classes.boxSizing}>
                    <Box height={HEADER_ROW_HEIGHT} width={widthSubscriptions + widthPayments } className={clsx(classes.center, classes.conversionsBGColor, classes.boxSizing)}>Conversions</Box>
                    <Box display={'flex'} height={HEADER_ROW_HEIGHT}>

                        {widthSubscriptions > 0 &&
                            <Box display={'flex'} height={HEADER_ROW_HEIGHT} width={widthSubscriptions} className={clsx(classes.center,classes.subscriptionsBGColor, classes.boxSizing)}>Subscriptions</Box>
                        }

                        {widthPayments > 0 &&
                            <Box display={'flex'} height={HEADER_ROW_HEIGHT} width={widthPayments} className={clsx(classes.center,classes.paymentsBGColor, classes.boxSizing)}>Payments</Box>
                        }

                    </Box>
                    <Box display={'flex'} height={HEADER_ROW_HEIGHT}>

                        <SubscriptionsHeader {...props} className={clsx(classes.subscriptionsBGColor, classes.boxSizing)}/>

                        {widthPayments > 0 && <PaymentsHeader {...props} className={clsx(classes.paymentsBGColor, classes.boxSizing)}/>}

                    </Box>
                </Box>
            }

        </React.Fragment>
    )
}

const SpendsHeader = ({spends, handleShowHideSpend, reports: {summary}}) => {

    const classes = headerStyles();
    let backgroundColor = '#F9ECCA';

    return (
        <Box style={{backgroundColor}} className={clsx(classes.borderRight, classes.boxSizing)} width={spends.length * COLUMN_WIDTH}>
            <Box justifyContent={'center'}
                 display="flex"
                 alignItems="center"
                 className={clsx(classes.spendSubHeader, classes.boxSizing)}
                 height={HEADER_ROW_HEIGHT}
            >
                Spend, USD

            </Box>

            {/*<Divider className={classes.divider} />*/}

            <Box style={{height: HEADER_HEIGHT - 2 * HEADER_ROW_HEIGHT}} align="center" className={clsx(classes.boxSizing)}>
                <Button variant={'contained'}
                        className={classes.spendBtn}
                        startIcon={spends.length === 1 ? <ArrowLeftIcon/> : <ArrowRightIcon/>}
                        endIcon={spends.length === 1 ? <ArrowRightIcon/> : <ArrowLeftIcon/>}
                        color={'primary'}
                        onClick={handleShowHideSpend}
                >
                    Show
                </Button>

            </Box>

            <Box display="flex" height={HEADER_ROW_HEIGHT} className={clsx(classes.spendCells, classes.boxSizing)}>
                {spends.map((spendKey) => (
                    <Box key={spendKey} width={COLUMN_WIDTH} className={clsx(classes.boxSizing)}>
                        <Box key={spendKey} className={clsx(classes.boxSizing)}>{SPEND_ALIASES[spendKey] || 'Not set'}</Box>
                        <Box align="end" justifyContent={'center'} className={clsx(classes.boxSizing)}>
                            $ {summary?.sources?.[spendKey]?.toLocaleString('en-US')}
                        </Box>
                    </Box>
                ))}
            </Box>

        </Box>
    );
};

const ProfitHeader = () => {

    // const classes = bodyStyles();
    const classes = headerStyles();


    const revenueColumns = React.useMemo(() =>{
        return (
            <Box display={'flex'} className={classes.boxSizing}>
                <Box className={clsx(classes.revenueProfitHeaderBG, classes.boxSizing, classes.borderRight)}>
                    <Box justifyContent={'center'}
                         display="flex"
                         alignItems="center"
                         width={CURRENT_PROFIT_COLUMNS.length * COLUMN_WIDTH}
                         height={HEADER_ROW_HEIGHT - BORDER_SIZE}
                         className={clsx(classes.borderBottom, classes.borderRight, classes.trialSubHeaderSection, classes.boxSizing)}
                    >
                        Current
                    </Box>
                    <Box  display={'flex'} className={classes.boxSizing}>
                    {CURRENT_PROFIT_COLUMNS.map((item, i) => {
                        const className = clsx(classes.spendCells, classes.boxSizing);
                        return (
                            <Box
                                key={item}
                                width={COLUMN_WIDTH}
                                className={className}
                            >
                                <Box px={0.5} height={40}>
                                    {item}
                                </Box>
                            </Box>
                        )
                    })}
                    </Box>
                </Box>

                <Box className={clsx(classes.revenueProfitHeaderBG, classes.boxSizing, classes.borderRight)}>
                    <Box justifyContent={'center'}
                         display="flex"
                         alignItems="center"
                         width={PREDICTIVE_1Y_PROFIT_COLUMNS.length * COLUMN_WIDTH}
                         height={HEADER_ROW_HEIGHT}
                         className={clsx(classes.borderBottom, classes.borderRight, classes.trialSubHeaderSection, classes.boxSizing)}
                    >
                        Predictive 1y
                    </Box>
                    <Box  display={'flex'} className={classes.boxSizing}>
                        {PREDICTIVE_1Y_PROFIT_COLUMNS.map((item, i) => {
                            let className = clsx(classes.spendCells, classes.boxSizing);
                            return (
                              <Box key={item} width={COLUMN_WIDTH} className={className}>
                                  <Box px={0.5} height={40}>
                                      {item}
                                  </Box>
                              </Box>
                            )
                        })}
                    </Box>
                </Box>

                <Box className={clsx(classes.revenueProfitHeaderBG, classes.boxSizing, classes.borderRight)}>
                    <Box justifyContent={'center'}
                         display="flex"
                         alignItems="center"
                         width={PREDICTIVE_MAX_PROFIT_COLUMNS.length * COLUMN_WIDTH}
                         height={HEADER_ROW_HEIGHT}
                         className={clsx(classes.borderBottom, classes.borderRight, classes.trialSubHeaderSection, classes.boxSizing)}
                    >
                        Predictive MAX
                    </Box>
                    <Box  display={'flex'} className={classes.boxSizing}>
                        {PREDICTIVE_MAX_PROFIT_COLUMNS.map((item, i) => {
                            let className = clsx(classes.spendCells, classes.boxSizing);
                            return (
                              <Box key={item} width={COLUMN_WIDTH} className={className}>
                                  <Box px={0.5} height={40}>
                                      {item}
                                  </Box>
                              </Box>
                            )
                        })}
                    </Box>
                </Box>

                <Box className={clsx(classes.revenueProfitHeaderBG, classes.boxSizing, classes.borderRight)}>
                    <Box justifyContent={'center'}
                         display="flex"
                         alignItems="center"
                         width={PREDICTIVE_6M_PROFIT_COLUMNS.length * COLUMN_WIDTH}
                         height={HEADER_ROW_HEIGHT}
                         className={clsx(classes.borderBottom, classes.borderRight, classes.trialSubHeaderSection, classes.boxSizing)}
                    >
                        Predictive 6m
                    </Box>
                    <Box  display={'flex'} className={classes.boxSizing}>
                        {/*['CAC/LTV', 'Revenue 6m', 'ROMI 6m', 'Predictive 2y+', 'ROMI 2y+', 'LTV_2y_plus']*/}
                        {/*['Revenue', 'Profit', 'ROMI', 'CAC / LTV', 'CPI / ARPU']*/}
                        {PREDICTIVE_6M_PROFIT_COLUMNS.map((item, i) => {
                            let className = clsx(classes.spendCells, classes.boxSizing);
                            return (
                                <Box key={item} width={COLUMN_WIDTH} className={className}>
                                    <Box px={0.5} height={40}>
                                        {item}
                                    </Box>
                                </Box>
                            )
                        })}
                    </Box>
                </Box>

                <Box className={clsx(classes.revenueProfitHeaderBG, classes.boxSizing, classes.borderRight)}>
                    <Box justifyContent={'center'}
                         display="flex"
                         alignItems="center"
                         width={PREDICTIVE_2Y_PROFIT_COLUMNS.length * COLUMN_WIDTH}
                         height={HEADER_ROW_HEIGHT}
                         className={clsx(classes.borderBottom, classes.borderRight, classes.trialSubHeaderSection, classes.boxSizing)}
                    >
                        Predictive 2y
                    </Box>
                    <Box  display={'flex'} className={classes.boxSizing}>
                        {PREDICTIVE_2Y_PROFIT_COLUMNS.map((item, i) => {
                            let className = clsx(classes.spendCells, classes.boxSizing);
                            return (
                                <Box key={item} width={COLUMN_WIDTH} className={className}>
                                    <Box px={0.5} height={40}>
                                        {item}
                                    </Box>
                                </Box>
                            )
                        })}
                    </Box>
                </Box>
                {/*<Box className={clsx(classes.revenueProfitHeaderBG, classes.boxSizing, classes.borderRight)}>*/}
                {/*    <Box justifyContent={'center'}*/}
                {/*         display="flex"*/}
                {/*         alignItems="center"*/}
                {/*         width={PREDICTIVE_2Y_PLUS_PROFIT_COLUMNS.length * COLUMN_WIDTH}*/}
                {/*         height={HEADER_ROW_HEIGHT}*/}
                {/*         className={clsx(classes.borderBottom, classes.borderRight, classes.trialSubHeaderSection, classes.boxSizing)}*/}
                {/*    >*/}
                {/*        Predictive 2y+*/}
                {/*    </Box>*/}
                {/*    <Box  display={'flex'} className={classes.boxSizing}>*/}
                {/*        {PREDICTIVE_2Y_PLUS_PROFIT_COLUMNS.map((item, i) => {*/}
                {/*            let className = clsx(classes.spendCells, classes.boxSizing);*/}
                {/*            return (*/}
                {/*                <Box key={item} width={COLUMN_WIDTH} className={className}>*/}
                {/*                    <Box px={0.5} height={40}>*/}
                {/*                        {item}*/}
                {/*                    </Box>*/}
                {/*                </Box>*/}
                {/*            )*/}
                {/*        })}*/}
                {/*    </Box>*/}
                {/*</Box>*/}
                {/*<Box className={clsx(classes.revenueProfitHeaderBG, classes.boxSizing, classes.borderRight)}>*/}
                {/*    <Box justifyContent={'center'}*/}
                {/*         display="flex"*/}
                {/*         alignItems="center"*/}
                {/*         width={3 * COLUMN_WIDTH}*/}
                {/*         height={HEADER_ROW_HEIGHT}*/}
                {/*         className={clsx(classes.borderBottom, classes.borderRight, classes.trialSubHeaderSection, classes.boxSizing)}*/}
                {/*    >*/}
                {/*        Predictive*/}
                {/*    </Box>*/}
                {/*    <Box  display={'flex'}>*/}
                {/*        {['Revenue', 'Profit', 'ROI'].map((item, i) => {*/}
                {/*            let className = clsx(classes.spendCells, classes.predictiveBGColor, classes.boxSizing);*/}
                {/*            return (*/}
                {/*                <Box key={item} width={COLUMN_WIDTH} className={className}>*/}
                {/*                    <Box px={0.5} height={40}>*/}
                {/*                        {item}*/}
                {/*                    </Box>*/}
                {/*                </Box>*/}
                {/*            )*/}
                {/*        })}*/}
                {/*    </Box>*/}
                {/*</Box>*/}
                <Box className={clsx(classes.revenueProfitHeaderBG, classes.boxSizing, classes.borderRight)}>
                    <Box justifyContent={'center'}
                         display="flex"
                         alignItems="center"
                         width={ITUNES_COLUMNS.length * COLUMN_WIDTH}
                         height={HEADER_ROW_HEIGHT}
                         className={clsx(classes.borderBottom, classes.borderRight, classes.trialSubHeaderSection, classes.boxSizing)}
                    >
                        iTunes
                    </Box>
                    <Box  display={'flex'}>
                        {ITUNES_COLUMNS.map((item, i) => {
                            let className = clsx(classes.spendCells, classes.boxSizing);
                            return (
                                <Box key={item} width={COLUMN_WIDTH} className={className}>
                                    <Box px={0.5} height={40}>
                                        {item}
                                    </Box>
                                </Box>
                            )
                        })}
                    </Box>
                </Box>
            </Box>
        )
    }, [classes]);

    const profitColumns = React.useMemo(() =>{
        return (
            <Box display={'flex'} width={COST_PER_PURCHASE_COLUMNS.length * COLUMN_WIDTH} className={clsx(classes.borderRight, classes.boxSizing)}>
                {COST_PER_PURCHASE_COLUMNS.map((item, i) => (
                        <Box key={item} width={COLUMN_WIDTH} className={clsx(classes.spendCells, classes.boxSizing)}>
                            <Box px={0.5} height={HEADER_HEIGHT - HEADER_ROW_HEIGHT} className={clsx(classes.boxSizing)}>
                               {item}
                            </Box>
                        </Box>
                    ))
                }
            </Box>
        )
    }, [classes]);

    // const trialSection = React.useMemo(() =>{
    //     return (
    //         <Box width={TRIAL_SECTION_COLUMNS.length * COLUMN_WIDTH} className={classes.boxSizing}>
    //             <Box justifyContent={'center'}
    //                  display="flex"
    //                  alignItems="center"
    //                  height={HEADER_ROW_HEIGHT}
    //                  className={clsx(classes.borderBottom, classes.borderRight, classes.trialSubHeaderSection, classes.boxSizing)}
    //             >
    //                 Trial section
    //             </Box>
    //
    //             <Box display={'flex'} className={classes.boxSizing}>
    //                 {TRIAL_SECTION_COLUMNS.map(item => (
    //                     <Box key={item}
    //                          width={COLUMN_WIDTH}
    //                          className={clsx(classes.spendCells,classes.boxSizing)}
    //                     >
    //                         <Box px={0.5} height={40}>{item}</Box>
    //                     </Box>
    //                 ))}
    //             </Box>
    //         </Box>
    //     )
    // }, [classes]);



    return (
        <Box className={clsx(classes.borderRight,classes.boxSizing)}>
            <Box justifyContent={'center'}
                 display="flex"
                 alignItems="center"
                 className={clsx(classes.spendSubHeader, classes.profitHeaderBG, classes.borderBottom, classes.boxSizing)}
                 height={HEADER_ROW_HEIGHT}
            >
                Daily report
            </Box>
            <Box display={'flex'} className={clsx(classes.profitHeaderBG, classes.boxSizing)} height={HEADER_HEIGHT - HEADER_ROW_HEIGHT}>
                {/*{trialSection}*/}
                {revenueColumns}
                {profitColumns}
            </Box>
        </Box>
    )
};

const SubscriptionsHeader = React.memo((props) =>
    <Box display={'flex'} height={HEADER_HEIGHT - 2 * HEADER_ROW_HEIGHT} className={props.className}>
        {props.reports.uniqueSubscriptions.map(HeaderCell)}
    </Box>);

const PaymentsHeader = React.memo((props) =>
    <Box display={'flex'} height={HEADER_HEIGHT - 2 * HEADER_ROW_HEIGHT} className={props.className}>
        {props.reports.uniquePayments.map(HeaderCell)}
    </Box>);

const HeaderCell = ({product_id, columnCount}) => {
    const classes = styles();
    return (
        <Box key={product_id}
             width={columnCount * COLUMN_WIDTH}
             height={HEADER_HEIGHT - 2 * HEADER_ROW_HEIGHT}
             className={clsx(classes.textEndCenter, classes.boxSizing)}
        >
            {product_id}
        </Box>
    )
};


export default React.memo(Header);