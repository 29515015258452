import {makeStyles} from "@material-ui/core/styles";

export const styles = makeStyles((theme) => ({
    bgGray: {
        backgroundColor: 'rgba(237,240,247,0.75)',
    },
    borderRight:{
        borderRight: '1px solid #E1E5EF'
    },
    borderRowBottom:{
        borderBottom:'1px solid rgba(225, 229, 239, 0.3)'
    },
    borderRowBottomLast:{
        borderBottom:'1px solid #CDD3E1'
    },
    backgroundTableHeader: {
        background: 'rgb(237 240 247 / 40%)',
    },
    bgDarkGreen:{
        backgroundColor: '#B3E4D2',
    },
    bgLightGreen:{
        backgroundColor: 'rgba(179, 228, 210, 0.6)',
    },
    // hover:{
    //     '&:hover':{
    //         backgroundColor: 'rgba(0, 0, 0, 0.04)',
    //         // '& > td': {
    //         //     backgroundColor: 'rgba(0, 0, 0, 0.04)',
    //         // },
    //         '& > td':{
    //             '& $bgLightGreen':{
    //                 backgroundColor: 'rgba(179, 228, 210, 0.4)',
    //             },
    //             '& $bgGray':{
    //                 backgroundColor: '#EDF0F76B !important',
    //             }
    //         }
    //     }
    // }
}));