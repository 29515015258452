import {round, get} from "lodash";
import React from "react";
import Tooltip from "@mui/material/Tooltip";
import moment from "moment";
import Stack from "@mui/material/Stack";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Box from "@mui/material/Box";
import {Column} from "../../../components/table";
import CountryFlag from "../../../components/CountryFlag";

export default [
  {
    align:   Column.Alignment.LEFT,
    dataKey: 'date',
    key: 'date',
    title: 'Date',
    width: 170,
    frozen:  Column.FrozenDirection.LEFT,
    cellRenderer: ({rowData, column: {filter}}) => {
      if (rowData?.countryCode)
        return (
          <>
            {rowData?.countryCode && <CountryFlag countryCode={rowData?.countryCode} style={{ fontSize:'2em',paddingRight:8 }} />}
            {rowData?.countryCode?.toUpperCase()}
          </>
        );

      const startDate = moment(get(rowData, 'period.start'), 'DD.MM.YYYY HH:mm:ss');
      const endDate = moment(get(rowData, 'period.finish'), 'DD.MM.YYYY HH:mm:ss');
      return (
        <Tooltip
          title={
            filter?.groupBy === 'day'
              ? startDate.format('DD-MM-YYYY')
              : [startDate.format('DD-MM-YYYY'), endDate.format('DD-MM-YYYY')].join(' to ')
          }
          placement="top"
        >
          <span>
            {filter?.groupBy === 'month' && startDate.format('MMMM YYYY')}
            {filter?.groupBy === 'week' && `Week ${startDate.format('W YYYY')}`}
            {filter?.groupBy === 'day' && startDate.format('DD MMMM YYYY')}
          </span>
        </Tooltip>
      )
    }
  },
  {
    align:   Column.Alignment.RIGHT,
    dataKey: 'installs',
    key: 'installs',
    title: (
      <Stack direction="row" spacing={1} alignItems="center">
        <Box component="span">First open</Box>
        <Tooltip
          arrow
          title={
            'First open'
          }
          placement="top"
        >
          <InfoOutlinedIcon fontSize="small" sx={{'&:hover': {cursor: 'pointer'}}}/>
        </Tooltip>
      </Stack>
    ),
    width: 120,
    cellRenderer: ({ cellData }) => cellData.toLocaleString('en-En')
  },
  {
    align:   Column.Alignment.RIGHT,
    dataKey: 'subscribers',
    key: 'subscribers',
    title: (
      <Stack direction="row" spacing={1} alignItems="center">
        <Box component="span">Subscribers</Box>
        <Tooltip
          arrow
          title={
            'Юзеры, которые стартанули ЛЮБУЮ подписку'
          }
          placement="top"
        >
          <InfoOutlinedIcon fontSize="small" sx={{'&:hover': {cursor: 'pointer'}}}/>
        </Tooltip>
      </Stack>
    ),
    width: 120,
    cellRenderer: ({ cellData }) => cellData.toLocaleString('en-En')
  },
  {
    align:   Column.Alignment.RIGHT,
    dataKey: 'payers',
    key: 'payers',
    title: (
      <Stack direction="row" spacing={1} alignItems="center">
        <Box component="span">Payers</Box>
        <Tooltip
          arrow
          title={
            'все юзеры, у которых есть хотя бы 1 платеж с деньгами (платный триал не считается) '
          }
          placement="top"
        >
          <InfoOutlinedIcon fontSize="small" sx={{'&:hover': {cursor: 'pointer'}}}/>
        </Tooltip>
      </Stack>
    ),
    width: 120,
    cellRenderer: ({ cellData }) => cellData.toLocaleString('en-En')
  },
  {
    align:   Column.Alignment.RIGHT,
    dataKey: 'trials.total',
    key: 'trials.total',
    title: (
      <Stack direction="row" spacing={1} alignItems="center">
        <Box component="span">Trials (total)</Box>
        <Tooltip
          arrow
          title="Trials total"
          placement="top"
        >
          <InfoOutlinedIcon fontSize="small" sx={{'&:hover': {cursor: 'pointer'}}}/>
        </Tooltip>
      </Stack>
    ),
    width: 120,
    cellRenderer: ({ cellData }) => cellData?.toLocaleString('en-En')
  },
  {
    align:   Column.Alignment.RIGHT,
    dataKey: 'trials.withFirstPayment',
    key: 'trials.withFirstPayment',
    title: (
      <Stack direction="row" spacing={1} alignItems="center">
        <Box component="span">Conversions</Box>
        <Tooltip
          arrow
          title={
            'Trials with first payment'
          }
          placement="top"
        >
          <InfoOutlinedIcon fontSize="small" sx={{'&:hover': {cursor: 'pointer'}}}/>
        </Tooltip>
      </Stack>
    ),
    width: 140,
    cellRenderer: ({ cellData }) => cellData?.toLocaleString('en-En')
  },
  {
    align:   Column.Alignment.RIGHT,
    dataKey: 'cpi',
    key: 'cpi',
    title: (
      <Stack direction="row" spacing={1} alignItems="center">
        <Box component="span">CPI</Box>
        <Tooltip
          arrow
          title="spend / first opens"
          placement="top"
        >
          <InfoOutlinedIcon fontSize="small" sx={{'&:hover': {cursor: 'pointer'}}}/>
        </Tooltip>
      </Stack>
    ),
    width: 120,
    cellRenderer: ({ cellData }) => cellData ? `$ ${cellData?.toLocaleString('en-En')}` : ''
  },
  {
    align:   Column.Alignment.RIGHT,
    dataKey: 'cpa',
    key: 'cpa',
    title: (
      <Stack direction="row" spacing={1} alignItems="center">
        <Box component="span">CPA</Box>
        <Tooltip
          arrow
          title="spend / subscribers"
          placement="top"
        >
          <InfoOutlinedIcon fontSize="small" sx={{'&:hover': {cursor: 'pointer'}}}/>
        </Tooltip>
      </Stack>
    ),
    width: 120,
    cellRenderer: ({ cellData }) => cellData ? `$ ${cellData?.toLocaleString('en-En')}` : ''
  },
  {
    align:   Column.Alignment.RIGHT,
    dataKey: 'ltvTrial',
    key: 'ltvTrial',
    title: (
      <Stack direction="row" spacing={1} alignItems="center">
        <Box component="span">TRIAL LTV</Box>
        <Tooltip
          arrow
          title="revenue / trials.total"
          placement="top"
        >
          <InfoOutlinedIcon fontSize="small" sx={{'&:hover': {cursor: 'pointer'}}}/>
        </Tooltip>
      </Stack>
    ),
    width: 120,
    cellRenderer: ({ cellData, rowData, column: { filter } }) => {
      return cellData ? `$ ${cellData?.toLocaleString('en-En')}` : ''
      // // const value = get(rowData, `revenue`);
      // const value = get(rowData, `predictiveRevenue.${filter?.dropdown}`);
      // const trialsTotal = get(rowData, 'trials.total');
      //
      // return value && trialsTotal !== 0
      //   ? `$ ${round(value/trialsTotal, 2)}`
      //   : '';
    }
  },
  {
    align:   Column.Alignment.RIGHT,
    dataKey: 'ltv',
    key: 'ltv',
    title: (
      <Stack direction="row" spacing={1} alignItems="center">
        <Box component="span">LTV</Box>
        <Tooltip
          arrow
          title="revenue / subscribers"
          placement="top"
        >
          <InfoOutlinedIcon fontSize="small" sx={{'&:hover': {cursor: 'pointer'}}}/>
        </Tooltip>
      </Stack>
    ),
    width: 120,
    cellRenderer: ({ cellData, rowData, column: { filter } }) => {
      const value = get(rowData, `predictiveLtv.${filter?.dropdown}`, 0);
      return value !== 0 ? `$ ${value?.toLocaleString('en-En')}` : '';
    }
  },
  {
    align:   Column.Alignment.RIGHT,
    dataKey: 'tCVR',
    key: 'tCVR',
    width: 120,
    title: (
      <Stack direction="row" spacing={1} alignItems="center">
        <Box component="span">tCVR</Box>
        <Tooltip
          arrow
          title="Все триалки / First open"
          placement="top"
        >
          <InfoOutlinedIcon fontSize="small" sx={{'&:hover': {cursor: 'pointer'}}}/>
        </Tooltip>
      </Stack>
    ),
    cellRenderer: ({ cellData }) => `${round(cellData * 100, 2).toLocaleString('en-En')} %`
  },
  {
    align:   Column.Alignment.RIGHT,
    dataKey: 'pCVR',
    key: 'pCVR',
    title: (
      <Stack direction="row" spacing={1} alignItems="center">
        <Box component="span">pCVR</Box>
        <Tooltip
          arrow
          title={
            'pCVR'
          }
          placement="top"
        >
          <InfoOutlinedIcon fontSize="small" sx={{'&:hover': {cursor: 'pointer'}}}/>
        </Tooltip>
      </Stack>
    ),
    width: 120,
    cellRenderer: ({ cellData }) => cellData ? `${(cellData*100)?.toLocaleString('en-En')} %` : ''
  },
  {
    align:   Column.Alignment.RIGHT,
    dataKey: 'install2Payers',
    key: 'install2Payers',
    width: 110,
    title: (
      <Stack direction="row" spacing={1} alignItems="center">
        <Box component="span">Install 2 paid</Box>
        <Tooltip
          arrow
          title="payers / first open"
          placement="top"
        >
          <InfoOutlinedIcon fontSize="small" sx={{'&:hover': {cursor: 'pointer'}}}/>
        </Tooltip>
      </Stack>
    ),
    // format: value => `${round(value * 100, 2).toLocaleString('en-En')} %`
    cellRenderer: ({ cellData }) => `${round(cellData * 100, 2).toLocaleString('en-En')} %`
  },
  {
    align:   Column.Alignment.RIGHT,
    dataKey: 'unsubscribeRate.1h',
    key: 'unsubscribeRate.1h',
    width: 120,
    title: (
      <Stack direction="row" spacing={1} alignItems="center">
        <Box component="span">Unsub rate 1h</Box>
        <Tooltip
          arrow
          title="% отписок (стоп автопродления) от старта подписки"
          placement="top"
        >
          <InfoOutlinedIcon fontSize="small" sx={{'&:hover': {cursor: 'pointer'}}}/>
        </Tooltip>
      </Stack>
    ),
    // format: value => `${value?.toLocaleString('en-En')} %`
    cellRenderer: ({ cellData }) => `${cellData.toLocaleString('en-En')} %`
  },
  {
    align:   Column.Alignment.RIGHT,
    dataKey: 'unsubscribeRate.1d',
    key: 'unsubscribeRate.1d',
    width: 120,
    title: (
      <Stack direction="row" spacing={1} alignItems="center">
        <Box component="span">Unsub rate 1d</Box>
        <Tooltip
          arrow
          title="% отписок (стоп автопродления) от старта подписки"
          placement="top"
        >
          <InfoOutlinedIcon fontSize="small" sx={{'&:hover': {cursor: 'pointer'}}}/>
        </Tooltip>
      </Stack>
    ),
    // format: value => `${value?.toLocaleString('en-En')} %`
    cellRenderer: ({ cellData }) => `${cellData.toLocaleString('en-En')} %`
  },
  {
    align:   Column.Alignment.RIGHT,
    dataKey: 'spend',
    key: 'spend',
    title: (
      <Stack direction="row" spacing={1} alignItems="center">
        <Box component="span">Cost</Box>
        <Tooltip
          arrow
          title={
            'Спенд'
          }
          placement="top"
        >
          <InfoOutlinedIcon fontSize="small" sx={{'&:hover': {cursor: 'pointer'}}}/>
        </Tooltip>
      </Stack>
    ),
    width: 120,
    // format: value => `$ ${value?.toLocaleString('en-En')}`,
    cellRenderer: ({ cellData }) => `$ ${cellData?.toLocaleString('en-En')}`
  },
  {
    align:   Column.Alignment.RIGHT,
    dataKey: 'revenue',
    key: 'revenue',
    title: 'Revenue, fact',
    width: 120,
    // format: value => `$ ${value?.toLocaleString('en-En')}`,
    cellRenderer: ({ cellData }) => `$ ${cellData?.toLocaleString('en-En')}`
  },
  {
    align:   Column.Alignment.RIGHT,
    dataKey: 'predictiveRevenue',
    key: 'predictiveRevenue',
    title: 'Revenue, predict',
    width: 120,
    // format: value => `$ ${value?.toLocaleString('en-En')}`,
    // render: (rowData, column) => {
    //   const value = get(rowData, `predictiveRevenue.${rowData.filter?.dropdown}`);
    //
    //   return column.format
    //     ? column.format(value)
    //     : value
    // }
    cellRenderer: ({ cellData, rowData, column: { filter } }) => {
      // const value = get(rowData, `predictiveRevenue?.${filter?.dropdown}`);
      //
      // return value ? `$ ${value?.toLocaleString('en-En')}` : null;

      return `$ ${cellData?.toLocaleString('en-En')}`
    }
  },
  {
    align:   Column.Alignment.RIGHT,
    dataKey: 'netProfitFact',
    key: 'netProfitFact',
    title: 'Net Profit, fact',
    width: 120,
    // format: value => `$ ${value?.toLocaleString('en-En')}`,
    // render: (rowData, column) => {
    //   const value = round(get(rowData, 'revenue', 0) - get(rowData, 'spend', 0));
    //
    //   return column.formatROI 4st week ? column.format(value) : value;
    // }
    cellRenderer: ({ rowData }) => {
      const value = round(get(rowData, 'revenue', 0) - get(rowData, 'spend', 0));
      return value ? `$ ${value?.toLocaleString('en-En')}` : null;
    }
  },
  {
    align:   Column.Alignment.RIGHT,
    dataKey: 'netProfitPredict',
    key: 'netProfitPredict',
    title: 'Net Profit, predict',
    width: 120,
    // format: value => `$ ${value?.toLocaleString('en-En')}`,
    // render: (rowData, column) => {
    //   const value = round(get(rowData, `predictiveRevenue.${rowData.filter?.dropdown}`, 0) - get(rowData, 'spend', 0));
    //
    //   return column.format ? column.format(value) : value;
    // }
    cellRenderer: ({ cellData, rowData, column: { filter } }) => {
      // const value = round(get(rowData, `predictiveRevenue.${filter?.dropdown}`, 0) - get(rowData, 'spend', 0));
      // return value ? `$ ${value?.toLocaleString('en-En')}` : null;

      return `$ ${cellData?.toLocaleString('en-En')}`;
    }
  },
  {
    align:   Column.Alignment.RIGHT,
    dataKey: 'roi.total',
    key: 'roi.total',
    title: 'ROI, fact',
    width: 120,
    // format: value => `${round(value * 100, 2).toLocaleString('en-En')} %`,
    cellRenderer: ({ cellData }) => `${round(cellData * 100, 2).toLocaleString('en-En')} %`,
  },
  {
    align:   Column.Alignment.RIGHT,
    dataKey: 'predictiveRoi',
    key: 'predictiveRoi',
    title: (
      <Stack direction="row" spacing={1} alignItems="center">
        <Box component="span">ROI, predict</Box>
        <Tooltip
          arrow
          title={
            'revenue predict / spend  (согласно ячейки с ЛТВ  180/ 365 / 720+)'
          }
          placement="top"
        >
          <InfoOutlinedIcon fontSize="small" sx={{'&:hover': {cursor: 'pointer'}}}/>
        </Tooltip>
      </Stack>
    ),
    width: 120,
    // format: value => `${value?.toLocaleString('en-En')} %`,
    // render: (rowData, column) => {
    //   const value = round(get(rowData, `predictiveRoi.${rowData.filter?.dropdown}`)  * 100, 2);
    //
    //   return column.format
    //     ? column.format(value)
    //     : value
    // }
    cellRenderer: ({  cellData, rowData, column: { filter } }) => {
      // const value = round(get(rowData, `predictiveRoi.${filter?.dropdown}`) * 100, 2);
      //
      // return value ? `${value?.toLocaleString('en-En')} %` : null;
      return `${cellData?.toLocaleString('en-En')} %`
    }
  },
  {
    align:   Column.Alignment.RIGHT,
    dataKey: 'roi.1w',
    key: 'roi.1w',
    title: (
      <Stack direction="row" spacing={1} alignItems="center">
        <Box component="span">ROI 1st week</Box>
        <Tooltip
          arrow
          title={
            'факт деньги на конец 0 недели от first open / spend'
          }
          placement="top"
        >
          <InfoOutlinedIcon fontSize="small" sx={{'&:hover': {cursor: 'pointer'}}}/>
        </Tooltip>
      </Stack>
    ),
    width: 120,
    // format: value => `${round(value * 100, 2).toLocaleString('en-En')} %`,
    cellRenderer: ({ cellData }) => `${round(cellData * 100, 2).toLocaleString('en-En')} %`,
  },
  {
    align:   Column.Alignment.RIGHT,
    dataKey: 'roi.2w',
    key: 'roi.2w',
    title: (
      <Stack direction="row" spacing={1} alignItems="center">
        <Box component="span">ROI 2st week</Box>
        <Tooltip
          arrow
          title={
            'факт деньги на конец 1 недели от first open / spend'
          }
          placement="top"
        >
          <InfoOutlinedIcon fontSize="small" sx={{'&:hover': {cursor: 'pointer'}}}/>
        </Tooltip>
      </Stack>
    ),
    width: 120,
    // format: value => `${round(value * 100, 2).toLocaleString('en-En')} %`,
    cellRenderer: ({ cellData }) => `${round(cellData * 100, 2).toLocaleString('en-En')} %`,
  },
  {
    align:   Column.Alignment.RIGHT,
    dataKey: 'roi.3w',
    key: 'roi.3w',
    title: (
      <Stack direction="row" spacing={1} alignItems="center">
        <Box component="span">ROI 3st week</Box>
        <Tooltip
          arrow
          title={
            'факт деньги на конец 2 недели от first open / spend'
          }
          placement="top"
        >
          <InfoOutlinedIcon fontSize="small" sx={{'&:hover': {cursor: 'pointer'}}}/>
        </Tooltip>
      </Stack>
    ),
    width: 120,
    // format: value => `${round(value * 100, 2).toLocaleString('en-En')} %`,
    cellRenderer: ({ cellData }) => `${round(cellData * 100, 2).toLocaleString('en-En')} %`,
  },
  {
    align:   Column.Alignment.RIGHT,
    dataKey: 'roi.4w',
    key: 'roi.4w',
    title: (
      <Stack direction="row" spacing={1} alignItems="center">
        <Box component="span">ROI 4st week</Box>
        <Tooltip
          arrow
          title={
            'факт деньги на конец 3 недели от first open / spend'
          }
          placement="top"
        >
          <InfoOutlinedIcon fontSize="small" sx={{'&:hover': {cursor: 'pointer'}}}/>
        </Tooltip>
      </Stack>
    ),
    width: 150,
    // format: value => `${round(value * 100, 2).toLocaleString('en-En')} %`,
    cellRenderer: ({ cellData }) => `${round(cellData * 100, 2).toLocaleString('en-En')} %`,
  }
]