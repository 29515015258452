import React, {useEffect} from "react";
import buildQueryString, {getLocaleDateString} from "../../services/helpers";
import Box from "@material-ui/core/Box";
import Select from "@material-ui/core/Select";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Chip from "@material-ui/core/Chip";
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";
// import {makeStyles} from "@material-ui/core/styles";
import {useHistory, useLocation} from "../../hooks";
import InputLabel from "@material-ui/core/InputLabel";
import {KeyboardDatePicker} from "@material-ui/pickers";
import Button from "@material-ui/core/Button";
// import IOSSwitch from "./../../components/IOSSwitch";
import {CONVERSION_MODEL} from "../../constants/Constants";
import Grid from "@material-ui/core/Grid";
import Switch from "@material-ui/core/Switch";



const strFormat = getLocaleDateString('ru-RU');


// const useStyles = makeStyles((theme) => ({
//     root: {
//
//     },
//     cellWidth105:{
//         minWidth:105
//     },
//     cellWidth:{
//         minWidth: 70,
//         maxWidth: 70
//     },
//     cellHeight:{
//         display: 'block',
//         // height: 24,
//     },
//     table:{
//         borderCollapse: 'collapse',
//     },
//     tableBorder:{
//         border: '1px solid #c0c0c063',
//     },
//     hideScroll:{
//         overflow: 'hidden',
//         position: 'relative',
//     },
//     chips: {
//         display: 'flex',
//         flexWrap: 'wrap',
//     },
//     chip: {
//         margin: 2,
//     },
//     dateField: {
//         marginLeft: theme.spacing(1),
//         marginRight: theme.spacing(1),
//         width: 200,
//     },
//     formControl: {
//         margin: theme.spacing(1),
//         minWidth: 120,
//     },
// }));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 'max-content',
        },
    },
};


const RenewalForm = ({onChange, legend, pending, handleLoad, filterData = {}}) => {

    // const classes  = useStyles();
    // const query    = useQuery();

    const history  = useHistory();
    const location = useLocation();

    const timer = React.useRef(null);

    useEffect(() => {

        return () => {
            clearTimer(timer)
        }
    }, []);

    function clearTimer(timer) {
        if (timer?.current !== null) clearTimeout(timer.current);
    }

    const onChangeFormParams = (...data) => {
        clearTimer(timer);

        const [name2,event,] = data;

        let value = null;
        let name = null;
        if ( event === undefined) {
            value = name2?.target?.value;
            name  = name2?.target?.name;
        } else if (event instanceof Date) {
            value = event;
            name = name2;
        } else {
            const [event, ] = data;
            value = event?.target?.value;
            name  = event?.target?.name;
        }
        const newData = {...filterData, ...{[name]: value}};
        timer.current = setTimeout(() => {
            onChange && onChange(newData);
            history.push(location.pathname + buildQueryString(newData));
            clearTimer(timer)
        }, 150);
    };

    return (
        <React.Fragment>
            <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
                <Box >
                    <InputLabel id="demo-simple-select-placeholder-label-label" shrink>
                        Products
                    </InputLabel>
                    <Select labelId="products"
                            id={'select-products'}
                            label={'products'}
                            autoWidth={true}
                            value={filterData?.products||[]}
                            variant={"outlined"}
                            multiple
                            input={
                                <OutlinedInput id="select-multiple-chip" label="Chip" />
                            }
                            inputProps={{
                                name: 'products'
                            }}
                            onChange={onChangeFormParams}
                            renderValue={(selected) =>
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                    {selected.map((value) => (
                                        <Chip key={value} label={value} size="small" />
                                    ))}
                                </Box>
                            }
                            MenuProps={MenuProps}
                    >
                        {legend?.map(({key, name}) =>
                            <MenuItem key={key} value={key}>
                                <Checkbox checked={filterData?.products?.indexOf(key) > -1} />
                                <ListItemText primary={name} style={{minWidth: 'max-content'}}/>
                            </MenuItem>
                        )}
                    </Select>
                </Box>

                <Box>
                    <InputLabel shrink id={'dateAfter'}>
                        Start Date
                    </InputLabel>

                    <KeyboardDatePicker
                        labelId='dateAfter'
                        autoOk
                        variant="inline"
                        inputVariant="outlined"
                        format={strFormat}
                        name={'dateAfter'}
                        value={filterData.dateAfter}
                        InputAdornmentProps={{position: "end"}}
                        onChange={onChangeFormParams.bind(this, 'dateAfter')}
                    />

                </Box>

                <Box>
                    <InputLabel id='dateBefore' shrink>
                        End Date
                    </InputLabel>

                    <KeyboardDatePicker
                        labelId={'dateBefore'}
                        autoOk
                        variant="inline"
                        inputVariant="outlined"
                        format={strFormat}
                        name={'dateBefore'}
                        value={filterData.dateBefore}
                        InputAdornmentProps={{position: "end"}}
                        onChange={onChangeFormParams.bind(this, 'dateBefore')}
                    />
                </Box>

                <Box>
                    <InputLabel htmlFor='dateBefore' shrink>
                        Group by days
                    </InputLabel>

                    <OutlinedInput type={'number'}
                                   name={'groupCountPeriod'}
                                   value={filterData.groupCountPeriod}
                                   fullWidth
                                   inputProps={{
                                       min:1
                                   }}
                                   onChange={onChangeFormParams} />
                </Box>
            </Box>

            <Box>
                <InputLabel shrink>
                    Conversion model
                </InputLabel>
                <Grid component={Box} container alignItems="center" spacing={0} >
                    <Grid item>{CONVERSION_MODEL.MANUAL}</Grid>
                    <Grid item>
                        <Switch
                            name='conversionModel'
                            value={
                                filterData.conversionModel === CONVERSION_MODEL.FACTUAL
                                    ? CONVERSION_MODEL.MANUAL
                                    : CONVERSION_MODEL.FACTUAL
                            }
                            onChange={onChangeFormParams} />
                    </Grid>
                    <Grid item>{CONVERSION_MODEL.FACTUAL}</Grid>
                </Grid>
            </Box>

            <Box mx={0.5} pb={2}>
                <Button variant={"contained"}
                        color={'primary'}
                        disabled={pending}
                        onClick={() => handleLoad(filterData)} >
                    Load{`${pending ? 'ing...':''}`}
                </Button>
            </Box>

        </React.Fragment>

    )
};

export default React.memo(RenewalForm)