import {format} from 'date-fns';
import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
import memoizeOne from "memoize-one";
import { useLocation } from "react-router-dom";

import qs from 'qs';

export const stringify = settings => {
    return qs.stringify(settings, {
        arrayFormat: 'brackets',
        addQueryPrefix: true,
        skipNulls: true,
        encodeValuesOnly: true
    });
};

export const parse = query => {
    return qs.parse(query, { ignoreQueryPrefix: true, comma: true });
};


// const queryString = require('query-string');
const snakeCaseKeys = require('snakecase-keys');

// eslint-disable-next-line
Date.prototype.isValid = function () {
    // An invalid date object returns NaN for getTime() and NaN is the only
    // object not strictly equal to itself.
    // eslint-disable-next-line
    return this.getTime() === this.getTime();
};
//
// function serializeQuery(params, prefix) {
//     const query = Object.keys(params).map((key) => {
//         const value  = params[key];
//
//         if (params.constructor === Array)
//             key = `${prefix}[]`;
//         else if (params.constructor === Object)
//             key = (prefix ? `${prefix}[${key}]` : key);
//
//         if (typeof value === 'object')
//             return serializeQuery(value, key);
//         else
//             return `${key}=${encodeURIComponent(value)}`;
//     });
//
//     return [].concat.apply([], query).join('&');
// }

// function clean(obj) {
//     for (let propName in obj) {
//
//         const val = obj[propName];
//
//         if (val === null || val === undefined || val === '' || (val instanceof Date && val.isValid() === false)) {
//             delete obj[propName];
//         } else if (val instanceof Date){
//             obj[propName] = format(val , 'yyyy-MM-dd')
//         }else if (val instanceof Array){
//             obj[propName] = val.filter(item => (item !== '' && item !== null && item !== undefined));
//             if (obj[propName]?.length === 0){
//                 delete obj[propName];
//             }
//         }
//     }
//
//     return obj;
// }

export default function buildQueryString(params = {}) {

    // params = clean(params) || {};
    // params = snakeCaseKeys(params);
    // params = serializeQuery(params);
    //
    // return JSON.stringify(params) === JSON.stringify({}) ? '' : `?${params}`;

    return stringify(snakeCaseKeys(params));
}

export function getLocaleDateString(local = null){

    const formats = {
        "ar-SA" : "dd/MM/yy",
        "bg-BG" : "dd.M.yyyy",
        "ca-ES" : "dd/MM/yyyy",
        "zh-TW" : "yyyy/M/d",
        "cs-CZ" : "d.M.yyyy",
        "da-DK" : "dd-MM-yyyy",
        "de-DE" : "dd.MM.yyyy",
        "el-GR" : "d/M/yyyy",
        "en-US" : "M/d/yyyy",
        "fi-FI" : "d.M.yyyy",
        "fr-FR" : "dd/MM/yyyy",
        "he-IL" : "dd/MM/yyyy",
        "hu-HU" : "yyyy. MM. dd.",
        "is-IS" : "d.M.yyyy",
        "it-IT" : "dd/MM/yyyy",
        "ja-JP" : "yyyy/MM/dd",
        "ko-KR" : "yyyy-MM-dd",
        "nl-NL" : "d-M-yyyy",
        "nb-NO" : "dd.MM.yyyy",
        "pl-PL" : "yyyy-MM-dd",
        "pt-BR" : "d/M/yyyy",
        "ro-RO" : "dd.MM.yyyy",
        "ru-RU" : "dd.MM.yyyy",
        "hr-HR" : "d.M.yyyy",
        "sk-SK" : "d. M. yyyy",
        "sq-AL" : "yyyy-MM-dd",
        "sv-SE" : "yyyy-MM-dd",
        "th-TH" : "d/M/yyyy",
        "tr-TR" : "dd.MM.yyyy",
        "ur-PK" : "dd/MM/yyyy",
        "id-ID" : "dd/MM/yyyy",
        "uk-UA" : "dd.MM.yyyy",
        "be-BY" : "dd.MM.yyyy",
        "sl-SI" : "d.M.yyyy",
        "et-EE" : "d.MM.yyyy",
        "lv-LV" : "yyyy.MM.dd.",
        "lt-LT" : "yyyy.MM.dd",
        "fa-IR" : "MM/dd/yyyy",
        "vi-VN" : "dd/MM/yyyy",
        "hy-AM" : "dd.MM.yyyy",
        "az-Latn-AZ" : "dd.MM.yyyy",
        "eu-ES" : "yyyy/MM/dd",
        "mk-MK" : "dd.MM.yyyy",
        "af-ZA" : "yyyy/MM/dd",
        "ka-GE" : "dd.MM.yyyy",
        "fo-FO" : "dd-MM-yyyy",
        "hi-IN" : "dd-MM-yyyy",
        "ms-MY" : "dd/MM/yyyy",
        "kk-KZ" : "dd.MM.yyyy",
        "ky-KG" : "dd.MM.yy",
        "sw-KE" : "M/d/yyyy",
        "uz-Latn-UZ" : "dd/MM yyyy",
        "tt-RU" : "dd.MM.yyyy",
        "pa-IN" : "dd-MM-yy",
        "gu-IN" : "dd-MM-yy",
        "ta-IN" : "dd-MM-yyyy",
        "te-IN" : "dd-MM-yy",
        "kn-IN" : "dd-MM-yy",
        "mr-IN" : "dd-MM-yyyy",
        "sa-IN" : "dd-MM-yyyy",
        "mn-MN" : "yy.MM.dd",
        "gl-ES" : "dd/MM/yy",
        "kok-IN" : "dd-MM-yyyy",
        "syr-SY" : "dd/MM/yyyy",
        "dv-MV" : "dd/MM/yy",
        "ar-IQ" : "dd/MM/yyyy",
        "zh-CN" : "yyyy/M/d",
        "de-CH" : "dd.MM.yyyy",
        "en-GB" : "dd/MM/yyyy",
        "es-MX" : "dd/MM/yyyy",
        "fr-BE" : "d/MM/yyyy",
        "it-CH" : "dd.MM.yyyy",
        "nl-BE" : "d/MM/yyyy",
        "nn-NO" : "dd.MM.yyyy",
        "pt-PT" : "dd-MM-yyyy",
        "sr-Latn-CS" : "d.M.yyyy",
        "sv-FI" : "d.M.yyyy",
        "az-Cyrl-AZ" : "dd.MM.yyyy",
        "ms-BN" : "dd/MM/yyyy",
        "uz-Cyrl-UZ" : "dd.MM.yyyy",
        "ar-EG" : "dd/MM/yyyy",
        "zh-HK" : "d/M/yyyy",
        "de-AT" : "dd.MM.yyyy",
        "en-AU" : "d/MM/yyyy",
        "es-ES" : "dd/MM/yyyy",
        "fr-CA" : "yyyy-MM-dd",
        "sr-Cyrl-CS" : "d.M.yyyy",
        "ar-LY" : "dd/MM/yyyy",
        "zh-SG" : "d/M/yyyy",
        "de-LU" : "dd.MM.yyyy",
        "en-CA" : "dd/MM/yyyy",
        "es-GT" : "dd/MM/yyyy",
        "fr-CH" : "dd.MM.yyyy",
        "ar-DZ" : "dd-MM-yyyy",
        "zh-MO" : "d/M/yyyy",
        "de-LI" : "dd.MM.yyyy",
        "en-NZ" : "d/MM/yyyy",
        "es-CR" : "dd/MM/yyyy",
        "fr-LU" : "dd/MM/yyyy",
        "ar-MA" : "dd-MM-yyyy",
        "en-IE" : "dd/MM/yyyy",
        "es-PA" : "MM/dd/yyyy",
        "fr-MC" : "dd/MM/yyyy",
        "ar-TN" : "dd-MM-yyyy",
        "en-ZA" : "yyyy/MM/dd",
        "es-DO" : "dd/MM/yyyy",
        "ar-OM" : "dd/MM/yyyy",
        "en-JM" : "dd/MM/yyyy",
        "es-VE" : "dd/MM/yyyy",
        "ar-YE" : "dd/MM/yyyy",
        "en-029" : "MM/dd/yyyy",
        "es-CO" : "dd/MM/yyyy",
        "ar-SY" : "dd/MM/yyyy",
        "en-BZ" : "dd/MM/yyyy",
        "es-PE" : "dd/MM/yyyy",
        "ar-JO" : "dd/MM/yyyy",
        "en-TT" : "dd/MM/yyyy",
        "es-AR" : "dd/MM/yyyy",
        "ar-LB" : "dd/MM/yyyy",
        "en-ZW" : "M/d/yyyy",
        "es-EC" : "dd/MM/yyyy",
        "ar-KW" : "dd/MM/yyyy",
        "en-PH" : "M/d/yyyy",
        "es-CL" : "dd-MM-yyyy",
        "ar-AE" : "dd/MM/yyyy",
        "es-UY" : "dd/MM/yyyy",
        "ar-BH" : "dd/MM/yyyy",
        "es-PY" : "dd/MM/yyyy",
        "ar-QA" : "dd/MM/yyyy",
        "es-BO" : "dd/MM/yyyy",
        "es-SV" : "dd/MM/yyyy",
        "es-HN" : "dd/MM/yyyy",
        "es-NI" : "dd/MM/yyyy",
        "es-PR" : "dd/MM/yyyy",
        "am-ET" : "d/M/yyyy",
        "tzm-Latn-DZ" : "dd-MM-yyyy",
        "iu-Latn-CA" : "d/MM/yyyy",
        "sma-NO" : "dd.MM.yyyy",
        "mn-Mong-CN" : "yyyy/M/d",
        "gd-GB" : "dd/MM/yyyy",
        "en-MY" : "d/M/yyyy",
        "prs-AF" : "dd/MM/yy",
        "bn-BD" : "dd-MM-yy",
        "wo-SN" : "dd/MM/yyyy",
        "rw-RW" : "M/d/yyyy",
        "qut-GT" : "dd/MM/yyyy",
        "sah-RU" : "MM.dd.yyyy",
        "gsw-FR" : "dd/MM/yyyy",
        "co-FR" : "dd/MM/yyyy",
        "oc-FR" : "dd/MM/yyyy",
        "mi-NZ" : "dd/MM/yyyy",
        "ga-IE" : "dd/MM/yyyy",
        "se-SE" : "yyyy-MM-dd",
        "br-FR" : "dd/MM/yyyy",
        "smn-FI" : "d.M.yyyy",
        "moh-CA" : "M/d/yyyy",
        "arn-CL" : "dd-MM-yyyy",
        "ii-CN" : "yyyy/M/d",
        "dsb-DE" : "d. M. yyyy",
        "ig-NG" : "d/M/yyyy",
        "kl-GL" : "dd-MM-yyyy",
        "lb-LU" : "dd/MM/yyyy",
        "ba-RU" : "dd.MM.yy",
        "nso-ZA" : "yyyy/MM/dd",
        "quz-BO" : "dd/MM/yyyy",
        "yo-NG" : "d/M/yyyy",
        "ha-Latn-NG" : "d/M/yyyy",
        "fil-PH" : "M/d/yyyy",
        "ps-AF" : "dd/MM/yy",
        "fy-NL" : "d-M-yyyy",
        "ne-NP" : "M/d/yyyy",
        "se-NO" : "dd.MM.yyyy",
        "iu-Cans-CA" : "d/M/yyyy",
        "sr-Latn-RS" : "d.M.yyyy",
        "si-LK" : "yyyy-MM-dd",
        "sr-Cyrl-RS" : "d.M.yyyy",
        "lo-LA" : "dd/MM/yyyy",
        "km-KH" : "yyyy-MM-dd",
        "cy-GB" : "dd/MM/yyyy",
        "bo-CN" : "yyyy/M/d",
        "sms-FI" : "d.M.yyyy",
        "as-IN" : "dd-MM-yyyy",
        "ml-IN" : "dd-MM-yy",
        "en-IN" : "dd-MM-yyyy",
        "or-IN" : "dd-MM-yy",
        "bn-IN" : "dd-MM-yy",
        "tk-TM" : "dd.MM.yy",
        "bs-Latn-BA" : "d.M.yyyy",
        "mt-MT" : "dd/MM/yyyy",
        "sr-Cyrl-ME" : "d.M.yyyy",
        "se-FI" : "d.M.yyyy",
        "zu-ZA" : "yyyy/MM/dd",
        "xh-ZA" : "yyyy/MM/dd",
        "tn-ZA" : "yyyy/MM/dd",
        "hsb-DE" : "d. M. yyyy",
        "bs-Cyrl-BA" : "d.M.yyyy",
        "tg-Cyrl-TJ" : "dd.MM.yy",
        "sr-Latn-BA" : "d.M.yyyy",
        "smj-NO" : "dd.MM.yyyy",
        "rm-CH" : "dd/MM/yyyy",
        "smj-SE" : "yyyy-MM-dd",
        "quz-EC" : "dd/MM/yyyy",
        "quz-PE" : "dd/MM/yyyy",
        "hr-BA" : "d.M.yyyy.",
        "sr-Latn-ME" : "d.M.yyyy",
        "sma-SE" : "yyyy-MM-dd",
        "en-SG" : "d/M/yyyy",
        "ug-CN" : "yyyy-M-d",
        "sr-Cyrl-BA" : "d.M.yyyy",
        "es-US" : "M/d/yyyy"
    };

    local = local || window?.navigator?.userLanguage || window?.navigator?.language || "en-US";

    return formats[local] || 'dd/MM/yyyy';
}

export function formatDateFromTimestamp(timestamp, locale = "ru-RU", add = '') {

    const _format = getLocaleDateString(locale);

    return format(new Date(timestamp * 1000), `${_format} ${add}`)
}

export function versionCompare(v1, v2, options = {zeroExtend: true, lexicographical: false}) {
    let lexicographical = options && options.lexicographical,
        zeroExtend = options && options.zeroExtend,
        v1parts = v1?.split('.'),
        v2parts = v2?.split('.');

    function isValidPart(x) {
        return (lexicographical ? /^\d+[A-Za-z]*$/ : /^\d+$/).test(x);
    }

    if (!v1parts?.every(isValidPart) || !v2parts?.every(isValidPart)) {
        return NaN;
    }

    if (zeroExtend) {
        while (v1parts.length < v2parts.length) v1parts.push("0");
        while (v2parts.length < v1parts.length) v2parts.push("0");
    }

    if (!lexicographical) {
        v1parts = v1parts.map(Number);
        v2parts = v2parts.map(Number);
    }

    for (let i = 0; i < v1parts.length; ++i) {
        if (v2parts.length === i) {
            return 1;
        }

        if (v1parts[i] === v2parts[i]) {
            continue;
        }
        else if (v1parts[i] > v2parts[i]) {
            return 1;
        }
        else {
            return -1;
        }
    }

    if (v1parts.length !== v2parts.length) {
        return -1;
    }

    return 0;
}

export function shortUpperCaseFirstChars(str) {
    return str.toLowerCase().split(' ').map(item => item.charAt(0).toUpperCase()).join('')
}

export const GoogleIcon = (props) =>
    <SvgIcon color={props.color} fontSize={'large'}>
        <path d="M12.545,10.239v3.821h5.445c-0.712,2.315-2.647,3.972-5.445,3.972c-3.332,0-6.033-2.701-6.033-6.032s2.701-6.032,6.033-6.032c1.498,0,2.866,0.549,3.921,1.453l2.814-2.814C17.503,2.988,15.139,2,12.545,2C7.021,2,2.543,6.477,2.543,12s4.478,10,10.002,10c8.396,0,10.249-7.85,9.426-11.748L12.545,10.239z" />
    </SvgIcon>;

export const FacebookIcon = (props) =>
    <SvgIcon color={props.color} fontSize={'large'}>
        <path d="M5 3h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2m13 2h-2.5A3.5 3.5 0 0 0 12 8.5V11h-2v3h2v7h3v-7h3v-3h-3V9a1 1 0 0 1 1-1h2V5z" />
    </SvgIcon>;

export function addNZeroAtEnd(str, count = 0, afterChar = '.') {

    if (str === 0 || str === '0')
        str = '0'+afterChar;
    str = str+'';

    const len = str?.length || 0;

    if (len >= 0) {
        const index = str?.indexOf(afterChar) || 0;

        for (let i = 0; i < (count - len + 1 + index); i++) {
            str += '0';
        }
    }

    return str;
}

export const alphabetLetters = memoizeOne((charA = 'a', charZ = 'z') => {

    let a = [], i = charA.charCodeAt(0), j = charZ.charCodeAt(0);
    for (; i <= j; ++i) {
        a.push(String.fromCharCode(i));
    }
    return a;
});


export const  isNumeric = (str) => {
    if (typeof str !== "string") return false; // we only process strings!
    if (typeof str !== "number") return true; // we only process strings!
    return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
        !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
};

export const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};

export const showNotification = () => {
    return (('Notification' in window) && (Notification.permission !== 'granted'))
};

export const deepValue = (o, k) => k.split('.').reduce((a, v) => a?.[v], o);


export function chunkify(a = [], n = 1, balanced = false) {

    if (n < 2)
        return [a];

    let len = a.length,
        out = [],
        i = 0,
        size;

    if (len % n === 0) {
        size = Math.floor(len / n);
        while (i < len) {
            out.push(a.slice(i, i += size));
        }
    }

    else if (balanced) {
        while (i < len) {
            size = Math.ceil((len - i) / n--);
            out.push(a.slice(i, i += size));
        }
    }

    else {

        n--;
        size = Math.floor(len / n);
        if (len % size === 0)
            size--;
        while (i < size * n) {
            out.push(a.slice(i, i += size));
        }
        out.push(a.slice(size * n));

    }

    return out;
}