import React, {useEffect, useRef, useState} from "react";
import {connect} from "react-redux";
import Box from "@material-ui/core/Box";
import {
    AutoSizer, CellMeasurer, CellMeasurerCache,
    ColumnSizer,
    Grid,
    ScrollSync
} from "react-virtualized";
import {Button} from "@material-ui/core";
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import scrollbarSize from "dom-helpers/scrollbarSize";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import {makeStyles} from "@material-ui/styles";
import SaveIcon from '@material-ui/icons/Save';
import CreateIcon from '@material-ui/icons/Create';
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore';
import {round} from "../../models/helpers";
import {saveDivide} from "../../models/helpers";
import clsx from "clsx";
import PropTypes from 'prop-types';
import { Skeleton } from '@material-ui/lab'
import InlineEdit from "../../components/InlineEdit";
import CircularProgress from "@material-ui/core/CircularProgress";
import useWindowSize from "../../hooks/useWindowSize";

const displayNames = {
    facebook: 'Facebook',
    ad_networks:'AdNetworks',
    unity:'Unity',
    google:'Google',
    snap_chat:'SnapChat',
    search_ads:'Search ads',
    total:'Total',
    sum: 'Sum',
    other: 'Other',
    twitter: 'Twitter',
    tik_tok: 'Tik Tok',

    // profits
    revenue:'Revenue',
    profit:'Profit',
    predictive_revenue:'Predictive revenue',
    total_profit:'Total  profit',
    cost_per_purchase:'Cost per purchase',
    purchase_count:'Purchases count',
    subscribes_count:'Subscribes count',

    // trial_section
    cost_trial:'Cost trial',
    trial_count:'Trial count',
    conversion_count:'Conversion count',
    conversion_trial_purchase:'Conversion trial/purchase',
};

const spendGroups = [
    'facebook',
    'ad_networks',
    'unity',
    'google',
    'snap_chat',
    'search_ads',
    'twitter',
    'tik_tok',
    'other',
    'sum'];


const useStyles = makeStyles(theme => ({
    GridRow:{
        position: 'relative',
        display: 'flex',
        flexDirection: 'row',
    },
    LeftSideGridContainer:{
        flex: '0 0 75px',
        zIndex: 10,
    },
    LeftSideGrid:{
        overflow: 'hidden !important',
    },
    GridColumn:{
        display: 'flex',
        flexDirection: 'column',
        flex: '1 1 auto',
    },
    HeaderGrid:{
        // width: '100%',
        overflow: 'hidden !important',
    },
    HeaderTitle:{
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: 14,
        '&>span':{
            width: 'inherit',
            verticalAlign: 'middle',
            height: 'inherit',
            display: 'table-cell',
        }
    },
    BodyGrid:{
        width: '100%',

    },
    conversionsTotalRow:{
        backgroundColor: 'rgba(0, 0, 0, 0.05)'
    },
    Conversions:{
        '&>div.ReactVirtualized__Grid__innerScrollContainer': {
            backgroundColor: '#E9F7EF',
            '& > div':{
                // boxShadow: 'inset 0px 0px 0 1px #fff',
                border: '1px solid #fff',
            }
        }
    },
    Profits:{
        '&>div.ReactVirtualized__Grid__innerScrollContainer': {
            backgroundColor: '#EBFBFA',
            '& > div':{
                // boxShadow: 'inset 0px 0px 0 1px #fff',
                borderBottom: '1px solid #fff',
                borderTop: '1px solid #fff',
            }
        }
    },
    Spends:{
        '&>div.ReactVirtualized__Grid__innerScrollContainer': {
            backgroundColor: 'rgba(249, 236, 202, 0.5)',
            '& > div':{
                borderBottom: '1px solid #fff',
                borderTop: '1px solid #fff',
            }
        }
    },
    borderRight:{
        borderRight: '1px solid #fff',
    },
    borderLeft:{
        borderLeft: '1px solid #fff',
    },
    negative:{
        backgroundColor: '#FAE0D8'
    },
    positive:{
        backgroundColor: '#BAECCD'
    },
    white:{
        backgroundColor: '#FFF'
    },
    totalSpendColumn:{
        backgroundColor: '#FAE0D8'
    },
    span: {
        // width: '100%',
        // margin: 'auto',
        fontSize: 13
    },
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
        zIndex: 1,
    },
}));

const useCustomInput = makeStyles(theme => ({
    root:{
        textAlign:'end',
        padding: 3,
    },
    input:{
         height:22,
        margin: 1,
        '& > .MuiOutlinedInput-input':{
            textAlign:'end',
            padding: '0px 4px',
        }
    },
    icon:{
        margin:'auto',
        '&:hover':{
            cursor:'pointer',
            '& path': {
                stroke: theme.palette.primary.main,
                fill: theme.palette.primary.main,
            }
        }
    },
    span: {
        width: '100%',
        margin: 'auto',
        fontSize: 13
    }
}));

export const CustomEditInput = React.memo((props) => {

    const {currency} = props;

    const classes = useCustomInput();
    const [isEdited, setIsEdited] = useState(false);
    const [value, setValue] = useState(props.value);
    const [oldValue] = useState(props.value);

    const inputActionHandle = () => {
        if (isEdited) {
            props.onChange && props.onChange(value);
        }
        setIsEdited(!isEdited);
    };

    const getIcon = (isSave) => {
        if (isSave)
            return (
                <React.Fragment>
                    <SaveIcon fontSize={'small'} className={classes.icon} onClick={inputActionHandle} />
                    <SettingsBackupRestoreIcon fontSize={'small'}  className={classes.icon}  onClick={() => {
                        setValue(oldValue);
                        setIsEdited(!isEdited);
                    }} />
                </React.Fragment>
            );

        return <CreateIcon fontSize={'small'}  className={classes.icon} onClick={inputActionHandle} />
    };

    if (isEdited){
        return (
                <Box style={props.style}
                     display={props?.display || 'inline-flex'}
                >
                    <OutlinedInput value={value}
                                   style={{height: 22, padding:0}}
                                   onChange={(e) =>
                                       setValue(e.target.value)
                                   }
                    />
                    <Box display={'inline-block'}
                         style={{
                             right: props?.right || -40,
                             position: 'absolute',
                             zIndex: 1,
                             height: 22,
                             borderRadius: 4,
                             backgroundColor: '#f9f3e3',
                             margin: '5px 0',
                    }}>
                        {getIcon(isEdited)}
                    </Box>
                </Box>

        )
    }

    return (
        <Box display={'inline-flex'} style={props.style}>
            <CustomCell id={props?.id || ''}
                        className={classes.span}
                        value={value}  currency={currency || false}/>
            {getIcon(isEdited)}
        </Box>
    )
});


CustomEditInput.propTypes = {
    currency: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};
CustomEditInput.defaultProps = {
    currency: false
};



const FIRST_ROW_HEIGHT = 126;
const PROFIT_TABLE_COUNT_COLUMNS = 13;
const COUNT_SUB_TABLES = 3;
const TRIAL_SECTION_COLUMN_COUNT = 4;






const useCustomCellStyles = makeStyles( theme => ({
    cell:{
        fontSize: 13,
        fontStyle: 'normal',
        fontWeight: 400,
        letterSpacing: '0em',
    },
    positive: {
        color: '#04A842'
    },
    negative:{
        color: '#A80404'
    }
}));

const CustomCell = (props) => {

    const classes = useCustomCellStyles();

    const {currency} = props;

    return (
        <Box id={props?.id||''}
             align={props?.align || 'right'}
             width={props?.width || '100%'}
             style={props.style}
             className={clsx(classes.cell, props?.className)}
        >
            {currency && '$ ' }

            { typeof props.value === 'number' ? props.value.toLocaleString('en-US') : props.value}

            &nbsp;
            {/*{(props?.subValue !== null && props?.subValue > 0) && <sup className={props?.subValue > 0 ? classes.positive: classes.negative}>{`(${props?.subValue}%)`}</sup>}*/}
        </Box>
    )
};

CustomCell.propTypes = {
    currency: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};
CustomCell.defaultProps = {
    currency: false
};



const profitHeaderMap = [
    'Revenue',
    'Profit',
    'Predictive revenue',
    'Predictive profit',
    'Revenue (iTunes)',
    'Profit (iTunes)',
    'Cost per purchase',
    'Purchases count',
    'Subscribes count',
];


const DEFAULT_SPEND_GROUP = ['sum'];

function DailySpendReport(props) {

    const styles = useStyles();

    const [spendGroup, setSpendGroup] = useState(DEFAULT_SPEND_GROUP);
    const conversionsCellCache = new CellMeasurerCache({
        defaultWidth: 90,
        minWidth: 90,
        fixedHeight: true,
        fixedWidth: false
    });

    const bodyBlock = useRef(null);
    const [_width, _height] = useWindowSize();

    const changeSpendGroupHandler = () => {
        if (spendGroup.length === 1)
            setSpendGroup(spendGroups);
        else
            setSpendGroup(DEFAULT_SPEND_GROUP);
    };

    const getSpendHeaderName = (alias) => {
        return displayNames?.[alias];
    };

    const data = (props.reports.data2() || []);

    const columnWidth = 100;
    // const overscanColumnCount = 10;
    // const rowCount = data.reduce((prev, current) => prev + 1 + current.dates?.length||0, 0);

    // const height = Math.min(rowCount * 30, 8*30);

    // const maxRowsVisible = (rowCount < 8 ? rowCount : 8);

    const conversionsCount = Array.from((props.reports.data || []).reduce((pV, {payments, subscriptions}) => {
        const paymentInfo  = payments.info;
        paymentInfo.forEach(items => {
            items.forEach((item) => {
                pV.add({
                    filter_id: `payments.${item.product_id}`,
                    product_id: item.product_id,
                    type: 'payments',
                    count: item.count,
                    value: item.count,
                    columnCount: 1
                });
            });
        });

        const subscriptionsInfo  = subscriptions.info;
        subscriptionsInfo.forEach(items => {
            items.forEach((item) => {
                pV.add({
                    product_id: item.product_id,
                    filter_id: `subscriptions.${item.product_id}`,
                    type: 'subscriptions',
                    count: item.count,
                    value: item.count,
                    columnCount: item.hasOwnProperty('trials') ? 2 : 1
                });

                if (item.hasOwnProperty('trials')){
                    pV.add({
                        product_id: item.product_id,
                        filter_id: `subscriptions.trials.${item.product_id}`,
                        type: 'subscriptions',
                        count: item.count,
                        trials: item.trials,
                        value: item.trials,
                        columnCount: 2
                    });
                }
            });
        });

        return pV;

    }, new Set()))
        .filter((item, index, items) =>
            items.findIndex(({filter_id}) => filter_id === item.filter_id) === index
        ).sort((a, b) => {
            if (a.type < b.type) return 1;
            if (a.type > b.type) return -1;
            return 0;
        });

    const headerConversions = conversionsCount
        .filter((item, index, items) =>
            items.findIndex(({product_id}) => product_id === item.product_id) === index
        )
        .sort((a, b) => {
            if (a.type < b.type) return 1;
            if (a.type > b.type) return -1;

            if (a.columnCount < b.columnCount) return -1;
            if (a.columnCount > b.columnCount) return 1;
            return 0;
        });

    const countHeaderColumnWithSubscription = conversionsCount.reduce((p, {type}) => p + (type === 'subscriptions' ? 1 : 0) , 0);
    const countHeaderColumnWithPayments     = conversionsCount.reduce((p, {type}) => p + (type === 'payments' ? 1 : 0) , 0);

    const CONVERSIONS_TABLE_COUNT_COLUMNS = conversionsCount.length;

    const _renderLeftHeaderCell = ({key, style}) => {
        return (
            <div key={key}
                 className={styles.HeaderTitle}
                 style={{
                     ...style,
                     ...{
                         backgroundColor:'#fff',
                         textAlign: 'center',
                         padding: '50% 0'
                     }
                 }}>
                Date
            </div>
        );
    };

    let isNeedLoadReports = useRef(true);
    let isNeedLoadSummary = useRef(true);
    let mainGridRef = useRef(null);

    const tableHeaderRef = useRef(null);
    const tableBodyRef = useRef(null);
    const datesRef = useRef(null);
    const profitTable = useRef(null);
    const spendTable = useRef(null);

    useEffect(() => {

            if (props.reports.summary === null && props.reports.pending === false && props.project?.active?.id && props.reports.error === null
                && isNeedLoadSummary.current
            ) {
                isNeedLoadSummary.current = false;
                props.getProjectReportSummary({projectId: props.project.active.id})
            }
            else if (props.reports.data === null && props.reports.pending === false && props.project?.active?.id && props.reports.error === null
                && isNeedLoadReports.current
            ) {
                isNeedLoadReports.current = false;
                props.getReports({projectId: props.project.active.id, limit: 5});
            }
            if (tableHeaderRef?.current && props.reports.pending === false && props.reports.data !== null) {
                tableHeaderRef.current.recomputeGridSize();
            }
            if (tableBodyRef?.current && props.reports.pending === false && props.reports.data !== null) {
                tableBodyRef.current.recomputeGridSize();
            }
            if (spendTable?.current && props.reports.pending === false && props.reports.data !== null) {
                spendTable.current.recomputeGridSize();
            }
        },
    [spendGroup, props]
    );


    const _renderSocialSpendHeaderCells = ({key, style, width}) => {
        return (
            <div key={key}
                 style={{
                     ...style,
                     ...{
                         backgroundColor: '#F9ECCA'
                     }
                 }}>
                <Box align={'center'} >Spend, USD</Box>

                <Box align={'center'} >
                    <Button variant={'contained'}
                            color={'primary'}
                            startIcon={spendGroup.length === 1 ? <ArrowLeftIcon/> : <ArrowRightIcon/>}
                            endIcon={spendGroup.length === 1 ? <ArrowRightIcon/> : <ArrowLeftIcon/>}
                            onClick={changeSpendGroupHandler}
                    >{spendGroup.length === 2 ? 'Show' : 'Hide'}</Button>
                </Box>

                <ColumnSizer
                    columnMaxWidth={spendGroup.length === 1 ? 120 : 90}
                    columnMinWidth={spendGroup.length === 1 ? 120 : 90}
                    columnCount={spendGroup.length}
                    width={width - scrollbarSize()}>
                    {() => (
                        <div
                            style={{
                                height: 30,
                                textAlign: 'center',
                                textTransform: 'capitalize',
                                position: 'absolute',
                                bottom: 0,
                            }}>
                            <Grid
                                columnWidth={spendGroup.length === 1 ? 120 : 90}
                                columnCount={spendGroup.length}
                                height={30}
                                rowHeight={30}
                                rowCount={1}
                                width={spendGroup.length * (spendGroup.length === 1 ? 120 : 90)}
                                noContentRenderer={() => <Skeleton animation={'wave'} variant={'text'} />}
                                cellRenderer={({columnIndex, key, style}) => {
                                    return (
                                        <div key={key}
                                             style={style}
                                             className={styles.HeaderTitle}>
                                            <span>
                                                {getSpendHeaderName(spendGroup[columnIndex])}
                                            </span>
                                        </div>
                                    )
                                }}/>
                        </div>
                    )}
                </ColumnSizer>
            </div>
        )
    };

    const _calcConversionValues = ({weekDayIndex, rowIndex, countDaysInWeek, type, product_id}) => {
        let value = 0;
        let trials = 0;

        const {info} = data[weekDayIndex][type];

        if (countDaysInWeek-1 !== rowIndex) {
            value = (info[rowIndex]).find(item => item.product_id === product_id)?.count || 0;
            trials = (info[rowIndex]).find(item => item.product_id === product_id)?.trials || 0;
        }
        else {
            info.forEach(_info => {
                const item = _info.find(item => item.product_id === product_id);
                value += item?.count || 0;
                trials += item?.trials || 0;
            });
        }

        return {
            value,
            trials
        }
    };

    const _calcProfitValues = (values, countDaysInWeek, rowIndex) => {

        if (countDaysInWeek - 1 === rowIndex)
            return (values || []).reduce((p, c) => p + c, 0);

        return values[rowIndex];
    };

    const _handleUpdateSpendValue = (date, source, value, weekDayIndex, rowIndex) => {

        props.postProjectReportsSpend({
            projectId: props.project.active.id,
            date,
            source,
            value,
            weekDayIndex,
            rowIndex});
    };

    const _handleUpdateRevenueValue = (date, value, weekDayIndex, rowIndex) => {

        props.postProjectReportsRevenue({
            projectId: props.project.active.id,
            date,
            value,
            weekDayIndex, rowIndex})
    };

    const maxHeight = (_height < 500 ? 500 : _height) - (bodyBlock?.current?.getBoundingClientRect()?.top || 100) - 50;

    return (
        <Box ref={bodyBlock}
             pb={3}
             height={maxHeight}
        >
            <ScrollSync>
                    {({
                        onScroll,
                        scrollLeft,
                        scrollTop,
                    }) => {
                        return (
                            <React.Fragment>

                                {props.reports.pending === true && <CircularProgress className={styles.buttonProgress}/>}

                                <Box style={{
                                    height: 50,
                                    background: '#F0F3F9',
                                    borderRadius: '7px 7px 0px 0px',
                                }}
                                     display="flex"
                                     px={3}
                                >
                                    <Box my={'auto'} pr={3}>
                                        <Box component={'span'}
                                             variant={'body2'}
                                             color={'#7D889A'}>Total spend:</Box>
                                        <b>
                                            &nbsp;$&nbsp;
                                            {props.reports?.summary?.total_spend.toLocaleString('en-US') || 'Loading...'}
                                        </b>
                                    </Box>
                                    <Box my={'auto'} pr={3}>
                                        <Box component={'span'}
                                             variant={'body2'}
                                             color={'#7D889A'}>Total rev:</Box>
                                        <b>
                                        &nbsp;$&nbsp;
                                            {props.reports?.summary?.total_revenue.toLocaleString('en-US') || 'Loading...'}
                                        </b>
                                    </Box>
                                    <Box my={'auto'} pr={3}>
                                        <Box component={'span'}
                                             variant={'body2'}
                                             color={'#7D889A'}>Total profit:</Box>
                                        <b>
                                        &nbsp;$&nbsp;
                                            {props.reports?.summary?.total_profit.toLocaleString('en-US') || 'Loading...'}
                                        </b>
                                    </Box>

                                </Box>

                                <div className={styles.GridRow}>
                                <div
                                    className={styles.LeftSideGridContainer}
                                    style={{
                                        position: 'absolute',
                                        left: 0,
                                        top: 0,
                                    }}>
                                    <Grid
                                        ref={mainGridRef}
                                        cellRenderer={_renderLeftHeaderCell}
                                        className={styles.HeaderGrid}
                                        width={columnWidth}
                                        height={FIRST_ROW_HEIGHT}
                                        rowHeight={FIRST_ROW_HEIGHT}
                                        columnWidth={columnWidth}
                                        rowCount={1}
                                        columnCount={1}

                                    />
                                </div>

                                <div className={styles.LeftSideGridContainer}
                                     style={{
                                         position: 'absolute',
                                         left: 0,
                                         top: FIRST_ROW_HEIGHT,
                                         backgroundColor: '#fff',
                                         boxShadow: '3px 3px 12px rgba(0, 0, 0, 0.1)',
                                         height: (maxHeight - 152 - scrollbarSize())
                                     }}
                                >
                                    <Grid ref={datesRef}
                                          // overscanColumnCount={1}
                                          overscanRowCount={3}
                                          columnWidth={100}
                                          columnCount={1}
                                          style={{height: maxHeight-152}}
                                          className={styles.LeftSideGrid}
                                          height={maxHeight-152}
                                          rowHeight={({index}) => {
                                              let countDays = data[index]?.dates.length || 1;
                                              countDays++;
                                              return countDays * 30 + 21;
                                          }}
                                          rowCount={data.length}
                                          scrollTop={scrollTop}
                                          width={100}

                                          cellRenderer={(dateParams) => {
                                              const {key, rowIndex, style} = dateParams;
                                              const dates = data[rowIndex]?.dates || [];
                                              const countDaysInWeek = 1 + dates.length;

                                              return (
                                                  <Grid
                                                        key={key}
                                                        style={style}
                                                        columnCount={1}
                                                        columnWidth={100}
                                                        height={countDaysInWeek * 30 - scrollbarSize()}
                                                        rowHeight={30}
                                                        rowCount={countDaysInWeek} // count days
                                                        width={100}
                                                        noContentRenderer={() => <Skeleton animation={'wave'} variant={'text'} />}
                                                        cellRenderer={({key, rowIndex, style}) => {

                                                            if (countDaysInWeek-1 === rowIndex)
                                                                style = {...style, ...{fontWeight:'bold'}};

                                                            return (
                                                                <CustomCell key={key}
                                                                            align={'left'}
                                                                            style={{...style, ...{lineHeight:'30px', paddingLeft: 16}}}
                                                                            value={dates?.[rowIndex] || 'On week'}
                                                                />
                                                            )
                                                        }}
                                              />)
                                          }}
                                    />
                                </div>

                                <div className={styles.GridColumn}>
                                    <AutoSizer>
                                        {({width}) => (
                                            <div>
                                                <div
                                                    style={{
                                                        backgroundColor: '#fff',
                                                        height: 30,
                                                        width: _width - 48 - scrollbarSize(),
                                                    }}>
                                                    <Grid ref={tableHeaderRef}
                                                          className={styles.HeaderGrid}
                                                          columnCount={1 + COUNT_SUB_TABLES}
                                                          overscanColumnCount={1}
                                                          // overscanRowCount={3}
                                                          scrollLeft={scrollLeft}
                                                          height={FIRST_ROW_HEIGHT}
                                                          rowHeight={FIRST_ROW_HEIGHT}
                                                          rowCount={1}
                                                          width={_width - 48 - scrollbarSize()}
                                                          columnWidth={({index}) => {
                                                              if (index === 1) return spendGroup.length * (spendGroup.length === 1 ? 120 : 90);
                                                              if (index === 2) return 13 * 90;
                                                              if (index === 3) return CONVERSIONS_TABLE_COUNT_COLUMNS * 90;
                                                              return 100
                                                          }}
                                                          noContentRenderer={() => <Skeleton animation={'wave'} variant={'text'} />}
                                                          cellRenderer={({columnIndex, key, style}) => {
                                                              if (columnIndex < 1) {
                                                                  return;
                                                              }

                                                              if (columnIndex === 1) {
                                                                  return _renderSocialSpendHeaderCells({key, style, width})
                                                              }

                                                              if (columnIndex === 2) {
                                                                  return (
                                                                      <div
                                                                          key={key}
                                                                          style={{
                                                                              ...style,
                                                                              ...{
                                                                                  height: FIRST_ROW_HEIGHT,
                                                                                  width: _width,
                                                                                  backgroundColor: '#BAF0ED',
                                                                                  boxShadow: 'inset 0px 0px 0px 1px #fff'
                                                                              }}}
                                                                      >
                                                                          <Box align={'center'}
                                                                               style={{
                                                                                   height: 30,
                                                                                   width: _width,
                                                                                   borderBottom: '2px solid #fff',
                                                                                   lineHeight: '30px',
                                                                               }}
                                                                          >
                                                                              Name of table
                                                                          </Box>

                                                                          <ColumnSizer
                                                                              style={{boxShadow: 'inset 0px 0px 0px 1px #fff'}}
                                                                              columnMaxWidth={90}
                                                                              columnMinWidth={90}
                                                                              columnCount={13}
                                                                              width={width}>
                                                                              {() => (
                                                                                  <div style={{
                                                                                           height: 30,
                                                                                           textAlign: 'center',
                                                                                           textTransform: 'capitalize',
                                                                                       }}>

                                                                                       <Grid
                                                                                           columnWidth={() => {
                                                                                               return 90;
                                                                                           }}
                                                                                           columnCount={13}
                                                                                           height={FIRST_ROW_HEIGHT - 30}
                                                                                           rowHeight={FIRST_ROW_HEIGHT - 30}
                                                                                           rowCount={1}
                                                                                           width={PROFIT_TABLE_COUNT_COLUMNS * 90}
                                                                                           noContentRenderer={() => <div>Nothing to show</div>}
                                                                                           cellRenderer={({columnIndex, key, style}) => {

                                                                                               if (columnIndex > (PROFIT_TABLE_COUNT_COLUMNS - TRIAL_SECTION_COLUMN_COUNT)) return;

                                                                                               if (columnIndex === (PROFIT_TABLE_COUNT_COLUMNS - TRIAL_SECTION_COLUMN_COUNT)) {
                                                                                                   return (
                                                                                                       <div key={key}
                                                                                                            style={style}
                                                                                                       >
                                                                                                           <Box
                                                                                                               align={'center'}
                                                                                                               className={styles.HeaderTitle}
                                                                                                               style={{
                                                                                                                   height: 30,
                                                                                                                   backgroundColor: '#A4E3E0',
                                                                                                                   width: 90 * 4,
                                                                                                                   lineHeight: '30px',
                                                                                                                   boxShadow: 'inset 1px -1px 0px 1px #fff',
                                                                                                               }}
                                                                                                           >
                                                                                                               <span>Trial section</span>
                                                                                                           </Box>

                                                                                                           <ColumnSizer
                                                                                                               columnMaxWidth={90}
                                                                                                               columnMinWidth={90}
                                                                                                               columnCount={TRIAL_SECTION_COLUMN_COUNT}
                                                                                                               width={90 * 4}>

                                                                                                               {() => (
                                                                                                                   <div
                                                                                                                       style={{
                                                                                                                           height: 30,
                                                                                                                           textAlign: 'center',
                                                                                                                           textTransform: 'capitalize',
                                                                                                                       }}>

                                                                                                                       <Grid
                                                                                                                           columnWidth={90}
                                                                                                                           columnCount={4}
                                                                                                                           height={FIRST_ROW_HEIGHT - 50}
                                                                                                                           rowHeight={FIRST_ROW_HEIGHT - 50}
                                                                                                                           rowCount={1}
                                                                                                                           width={4 * 90}
                                                                                                                           noContentRenderer={() =>
                                                                                                                               <div>Nothing to show</div>}
                                                                                                                           cellRenderer={({columnIndex, key, style}) => {

                                                                                                                               const subProfitHeaderMap = {
                                                                                                                                   0: 'Cost trial',
                                                                                                                                   1: 'Trial count',
                                                                                                                                   2: 'Conversion count',
                                                                                                                                   3: 'Conversion trial/purchase',
                                                                                                                               };

                                                                                                                               return <Box align={'center'}
                                                                                                                                           className={styles.HeaderTitle}
                                                                                                                                           key={key}
                                                                                                                                           style={style}
                                                                                                                               >
                                                                                                                                   <span>
                                                                                                                                       {subProfitHeaderMap?.[columnIndex]}
                                                                                                                                   </span>
                                                                                                                               </Box>
                                                                                                                           }}
                                                                                                                       />
                                                                                                                   </div>
                                                                                                               )}
                                                                                                           </ColumnSizer>
                                                                                                       </div>
                                                                                                   );
                                                                                               }

                                                                                               if (columnIndex === 5 || columnIndex === 8) {
                                                                                                   style = {...style, ...{borderRight: '2px solid #fff'}};
                                                                                               }

                                                                                               return (
                                                                                                   <div key={key}
                                                                                                        className={styles.HeaderTitle}
                                                                                                        style={{
                                                                                                            ...style,
                                                                                                            ...{
                                                                                                                height: FIRST_ROW_HEIGHT - 30,
                                                                                                                bottom: 0,
                                                                                                                top: 'unset',
                                                                                                            }
                                                                                                        }}>
                                                                                                       <span>
                                                                                                           {profitHeaderMap?.[columnIndex]}
                                                                                                       </span>
                                                                                                   </div>
                                                                                               )
                                                                                           }}
                                                                                       />
                                                                                  </div>
                                                                              )}
                                                                          </ColumnSizer>
                                                                      </div>
                                                                    )
                                                              }

                                                              if (columnIndex === 3) {

                                                                  return (
                                                                      <div
                                                                          key={key}
                                                                          style={{...style, ...{backgroundColor: '#D2EFDD'}}}>
                                                                          <Box align={'center'}
                                                                               className={styles.HeaderTitle}
                                                                               style={{
                                                                                   height: 30,
                                                                                   lineHeight: '30px'
                                                                               }}
                                                                          >
                                                                              <span>
                                                                                  Conversions
                                                                              </span>
                                                                          </Box>

                                                                          <ColumnSizer
                                                                              columnMaxWidth={90}
                                                                              columnMinWidth={90}
                                                                              columnCount={2}
                                                                              width={90 * CONVERSIONS_TABLE_COUNT_COLUMNS}>

                                                                              {() => (
                                                                                  <div
                                                                                      style={{
                                                                                          height: 30,
                                                                                          textAlign: 'center',
                                                                                          textTransform: 'capitalize',
                                                                                      }}>

                                                                                      <Grid columnCount={2}
                                                                                            height={30}
                                                                                            rowHeight={30}
                                                                                            rowCount={1}
                                                                                            columnWidth={({index})=>{

                                                                                                return 90 * (index === 0 ?
                                                                                                    countHeaderColumnWithSubscription :
                                                                                                    countHeaderColumnWithPayments)
                                                                                            }}

                                                                                            width={CONVERSIONS_TABLE_COUNT_COLUMNS * 90}
                                                                                            noContentRenderer={() => <div>Nothing to show</div>}
                                                                                            cellRenderer={({columnIndex, key, style}) => {
                                                                                                const subProfitHeaderMap = [
                                                                                                    'Subscriptions',
                                                                                                    'Payments',
                                                                                                ];

                                                                                            style = {
                                                                                                ...style,

                                                                                                ...{
                                                                                                    borderLeft: '1px solid rgb(255, 255, 255)',
                                                                                                    borderRight: '1px solid rgb(255, 255, 255)',
                                                                                                    backgroundColor: columnIndex === 0 ? '#ceeadc' : 'transparent'
                                                                                                }
                                                                                            };

                                                                                            return <Box key={key}
                                                                                                        align={'center'}
                                                                                                        style={style}
                                                                                                        className={styles.HeaderTitle}>
                                                                                                <span>
                                                                                                    {subProfitHeaderMap?.[columnIndex]}
                                                                                                </span>
                                                                                            </Box>
                                                                                      }}
                                                                                      />
                                                                                  </div>
                                                                              )}
                                                                          </ColumnSizer>

                                                                          <ColumnSizer
                                                                              columnMaxWidth={90}
                                                                              columnMinWidth={90}
                                                                              columnCount={CONVERSIONS_TABLE_COUNT_COLUMNS}
                                                                              width={90 * (CONVERSIONS_TABLE_COUNT_COLUMNS) || 1}>
                                                                              {({adjustedWidth}) => (
                                                                                  <div
                                                                                      style={{
                                                                                          height: 60,
                                                                                          textAlign: 'center',
                                                                                          textTransform: 'capitalize',
                                                                                          position: 'absolute',
                                                                                          bottom: 0,
                                                                                      }}>
                                                                                      <Grid
                                                                                          columnWidth={({index}) => {
                                                                                              return headerConversions[index]?.columnCount * 90
                                                                                          }}
                                                                                          columnCount={headerConversions.length}
                                                                                          height={60}
                                                                                          rowHeight={60}
                                                                                          rowCount={1}
                                                                                          width={adjustedWidth}
                                                                                          noContentRenderer={() => <div>Nothing to show</div>}
                                                                                          cellRenderer={({columnIndex, key, style, parent, rowIndex}) => {

                                                                                              return (
                                                                                                  <CellMeasurer
                                                                                                      cache={conversionsCellCache}
                                                                                                      columnIndex={columnIndex}
                                                                                                      key={key}
                                                                                                      parent={parent}
                                                                                                      rowIndex={rowIndex}
                                                                                                  >
                                                                                                      <div
                                                                                                          style={{...style, ...{wordBreak: 'break-all', backgroundColor: '#D2EFDD'}}}>
                                                                                                          <Box align={'center'}
                                                                                                               className={styles.HeaderTitle}
                                                                                                               style={{
                                                                                                                   borderLeft: '1px solid #fff',
                                                                                                                   borderRight: '1px solid #fff',
                                                                                                                   height: 'inherit'
                                                                                                               }} >
                                                                                                              <span>
                                                                                                                 {headerConversions[columnIndex]['product_id']}
                                                                                                              </span>
                                                                                                          </Box>
                                                                                                      </div>
                                                                                                  </CellMeasurer>
                                                                                              )
                                                                                          }}/>
                                                                                  </div>
                                                                              )}
                                                                          </ColumnSizer>
                                                                      </div>
                                                                  )
                                                              }
                                                        }}
                                                    />
                                                </div>

                                                <div style={{
                                                    height: (maxHeight - 152),
                                                    width,
                                                    top: FIRST_ROW_HEIGHT,
                                                    position:'absolute',
                                                }}
                                                >
                                                    <Grid ref={tableBodyRef}
                                                          className={styles.BodyGrid}
                                                          style={{height: (maxHeight - 152 - scrollbarSize())}}
                                                          overscanColumnCount={1}
                                                          overscanRowCount={3}
                                                          rowCount={data.length}
                                                          width={width}
                                                          onScroll={(data) => {

                                                              if (data.scrollHeight - (data.clientHeight + data.scrollTop) < 300 &&
                                                                  props.reports.pending === false &&
                                                                  props.project?.active?.id)
                                                              {
                                                                  props.getReports({projectId: props.project.active.id, offset: props.reports.data?.length || 0})
                                                              }
                                                              onScroll(data);
                                                          }}

                                                          columnWidth={({index}) => {
                                                              if (index === 1) return spendGroup.length * (spendGroup.length === 1 ? 120 : 90);
                                                              if (index === 2) return PROFIT_TABLE_COUNT_COLUMNS * 90;
                                                              if (index === 3) return CONVERSIONS_TABLE_COUNT_COLUMNS * 90;
                                                              return 100
                                                          }}
                                                          columnCount={4}
                                                          height={maxHeight - 152 - scrollbarSize()}
                                                          rowHeight={({index}) => {
                                                              let countDays = data[index]?.dates.length || 1;
                                                              countDays++;
                                                              return countDays * 30 + 21;
                                                          }}

                                                          noContentRenderer={() => {

                                                              if (props.reports.pending === true && props.reports.data === null)
                                                                  return 'Loading...';

                                                              return "No data";
                                                          }}

                                                          cellRenderer={(dateParams) => {

                                                              const {columnIndex, key, rowIndex: weekDayIndex, style } = dateParams;



                                                              const dates = data[weekDayIndex]?.dates || [];
                                                              const countDaysInWeek = 1 + dates.length;

                                                              if (columnIndex === 1) { // render spend columns
                                                                  return (
                                                                      <Grid
                                                                          ref={spendTable}
                                                                          key={key}
                                                                          className={styles.Spends}
                                                                          style={style}
                                                                          columnCount={spendGroup.length}
                                                                          columnWidth={spendGroup.length === 1 ? 120 : 90}
                                                                          height={countDaysInWeek * 30 - scrollbarSize()}
                                                                          rowHeight={30}
                                                                          rowCount={countDaysInWeek} // count days
                                                                          width={spendGroup.length * (spendGroup.length === 1 ? 120 : 90)}
                                                                          noContentRenderer={() => <Skeleton animation={'wave'} variant={'text'} />}
                                                                          cellRenderer={({key, rowIndex, columnIndex, style, isScrolling}) => {

                                                                              if (isScrolling)
                                                                                  return <Skeleton animation={'wave'} variant={'text'} />;

                                                                              let totalClassName = '';
                                                                              if (countDaysInWeek - 1 === rowIndex) {
                                                                                  style = {...style, ...{fontWeight: 'bold'}};
                                                                                  totalClassName = clsx(totalClassName, styles.conversionsTotalRow);
                                                                              }

                                                                              if (spendGroup.length-1 === columnIndex){
                                                                                  totalClassName = clsx(totalClassName, styles.totalSpendColumn)
                                                                              }

                                                                              const spendKey = spendGroup[columnIndex];

                                                                              const value = _calcProfitValues(data[weekDayIndex]?.spends[spendKey], countDaysInWeek, rowIndex);

                                                                              if (spendGroup.length-1 === columnIndex || countDaysInWeek - 1 === rowIndex)
                                                                                  return (
                                                                                      <CustomCell key={key}
                                                                                                  currency={true}
                                                                                                  className={totalClassName}
                                                                                                  style={{...style, ...{lineHeight: '30px'}}}
                                                                                                  value={value}
                                                                                      />
                                                                                  );

                                                                              return (
                                                                                  <Box key={key} style={style} align={'end'} className={styles.span}>
                                                                                      <InlineEdit
                                                                                          type={'number'}
                                                                                          currency
                                                                                          text={value}
                                                                                          onSetText={newValue => _handleUpdateSpendValue(data[weekDayIndex]['dates'][rowIndex], spendKey, newValue, weekDayIndex, rowIndex)}
                                                                                      />
                                                                                  </Box>
                                                                              );
                                                                          }}
                                                                      />);
                                                              }
                                                              else if (columnIndex === 2) { // render profit columns

                                                                  let payments = {}, revenue= {}, profit= {}, spends= {}, subscriptions = {};

                                                                  if (data[weekDayIndex]) {
                                                                      payments = data[weekDayIndex]?.payments;
                                                                      revenue = data[weekDayIndex]?.revenue;
                                                                      profit = data[weekDayIndex]?.profit;
                                                                      spends = data[weekDayIndex]?.spends;
                                                                      subscriptions = data[weekDayIndex]?.subscriptions;
                                                                  }

                                                                  return (
                                                                      <Grid
                                                                          ref={profitTable}
                                                                          key={key}
                                                                          className={styles.Profits}
                                                                          style={style}
                                                                          columnCount={13}
                                                                          columnWidth={90}
                                                                          height={countDaysInWeek * 30 - scrollbarSize()}
                                                                          rowHeight={30}
                                                                          rowCount={countDaysInWeek} // count days
                                                                          width={13 * 90}
                                                                          noContentRenderer={() => <Skeleton animation={'wave'} variant={'text'} />}
                                                                          cellRenderer={({key, rowIndex, columnIndex, style, isScrolling}) => {

                                                                              if (isScrolling)
                                                                                  return <Skeleton animation={'wave'} variant={'text'} />;

                                                                              let totalClassName = '';
                                                                              let subValue = null;
                                                                              if (countDaysInWeek - 1 === rowIndex) {
                                                                                  style = {...style, ...{fontWeight: 'bold'}};
                                                                                  totalClassName = clsx(totalClassName, styles.conversionsTotalRow);
                                                                              }

                                                                              if (columnIndex === 5 || columnIndex === 8) {
                                                                                  totalClassName = clsx(totalClassName, styles.borderRight);
                                                                              } else if (columnIndex === 6 || columnIndex === 9) {
                                                                                  style = {...style, ...{width: 89}};
                                                                                  totalClassName = clsx(totalClassName, styles.borderLeft);
                                                                              }

                                                                              let value = '';
                                                                              let currency = false;

                                                                              // Revenue
                                                                              if (columnIndex === 0) {
                                                                                  value = _calcProfitValues(revenue?.value, countDaysInWeek, rowIndex);
                                                                                  value = round(value, 2);
                                                                                  currency = true;
                                                                                  totalClassName = clsx(totalClassName, countDaysInWeek - 1 !== rowIndex ? styles.white : styles.conversionsTotalRow);
                                                                              }
                                                                              else if (columnIndex === 1) { // Profit
                                                                                  value = _calcProfitValues(profit?.value,  countDaysInWeek, rowIndex);
                                                                                  value = round(value, 2);
                                                                                  currency = true;
                                                                                  totalClassName = clsx(totalClassName, countDaysInWeek - 1 !== rowIndex ? styles.white : styles.conversionsTotalRow);
                                                                              }
                                                                              else if (columnIndex === 2) { // Predictive revenue
                                                                                  value = _calcProfitValues(revenue?.value_manual,  countDaysInWeek, rowIndex);
                                                                                  value = round(value, 2);
                                                                                  currency = true;
                                                                              }
                                                                              else if (columnIndex === 3) { // Predictive profit
                                                                                  const revenueValue = _calcProfitValues(revenue?.value_manual,  countDaysInWeek, rowIndex);
                                                                                  const spendSum = _calcProfitValues(spends?.sum,  countDaysInWeek, rowIndex);
                                                                                  value = revenueValue - spendSum;
                                                                                  value = round(value, 2);
                                                                                  currency = true;

                                                                                  totalClassName = clsx(totalClassName, value < 0 ? styles.negative : styles.positive);

                                                                                  subValue = round(saveDivide((value - profit.value_manual_roi), value) * 100, 0);
                                                                              }
                                                                              else if (columnIndex === 4) { // Revenue (iTunes)
                                                                                  value = _calcProfitValues(revenue?.value_cursive,  countDaysInWeek, rowIndex);
                                                                                  value = round(value, 2);
                                                                                  currency = true;

                                                                                  if (countDaysInWeek-1 !== rowIndex)
                                                                                      return (
                                                                                          <Box key={key} style={style} align={'end'} className={styles.span}>
                                                                                              <InlineEdit
                                                                                                  type={'number'}
                                                                                                  currency
                                                                                                  text={value}
                                                                                                  onSetText={newValue => _handleUpdateRevenueValue(data[weekDayIndex]['dates'][rowIndex], newValue, weekDayIndex, rowIndex)}
                                                                                              />
                                                                                          </Box>
                                                                                      );
                                                                              }
                                                                              else if (columnIndex === 5) { // Profit (iTunes)
                                                                                  value = _calcProfitValues(profit?.value_cursive,  countDaysInWeek, rowIndex);
                                                                                  value = round(value, 2);
                                                                                  totalClassName = clsx(totalClassName, value < 0 ? styles.negative : styles.positive);
                                                                                  currency = true;
                                                                              }
                                                                              else if (columnIndex === 6) { // Cost Per Purchase
                                                                                  value = _calcProfitValues(payments?.cost,countDaysInWeek, rowIndex);

                                                                                  if (countDaysInWeek-1 === rowIndex) {
                                                                                      const profitValue = _calcProfitValues(profit?.value_cursive,  countDaysInWeek, rowIndex);
                                                                                      const valueCount = _calcProfitValues(payments?.count,  countDaysInWeek, rowIndex);

                                                                                      value = Math.abs(round(saveDivide(profitValue, valueCount), 2));
                                                                                  }

                                                                                  currency = true;
                                                                              }
                                                                              else if (columnIndex === 7) { // Purchases Count
                                                                                  value = _calcProfitValues(payments?.count,  countDaysInWeek, rowIndex);
                                                                                  value = round(value, 2);
                                                                              }
                                                                              else if (columnIndex === 8) { // Subscribes Count
                                                                                  value = _calcProfitValues(subscriptions?.count,  countDaysInWeek, rowIndex);
                                                                                  value = round(value, 2);
                                                                              }
                                                                              else if (columnIndex === 9) { // Cost Trial
                                                                                  // if (countDaysInWeek - 1 !== rowIndex) {
                                                                                      const cost = _calcProfitValues(subscriptions?.cost, countDaysInWeek, rowIndex);
                                                                                      const countTrials = _calcProfitValues(subscriptions?.count_trials,  countDaysInWeek, rowIndex);
                                                                                      value = round(saveDivide(cost, countTrials), 2);
                                                                                      currency = true;
                                                                                  // }
                                                                                  // const spendSum = _calcProfitValues(spends?.sum,  countDaysInWeek, rowIndex);

                                                                                  // value = round(saveDivide(spendSum, trialCount), 2);
                                                                                  // currency = value !== 0;
                                                                                  // value = value || '-'
                                                                              }
                                                                              else if (columnIndex === 10) { // Trial Count
                                                                                  value = _calcProfitValues(subscriptions?.count_trials,  countDaysInWeek, rowIndex);
                                                                                  value = round(value, 2);
                                                                              }
                                                                              else if (columnIndex === 11) { // Conversion Count
                                                                                  value = _calcProfitValues(subscriptions?.count_conversions,  countDaysInWeek, rowIndex);
                                                                                  value = round(value, 2);
                                                                              }
                                                                              else if (columnIndex === 12) { // Conversion Trial/Purchase

                                                                                  if (countDaysInWeek - 1 === rowIndex) {

                                                                                      // const sumCountConversions = _calcProfitValues(subscriptions?.count_conversions,  countDaysInWeek, rowIndex);
                                                                                      // const sumCountTrials = _calcProfitValues(subscriptions?.count_trials,  countDaysInWeek, rowIndex);
                                                                                      // value = '-';  // saveDivide(sumCountConversions, sumCountTrials);

                                                                                  } else {
                                                                                      value = _calcProfitValues(subscriptions?.conversions_rate,  countDaysInWeek, rowIndex);
                                                                                      value = round(value, 2);
                                                                                  }


                                                                              }

                                                                              return (
                                                                                  <CustomCell key={key}
                                                                                              currency={currency}
                                                                                              className={totalClassName}
                                                                                              style={{...style, ...{lineHeight: '30px'}}}
                                                                                              value={value}
                                                                                              subValue={subValue}
                                                                                  />
                                                                              )
                                                                          }}
                                                                      />);
                                                              }
                                                              else if (columnIndex === 3) {// conversions columns
                                                                  return (
                                                                      <Grid
                                                                          className={styles.Conversions}
                                                                          key={key}
                                                                          style={style}
                                                                          columnWidth={({index}) => {
                                                                              return headerConversions[index]?.columnCount * 90
                                                                          }}
                                                                          columnCount={headerConversions.length}
                                                                          height={countDaysInWeek * 30 - scrollbarSize()}
                                                                          rowHeight={30}
                                                                          rowCount={countDaysInWeek} // count days
                                                                          width={CONVERSIONS_TABLE_COUNT_COLUMNS * 90}
                                                                          noContentRenderer={() => <Skeleton
                                                                              animation={'wave'} variant={'text'}/>}
                                                                          cellRenderer={({key, rowIndex, columnIndex, style, isScrolling}) => {

                                                                              if (isScrolling)
                                                                                  return <Skeleton animation={'wave'}
                                                                                                   variant={'text'}/>;

                                                                              const {product_id, type, columnCount} = headerConversions[columnIndex];

                                                                              const {value, trials} = _calcConversionValues({
                                                                                  weekDayIndex,
                                                                                  rowIndex,
                                                                                  countDaysInWeek,
                                                                                  type,
                                                                                  product_id
                                                                              });

                                                                              style = {...style, ...{lineHeight: '30px'}};
                                                                              // check if total
                                                                              let totalClassName = '';
                                                                              if (countDaysInWeek - 1 === rowIndex) {
                                                                                  style = {...style, ...{fontWeight: 'bold'}};
                                                                                  totalClassName = styles.conversionsTotalRow;
                                                                              }

                                                                              if (columnCount === 2) {
                                                                                  return <Box display="flex"
                                                                                              justifyContent="center"
                                                                                              key={key}
                                                                                              style={{
                                                                                                  ...style,
                                                                                                  ...{
                                                                                                      backgroundColor: '#d7f7f4'
                                                                                                  }
                                                                                              }}
                                                                                              className={totalClassName}>
                                                                                      <CustomCell align={'center'}
                                                                                                  width={'50%'}
                                                                                                  value={trials}/>
                                                                                      <CustomCell align={'center'}
                                                                                                  width={'50%'}
                                                                                                  value={value}/>
                                                                                  </Box>
                                                                              }

                                                                              return (
                                                                                  <CustomCell align={'center'}
                                                                                              className={totalClassName}
                                                                                              key={key}
                                                                                              style={style}
                                                                                              value={value}
                                                                                  />
                                                                              )
                                                                          }}
                                                                      />
                                                                  );
                                                              }

                                                              return (
                                                                  <Box style={style} key={key}>-</Box>
                                                              );
                                                          }}
                                                    />

                                                </div>

                                            </div>
                                        )}
                                    </AutoSizer>
                                </div>
                            </div>

                            </React.Fragment>
                        )
                    }}
                </ScrollSync>
        </Box>
    );
}


const mapState = state => ({
    reports: state.reports,
    profile: state.profile,
    project: state.project,
});

const mapDispatch = ({
                         reports:{
                             getReports,
                             getProjectReportSummary,
                             postProjectReportsSpend,
                             postProjectReportsRevenue,
                         },
                     }) => ({
    getReports: (data) => getReports(data),
    getProjectReportSummary: (data) => getProjectReportSummary(data),
    postProjectReportsSpend: (data) => postProjectReportsSpend(data),
    postProjectReportsRevenue: (data,data2) => postProjectReportsRevenue(data, data2),
});



export default connect(mapState, mapDispatch)(DailySpendReport)