import ApiService from "../services/api";
import {checkResponseError} from './helpers'
import ReportService from '../services/ReportService';


export default {
    state: {
        pending:   false,
        error:     null,
        errorCode: null,

        activeLegend: null,
        legend:       null,
        renewals:     null,
        columns:      [],
        dates:        [],
    },
    reducers: {
        authStart(state) {
            return {
                ...state,
                pending: true
            }
        },
        updateError(state, data) {
            return {
                ...state,
                ...data,
                pending: false
            }
        },
        updateReport(state, data) {
            return {
                ...state,
                ...data,
                pending: false
            }
        }
    },

    effects: (dispatch) => ({

        async getReportRenewals(data) {
            try {
                dispatch.report.authStart();

                const responseData = await ApiService.getReportRenewals(data);

                // console.log('getReportRenewals', responseData);

                checkResponseError(dispatch.report, responseData);

                const legend = Object.keys(responseData.legend).reduce((prev, key) => {
                    prev.push({
                        key,
                        name: responseData.legend[key].name
                    });
                    return prev;
                }, []);

                let renewals = ReportService.reportRenewalsTransformDates(responseData.renewals);

                let dates = [];
                if (data?.dateAfter && data?.dateBefore) {

                } else dates = ReportService.reportRenewalsDates(renewals);

                const {columns, exportColumnHeader}  = ReportService.reportRenewalsColumns(renewals);

                dispatch.report.updateReport({
                    legend,
                    renewals: renewals.reverse(),
                    columns,
                    exportColumnHeader,
                    dates,
                });

            } catch (e) {
                process.env.NODE_ENV === 'development' && console.log('Catch getReport', e);
                dispatch.report.updateReport({error: e.message})
            }
        }
    })
}