import {useSelector} from "react-redux";
import {preparePaymentsData} from "./utils";
import BaseTable from "./BaseTable";
import React from "react";
import {isNil} from "lodash";

const rows = [
    { field: 'payments', format: (value) => isNil(value) ? '' : `${value.toLocaleString('en-US')}` },
    { field: 'ROMI', format: (value) => isNil(value) ? '' : `${value.toLocaleString('en-US')}%` },
    { field: 'profit', format: (value) => isNil(value) ? '' : `$ ${value.toLocaleString('en-US')}` },
    { field: 'LTV', format: (value) => isNil(value) ? '' : `$ ${value.toLocaleString('en-US')}` },
    { field: 'ARPU', format: (value) => isNil(value) ? '' :  `$ ${value.toLocaleString('en-US')}` },
    { field: 'revenue', format: (value) => isNil(value) ? '' : `$ ${value.toLocaleString('en-US')}` },
    { field: 'users_payed', format: (value) => isNil(value) ? '' : `${value.toLocaleString('en-US')}` }
];

const PredictiveTableComponent = () => {
    const {
        pending = false,
        manual: manualData = [],
    } = useSelector(state => state.veryGood);

    const data = preparePaymentsData(manualData);

    return (
        <BaseTable tableName="Manual Payments" data={data} rows={rows} loading={pending} />
    )
};

export default PredictiveTableComponent