import ApiService from "../services/api";
import {checkResponseError} from "./helpers";
import camelcaseKeysDeep from "camelcase-keys-deep";
import {reduce} from "lodash";


export default {
  state: {
    pending: false,
    error: null,
    errorCode: null,

    data: {},
    controller: null
  },
  reducers: {
    authStart(state, data) {
      return {
        ...state,
        ...data,
        pending: true,
        error: null,
        errorCode: null,
      }
    },
    updateError(state, data) {
      return {
        ...state,
        ...data,
        pending: false
      }
    },
    update(state, data) {
      return {
        ...state,
        ...data,
        pending: false
      }
    },
  },

  effects: (dispatch) => ({
    async getData(data, rootState) {
      if (rootState.calendarDash.controller !== null)
        rootState.calendarDash.controller.abort();

      const controller = new AbortController();
      dispatch.calendarDash.authStart({controller});

      ApiService.getCalendarDash({...data, signal: controller.signal})
        .then(responseData => {
          checkResponseError(dispatch.home, responseData);
          dispatch.calendarDash.update({
            data: camelcaseKeysDeep(reduce(responseData, (acc, value, key) => ([
              ...acc,
              {
                ...value,
                date: key
              }
            ]), [])),
            controller: null
          });
        })
        .catch(e => {
          console.log('getData error ', { e } );
          dispatch.calendarDash.updateError({error: e.message})
        })
    }
  })
}