import React, {useEffect, useState} from "react";
import {Box, InputLabel} from "@material-ui/core";
import {connect} from "react-redux";
import Button from "@material-ui/core/Button";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
// import Avatar from "@material-ui/core/Avatar";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from '@material-ui/icons/Delete';
import List from "@material-ui/core/List";
import TextField from "@material-ui/core/TextField";
// import Checkbox from "@material-ui/core/Checkbox";
// import FormControlLabel from "@material-ui/core/FormControlLabel";
// import OutlinedInput from "@material-ui/core/OutlinedInput";
import {nanoid} from "nanoid";
import {versionCompare} from "../../services/helpers";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

const MAX_SEGMENTS_COUNT = 6;

function Create(props){

    const [segments, setSegments] = useState([]);
    const [testData, setTestData] = useState({
        id:null,
        version:null,
        title: null,
        testName:null,
        description:null,
    });

    const [error] = useState();

    const selectedVersionData = props.createAbTest.versions?.find(item => versionCompare(item.version, testData.version ?? '') === 0) ?? null;
    const countLettersAvailable = selectedVersionData?.letters_available?.length ?? 0;

    useEffect(()=>{
        if ( props.createAbTest.error === null && props.createAbTest.versions === null && props.createAbTest.pending === false
            && props.project?.active?.id && props.project.pending === false){

            props.getAbTestNew({projectId: props.project.active.id})
        }
    },[props]);

    const hasError = Object.keys(error||{}).reduce((p, c) => {

        if (error?.id !== null) return true;

        return p;
    }, false);

    const disableSave = () => {
        return (testData.version?.length > 0 && testData.title?.length > 0 && testData.testName?.length > 0  && testData.description?.length > 0) === false
    };


    const onUpdateTestData = (event) => {

        // if (event.target.name === 'id') {
        //     if (event.target.value?.length > 0 && (event.target.value?.includes('\\') || event.target.value?.includes('/'))){
        //         setError({...error, ...{[event.target.name]:'Not valid symbol'}})
        //     }else {
        //         setError({...error, ...{[event.target.name]:null}})
        //     }
        // }

        setTestData({...testData, ...{[event.target.name]:event.target.value}})
    };




    const onAddItem = () => {

        const letter = availableLetters?.[availableLetters.length];

        setSegments([...segments, ...[{
                id:nanoid(),
                description:'',
                frontLetter: true,
                letter
            }]]);
    };

    const onUpdateSegment = (item) => (event) => {
        let index = segments.findIndex(_item=> _item.id === item.id);
        if (index !== -1) {
            let arr = [...segments];
            if (event.target.name === 'frontLetter')
                arr[index][event.target.name] = event.target.checked;
            else
                arr[index][event.target.name] = event.target.value;
            setSegments(arr);
        }
    };

    const onDeleteItem = (item)=>{
        let index = segments.findIndex(_item=> _item.id === item.id);
        if (index !== -1) {
            segments.splice(index, 1);
            setSegments([...segments]);
        }
    };

    const onSave = () => {

        const projectId = props.project.active.id;

        const {id , ...data} = testData;

        const variantLetterFront = segments.reduce((p, c) => {
            p.push(c.letter);
            return p;
        }, []);
        
        const variantDescription = segments.reduce((p, c) => {
            p.push(c.description);
            return p;
        }, []);

        props.postAbTestNew({...data, ...{projectId, variantLetterFront, variantDescription}});
    };

    const selectedLetters = segments.reduce((p, c) => {
        p.push(c.letter);
        return p;
    } , []);

    const availableLetters =  selectedVersionData?.letters_available?.filter(( letter ) => {
        return selectedLetters.indexOf( letter ) < 0;
    } );


    const disableAddNewSegment = segments.filter((item) => item.letter?.length === 0 || item.description?.length===0)?.length > 0;

    return (
        <Box mx={'auto'} style={{height: '100%', maxWidth:818, }} >



            <h1>Create AB Test</h1>

            {/*<Box display={'flex'}>*/}
            {/*    <Box flexShrink={0} width={80}>ID</Box>*/}
            {/*    <Box width="100%">*/}
            {/*        <TextField variant={'outlined'}*/}
            {/*                   name='id'*/}
            {/*                   error={error?.id?.length > 0}*/}
            {/*                   helperText={error?.id}*/}
            {/*                   onChange={onUpdateTestData}/>*/}
            {/*    </Box>*/}
            {/*</Box>*/}

            <Box display={'flex'}>
                <Box flexShrink={0} width={80}>Version</Box>
                <Box width="100%">
                    {/*<TextField variant={'outlined'} name='version'*/}
                    {/*               error={lastVersionInfo === null && testData.version?.length > 0}*/}
                    {/*               helperText={errorText}*/}
                    {/*               onChange={onUpdateTestData}/>*/}

                    <Select
                        variant={'outlined'}
                        value={testData.version ?? ''}
                        name="version"
                        onChange={onUpdateTestData}
                    >
                        {props.createAbTest.versions?.map(item=>
                            <MenuItem key={item.version}
                                      value={item.version}
                                      selected={testData.version === item.version}
                            >
                                {item.version}
                            </MenuItem>
                        )}
                    </Select>
                </Box>
            </Box>

            <Box display={'flex'}>
                <Box flexShrink={0} width={80}>Title</Box>
                <Box width="100%">
                    <TextField variant={'outlined'}
                               name='title'

                               onChange={onUpdateTestData}/>
                </Box>
            </Box>

            <Box display={'flex'}>
                <Box flexShrink={0} width={80}>TestName</Box>
                <Box width="100%">
                    <TextField variant={'outlined'}
                               multiline
                               rows={2}
                               name='testName'
                               onChange={onUpdateTestData}/>
                </Box>
            </Box>

            <div>
                <InputLabel htmlFor='outlined-multiline-static'>
                    Description
                </InputLabel>

                <TextField multiline
                           fullWidth
                           rows={2}
                           width={'100%'}
                           variant={'outlined'}
                           name='description'
                           onChange={onUpdateTestData}/>

            </div>
            <Box mt={3}>Segments</Box>


            {countLettersAvailable > 0 ?
                <React.Fragment>
                <List dense={false}>
                {segments.map((item, i) => (
                    <ListItem key={item.id}>
                        <ListItemAvatar>
                            <React.Fragment>
                                <InputLabel htmlFor={`front-letter-${i}`}>
                                    Letter
                                </InputLabel>
                                <Select id={`front-letter-${i}`}
                                        variant={'outlined'}
                                        value={item?.letter||''}
                                        // defaultValue={availableLetters?.[0]}
                                        name="letter"
                                        onChange={onUpdateSegment(item)}
                                >
                                    {selectedVersionData?.letters_available?.map(letter =>
                                        <MenuItem key={letter}
                                                  value={letter}
                                                  disabled={selectedLetters?.includes(letter) && item?.letter !== letter}
                                                  selected={item?.letter === letter}
                                        >
                                            {letter}
                                        </MenuItem>
                                    )}
                                </Select>
                            </React.Fragment>
                        </ListItemAvatar>
                        <ListItemText>

                            <InputLabel htmlFor='outlined-multiline-static'>
                                Description
                            </InputLabel>
                            <TextField multiline
                                       fullWidth
                                       rows={2}
                                       width={'100%'}
                                       variant={'outlined'}
                                       className={'multilineTextInput'}
                                       name="description"
                                       onChange={onUpdateSegment(item)}
                                       value={item.description}/>

                            {/*<Box>*/}
                            {/*    <FormControlLabel*/}
                            {/*        value="start"*/}
                            {/*        control={*/}
                            {/*            <Checkbox color="primary"*/}
                            {/*                      name="frontLetter"*/}
                            {/*                      checked={item.frontLetter}*/}
                            {/*                      onChange={onUpdateSegment(item)} />}*/}
                            {/*        label="Start"*/}
                            {/*        labelPlacement="start"*/}
                            {/*    />*/}
                            {/*</Box>*/}

                        </ListItemText>
                        <ListItemSecondaryAction>
                            <IconButton edge="end" aria-label="delete" onClick={onDeleteItem.bind(this, item)}>
                                <DeleteIcon />
                            </IconButton>
                        </ListItemSecondaryAction>
                    </ListItem>)
                )}
                </List>
                    {countLettersAvailable === segments?.length || segments?.length === MAX_SEGMENTS_COUNT ? null :
                    <Box>
                        <Button type={'button'}
                                variant={'outlined'}
                                disabled={disableAddNewSegment && segments?.length > 0}
                                onClick={onAddItem} >
                            Add segment
                        </Button>
                    </Box>}
                </React.Fragment>: null
            }

            {
                segments?.length >=2 && hasError === false &&
                <Box pt={2}>
                    <Button type={'button'}
                            variant={'contained'}
                            disabled={props.createAbTest.pending || disableAddNewSegment || disableSave()}  color="primary" onClick={onSave} >Save</Button>
                </Box>
            }
        </Box>
    );
}


const mapState = state => ({
    createAbTest: state.createAbTest,
    project: state.project,
});

const mapDispatch = ({
                         createAbTest:{
                             getAbTestNew,
                             postAbTestNew
                         },

                     }) => ({
    getAbTestNew,
    postAbTestNew,
});



export default connect(mapState, mapDispatch)(Create);