import {calcFields, round, saveDivide} from './../models/helpers';
import {ALL_SPEND_GROUP} from './../views/daily/Report'
import { parseISO, format } from 'date-fns'
import { getDaysInMonth, differenceInDays, addDays} from 'date-fns'
import * as Column from "react-base-table";
import { isNil, sum, get, reduce, isString, isDate } from "lodash";


class ReportService {
    static prepareExportData(data, products, headerData){
        let exportData = [];

        if (headerData)
            exportData.push(headerData);

        data.forEach((dataItem) => {
            let dataRow = [];

            (headerData||[]).forEach(headerKey => {

                let rowValue = dataItem?.[headerKey];
                let isDates = false;

                if (!rowValue) {
                    isDates = dataItem?.elements?.hasOwnProperty(headerKey) || false;
                    rowValue = dataItem?.elements?.[headerKey] || {}
                }

                if (typeof  rowValue === 'object') {

                    let sum = 0;

                    if (headerKey === 'sumFact') {
                        sum = ReportService.sumForFields(rowValue, products, ['rsp_f24', 'rsp_a24', 'lifetime']);
                        dataRow.push(Math.ceil(sum * 100)/100)
                    }
                    else if (headerKey === 'sumPred' || headerKey === 'sum') {
                        sum = ReportService.sumForFields(rowValue, products, ['rsp_f24', 'rsp_a24', 'esp_act', 'esp_gra', 'lifetime']);
                        dataRow.push(Math.ceil(sum * 100)/100)
                    }
                    else if (headerKey === 'roiFact') {
                        const costs = dataItem?.costs||0;
                        let value = ReportService.sumForFields(rowValue, products, ['rsp_f24', 'rsp_a24', 'lifetime']);
                        value = saveDivide(value, costs);

                        if (value !== 0)
                            value = round((value - 1) * 100, 2);

                        dataRow.push(Math.ceil(value*100)/100);
                    } else if (headerKey === 'roiPred') {

                        const costs = dataItem?.costs||0;
                        let value = ReportService.sumForFields(rowValue, products, ['rsp_f24', 'rsp_a24', 'esp_act', 'esp_gra', 'lifetime']);

                        value = saveDivide(value, costs);
                        if (value !== 0)
                            value = round((value - 1) * 100, 2);

                        dataRow.push(Math.ceil(value*100)/100);
                    }
                    else {

                        if (isDates) {

                            let value = 0;

                            (products||[]).forEach(field => {
                                value += (rowValue?.[field] || 0)
                            });

                            value = round(value, 2);

                            dataRow.push(Math.ceil(value * 100) / 100);

                        } else {
                            let value = ReportService.sumForFields(rowValue, products, []);
                            dataRow.push(Math.ceil(value * 100) / 100);
                        }
                    }

                } else if (typeof rowValue === 'number') {
                    dataRow.push(Math.ceil(rowValue*100)/100)
                }else if (typeof rowValue === 'string') {
                    dataRow.push(rowValue)
                }
            });

            exportData.push(dataRow);
        });

        let sumByColumns = ['sum'];

        exportData.forEach((row, i) => {
            if (i !== 0) {
                row.forEach((rowColumn, j) => {
                    if (j !== 0)
                        sumByColumns[j] = (sumByColumns?.[j] || 0) + (rowColumn || 0)
                })
            }
        });

        exportData.push(sumByColumns);

        // DEBUG && console.log('PrepareExportData result', exportData);

        return exportData;
    }

    static getLastArrayValue(values) {
        return values[values.length - 1];
    }

    static calculateTotalForWeek(responseData, uniqueSubscriptions, uniquePayments) {

        responseData.reduce((p, weekData) => {

            const {subscriptions, spends, profit, payments} = weekData;

            weekData.dates.push('week');

            ['value', 'value_cursive', 'value_manual'].forEach(fieldName => {
                // eslint-disable-next-line
                weekData.profit?.[fieldName]?.push(
                    round(
                        sum(profit?.[fieldName]),
                        2
                    )
                );
            });

            const {sum: spendsSum} = spends;
            const weekSpendsSum = sum(spendsSum);

            const sumWeekProfitValue = this.getLastArrayValue(weekData.profit.value);
            const sumWeekProfitValueManual = this.getLastArrayValue(weekData.profit.value_manual);

            // weekData.spends.sum.push(weekSpendsSum);
            weekData.profit.value_roi.push(
                round(
                    saveDivide(sumWeekProfitValue, weekSpendsSum) * 100,
                    2
                )
            );

            weekData.profit.value_manual_roi.push(
                round(
                    saveDivide(sumWeekProfitValueManual, weekSpendsSum) * 100,
                    2
                )
            );

            const sumWeekProfitValueCursive = this.getLastArrayValue(weekData.profit.value_cursive);
            weekData.profit.value_cursive_roi.push(
                round(
                    saveDivide(sumWeekProfitValueCursive, weekSpendsSum) * 100,
                    2
                )
            );

            weekData.revenue.value_cursive.push(
                round(
                    sum(weekData.revenue.value_cursive),
                    2
                )
            );

            weekData.revenue.value.push(
                sum(weekData.revenue.value)
            );

            const sumWeekRevenueValueManual = weekData.revenue.value_manual;
            weekData.revenue.value_manual.push(
                round(
                    sum(sumWeekRevenueValueManual),
                    2
                )
            );

            const {count: paymentsCount} = payments;
            const sumWeekPaymentCount = sum(paymentsCount);

            weekData.payments.count.push(sumWeekPaymentCount);
            weekData.payments.unique.push(sum(weekData.payments.unique));
            weekData.subscriptions.unique.push(sum(weekData.subscriptions.unique));

            // Cost per purchase
            weekData.payments.cost.push(
                Math.abs(
                    round(
                        saveDivide(weekSpendsSum, sumWeekPaymentCount),
                        2
                    )
                )
            );

            ALL_SPEND_GROUP.forEach(spendName => {
                // eslint-disable-next-line
                weekData.spends?.[spendName]?.push(
                    sum(weekData.spends?.[spendName])
                );
            });

            ['count', 'count_conversions', 'count_trials', 'count_renewable'].forEach(fieldName => {
                // eslint-disable-next-line
                weekData.subscriptions?.[fieldName]?.push(
                    sum(subscriptions?.[fieldName])
                );
            });

            // const {count_conversions, count_trials} = weekData.subscriptions;
            // const sumWeekCountConversions = this.getLastArrayValue(count_conversions);
            // const sumWeekCountTrials = this.getLastArrayValue(count_trials);

            // Cost trial
            weekData.subscriptions.cost.push(
                round(
                    Math.abs(
                        saveDivide(weekSpendsSum, sum(weekData.trials.count))
                    ), 2
                )
            );

            // Conversion trial/purchase
            // eslint-disable-next-line
            weekData.subscriptions?.conversions_rate.push(
                round(
                    saveDivide(sum(weekData.trials.converted), sum(weekData.trials.count)),
                    2)
            );

            const weekUsers = weekData.users;

            const sumUsers = sum(weekUsers);
            weekData.users.push(sumUsers);


            let sumWeekSubscriptions = {};

            weekData.subscriptions.info.forEach(weekSubscriptions => {
                weekSubscriptions.forEach(({count, product_id, trials}) => {

                    trials = trials || 0;

                    let daySubscriptions = sumWeekSubscriptions?.[product_id] ?? {count: 0, product_id, trials: 0};

                    sumWeekSubscriptions[product_id] = {
                        count: daySubscriptions.count + count,
                        trials: daySubscriptions.trials + trials,
                        product_id,
                    }
                })
            });

            weekData.subscriptions.info.push(Object.values(sumWeekSubscriptions));


            let sumWeekPayments = {};

            weekData.payments.info.forEach(weekPayments => {
                weekPayments.forEach(({count, product_id, trials}) => {

                    trials = trials || 0;

                    let dayPayments = sumWeekPayments?.[product_id] ?? {count: 0, product_id, trials: 0};

                    sumWeekPayments[product_id] = {
                        count: dayPayments.count + count,
                        trials: dayPayments.trials + trials,
                        product_id,
                    }
                })
            });

            weekData.payments.info.push(Object.values(sumWeekPayments));
            weekData.revenue.predictive_6m.push(round(sum(weekData.revenue.predictive_6m), 2));
            weekData.revenue.predictive_2y_plus = get(weekData.revenue, 'predictive_2y+');
            weekData.revenue.predictive_2y_plus.push(round(sum(weekData.revenue.predictive_2y_plus), 2));

            // ROMI — (Revenue - Spent) / Spent * 100%
            weekData.revenue.ROMI_6m = reduce(weekData.spends.sum, (acc, spent, index) => ([
                ...acc,
                spent !== 0
                    ? round((weekData.revenue.predictive_6m[index] - spent) / spent * 100, 2)
                    : 0
                ]) , []);

            weekData.revenue.ROMI_2y_plus = reduce(weekData.spends.sum, (acc, spent, index) => ([
                ...acc,
                spent !== 0
                    ? round((weekData.revenue.predictive_2y_plus[index] - spent) / spent * 100, 2)
                    : 0
            ]) , []);



            weekData.current_romi = reduce(weekData.revenue.value, (acc, rev, index) => ([
                ...acc,
                round(saveDivide((rev - weekData.spends.sum[index]), weekData.spends.sum[index]) * 100, 2)
            ]), []);
            weekData.current_romi.push(sum(weekData.current_romi));

            weekData.payed.push(round(sum(weekData.payed), 2));
            // P.Users
            weekData.payed_1.push(round(sum(weekData.payed_1), 2));
            // CAC Count
            weekData.payed_2.push(round(sum(weekData.payed_2), 2));

            // // CAC — Spent / Payed users
            weekData.CAC = reduce(weekData.payed_2, (acc, item, index) => ([
                ...acc,
                item !== 0 ? round(weekData.spends.sum[index] / item, 2) : 0
            ]) , []);

            // LTV — Revenue / Payed users
            weekData.LTV_2y_plus = reduce(weekData.payed_2, (acc, payed, index) => ([
                ...acc,
                payed !== 0 ? round(weekData.revenue.predictive_2y_plus[index] / payed, 2) : 0
            ]) , []);
            weekData.LTV_6m = reduce(weekData.payed_2, (acc, payed, index) => ([
                ...acc,
                payed !== 0 ? round(weekData.revenue.predictive_6m[index] / payed, 2) : 0
            ]) , []);

            weekData.first_open = weekData.users;

            // Subrate —  Payed users / First Open
            weekData.subrate = reduce(weekData.users, (acc, item, index) => ([
                ...acc,
                item !== 0
                    ? round(weekData.payed_2[index] * 100 / item, 2)
                    : 0
            ]), []);

            // weekData.subrate.push(sumUsers!==0 ? round(sum(weekData.payed_2) * 100 / sumUsers, 2) : 0);


            const trialsCount = [...weekData.trials.count, sum(weekData.trials.count)];
            weekData.in_trial = reduce(weekData.users, (acc, item, index) => ([
                ...acc,
                item !== 0
                    ? round((trialsCount[index] || 0) * 100 / item, 2)
                    : 0
            ]), []);

            p.push(weekData);

            // CPI — Spent / First Open
            weekData.CPI = reduce(weekData.users, (acc, item, index) => ([
                ...acc,
                item !== 0 ? round(weekData.spends.sum[index] / item, 2) : 0
            ]), []);


            weekData.revenue.predictive_1y.push(round(sum(weekData.revenue.predictive_1y), 2));
            weekData.revenue.predictive_2y.push(round(sum(weekData.revenue.predictive_2y), 2));
            weekData.revenue.predictive_max.push(round(sum(weekData.revenue.predictive_max), 2));

            weekData.revenue.ROMI_1y = reduce(weekData.spends.sum, (acc, spent, index) => ([
                ...acc,
                spent !== 0
                    ? round((weekData.revenue.predictive_1y[index] - spent) / spent * 100, 2)
                    : 0
            ]) , []);
            weekData.revenue.ROMI_2y = reduce(weekData.spends.sum, (acc, spent, index) => ([
                ...acc,
                spent !== 0
                    ? round((weekData.revenue.predictive_2y[index] - spent) / spent * 100, 2)
                    : 0
            ]) , []);
            weekData.revenue.ROMI_max = reduce(weekData.spends.sum, (acc, spent, index) => ([
                ...acc,
                spent !== 0
                    ? round((weekData.revenue.predictive_max[index] - spent) / spent * 100, 2)
                    : 0
            ]) , []);

            weekData.LTV_1y = reduce(weekData.payed_2, (acc, payed, index) => ([
                ...acc,
                payed !== 0 ? round(weekData.revenue.predictive_1y[index] / payed, 2) : 0
            ]) , []);
            weekData.LTV_2y = reduce(weekData.payed_2, (acc, payed, index) => ([
                ...acc,
                payed !== 0 ? round(weekData.revenue.predictive_2y[index] / payed, 2) : 0
            ]) , []);
            weekData.LTV_max = reduce(weekData.payed_2, (acc, payed, index) => ([
                ...acc,
                payed !== 0 ? round(weekData.revenue.predictive_max[index] / payed, 2) : 0
            ]) , []);



            weekData.trials.converted.push(sum(weekData.trials.converted));
            weekData.trials.count.push(sum(weekData.trials.count));
            weekData.trials.in_billing.push(sum(weekData.trials.in_billing));
            weekData.trials.in_trial.push(sum(weekData.trials.in_trial));
            weekData.subscriptions.count_primary.push(sum(weekData.subscriptions.count_primary));
            weekData.subscriptions.count_renewable.push(sum(weekData.subscriptions.count_renewable));


            // ROMI — (Revenue - Spent) / Spent * 100%

            // ARPU — Revenue / First Open
            // CAC — Spent / Payed users
            // LTV — Revenue / Payed users
            return p;
        }, []);

        return responseData;
    }


    static abTestSummary(data) {

        let totalDetails = {
            lifetime: "-",
            price: 0,
            product: {id: "", period: "", trials: 0},
            product_id: "-",
            purchases: "-",
            trials: "-",
            type: "-",
            userLTV: "-",
            c1: '-',
            c2: '-',
            paidCVR: '-',

            sumPriceToPaid: 0,
            sumPriceToPaidWithLifetime: 0,
            sumTrials: 0,
            sumPurchases: 0,

            sumPurchasesWithTrials: 0,
        };

        let total = 0;
        if (data.summary.length === 0) {
            data.summary.push({
                product_id: '-',
                product_price: 0,
                total_trials: 0,
                total_purchases: 0,
                product_conversion: 0,
            })
        }
        data.summary = data.summary.reduce((prevSummary, summary) => {

            const {product_id, price, trials, purchases, lifetime, c1, c2, paidCVR, userLTV, priceOnPaid, priceOnPaidLifetime} = calcFields(data.users,
                {
                    product_id: summary.product_id,
                    price: summary.product_price,
                    price_2pp: summary?.product_price_2pp || 0,
                    trials: summary.total_trials,
                    purchases: summary.total_purchases,
                    lifetime: summary.product_conversion,
                });
            total += userLTV;
            if (trials > 0) {
                totalDetails.sumPurchasesWithTrials += purchases ?? 0;
            }
            totalDetails.sumTrials += trials ?? 0;
            totalDetails.sumPurchases += purchases ?? 0;
            totalDetails.sumPriceToPaid += priceOnPaid;
            totalDetails.sumPriceToPaidWithLifetime += priceOnPaidLifetime;

            prevSummary.push({
                ...summary,
                ...{
                    product_id,
                    price,
                    trials,
                    purchases,
                    lifetime,
                    c1,
                    c2,
                    paidCVR,
                    userLTV: round(userLTV, 3),
                    priceOnPaid,
                    priceOnPaidLifetime
                }
            });
            return prevSummary;
        }, []);

        data.sumPriceToPaid = round((totalDetails.sumPriceToPaidWithLifetime || 0), 2);
        data.total = total;
        if (data.summary.length > 0) {
            totalDetails.c1 = round(saveDivide(totalDetails.sumTrials, data.users) * 100, 2);
            totalDetails.c2 = round(saveDivide(totalDetails.sumPurchasesWithTrials, totalDetails.sumTrials) * 100, 2);
            totalDetails.price = round(saveDivide(totalDetails.sumPriceToPaid, totalDetails.sumPurchases), 2);

            totalDetails.trials = round(totalDetails.sumTrials, 2);
            totalDetails.purchases = round(totalDetails.sumPurchases, 2);
            totalDetails.paidCVR = round(saveDivide(totalDetails.sumPurchases, data.users) * 100, 2);
            totalDetails.userLTV = round(total, 3);


            totalDetails.lifetime = round(saveDivide(totalDetails.sumPriceToPaidWithLifetime, totalDetails.sumPriceToPaid), 1);
            totalDetails.priceOnPaid = totalDetails.sumPriceToPaid;
            totalDetails.priceOnPaidLifetime = totalDetails.sumPriceToPaidWithLifetime;

            data.summary.push(totalDetails);
        }

        return data;
    }

    static convertHistogramHistoryForChart(histogramHistory) {

        let date = [];
        let payments = [];
        let users = [];

        histogramHistory.forEach(({date: periodDate, users: countUsers, payments: paymentCount}) => {
            date.push(periodDate);
            users.push(countUsers);
            payments.push(paymentCount);
        });

        return {date, users, payments}
    }

    static renewalsCalculatorData(renewals, products) {
        if (products === null || products.length === 0 || [null, undefined].includes(renewals)) return [];

        let res = {};

        products.forEach(_product_id => {

            res[_product_id] = Object.keys(renewals).reduce(function (out, date) {

                let data = renewals[date]?.data?.find(({product_id}) => product_id === _product_id);

                const dist_countries2 = reduce(data.dist_countries, (acc, value, isoCode) => ([
                  ...acc,
                    {
                        isoCode,
                        ...value
                    }
                ]), []).sort((a, b) => b.total - a.total);

                out.push({
                    date,
                    ...{
                        id: date
                    },
                    ...data,
                    dist_countries2
                });
                return out;
            }, [])
        });


        return res;
    }

    static reportRenewalsTransformDates(renewals) {

        return renewals.reduce((prev, current) => {

            const date = parseISO(current.date);
            const dateStart = new Date(date);
            const dateEnd = new Date(date);
            const lastDayInMonth = getDaysInMonth(date);

            dateStart.setDate(1);
            dateEnd.setDate(lastDayInMonth);
            dateEnd.setHours(23, 59, 59);

            current.dateInfo = {
                date,
                dateStart,
                dateEnd
            };

            prev.push(current);
            return prev;
        }, []);
    }

    static reportRenewalsDates(renewals) {

        let dates = new Set();

        if (renewals.length > 0) {
            const rowDates = Object.keys(renewals);

            rowDates.forEach(rowDate => {
                const colDates = Object.keys(renewals[rowDate].elements);

                colDates.forEach(colDate => {
                    dates.add(colDate);
                });
            });
        }

        return Array.from(dates);
    }

    static reportRenewalsColumns(renewals) {

        let exportColumnHeader = [];
        let columns = [];

        renewals = renewals.reduce((p, {date}) => {

            date = format(parseISO(date), 'yyyy-MM');
            p = {...p, ...{[date]: date}};
            return p;
        }, {});

        const dates = Object.keys(renewals);

        columns.push({
            dataKey: 'date',
            title: 'Date',
            width: 170,
            align: Column.Alignment.RIGHT
        });
        exportColumnHeader.push('date');

        columns.push({
            dataKey: 'costs',
            title: 'Costs',
            width: 160,
            align: Column.Alignment.CENTER
        });
        exportColumnHeader.push('costs');

        dates.forEach((date) => {
            columns.push({
                dataKey: `elements.${date}`,
                title: `${date}`,
                width: 100,
                align: Column.Alignment.RIGHT
            });
            exportColumnHeader.push(date);
        });

        columns.push({
            dataKey: `sumFact`,
            title: `Sum Fact`,
            width: 130,
            align: Column.Alignment.RIGHT,
        });
        exportColumnHeader.push('sumFact');

        columns.push({
            dataKey: `roiFact`,
            title: `RIO Fact`,
            width: 90,
            align: Column.Alignment.RIGHT,
        });
        exportColumnHeader.push('roiFact');

        columns.push({
            dataKey: `sumPred`,
            title: `Sum Pred`,
            width: 130,
            align: Column.Alignment.RIGHT,
        });
        exportColumnHeader.push('sumPred');

        columns.push({
            dataKey: `roiPred`,
            title: `RIO Pred`,
            width: 90,
            align: Column.Alignment.RIGHT,
        });
        exportColumnHeader.push('roiPred');

        columns.push({
            dataKey: `sum`,
            title: `Sum`,
            width: 130,
            align: Column.Alignment.RIGHT,
        });
        exportColumnHeader.push('sum');

        return {columns, exportColumnHeader};
    }


    static filterRenewals(renewals, startDate = null, endDate = null) {

        if (!isNil(startDate) && (isString(startDate) || !isDate(Date)))
            startDate = new Date(startDate);

        if (!isNil(endDate) && (isString(endDate) || !isDate(endDate)))
            endDate = new Date(endDate);

        if ((startDate)) {
            startDate = new Date(Date.now());
            startDate.setDate(0);
            startDate.setHours(0, 0, 0);
        }

        if (isNil(endDate))
            endDate = new Date(Date.now());

        let feature = {
            date: 'feature',
            total: 0,
            users: 0,
            costs: 0,
            elements: {},
            children: [],
            sum: {},
        };
        let early = {
            date: 'early',
            total: 0,
            users: 0,
            costs: 0,
            elements: {},
            children: [],
            sum: {},
        };

        const features = [];
        const earlies = [];

        let newRenewals = renewals.reduce((prevVal, currentItem) => {

            const {date: currentItemDate} = currentItem.dateInfo;
            const {sum} = this.calcSumForFields({}, currentItem.elements);

            if (endDate !== null && currentItemDate > endDate) {
                // feature
                feature = this.mergeRenewalsElements(feature, currentItem);
                const {sum: ftSum} = this.calcSumForFields(feature.sum, currentItem.elements);
                feature.sum = ftSum;

                currentItem = {...currentItem, ...{parentId: 'feature', ...{sum}}};

                features.push(currentItem);

            } else if (startDate !== null && currentItemDate < startDate) {
                // early
                early = this.mergeRenewalsElements(early, currentItem);
                const {sum: earlySum} = this.calcSumForFields(early.sum, currentItem.elements);
                early.sum = earlySum;

                currentItem = {...currentItem, ...{parentId: 'early', ...{sum}}};

                earlies.push(currentItem)
            } else {
                // current period
                currentItem.sum = sum;

                prevVal.push(currentItem);
            }

            return prevVal;

        }, []);

        if (renewals.length > 0) {

            feature.children = features;
            early.children = earlies;

            newRenewals.unshift(feature);
            newRenewals.push(early);
        }

        return newRenewals;
    }

    static mergeRenewalsElements(obj = {}, currentItem = {}) {

        const {users, total, costs, elements} = currentItem;
        const datesCurrent = Object.keys(elements);
        const datesPrev = Object.keys(obj.elements);

        const dates = [...datesPrev, ...datesCurrent];

        dates.forEach(date => {
            if (obj.elements.hasOwnProperty(date) === false) {
                obj.elements = {...obj.elements, ...{[date]: {}}};
            }

            Object.keys(elements?.[date] || {}).forEach(props => {
                if (obj.elements[date].hasOwnProperty(props)) {
                    obj.elements[date][props] += elements[date][props];
                } else {
                    obj.elements[date] = {...obj.elements[date], ...{[props]: elements[date][props]}};
                }
            })
        });

        obj.costs += (costs || 0);
        obj.users += (users || 0);
        obj.total += (total || 0);

        obj.costs = round(obj.costs, 2);
        obj.users = round(obj.users, 2);
        obj.total = round(obj.total, 2);

        return obj;
    }


    static calcSumForFields(sum = {}, elements = {}) {

        Object.keys(elements).forEach(date => {

            const fields = Object.keys(elements[date]);

            fields.forEach((field) => {
                if (sum.hasOwnProperty(field) === false)
                    sum = {...sum, ...{[field]: 0}};

                sum[field] += (elements[date][field] || 0);
            });
        });

        return {
            sum,
            sumFact: sum,
            sumPred: sum,
        };
    }


    static calculatorHomeTransformCalcGrows(calculator) {


        let days = calculator?.data?.days;

        if (days) {

            days = days.reduce((p, c, index, items) => {

                let {amount_in_app, amount_sub_first, amount_sub_total} = p;

                if (index === 0) {
                    amount_in_app = {...amount_in_app, ...{growth: 0}};
                    amount_sub_first = {...amount_sub_first, ...{growth: 0}};
                    amount_sub_total = {...amount_sub_total, ...{growth: 0}};
                } else {
                    const prevIndex = index - 1;

                    const {amount_in_app: prev_amount_in_app, amount_sub_first: prev_amount_sub_first, amount_sub_total: prev_amount_sub_total} = items[prevIndex];

                    console.log('prev', prev_amount_in_app, prev_amount_sub_first, prev_amount_sub_total);


                    amount_in_app = {...amount_in_app, ...{growth: this.calcPercent(amount_in_app?.value || 0, prev_amount_in_app?.value || 0)}};
                    amount_sub_first = {...amount_sub_first, ...{growth: this.calcPercent(amount_sub_first?.value || 0, prev_amount_sub_first?.value || 0)}};
                    amount_sub_total = {...amount_sub_total, ...{growth: this.calcPercent(amount_sub_total?.value || 0, prev_amount_sub_total?.value || 0)}};
                }

                c = {...c, ...{amount_in_app, amount_sub_first, amount_sub_total}};

                p.push(c);
                return p;
            }, []);


            calculator.data.days = days;
        }

        return calculator;
    }

    static calcPercent(a, b) {
        return round((saveDivide(a / b) - 1) * 100, 2);
    }


    static sumElementForMonth(renewals, stringYearMonth, dateEl) {

        let sum = {};

        renewals.forEach(({date, elements}) => {

            const groupYearMonthDate = format(parseISO(date), 'yyyy-MM');
            if (sum.hasOwnProperty(groupYearMonthDate) === false)
                sum = {...sum, ...{[groupYearMonthDate]: {}}};


            Object.keys(elements).forEach(strDate => {
                const tmpDate = parseISO(strDate);

                if (tmpDate >= dateEl) {

                    const dateEl = format(tmpDate, 'yyyy-MM');

                    if (dateEl === stringYearMonth) {

                        const fields = Object.keys(elements[strDate]);

                        fields.forEach((field) => {
                            if (sum[groupYearMonthDate].hasOwnProperty(field) === false)
                                sum[groupYearMonthDate] = {...sum[groupYearMonthDate], ...{[field]: 0}};

                            sum[groupYearMonthDate][field] += (elements[strDate][field] || 0);
                        });
                    }
                }
            });
        });

        return sum;
    }


    static groupByCountOfType(dateAfter:Date, dateBefore:Date, count:number = 1, type:string = 'days') {

        const startDate = dateAfter;

        let newDates = [];

        while (dateAfter <= dateBefore) {

            const indexGroupDay = this.indexGroupDay(dateAfter, startDate, count, type);

            // добавить если нету значения
            if (!newDates[indexGroupDay]) {
                let endDate = addDays(dateAfter, count - 1);

                newDates.push({
                    date: this.groupDateString(dateAfter, endDate), // format(dateAfter, "yyyy-MM-dd"),
                    dateInfo:{
                        startDate: dateAfter,
                        endDate,
                    },
                    elements: {},
                    children: [],

                    costs: 0,

                    sum:{},
                    sumFact:{},
                    sumPred:{},

                    roiFact:{},
                    rioPred:{},
                });
            }

            dateAfter = addDays(dateAfter, 1);
        }

        return newDates;
    }

    static reportRenewals(data = [], dateAfter, dateBefore, count = 1) {

        // console.log('reportRenewals', { data, dateAfter, dateBefore, count });
        if (!(dateAfter instanceof Date))
            dateAfter  = parseISO(dateAfter);
        if (!(dateBefore instanceof Date))
            dateBefore = parseISO(dateBefore);

        let groupDates = this.groupByCountOfType(dateAfter, dateBefore, count);

        data.forEach(({date, costs: mainCosts, elements}) => {
            const dateCol = parseISO(date);
            const dateColFormat = format(dateCol, 'yyyy-MM');

            const costIndex  = this.indexGroupDay(dateCol, dateAfter, count);
            if (costIndex < groupDates.length) {
                let costDateInfo = groupDates[costIndex]?.dateInfo || {};
                let sumFact        = groupDates[costIndex]?.sumFact || {};
                let sumPred        = groupDates[costIndex]?.sumPred || {};
                let costs        = groupDates[costIndex]?.sumFact?.costs || 0;

                if (costDateInfo?.startDate <= dateCol && dateCol <= costDateInfo?.endDate) {
                    costs += (mainCosts || 0)
                }
                groupDates[costIndex] = {
                    ...groupDates[costIndex], ...{
                        costs,
                        sumFact: {...sumFact, ...{costs}},
                        sumPred: {...sumPred, ...{costs}},
                    }
                };
            }

            const elementDates = Object.keys(elements);

            elementDates.forEach(elementDate => {
                const dateEl  = parseISO(elementDate);
                const element = elements?.[elementDate];

                const indexGroupDay = this.indexGroupDay(dateEl, dateAfter, count);

                let dateInfo = groupDates[indexGroupDay]?.dateInfo || {};

                // просуммировать колонки
                const groupElements = groupDates[indexGroupDay]?.elements;
                let prevColumnDateSum = groupElements?.[dateColFormat] || {};

                let prevColSumFact = groupDates[indexGroupDay]?.sumFact || {};
                let prevColSumPred = groupDates[indexGroupDay]?.sumPred || {};

                let prevColumnSum = groupDates[indexGroupDay]?.sum || {};

                if (dateInfo?.startDate <= dateEl && dateEl <= dateInfo?.endDate) {
                    // колонки к датам
                    prevColumnDateSum = this.mergeWithSumObjectFields(prevColumnDateSum, element);
                    // колонки Сумма фактическая и Сумма предиктив
                    prevColSumFact    = this.mergeWithSumObjectFields(prevColSumFact, element);
                    prevColSumPred    = this.mergeWithSumObjectFields(prevColSumPred, element);
                    // колонка суммы
                    prevColumnSum     = this.mergeWithSumObjectFields(prevColumnSum, element);
                }

                groupDates[indexGroupDay] = {
                    ...groupDates[indexGroupDay], ...{
                    sumFact: {...prevColSumFact},
                    sumPred: {...prevColSumPred},

                    roiFact: {...prevColSumFact},
                    roiPred: {...prevColSumPred},

                    sum:     prevColumnSum
                }};

                groupDates[indexGroupDay]['elements'] = {
                    ...groupElements,
                    ...{[dateColFormat]: prevColumnDateSum},
                }

            });
        });

        return groupDates;
    }

    static indexGroupDay(dateAfter: Date, startDate:Date, count:number = 1, type:string = 'day') {

        const diffInType = differenceInDays(dateAfter, startDate) + 1;
        return Math.ceil(diffInType / count) - 1;
    }


    static groupDateString(startDate : Date, endDate: Date) {

        if (format(startDate, 'yyyy-MM-dd') === format(endDate, 'yyyy-MM-dd'))
            return format(startDate, 'yyyy-MM-dd');

        let startDateString = '';
        let endDateString   = '';

        const startDateMonth = startDate.getMonth();
        const endDateMonth   = endDate.getMonth();

        const startDateYear  = startDate.getFullYear();
        const endDateYear    = endDate.getFullYear();

        if (startDateYear !== endDateYear) {
            startDateString = ` ${startDateYear}`;
            endDateString   = ` ${endDateYear}`;
        } else {
            endDateString   = ` ${endDateYear}`;
        }

        if (startDateMonth !== endDateMonth) {
            startDateString = ` ${format(startDate, 'MMM')}${startDateString}`;
            endDateString   = ` ${format(endDate, 'MMM')}${endDateString}`;
        }else {
            endDateString   = ` ${format(endDate, 'MMM')}${endDateString}`;
        }

        startDateString = `${startDate.getDate()}${startDateString}`;
        endDateString   = `${endDate.getDate()}${endDateString}`;

        return `${startDateString}-${endDateString}`
    }


    static mergeWithSumObjectFields(current, item){
        // получить свойства
        const currentFields = Object.keys(current);
        const itemFields    = Object.keys(item);
        // уникальные свойства двух объектов
        const fields = Array.from(new Set([...currentFields, ...itemFields]));

        fields.forEach((field) => {
            // добавить свойство если его нету
            if (current.hasOwnProperty(field) === false)
                current = {...current, ...{[field]: 0}};

            current[field] += (item?.[field] || 0);
        });

        return current;
    }

    static sumForFields(object, selectedFields, fields = []) {
        let value = 0;

        fields.filter(field => (selectedFields||[]).includes(field)).forEach(field => {
            value += (object?.[field] || 0)
        });

        return value;
    };

    static cancelled(cancelled) {



        const periods = Object.keys(cancelled);

        let cancelledData = [];

        let row = 0;

        periods.forEach(period => {

            const {data, users} = cancelled[period];

            data.forEach( (
                // item
                {
                               product,
                               days,
                               alias,
                               product_id,
                               subscriptions:{
                                   total:     subscriptions_total,
                                   active:    subscriptions_active,
                                   renewable: subscriptions_renewable,
                                   'active+cancelled': subscriptions_active_and_cancelled,
                                   'active+renewable': subscriptions_active_and_renewable,
                                   'expired+renewable': subscriptions_expired_and_renewable,
                               },
                //             /*:{ active, 'active+cancelled' : active_cancelled, 'active+renewable':active_renewable, 'expired+renewable': expired_renewable, renewable, total},*/
                               cancelled
                           }
                           , index) => {

                let other_cancelled = {};
                ['before_first_payment', 'after_30d', 'first_30d', 'first_day', 'first_hour', 'unknown'].forEach(item => {
                    const value = cancelled[item];
                    other_cancelled = {
                        ...other_cancelled,
                        ...{
                            [`cancelled_${item}`]:{
                                value,
                                percent: `${Math.ceil(saveDivide(value, subscriptions_total)*10000)/100} %`,
                            },
                        }
                    }
                });

                let cancelled_days = {};
                const _days = [2,3,4,6,8,10,15,20,25,30];
                _days.forEach((item, index) => {
                    const value = cancelled.days[`${item}`];
                    const prevValue = index === 0
                      ? (cancelled.first_hour + cancelled.first_day)
                      : cancelled_days[`cancelled_days_${_days[index - 1]}`]?.value || 0;

                    cancelled_days = {
                        ...cancelled_days,
                        ...{
                            [`cancelled_days_${item}`]:{
                                value: value + prevValue,
                                percent: `${Math.ceil(saveDivide(value + prevValue, subscriptions_total)*10000)/100} %`,
                            },
                        }}
                });

                let cancelled_hours = {};

                const hours = [2,4,8,12,18,24];
                hours.forEach((item, index) => {
                    const value = cancelled.hours[`${item}`];
                    const prevValue = index === 0
                      ? cancelled.first_hour
                      : cancelled_hours[`cancelled_hours_${hours[index - 1]}`]?.value || 0;

                    cancelled_hours = {
                        ...cancelled_hours,
                        ...{
                            [`cancelled_hours_${item}`]:{
                                value: value + prevValue,
                                percent: `${Math.ceil(saveDivide(value + prevValue, subscriptions_total)*10000)/100} %`,
                            },
                        }}
                });

                let cancelled_minutes = {};
                const minutes = [5,10,20,30,45,60];
                minutes.forEach((item, index) => {
                    const value = cancelled.minutes[`${item}`];
                    const prevValue = cancelled_minutes[`cancelled_minutes_${minutes[index - 1]}`]?.value || 0;

                    cancelled_minutes = {
                        ...cancelled_minutes,
                        ...{
                            [`cancelled_minutes_${item}`]:{
                                value: value + prevValue,
                                percent: `${Math.ceil(saveDivide(value + prevValue, subscriptions_total)*10000)/100} %`,
                            },
                        }}
                });


                cancelledData.push({
                    id: `row-${row}`,
                    period,
                    product,
                    days,
                    alias,
                    product_id,
                    subscriptions_total,
                    subscriptions_active :{
                        value:    subscriptions_active,
                        percent: `${Math.ceil(saveDivide(subscriptions_active, subscriptions_total)*10000)/100} %`,
                        tooltip:{
                            subscriptions_active_and_cancelled,
                            subscriptions_active_and_renewable,
                        }
                    },
                    subscriptions_renewable :{
                        value: subscriptions_renewable,
                        percent: `${Math.ceil(saveDivide(subscriptions_renewable, subscriptions_total)*10000)/100} %`,

                        tooltip:{
                            subscriptions_active_and_renewable,
                            subscriptions_expired_and_renewable,
                        }
                    },

                    ...cancelled_minutes,
                    ...cancelled_hours,
                    ...cancelled_days,
                    ...other_cancelled,

                    ...{
                        data,
                        users: users||'-',
                        subRowIndex: index,
                    }
                });

                row++;
            })
        });

        console.log('CancelledData', cancelledData);

        return cancelledData
    }
}

export default ReportService;