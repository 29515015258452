import React from "react";
import Box from "@material-ui/core/Box";

const Warnings = ({ data }) => {
    return (
        <Box p={1}>
            {data?.map(({ text }) =>
                <Box p={0.5} key={text}>{text}</Box>
            )}
        </Box>
    )
};


export default Warnings;