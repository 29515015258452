import palette from "../palette";

export default {
    root: {
        fill: palette.darkGrey,
    },
    colorPrimary:{
        fill: palette.primary.main,
    }
};
