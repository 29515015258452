import ApiService from "../services/api";
import {checkResponseError} from './helpers'


export default {
    state: {
        pending: false,
        error: null,
        errorCode: null,

        projects: null,
    },
    reducers: {
        authStart(state) {
            return {
                ...state,
                pending: true
            }
        },
        updateError(state, data) {
            return {
                ...state,
                ...data,
                pending: false
            }
        },
        updateProjects(state, data){
            return {
                ...state,
                ...data,
                pending: false
            }
        }
    },

    effects: (dispatch) => ({

        async projects(){
            try{
                dispatch.projects.authStart();

                const responseData = await ApiService.getProjects();

                checkResponseError(dispatch.projects, responseData);

                dispatch.projects.updateProjects({projects: responseData?.projects});

                return responseData;
            }
            catch (e) {
                console.log('Catch projects', e);
                dispatch.projects.updateProjects({error: e.message})
            }
        },

    })
};