import React, {useEffect, useState } from "react";
import {connect, useSelector} from "react-redux";
import {Box, Button, InputLabel} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {KeyboardDatePicker} from "@material-ui/pickers";
import Typography from "@material-ui/core/Typography";
import InlineEdit from "../../components/InlineEdit";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import {round} from "../../models/helpers";
import CircularProgress from "@material-ui/core/CircularProgress";
import clsx from "clsx";
import { getLocaleDateString } from "../../services/helpers";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import SelectProductsCheckboxes from "../../components/SelectProductsCheckboxes";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import { isNumber } from 'lodash'



const makeStyle = makeStyles((theme) => ({
    List: {
        width: '100%',
        border: '1px solid #DDD',
        marginTop: 15
    },
    formControl: {
        minWidth: 120,
    },
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
        zIndex: 1,
    },


    activeAccordion:{
        border: '1px solid #E0E4EF',
        boxSizing: 'border-box',
        boxShadow: '0 5px 7px rgba(0, 0, 0, 0.03)',
        borderRadius: 7,
        backgroundColor: '#E8EBF3',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '33.33%',
        flexShrink: 0,
        color: '#000'
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(13),
        color: '#7083A0',
    },

    conversionBoxCell:{
        backgroundColor:'#F4F6FA',
        minWidth: 'max-content',
        lineHeight:'30px',
    },
    conversionBoxCellOdd:{
        background: '#F7F9FC',
        fontSize: theme.typography.pxToRem(14),
    },
    conversionBoxCellEven:{
        background: '#fff',
        fontSize: theme.typography.pxToRem(14),
    }

}));

const format = getLocaleDateString('ru-RU');


const PeriodForm = (props) => {

    const [filters, setFilters] = useState({
        dateLeft: null,
        dateRight:null,
    });

    const handleChangeDates = (prop, event) => {
        if (null === event) return;

        let value = (event?.target?.value) ? event?.target?.value : event;

        if ('SyntheticEvent' === value?.constructor?.name )
            value = '';

        setFilters({
            ...filters,
            [prop]: value
        })
    };

    const handleLoadProductConversionsForPeriod = (productId) => {
        if (filters.dateLeft <= filters.dateRight ){
            props.getProductConversions({
                type: 'period',
                productId,
                dateLeft: filters.dateLeft,
                dateRight: filters.dateRight,
            });
        }
    };

    const handleDownloadReport = (product) => {

        const {dateLeft:dateAfter, dateRight:dateBefore} = filters;

        let queryParams = {
            projectId: props.project.active.id,
            dateAfter,
            dateBefore,
        };

        queryParams = {...queryParams, ...{ products : [product]}};

        props.download(queryParams)
    };

    return (
        <React.Fragment>
            <Box display={'block'} width="100%">
                <Box display={{xs: 'block', sm: 'block', md: 'flex', lg: 'flex'}} width="100%" >
                    <Box width="100%" >
                        <InputLabel htmlFor='startDate'>
                            Start Date
                        </InputLabel>

                        <KeyboardDatePicker
                            autoOk
                            variant="inline"
                            inputVariant="outlined"
                            format={format}
                            name={'dateLeft'}
                            value={filters.dateLeft}
                            InputAdornmentProps={{position: "end"}}
                            onChange={handleChangeDates.bind(this, 'dateLeft')}
                        />
                    </Box>

                    <Box width="100%" >
                        <InputLabel htmlFor='startDate'>
                            End Date
                        </InputLabel>

                        <KeyboardDatePicker
                            autoOk
                            variant="inline"
                            inputVariant="outlined"
                            format={format}
                            name={'dateRight'}
                            value={filters.dateRight}
                            InputAdornmentProps={{position: "end"}}
                            onChange={handleChangeDates.bind(this, 'dateRight')}
                        />
                    </Box>

                </Box>
            </Box>

            <Box display={'block'} width="100%">
                <Box display={{xs: 'block', sm: 'block', md: 'flex', lg: 'flex'}} width="100%">

                    <Box width="100%" alignSelf="flex-end" align={'end'} p={0.5}>
                        <Button style={{height: 49, width:'100%'}}
                                variant={"contained"}
                                color={'primary'}
                                disabled={!filters.dateRight || !filters.dateLeft}
                                onClick={handleLoadProductConversionsForPeriod.bind(this, props.productId)}
                        >Apply</Button>
                    </Box>
                    <Box width="100%" alignSelf="flex-end" p={0.5}>
                        <Button style={{height: 49, width:'100%'}}
                                variant={"outlined"}
                                color={'primary'}
                                onClick={handleDownloadReport.bind(this, props.productId)}
                                disabled={!filters.dateRight || !filters.dateLeft}
                                endIcon={
                                    props.products.startDownload ? <CircularProgress size={20} /> : null
                                }
                        >Download report</Button>
                    </Box>
                </Box>
            </Box>
        </React.Fragment>
    )
};




const GrowthReport = (props) => {
    const classes = makeStyle();

    const [tabValue, setTabValue] = useState('week');
    const [expanded, setExpanded] = useState(false);

    const [reportForm, setReportForm] = useState({
        dateAfter: null,
        dateBefore:null,
        products:{},
    });
    const { active } = useSelector(state => state.project);

    const periods = props.products.helpers?.periods || [];

    const countSelected = Object.keys(reportForm.products).reduce((p, c) => p + reportForm.products[c] , 0);

    useEffect(() => {

        if (props.products.pending === false && props.products.products === null &&
            props.project?.active?.id && props.project.pending === false) {
            props.getProducts({projectId: props.project.active.id});
        }
    }, [props, active]);

    const products = (props.products.products || [])
        .filter(product => product.params.period === tabValue || tabValue === null );

    const handleChangeDates = (prop, event) => {

        if (null === event) return;

        let value = (event?.target?.value) ? event?.target?.value : event;

        if ('SyntheticEvent' === value?.constructor?.name )
            value = '';

        setReportForm({
            ...reportForm,
            [prop]: value
        })
    };
    const handleSelectProduct = (event) => {
        const {products} = reportForm;
        setReportForm(prevState =>
            ({
                ...prevState,
                ...{
                    products:{
                        ...products, [event.target.name]: event.target.checked
                    }
                }
            })
        );
    };
    const handleChangeField = ({ productId, field, value, country }) => {
        const productIndex = (props.products.products || []).findIndex(({product_id}) => product_id === productId);
        if (productIndex !== -1) {
            const product = props.products.products[productIndex];

            if (field === 'period'){
                product.params.period = value;
            }
            if (field === 'trials'){
                product.params.trials = parseInt(value);
            }
            if (field === 'conversion'){
                product.odds.conversion_manual = parseFloat(value);
            }
            if (field === 'alias'){
                product.alias = value;
            }
            if (field === 'price'){
                product.params.price = value;
            }
            if (field === 'price_trial'){
                product.params.price_trial = value;
            }

            const _products = (props.products.products || []);

            _products[productIndex] = product;

            props.postProduct({
                productId,
                field,
                value: (isNumber(value) ? parseFloat(value) : value),
                country,
                projectId: props.project.active.id
            }).then(() => {
                props.updateProducts({ products: _products });
            });
        }
    };
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
    const handleLoadProductConversionsForCountries = (productId) => {
        if (expanded !== false){
            props.getProductConversions({
                type: 'countries',
                productId,
            });
        }
    };
    const handleChangeTab = (event, newValue) => {
        setTabValue(newValue)
    };
    const handleDownloadReport = () => {

        const {products, dateAfter, dateBefore} = reportForm;

        let queryParams = {
            projectId: props.project.active.id,
            dateAfter: dateAfter,
            dateBefore:dateBefore,
        };

        const productNames = Object.keys(products).reduce((p, c) => {
            if (products[c])
                p.push(c);

            return p;
        } , []);

        if (productNames.length > 0) {
            queryParams = {...queryParams, ...{ products : productNames}}
        }

        props.download(queryParams)
    };

    return (
        <React.Fragment>

            <Box mx={'auto'} style={{height: '100%', maxWidth:818, }} display={'grid'} >

                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1c-content"
                        id="panel1c-header"
                    >
                        <Typography>Download Reports { countSelected > 0 ? ` (selected: ${countSelected})` : '(All)'} </Typography>
                    </AccordionSummary>

                    <AccordionDetails>

                        <Box display={'block'} width="100%">
                            <Box display={{xs: 'block', sm: 'block', md: 'flex', lg: 'flex'}} width="100%" >
                                <Box width="100%" >
                                    <InputLabel htmlFor='startDate'>
                                        Start Date
                                    </InputLabel>

                                    <KeyboardDatePicker
                                        autoOk
                                        variant="inline"
                                        inputVariant="outlined"
                                        format={format}
                                        name={'dateAfter'}
                                        value={reportForm.dateAfter}
                                        InputAdornmentProps={{position: "end"}}
                                        onChange={handleChangeDates.bind(this, 'dateAfter')}
                                    />
                                </Box>

                                <Box width="100%" >
                                    <InputLabel htmlFor='startDate'>
                                        End Date
                                    </InputLabel>

                                    <KeyboardDatePicker
                                        autoOk
                                        variant="inline"
                                        inputVariant="outlined"
                                        format={format}
                                        name={'dateBefore'}
                                        value={reportForm.dateBefore}
                                        InputAdornmentProps={{position: "end"}}
                                        onChange={handleChangeDates.bind(this, 'dateBefore')}
                                    />
                                </Box>

                                <Box width="100%" alignSelf="flex-end" p={0.5} style={{zIndex: 1}}>
                                    <SelectProductsCheckboxes {...props}
                                                              name={'Choose products'}
                                                              items={props.products.products}
                                                              listChecked={reportForm?.products}
                                                              onChange={handleSelectProduct.bind(this)} />
                                </Box>

                                <Box width="100%" alignSelf="flex-end" p={0.5}>
                                    <Button style={{height: 49, width:'100%'}}
                                            variant={"outlined"}
                                            color={'primary'}
                                            onClick={handleDownloadReport.bind(this)}
                                            disabled={!reportForm.dateBefore || !reportForm.dateAfter}
                                            endIcon={
                                                props.products.startDownload ? <CircularProgress size={20} />:null}
                                    >
                                        Download
                                    </Button>
                                </Box>
                            </Box>
                        </Box>

                    </AccordionDetails>
                </Accordion>

                {props.products.pending && <CircularProgress className={classes.buttonProgress}/>}


            <Tabs value={tabValue}
                  onChange={handleChangeTab}
                  indicatorColor="primary"
                  textColor="primary"
                  scrollButtons="on"
                  variant="scrollable"
            >
                {periods.map(period =>
                    <Tab key={period} label={period}  value={period}/>
                )}
            </Tabs>

            {products.map((product, i) =>

                <Accordion key={i}
                           className={classes.activeAccordion}
                           expanded={expanded === product.product_id}
                           onChange={handleChange(product.product_id)}

                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                    >
                        <Box display={'block'} onClick={event => event?.stopPropagation()}>
                            <Typography component={Box} className={classes.heading}>
                                {product.alias === product.product_id ?
                                <InlineEdit text={product.alias}
                                            onSetText={
                                                value => handleChangeField({
                                                    productId: product.product_id,
                                                    field: 'alias',
                                                    value
                                                })}/>
                                            : product.product_id}
                            </Typography>

                            {product.alias !== product.product_id &&
                                <Typography component={Box} className={classes.secondaryHeading}>
                                    <InlineEdit text={product.alias}
                                                onSetText={
                                                    value => handleChangeField({
                                                        productId: product.product_id,
                                                        field: 'alias',
                                                        value
                                                    })
                                                }/>
                                </Typography>
                            }
                        </Box>
                    </AccordionSummary>
                    {
                        expanded === product.product_id &&
                        <AccordionDetails style={{display: 'contents'}}>
                            <Box display={'flex'} px={1} pt={0} pb={0}>
                                <Box width={'100%'} m={1}>
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1c-content"
                                            id="panel1c-header"
                                        >
                                            <div>
                                                <Typography>Choose dates from report</Typography>
                                            </div>
                                        </AccordionSummary>

                                        <AccordionDetails style={{display: 'block'}}>
                                            <PeriodForm {...props} productId={product.product_id} />
                                        </AccordionDetails>
                                    </Accordion>
                                </Box>

                                <Box width={'100%'} m={1}>
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="settings-content"
                                            id="settings-header"
                                        >
                                            <div>
                                                <Typography>Config</Typography>
                                            </div>
                                        </AccordionSummary>

                                        <AccordionDetails>
                                            <Box display={{xs: 'block', sm: 'block', md: 'block', lg: 'block'}} width={'100%'}>
                                                <Box display={'flex'} px={3} style={{ lineHeight: '30px' }}>
                                                    <Box flexShrink={0} >Period:</Box>
                                                    <Box align={'end'} width={'100%'}>
                                                        <Select
                                                            style={{height: 24}}
                                                            id="period-label"
                                                            variant={'outlined'}
                                                            value={product.params.period}
                                                            onChange={
                                                                e => handleChangeField({
                                                                    productId: product.product_id,
                                                                    field: 'period',
                                                                    value: e.target.value
                                                                })}
                                                        >
                                                            {periods.map(period =>
                                                                <MenuItem key={period} value={period}>{period}</MenuItem>
                                                            )}
                                                        </Select>
                                                    </Box>
                                                </Box>

                                                <Box display={'flex'} px={3} style={{lineHeight: '30px'}}>
                                                    <Box flexShrink={0}>Count days trial:</Box>
                                                    <Box align={'end'} width={'100%'}>
                                                        <InlineEdit
                                                            type={'number'}
                                                            text={product.params.trials}
                                                            onSetText={
                                                                value => handleChangeField({
                                                                    productId: product.product_id,
                                                                    field: 'trials',
                                                                    value
                                                                })
                                                            }
                                                        />
                                                    </Box>
                                                </Box>
                                                <Box display={'flex'} px={3}>
                                                    <Box flexShrink={0}>Price:</Box>
                                                    {/*<Box align={'end'} width={'100%'}>$&nbsp;{product?.params?.price_trial}</Box>*/}

                                                    <Box display='flex' justifyContent='flex-end' width={'100%'}>
                                                        $&nbsp;
                                                        <InlineEdit
                                                            type={'number'}
                                                            text={product?.params?.price_trial}
                                                            onSetText={
                                                                value => handleChangeField({
                                                                    productId: product.product_id,
                                                                    field: 'price_trial',
                                                                    value
                                                                })
                                                            }
                                                        />
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </AccordionDetails>
                                    </Accordion>
                                </Box>

                                {product.params.period !== 'lifetime' &&
                                    <Box>
                                        <FormControlLabel
                                            control={
                                                <Checkbox checked={reportForm.products?.[product.product_id] || false} onChange={handleSelectProduct.bind(this)} name={product.product_id} />
                                            }
                                            label="Download"
                                        />
                                    </Box>
                                }
                            </Box>

                            <Table size="small">
                                <TableHead style={{minWidth: 'max-content', backgroundColor:'#fff'}}>
                                    <TableRow>
                                        <TableCell align={'right'} >Price, USD</TableCell>
                                        <TableCell align={'right'}>Manual</TableCell>
                                        <TableCell align={'right'}>Actual</TableCell>
                                        <TableCell align={'right'}>Predictive</TableCell>
                                        <TableCell align={'right'}>LTV, USD</TableCell>

                                        <TableCell align={'right'}>Total / Active count</TableCell>
                                        <TableCell align={'left'}>Country</TableCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    <TableRow>
                                        <TableCell align={'left'}>
                                            <InlineEdit type={'number'}
                                                        currency
                                                        text={product.params?.price || 0}
                                                        onSetText={
                                                            value => handleChangeField({
                                                                productId: product.product_id,
                                                                field: 'price',
                                                                value
                                                            })}
                                            />
                                        </TableCell>
                                        <TableCell align={'right'}>
                                            <InlineEdit
                                                type={'number'}
                                                text={ round(product.odds.conversion_manual, 2) || 0}
                                                onSetText={
                                                    value => handleChangeField({
                                                        productId: product.product_id,
                                                        field: 'conversion',
                                                        value
                                                    })}
                                            />
                                        </TableCell>
                                        <TableCell align={'right'}>{product.odds.conversion_factual}</TableCell>
                                        <TableCell align={'right'}>3.6</TableCell>
                                        <TableCell align={'right'}>{round((product.params?.price || 0) * (product.odds.conversion_manual || 0), 2) }</TableCell>

                                        <TableCell align={'right'}>
                                            {product.odds.payments_total}
                                            &nbsp;/&nbsp;
                                            {product.odds.subscriptions_active}
                                        </TableCell>
                                        <TableCell align={'left'}>
                                            <Button component={Box}
                                                    py={0}
                                                    endIcon={<ArrowDropDownIcon />}
                                                    onClick={handleLoadProductConversionsForCountries.bind(this, product.product_id)}
                                            >
                                                All countries
                                            </Button>
                                        </TableCell>
                                    </TableRow>

                                    { products.conversions?.[product.product_id]?.map(({value_manual, value, odds, country}, i) =>
                                        <TableRow key={i} className={ clsx(classes.conversionBoxCell, i%2 ? classes.conversionBoxCellOdd : classes.conversionBoxCellEven)}>

                                            <TableCell colSpan={2} align={'right'}>
                                                <InlineEdit
                                                    type={'number'}
                                                    text={ value_manual || 0}
                                                    onSetText={
                                                        value => handleChangeField({
                                                            productId: product.product_id,
                                                            field: 'conversion_country',
                                                            value,
                                                            country: country.code
                                                        })}
                                                />
                                            </TableCell>

                                            <TableCell align={'right'}>
                                                {value}
                                            </TableCell>

                                            <TableCell align={'right'}>
                                                3.6
                                            </TableCell>

                                            <TableCell align={'right'}>
                                                {round( (product.params?.price ?? 0) * (value_manual ?? 0), 2) }
                                            </TableCell>

                                            <TableCell align={'right'}>
                                                {odds.payments_total}
                                                &nbsp;/&nbsp;
                                                {odds.subscriptions_active}
                                            </TableCell>

                                            <TableCell align={'left'}>
                                                {country.name}
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>

                            </Table>

                        </AccordionDetails>
                    }
                </Accordion>

            )}

        </Box>
        </React.Fragment>
    );
};

const mapState = state => ({
    products: state.products,
    project: state.project,
});

const mapDispatch = ({
                         products:{
                             getProducts,
                             postProduct,
                             updateProducts,
                             getProductConversions,
                             download
                         },
                     }) => ({
    postProduct: (data) => postProduct(data),
    getProducts,
    updateProducts,
    getProductConversions: (data) => getProductConversions(data),
    download
});
export default connect(mapState, mapDispatch)(GrowthReport)