import {reduce, groupBy, sumBy, round, sum, values} from "lodash";
import moment from "../../../core/moment";
import memoizeOne from "memoize-one";

const dateFormat = 'YYYY-MM-DD';
const groupingData = ({data, grouping}) =>
    groupBy(reduce(data, (acc, item, date) => ([
        ...acc,
        {
            date,
            ...item
        }
    ]), []), ({date}) => moment(date).endOf(grouping).format(dateFormat));

const memoizedGroupingData = memoizeOne(groupingData);

const prepareBarData = ({data, grouping, selectedProducts = []}) => {
    const groupedByDate = memoizedGroupingData({ data, grouping });

    const renewableData = reduce(groupedByDate, (acc, weekValues, date) => {
        return [
            ...acc,
            {
                period: date,
                ...reduce(selectedProducts, (cAcc, cItem) => {
                    const sumItems = sumBy(weekValues, `renewable.products.${cItem}`);
                    return {
                        ...cAcc,
                        ...(sumItems && {[cItem]: round(sumItems, 2)})
                    };
                }, {}),
            }
        ];
    }, []);

    const canceledData = reduce(groupedByDate, (acc, weekValues, date) => {
        return [
            ...acc,
            {
                period: date,
                ...reduce(selectedProducts, (cAcc, cItem) => {
                    const sumItems = sumBy(weekValues, `canceled.products.${cItem}`);
                    return {
                        ...cAcc,
                        ...(sumItems && {[cItem]: round(sumItems, 2)})
                    };
                }, {}),
            }
        ];
    }, []);

    return {
        canceledData,
        renewableData,
        ...({
            canceledSeries: canceledData.reduce((acc2, {period, ...otherProducts}) => ([
                ...acc2,
                {
                    x: period,
                    y: round(sum(values(otherProducts)), 2),
                }
            ]), []),
            renewableSeries: renewableData.reduce((acc2, {period, ...otherProducts}) => ([
                ...acc2,
                {
                    x: period,
                    y: round(sum(values(otherProducts)), 2),
                }
            ]), [])
        })
    }
};


export default prepareBarData;
