import ApiService from "../services/api";
import {checkResponseError} from './helpers'


export default {
    state: {
        pending:   false,
        error:     null,
        errorCode: null,

        actionLog:  null,
        cohorts:    null,
        histogram:  null,
        statistics: null,

    },
    reducers: {
        authStart(state) {
            return {
                ...state,
                pending: true
            }
        },
        updateError(state, data) {
            return {
                ...state,
                ...data,
                pending: false
            }
        },
        updateVersionLog(state, data){
            return {
                ...state,
                ...data,
                pending: false
            }
        }
    },

    effects: (dispatch) => ({

        async getVersionLog(data){
            try{

                dispatch.versionLog.authStart();

                const responseData = await ApiService.getVersionLog(data);

                checkResponseError(dispatch.versionLog, responseData);

                dispatch.versionLog.updateVersionLog({
                    actionLog:  responseData?.action_log,
                    cohorts:    responseData?.cohorts,
                    histogram:  responseData?.histogram,
                    statistics: responseData?.statistics,
                });

                return responseData;
            }
            catch (e) {
                console.log('Catch versionLog', e);
                dispatch.versionLog.updateError({error: e.message})
            }
        },
    })
};