import React from "react";
import { Paper, TableHead, TableRow, TableContainer, TableBody } from '@mui/material';
import {Cell, StyledTable} from "../styled";
import Tooltip from "@material-ui/core/Tooltip";
import LTVDistribution from "./LTVDistribution";


const HeaderCells = ({ data = [] }) => (
    <>
        {data?.map(({ key, date, name }) => (
            <Cell key={key} align='right'>
                {name || date}
            </Cell>
        ))}
    </>
);

const BaseTable = ({ tableName, rows, data, loading = false}) => (
    <TableContainer component={Paper} mb={1}>
         <StyledTable sx={{minWidth: 650}} size="small">
             {loading && <caption>Loading...</caption>}

             <TableHead>
                {tableName && (
                    <TableRow>
                        <Cell align='center' colSpan={data.length + 1}>
                            {tableName}
                        </Cell>
                    </TableRow>
                )}

                {data.length > 0 &&
                    <TableRow>
                        <Cell>Период</Cell>
                        <HeaderCells data={data}/>
                    </TableRow>
                }
            </TableHead>

             {data.length > 0 &&
                 <TableBody>
                     {rows.map(({field, format}) => (
                         <TableRow key={field}>
                             <Cell align="left">{field}</Cell>
                             {data.map(payment => {
                                 const cell = <Cell
                                     key={`cell-${payment.key}-${field}`}>{format(payment?.[field])}</Cell>;

                                 if (field !== 'LTV') return cell;

                                 return (
                                     <Tooltip
                                         key={`tooltip-${payment.key}-${field}`}
                                         disableHoverListener={payment?.data?.LTV_distribution?.length === 0}
                                         title={<LTVDistribution data={payment?.data?.LTV_distribution || []} />}
                                     >
                                         {cell}
                                     </Tooltip>
                                 )
                             })}
                         </TableRow>
                     ))}
                 </TableBody>
             }
        </StyledTable>
    </TableContainer>
);

export default BaseTable;
