import {Box} from "@material-ui/core";
import React, {useEffect, useRef, useState} from "react";
import {
    ALL_SPEND_GROUP,
    BORDER_SIZE,
    COLUMN_WIDTH,
    DEFAULT_SPEND_GROUP,
    HEADER_HEIGHT,
    LEFT_COLUMN_WIDTH,
    MIN_TABLE_CONTAINER_HEIGHT,
    PROFIT_COLUMNS_COUNT,
    ROW_HEIGHT,
    ROWS_SEPARATE_HEIGHT,
} from "../Report";
import useWindowSize from "../../../hooks/useWindowSize";
import {makeStyles} from "@material-ui/styles";
import {connect} from "react-redux";
import {ScrollSync, ScrollSyncPane} from "react-scroll-sync";
import clsx from "clsx";
import Header from "./Header";
import {round} from "../../../models/helpers";
import InlineEdit from "../../../components/InlineEdit";



const mapState = state => ({
    reports: state.reports,
    profile: state.profile,
    project: state.project,
});

const mapDispatch = ({
                         reports:{
                             getReports,
                             getProjectReportSummary,
                             postProjectReportsSpend,
                             postProjectReportsRevenue,
                             updateReports,
                         },
                     }) => ({
    getReports: (data) => getReports(data),
    getProjectReportSummary: (data) => getProjectReportSummary(data),
    postProjectReportsSpend: (data) => postProjectReportsSpend(data),
    postProjectReportsRevenue: (data,data2) => postProjectReportsRevenue(data, data2),
    updateReports:(data) => updateReports(data)
});

const TotalHeader =  connect(mapState, mapDispatch)((props) => {

    useEffect(() => {
        if (props.reports.summary === null && props.reports.pending === false
            && props.project?.active?.id && props.project.pending === false && props.reports.error === null) {
            props.getProjectReportSummary({projectId: props.project.active.id})
        }
    }, [props]);


    return (
        <Box style={{
            height: 50,
            background: '#F0F3F9',
            borderRadius: '7px 7px 0px 0px',
        }}
             display="flex"
             px={3}
        >
            <Box my={'auto'} pr={3}>
                <Box component={'span'}
                     variant={'body2'}
                     color={'#7D889A'}>Total spend:</Box>
                <b>
                    &nbsp;$&nbsp;
                    {props.reports?.summary?.total_spend.toLocaleString('en-US') || 'Loading...'}
                </b>
            </Box>
            <Box my={'auto'} pr={3}>
                <Box component={'span'}
                     variant={'body2'}
                     color={'#7D889A'}>Total rev:</Box>
                <b>
                    &nbsp;$&nbsp;
                    {props.reports?.summary?.total_revenue.toLocaleString('en-US') || 'Loading...'}
                </b>
            </Box>
            <Box my={'auto'} pr={3}>
                <Box component={'span'}
                     variant={'body2'}
                     color={'#7D889A'}>Total profit:</Box>
                <b>
                    &nbsp;$&nbsp;
                    {props.reports?.summary?.total_profit.toLocaleString('en-US') || 'Loading...'}
                </b>
            </Box>

        </Box>
    )
});




const styles =  makeStyles( theme => ({
    root:{
        fontSize: 12,
        borderRadius: 7,
        boxShadow: 'rgba(0, 0, 0, 0.1) 3px 3px 12px',

        '&.cell':{
            '& *': {
                color: 'red'
            }
        }
    },
    header:{
        borderBottom: `${BORDER_SIZE}px solid #fff`,
    },
    revenueClass:{
        backgroundColor: '#F0F9FC'
    },
    subscriptionsWithTrial:{
        backgroundColor: '#E9F7EF'
    },
    subscriptions:{
        backgroundColor: '#D2EFDD'
    },
    payments:{
        backgroundColor: '#BAF0ED'
    },
    borderRight:{
        borderRight: '1px solid #fff',
    },
    spendBG:{
        backgroundColor: '#F9ECCA'
    },
    spendSumBG:{
        backgroundColor: '#FAE0D8'
    },
    roiBGColor:{
        background: '#E6F3F7',
        '&>.positiveBGColor':{
            backgroundColor:'#BAECCD',
            borderBottom: `${BORDER_SIZE}px solid #fff`,
            width: `${COLUMN_WIDTH - BORDER_SIZE}px`,
            height: `${ROW_HEIGHT - BORDER_SIZE}px`,
        },
        '&>.negativeBGColor':{
            backgroundColor:'#FAE0D8',
            borderBottom: `${BORDER_SIZE}px solid #fff`,
            width: `${COLUMN_WIDTH - BORDER_SIZE}px`,
            height: `${ROW_HEIGHT - BORDER_SIZE}px`,
        },
        '& > .neutralBGColor': {
            backgroundColor: '#f6fac2',
            borderBottom: `${BORDER_SIZE}px solid #fff`,
            width: `${COLUMN_WIDTH - BORDER_SIZE}px`,
            height: `${ROW_HEIGHT - BORDER_SIZE}px`,
        }
    },
    predictiveBGColor:{
        background: '#E6F3F7',
        '&>.positiveBGColor':{
            backgroundColor:'#BAECCD',
            borderBottom: `${BORDER_SIZE}px solid #fff`,
            width: `${COLUMN_WIDTH - BORDER_SIZE}px`,
            height: `${ROW_HEIGHT - BORDER_SIZE}px`,
            '& > sup':{
                paddingRight: 4,
            },
            '& > div:not(.cell)':{
                paddingRight: 8,
            }
        },
        '&>.negativeBGColor':{
            backgroundColor:'#FAE0D8',
            borderBottom: `${BORDER_SIZE}px solid #fff`,
            width: `${COLUMN_WIDTH - BORDER_SIZE}px`,
            height: `${ROW_HEIGHT - BORDER_SIZE}px`,
            '& > sup':{
                paddingRight: 4,
            },
            '& > div:not(.cell)':{
                paddingRight: 8,
            }
        },
        '& > .neutralBGColor': {
            backgroundColor: '#f6fac2',
            borderBottom: `${BORDER_SIZE}px solid #fff`,
            width: `${COLUMN_WIDTH - BORDER_SIZE}px`,
            height: `${ROW_HEIGHT - BORDER_SIZE}px`,
            '& > sup':{
                paddingRight: 4,
            },
            '& > div:not(.cell)':{
                paddingRight: 8,
            }
        }
    }
}));


function ScrollTable(props){

    const classes = styles();

    const boxContainer  = useRef(null);
    // const headerRef     = useRef(null);
    // const leftColumnRef = useRef(null);

    const [_width, _height] = useWindowSize();
    const maxHeight = (_height < MIN_TABLE_CONTAINER_HEIGHT ? MIN_TABLE_CONTAINER_HEIGHT : _height) - (boxContainer?.current?.getBoundingClientRect()?.top || 100) - 50;

    let sumPaddingRightLeft = LEFT_COLUMN_WIDTH;
    if (boxContainer?.current?.getBoundingClientRect()) {

        const {
            // bottom, height, top, x, y,
            left, right,  width
        } = boxContainer?.current?.getBoundingClientRect();
        const paddingRight = (right - width) || 0;
        sumPaddingRightLeft = LEFT_COLUMN_WIDTH + (left + paddingRight) || 0;

    }

    const [spends, setSpends] = useState(DEFAULT_SPEND_GROUP);
    const handleShowHideSpend = () => {

        if (ALL_SPEND_GROUP.length !== spends.length) {
            setSpends(ALL_SPEND_GROUP);
        } else {
            setSpends(DEFAULT_SPEND_GROUP);
        }
    };

    useEffect(() => {

        if (props.reports.data === null && props.reports.pending === false
            && props.project?.active?.id && props.project.pending === false && props.reports.error === null) {

            props.getReports({projectId: props.project.active.id, limit: 5});
        }

    }, [props]);

    const _handleUpdateSpendValue = (date, source, value, weekDayIndex, rowIndex) => {

        setTimeout(() =>
            props.postProjectReportsSpend({
                projectId: props.project.active.id,
                date,
                source,
                value,
                weekDayIndex,
                rowIndex}),
            0);
    };

    const _handleUpdateRevenueValue = (date, value, weekDayIndex, rowIndex) => {

        setTimeout(() =>
            props.postProjectReportsRevenue({
                projectId: props.project.active.id,
                date,
                value,
                weekDayIndex,
                rowIndex
            })
        , 0)
    };


    const WeekBody = ({dates, id}, i) => {

        const predictiveProfit = props.reports.data[i].revenue.value_manual
            .map((value, index) =>
                round(value -  props.reports.data[i].spends.sum[index], 2)
            );

        let info2 = {};

        const subscriptionsInWeek = props.reports.data[i].subscriptions.info;
        const paymentsInWeek = props.reports.data[i].payments.info;

        props.reports.uniqueSubscriptions.forEach(({product_id}) => {
            let data = info2?.[product_id] || {count:[], trials:[]};
            let {count, trials} = data;

            subscriptionsInWeek.forEach(subscriptionsPerDay => {
                const subscription = subscriptionsPerDay.find(({product_id : sub_product_id}) => product_id === sub_product_id);

                count.push(subscription.count);

                if (subscription.hasOwnProperty('trials'))
                    trials.push(subscription.trials);
            });

            info2 = {...info2, ...{[product_id]: {count, trials}}}

        });

        props.reports.uniquePayments.forEach(({product_id}) => {
            let data = info2?.[product_id] || {count:[], trials:[]};
            let {count, trials} = data;

            paymentsInWeek.forEach(paymentsPerDay => {
                const subscription = paymentsPerDay.find(({product_id : sub_product_id}) => product_id === sub_product_id);

                count.push(subscription.count);

                if (subscription.hasOwnProperty('trials'))
                    trials.push(subscription.trials);
            });

            info2 = {...info2, ...{[product_id]: {count, trials}}}
        });

        return (
            <Box key={i}
                 display="flex"
                 flexDirection="row"
                 height={ (1 + dates?.length) * ROW_HEIGHT + ROWS_SEPARATE_HEIGHT}
                 width={(spends.length + PROFIT_COLUMNS_COUNT) * COLUMN_WIDTH}
            >
                <Spends {...props} rowIndex={i} id={id} onChange={
                    (newValue, spendKey, weekDayIndex) =>
                        _handleUpdateSpendValue(props.reports.data[i].dates[weekDayIndex], spendKey, newValue, weekDayIndex, i )
                }/>

                {/* Revenue */}
                <WeekValues values={props.reports.data[i].revenue.value}
                            keyId={`${id}-${i}-Revenue`}
                            className={classes.revenueClass}
                            currency={true}
                />
                {/* Profit */}
                <WeekValues values={props.reports.data[i].profit.value}
                            keyId={`${id}-${i}-Profit`}
                            className={clsx(classes.revenueClass, classes.predictiveBGColor, classes.borderRight)}
                            bgColor={true}
                            currency={true}/>
                {/* Profit ROI */}
                <WeekValues values={props.reports.data[i].profit.value_roi}
                            keyId={`${id}-${i}-profit-roi`}
                            className={clsx(classes.revenueClass, classes.roiBGColor)}
                            bgColor={true}
                            percent={true} />

                {/* Predictive Revenue */}
                <WeekValues values={props.reports.data[i].revenue.value_manual}
                            keyId={`${id}-${i}-predictive-revenue`}
                            className={classes.predictiveBGColor}
                            currency={true}/>
                {/* Predictive Profit */}
                <WeekValues values={predictiveProfit}
                            keyId={`${id}-${i}-predictive-profit`}
                            className={clsx(classes.predictiveBGColor, classes.borderRight)}
                            bgColor={true}
                            currency={true}/>
                {/* Predictive ROI */}
                <WeekValues values={props.reports.data[i].profit.value_manual_roi}
                            keyId={`${id}-${i}-predictive-roi`}
                            className={clsx(classes.predictiveBGColor, classes.roiBGColor)}
                            bgColor={true}
                            percent={true} />

                {/* Revenue (iTunes) */}
                <WeekValues values={props.reports.data[i].revenue.value_cursive}
                            keyId={`${id}-${i}-revenue-itunes`}
                            className={classes.revenueClass}
                            bgColor={true}
                            isEditable={true}
                            onChange={(newValue, weekDayIndex) =>
                                _handleUpdateRevenueValue(props.reports.data[i].dates[weekDayIndex], newValue, weekDayIndex, i )
                            }
                            currency={true}/>
                {/* Profit (iTunes) */}
                <WeekValues values={props.reports.data[i].profit.value_cursive}
                            keyId={`${id}-${i}-profit-itunes`}
                            headerValues={props.reports.data[i].profit.value_cursive_roi}
                            className={ clsx(classes.revenueClass, classes.predictiveBGColor, classes.borderRight)}
                            bgColor={true}
                            currency={true}/>
                {/* Cost per purchase */}
                <WeekValues values={props.reports.data[i].payments.cost}
                            keyId={`${id}-${i}-cost-per-purchase`}
                            className={classes.revenueClass}
                            currency={true}/>
                {/* Purchases count */}
                <WeekValues values={props.reports.data[i].payments.count}
                            keyId={`${id}-${i}-purchase-count`}
                            className={classes.revenueClass} />
                {/* Subscribes count */}
                <WeekValues values={props.reports.data[i].subscriptions.count}
                            keyId={`${id}-${i}-subscribes-count`}
                            className={clsx(classes.revenueClass, classes.borderRight)} />
                {/* Cost trial */}
                <WeekValues values={props.reports.data[i].subscriptions.cost}
                            keyId={`${id}-${i}-cost-trial`}
                            className={classes.revenueClass}
                            currency={true}/>
                {/* Trial count */}
                <WeekValues values={props.reports.data[i].subscriptions.count_trials}
                            keyId={`${id}-${i}-trial-count`}
                            className={classes.revenueClass} />
                {/* Conversion count */}
                <WeekValues values={props.reports.data[i].subscriptions.count_conversions}
                            keyId={`${id}-${i}-conversion-count`}
                            className={classes.revenueClass} />
                {/* Conversion trial/purchase */}
                <WeekValues values={props.reports.data[i].subscriptions.conversions_rate}
                            keyId={`${id}-${i}-Conversion-trial-purchase`}
                            className={classes.revenueClass} />

                {props.reports.uniqueSubscriptions.map(({product_id, columnCount}) => {

                    if (columnCount === 2) {
                        return (
                            <React.Fragment key={`trials_${product_id}`}>
                                <WeekValues values={info2[product_id].count}
                                            keyId={`${id}-${i}-${product_id}-subscriptions-first-column`}
                                            className={classes.subscriptionsWithTrial}/>
                                <WeekValues values={info2[product_id].trials}
                                            keyId={`${id}-${i}-${product_id}-subscriptions-second-column`}
                                            width={COLUMN_WIDTH-BORDER_SIZE}
                                            className={clsx(classes.subscriptionsWithTrial, classes.borderRight)} />
                            </React.Fragment>
                        )
                    }

                    return (
                        <WeekValues key={`count_${product_id}`}
                                    values={info2[product_id].count}
                                    width={COLUMN_WIDTH-BORDER_SIZE}
                                    className={clsx(classes.subscriptions, classes.borderRight)}  />
                    )
                })}

                {props.reports.uniquePayments.map(({product_id, columnCount}) => {

                    if (columnCount === 2) {
                        return (
                            <React.Fragment key={`trials_${product_id}`}>
                                <WeekValues values={info2[product_id].count}
                                            keyId={`${id}-${i}-${product_id}-payment-first-column`}/>
                                <WeekValues values={info2[product_id].trials}
                                            keyId={`${id}-${i}-${product_id}-payment-first-column`}
                                            className={clsx(classes.payments, classes.borderRight)}  />
                            </React.Fragment>
                        )
                    }

                    return (
                        <WeekValues key={`count_${product_id}`}
                                    values={info2[product_id].count}
                                    className={clsx(classes.payments, classes.borderRight)}  />
                    )
                })}

        </Box>)
    };

    const Spends = (props) => spends.map((spendKey) =>
        <Box width={COLUMN_WIDTH}
             className={clsx(classes.spendBG, {[classes.spendSumBG]: spendKey==='sum'})}
             key={spendKey}
             style={{marginBottom: ROWS_SEPARATE_HEIGHT, textAlign: 'end'}}
             justifyContent="flex-end">

            {props.reports.data[props.rowIndex].spends[spendKey]
                .map(
                    Cell.bind(this, {
                        headerValue:[],
                        currency:true,
                        onChange:(newValue, weekDayIndex) => props.onChange(newValue, spendKey, weekDayIndex),
                        isEditable:(spendKey !=='sum')
                    })
                )}
        </Box>
    );

    const onSync = (el) => {
        // console.log('getBoundingClientRect', el.querySelector(':scope > div:last-child'));
    };

    const onScroll = (e) => {
        // e.persist();

        const {y} = e.target.querySelector(':scope > div:last-child').getBoundingClientRect();

        if (props.reports.pending === false && y < 600) {
            props.getReports({projectId: props.project.active.id, offset: props.reports.data.length});
        }
        // console.log('onScroll', boxContainer?.current?.getBoundingClientRect(), );
    };


    return (
        <Box ref={boxContainer} height={maxHeight} width={'100%'} className={classes.root}>

            <TotalHeader/>

            <ScrollSync onSync={onSync}>
                <React.Fragment>
                    <Box display="flex" height={HEADER_HEIGHT} className={classes.header}>

                        <Box flexShrink={0} width={LEFT_COLUMN_WIDTH} display={'flex'} justifyContent="center" alignItems="center">Date</Box>

                        <ScrollSyncPane group="horizontal">
                            <Box  width={_width - sumPaddingRightLeft} style={{overflow: 'auto'}} display={'flex'}>

                                <Header {...props} spends={spends} handleShowHideSpend={handleShowHideSpend}/>

                            </Box>
                        </ScrollSyncPane>

                    </Box>


                    <Box display="flex" height={200}>

                        <ScrollSyncPane group="vertical"   >
                            <Box flexShrink={0} width={LEFT_COLUMN_WIDTH} style={{ display: 'block', overflowY: 'auto',}} onScroll={onScroll}>
                                {
                                    props.reports.data?.map(WeekDates)
                                }
                            </Box>
                        </ScrollSyncPane>

                        {/* Render Body Cells  */}
                        <ScrollSyncPane group={["horizontal", "vertical"]}>
                            <Box  width={_width - sumPaddingRightLeft} style={{ display: 'block', overflowY: 'auto',}}>

                                {
                                    props.reports.data?.map(WeekBody)
                                }

                            </Box>
                        </ScrollSyncPane>
                    </Box>
                </React.Fragment>
            </ScrollSync>

        </Box>
    )
}


const WeekValues = ({values, headerValues=[], className='', currency=false, percent=false, bgColor=false, isEditable=false, onChange, keyId}) =>
    <Box width={COLUMN_WIDTH - BORDER_SIZE}
         className={className}
         style={{marginBottom: ROWS_SEPARATE_HEIGHT,textAlign: 'end' }}
         justifyContent="flex-end">

        {values.map(Cell.bind(this, {
                headerValues,
                currency,
                percent,
                bgColor,
                isEditable,
                onChange:(newValue, weekDayIndex) => onChange(newValue, weekDayIndex), keyId}
            )
        )}

    </Box>;


export const WeekDates = ({dates, id}) =>
    <Box key={id} height={ (dates?.length) * ROW_HEIGHT + ROWS_SEPARATE_HEIGHT} className={'cells'} >
        <WeekValues values={[...dates,
            // ...['on week']
        ]} />
    </Box>;

const Cell = ({headerValues=[],currency, percent, bgColor=false, isEditable=false, onChange, keyId}, value, i) => {

    let outValue = value;
    if (currency === true) outValue = `$ ${value.toLocaleString('en-US')}`;
    if (percent === true) outValue  = `${value} %`;

    let classBG = '';
    let supColor = '';
    if (bgColor === true) {
        classBG = 'positiveBGColor';
        if ((value < 700 && currency) || (value < 30 && percent)) classBG = 'neutralBGColor';
        if (value < 0 && (currency || percent)) classBG = 'negativeBGColor';


        if (headerValues.length > 0)
            classBG = value >= 0 ? 'positiveBGColor' : 'negativeBGColor';
        supColor = headerValues[i] < 0 ? 'negativeColor' : 'positiveColor';
    }

    return (
        <Box key={`${keyId}-${i}`}
             height={ROW_HEIGHT}
             width={COLUMN_WIDTH }
             className={classBG}
             display={headerValues.length > 0 ? 'grid' : 'block'}
        >

            {isEditable ? <InlineEdit type={'number'}
                                       currency
                                       text={value}
                                       onSetText={newValue => onChange(newValue, i)}
            />
            :
                (headerValues.length > 0 ?
                    <React.Fragment><sup className={supColor} >({`${headerValues[i]}%`})</sup><Box
                        alignItems="center">{value}&nbsp;</Box></React.Fragment>
                    :
                    <Box height={'100%'} className={'cell'}>
                        {outValue}&nbsp;
                    </Box>)
            }
        </Box>
    )
};


export default connect(mapState, mapDispatch)(ScrollTable);