import {alpha, makeStyles} from "@material-ui/core";


const useStyles = makeStyles((theme) => ({
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.primary.main,
    },
    form: {
        padding: '16px 8px',
        width: '100%', // Fix IE 11 issue.
        border: `1px solid #E0E4EF`,
        boxShadow: `0px 5px 7px ${alpha(theme.palette.black, 0.03)}, 0px 30px 50px rgba(1, 11, 99, 0.05)`,
        borderRadius: 7,
        backgroundColor: theme.palette.secondary.dark,

        '& > .MuiGrid-grid-xs-true': {
            minWidth: 180,
        }
    },
    title: {
        textAlign: 'center',
    },
    submit: {
        minWidth: 192,
    },
    section: {
        padding: `${theme.spacing(1)}px ${theme.spacing(3)}px`,
        display: 'flex',
        alignItems: 'baseline',
        '& > *': {
            padding: 0,
            margin: 0,
        },
    },
    span: {
        flex: 1,
        borderBottom: `2px dotted ${theme.palette.darkGrey}`,
        minWidth: 20,
    },
    total: {
        marginTop: theme.spacing(5),
        boxShadow: '0px 5px 7px rgba(0, 0, 0, 0.03)',
        paddingBottom: theme.spacing(0.5),
        borderRadius: 7,
        '&>div': {
            width: '100%',
            '&>div': {
                background: '#F0F3F9',
                padding: `${theme.spacing(2) + 2}px ${theme.spacing(3) + 2}px ${theme.spacing(2) - 2}px`,
                marginBottom: theme.spacing(0.5),
                [theme.breakpoints.down('md')]: {
                    borderRadius: 7,
                },
            },
            [theme.breakpoints.up('md')]: {
                '&:first-child': {
                    '&>div': {
                        '&:first-child': {
                            borderTopLeftRadius: 7,
                            borderBottomLeftRadius: 7,

                        }
                    }
                },
                '&:last-child': {
                    '&>div': {
                        '&:first-child': {
                            borderTopRightRadius: 7,
                            borderBottomRightRadius: 7,
                        },
                    }
                },
            }

        }
    },
    table: {
        borderRadius: 7,
        '&>thead.MuiTableHead-root': {
            // '&>tr::first-child>th':{
            color: `${theme.palette.black} !important`,
            boxShadow: `0px 5px 7px ${alpha(theme.palette.black, 0.03)}`,
            // }
        }
    },
    odd: {
        backgroundColor: theme.palette.secondary.light,
    },
    even: {
        backgroundColor: theme.palette.white,
    },
    profitCeil: {
        backgroundColor: theme.palette.secondary.dark
    },
    positive: {
        color: theme.palette.primary.main
    },
    negative: {
        color: theme.palette.error.main
    },
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    textAlignEnd: {
        textAlign: 'end'
    },
    textAlignCenter: {
        textAlign: 'center'
    },
    formControl: {
        // margin: theme.spacing(1),
        minWidth: 250,
        maxWidth: 300,
    },
}));

export default useStyles;