import {isEmpty, round, isObject} from "lodash";
import Box from "@material-ui/core/Box";
import Loader from "./../../../components/table/loader";
import {EmptyBox} from "./../../../components/table/style";
import React from "react";

const Money = value => isObject(value) ? '' : `$ ${round(value, 2)?.toLocaleString('en-US')}`;
const Percent = value => isObject(value) ? '' : `${round(value, 3)} %`;

export const CellFormatRender = {
    revenue: Money,
    subrate: Percent,
    profit: Money,
    ROMI: Percent,
    CAC: Money,
    LTV: Money,
    ARPU: Money,
    CAC_trials: Money,
    money: Money,
    price: Money,
    amount: Money,
    percent: Percent
};

export const emptyRenderer = ({ data, loading = false }) => (
    <Box width="100%" height="100%">
        {loading && isEmpty(data) && <Loader loading={loading} />}
        {!loading && isEmpty(data) && <EmptyBox>Nothing to show</EmptyBox>}
    </Box>
);