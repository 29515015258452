import React from "react";
import {ResponsiveLine} from '@nivo/line'
import Card from "@mui/material/Card";
import {linearGradientDef} from "@nivo/core";
import moment from "../../../core/moment";
import {sum} from "lodash";

const LineChart = ({ data = []}) => {
    return (
        <Card sx={{mt: 2, height: 450, width: '100%'}}>
            <ResponsiveLine
                data={data}
                xScale={{
                    type: 'time',
                    // type: 'linear',
                    format: '%Y-%m-%d',
                    useUTC: false,
                    precision: 'day',
                    // stacked: true
                }}
                xFormat="time:%Y-%m-%d"
                axisLeft={{
                    legend: 'Money, $',
                    legendOffset: 12,
                }}
                axisBottom={{
                    format: '%d %b %Y',
                    tickValues: 'every 14 days',
                    legend: `Time day`,
                    legendOffset: -12,
                }}
                curve="monotoneX"
                enablePointLabel={false}
                pointSize={0}
                pointBorderWidth={1}
                pointBorderColor={{
                    from: 'color',
                    modifiers: [['darker', 0.3]],
                }}
                margin={{top: 20, right: 160, bottom: 70, left: 40}}
                yScale={{
                    type: 'linear',
                    // max: maxForYLabel,
                    stacked: true,
                    reverse: false
                }}
                axisTop={null}
                axisRight={null}
                colors={['#028ee6', '#774dd7']}
                pointLabel="y"
                pointLabelYOffset={-12}
                useMesh
                legends={[
                    {
                        anchor: 'bottom-right',
                        direction: 'column',
                        justify: false,
                        translateX: 100,
                        translateY: 0,
                        itemsSpacing: 0,
                        itemDirection: 'left-to-right',
                        itemWidth: 80,
                        itemHeight: 20,
                        itemOpacity: 0.75,
                        symbolSize: 12,
                        symbolShape: 'circle',
                        symbolBorderColor: 'rgba(0, 0, 0, .5)'
                    }
                ]}
                enableArea={true}
                defs={[
                    linearGradientDef('gradientA', [
                        {offset: 0, color: 'inherit'},
                        {offset: 100, color: 'inherit', opacity: 0},
                    ]),
                ]}
                fill={[{match: '*', id: 'gradientA'}]}

                yFormat={value =>
                    `$ ${Number(value).toLocaleString('en-US', {
                        minimumFractionDigits: 2,
                    })}`
                }
                enableSlices="x"
                sliceTooltip={({slice}) => (
                    <div
                        style={{
                            background: 'white',
                            padding: '9px 12px',
                            border: '1px solid #ccc',
                        }}
                    >
                        <strong>{moment(slice.points[0].data.x).format('DD-MM-YYYY')}</strong>
                        <table>
                            {slice.points.map(point => (
                                <tr
                                    key={point.id}
                                    style={{
                                        color: point.serieColor,
                                        padding: '3px 0',
                                    }}
                                >
                                    <td>{point.serieId}</td>
                                    <td>{point.data.yFormatted}</td>
                                </tr>
                            ))}
                            <tr>
                                <td>Total</td>
                                <td>$ {Math.ceil(sum(slice.points.map(point => point.data.y)) * 100) / 100}</td>
                            </tr>
                        </table>
                    </div>
                )}
            />
        </Card>
    );
};

export default LineChart