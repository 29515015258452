


export default {
    state: {
        pending: false,
        error: null,
        errorCode: null,

        list: null,
    },

    reducers:{
        authStart(state) {
            return {
                ...state,
                pending: true
            }
        },
        updateError(state, data) {
            return {
                ...state,
                ...data,
                pending: false
            }
        },
        updateGrowth(state, data){
            return {
                ...state,
                ...data,
                pending: false
            }
        }
    },

    effects: (dispatch) => ({

    })
}