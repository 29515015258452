import React from "react";
import {Typography} from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";


const TableHeaderCell = ({ className, column })  => {

    if (column?.tooltip) {
        return (
            <Tooltip className={className} title={column?.tooltip} placement="top-start" arrow>
                <div >{column.title}</div>
            </Tooltip>
        )
    }

    return <div className={className}>{column.title}</div>
};

const TrialReportCell = ({columnIndex, cellData, rowData}) => {

    if (columnIndex < 2) return <div key={`${rowData.id}-${columnIndex}`} style={{display: 'contents'}}>{cellData}</div>;

    return (
        <div key={`${rowData.id}-${columnIndex}`}>
            <div>{cellData}</div>

            {columnIndex >= 8 ?
                cellData ? <Typography variant={'overline'}>{Math.ceil(cellData / (rowData?.conversions||0) * 10000) / 100}%</Typography>:null
                :
                rowData?.trials > 0 && <Typography variant={'overline'}>{Math.ceil(cellData / rowData.trials * 10000) / 100}%</Typography>
            }
        </div>
    );
};


export default {
    TableCell: TrialReportCell,
    TableHeaderCell: TableHeaderCell
}