import ApiService from "../services/api";

export default {
    state: {
        pending: false,
        error: null,
        errorCode: null,

        text: null
    },

    reducers:{
        authStart(state) {
            return {
                ...state,
                pending: true
            }
        },
        updateError(state, data) {
            return {
                ...state,
                ...data,
                pending: false
            }
        },
        updatePredictiveDebug(state, data){
            return {
                ...state,
                ...data,
                pending: false
            }
        }
    },

    effects: (dispatch) => ({
        async getPredictiveDebug(data) {
            try {
                dispatch.predictiveDebug.authStart();
                const responseData = await ApiService.getPredictiveDebug(data);
                dispatch.predictiveDebug.updatePredictiveDebug({ text: responseData });
            }
            catch (e) {
                console.log('Catch deeplinks', e);
                dispatch.predictiveDebug.updateError({error: e.message})
            }
        }
    })
}