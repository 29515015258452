import styled from "styled-components";
import Table from "@material-ui/core/Table";
import {TableCell} from "@mui/material";
import React from "react";

export const StyledTable = styled(Table)`
    && {
        border: 1px solid #c0c0c063;
        border-collapse: collapse;
        &> tr, td {
            border: 1px solid #c0c0c063;
            border-collapse: collapse;
        }
        &> tr, th {
            border: 1px solid #c0c0c063;
            border-collapse: collapse;
        }
    }
`;

export const Cell = props => <TableCell size="small" {...props} />;