import React, { useState, memo } from "react";
import {useSelector} from "react-redux";
import {intersection, reduce} from 'lodash';
import Button from "@mui/material/Button";
import Popover from "@mui/material/Popover";
import IndeterminateCheckbox from "./IndeterminateCheckbox";


const SubscriptionTypes = ({ onChange }) => {
    const {periods = {}} = useSelector(state => state.recurringRevenue);
    const [selectedProducts, setSelectedProducts] = useState([]);

    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        onChange(selectedProducts);
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;



    const menu = reduce(periods, (acc, {products, title}) => ([
        ...acc,
        <IndeterminateCheckbox
            key={title}
            title={title}
            products={products}
            selectedProducts={intersection(selectedProducts, products)}
            setSelectedProducts={setSelectedProducts}
        />
    ]), []);


    return (
        <>
            <Button aria-describedby={id} variant="contained" onClick={handleClick}>
                Sub.Type
            </Button>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                {menu}
            </Popover>
        </>
    );
};

export default memo(SubscriptionTypes);