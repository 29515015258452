import ApiService from "../services/api";
import {checkResponseError} from './helpers'
import {findIndex, set} from 'lodash'


export default {
    state: {
        pending: false,
        error: null,
        errorCode: null,

        data: null,
        active:null,
    },
    reducers: {
        authStart(state) {
            return {
                ...state,
                pending: true
            }
        },
        updateError(state, data) {
            return {
                ...state,
                ...data,
                pending: false
            }
        },
        updateProject(state, data){
            return {
                ...state,
                ...data,
                pending: false
            }
        }
    },

    effects: (dispatch) => ({
        async getCountriesList(data){
            try{

                dispatch.project.authStart();

                const responseData = await ApiService.getCountriesList(data);

                checkResponseError(dispatch.projects, responseData);

                if (responseData && responseData?.countries) {
                    responseData.countries.unshift({
                        code: "*",
                        flag: null,
                        name: "All Countries",
                    });
                }

                dispatch.project.updateProject({data: responseData});

                return responseData;
            }
            catch (e) {
                console.log('Catch projects', e);
                dispatch.project.updateProject({error: e.message})
            }
        },

        async postProject(data, rootState) {
            dispatch.project.authStart();
            const responseData = await ApiService.postProject(data);
            checkResponseError(dispatch.projects, responseData);

            const active = {
                ...rootState.project.active,
                options: {
                    [data.option]: data.value
                }
            };

            dispatch.project.updateProject({
                active
            });

            const index = findIndex(rootState.projects.projects, {'id': active.id});

            if (index !== -1) {
                dispatch.projects.updateProjects({
                    projects: set(rootState.projects.projects, `${index}`, active)
                });
            }
        }
    })
};