import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {SignInComponent} from "../views/start";
import { Route, Switch, withRouter,} from 'react-router-dom';
import Alert from "@material-ui/lab/Alert";
import {connect, useDispatch, useSelector} from "react-redux";
import {Box, Snackbar, Container} from "@material-ui/core";
import {PrivateRoute, NavBar, FilterForm} from "../components";
import Countries from "../views/settings/Countries";
import {Report} from "../views/abtests";
import {Report as DeeplinkReport} from "../views/deeplink";
import {NewReport} from "../views/daily";
import GrowthReport from "../views/product/GrowthReport";
import Home from "../views/home";
import HomePage from "../views/main/HomePage";
import ScrollTable from "../views/daily/ScrollTable/ScrollTable";
import Create from "../views/abtests/Create";
import {VersionsList} from "../views/versions";
import Renewals from "../views/product/Renewals";
import {DetailsRenewals} from "../views/report/DetailsRenewals";
import {ReportRenewals} from "./../views/report"
import Cancelled from "../views/cancelled/Cancelled";
import {LEFT_MENU_WIDTH} from "../constants/Constants";
import TrialReport from "../views/calculator/TrialReport";
import WeeklyPage from "../pages/WeeklyPage/WeeklyPage";
import MonthlyPage from "../pages/MonthlyPage/MonthlyPage";
import RecurringRevenuePage from "../pages/RecurringRevenuePage/RecurringRevenuePage";
import CalendarDashPage from "../pages/CalendarDashPage/CalendarDashPage";
import CohortsDashPage from "../pages/CohortsDashPage/CohortsDashPage";
import SubscriptionsAnalysisPage from "../pages/SubscriptionsAnalysisPage/SubscriptionsAnalysisPage";


const drawerWidth = LEFT_MENU_WIDTH;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    grow: {
        flexGrow: 1,
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        '&::-webkit-scrollbar': { /* Hide scrollbar for Chrome, Safari and Opera */
            display: 'none',
            width: 1,
        },
        msOverflowStyle: 'none',  /* IE and Edge */
        scrollbarWidth: 'none',  /* Firefox */
    },
    hideScrollBar:{
        '&::-webkit-scrollbar': { /* Hide scrollbar for Chrome, Safari and Opera */
            display: 'none',
            width: 1,
        },
        msOverflowStyle: 'none',  /* IE and Edge */
        scrollbarWidth: 'none',  /* Firefox */
        overflowY: 'scroll',
        margin: `0 ${theme.spacing(1)}px`,
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9) + 1,
        },
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        zIndex: 1,
        backgroundColor: '#fff',
        position: 'sticky',
        top: 0,
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        // padding: theme.spacing(10, 2, 2),
        height:`100vh`,
        overflowY:'scroll',
    },
    container:{
        margin: theme.spacing(10, 'auto', 2),

    }
}));


export const tabs = [
    'home',
    'home2',
    'settings',
    'abtests',
    'deeplink',
    'products',
    'daily',
    'weekly',
    'monthly',
    'versions',
    'renewals',
    'renewalReport',
    'detailrenewals',
    'cancelled',
    'conversions',
    'recurring_revenue',
    'calendar_dash',
    'cohorts_dash',
    'subscriptions_analysis'
];


const DashboardLayout = (props) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const [openError] = useState(props.profile.error !== null);

    const { active } = useSelector(state => state.project);



    const handleClose = () => {

        if (props.profile.error) {
            props.profileUpdateError({error: null, errorCode: null});
        } else if (props.calculator.error) {
            props.calculatorUpdateError({error: null, errorCode: null});
        } else if (props.projects.error) {
            props.projectsUpdateError({error: null, errorCode: null});
        } else if (props.products.error) {
            props.productsUpdateError({error: null, errorCode: null});
        } else if (props.abTestsReport.error) {
            props.abTestsReportUpdateError({error: null, errorCode: null});
        }else if (props.abTestsShort.error) {
            props.abTestsShortUpdateError({error: null, errorCode: null});
        }else if (props.projectTests.error) {
            props.projectTestsUpdateError({error: null, errorCode: null});
        }else if (props.settings.error) {
            props.settingsUpdateError({error: null, errorCode: null});
        }else if (props.reports.error) {
            props.reportsUpdateError({error: null, errorCode: null});
        }else if (props.createAbTest.error) {
            props.updateCreateAbTest({error: null, errorCode: null});
        }else if (props.versions.error) {
            props.updateVersions({error: null, errorCode: null});
        }else if (props.versionLog.error) {
            props.updateVersionLog({error: null, errorCode: null});
        }else if (props.renewals.error) {
            props.updateRenewals({error: null, errorCode: null});
        }else if (props.renewalcaluator.error) {
            props.updateRenewalsCalculator({error: null, errorCode: null});
        }else if (props.calculatorhome.error) {
            props.homeUpdateCalculator({error: null, errorCode: null});
        }else if (props.calculatorpredictive.error) {
            props.calcPredictiveUpdate({error: null, errorCode: null});
        }
    };

    const errorText = props.profile.error || props.calculator.error || props.projects.error ||
        props.products.error || props.abTestsReport.error || props.abTestsShort.error ||
        props.projectTests.error || props.settings.error || props.reports.error || props.createAbTest.error ||
        props.versions.error || props.versionLog.error || props.renewals.error ||
        props.renewalcaluator.error || props.calculatorhome.error || props.calculatorpredictive.error ||
        null;

    useEffect(() => {
        if (parseInt(active?.id) > 0) {
            dispatch({
                type: 'calculator/getCalculator',
                payload: {
                    projectId: active?.id
                }
            });
        }
    }, [active, dispatch]);

    return (
        <Box display={'flex'}>

            <NavBar {...props} />

            <Snackbar open={openError || errorText !== null} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="error">
                    {errorText}
                </Alert>
            </Snackbar>

            <main className={classes.content}>
                <div className={classes.appBarSpacer}/>

                <Container maxWidth={false} className={classes.container} id={'ContainerId'}>
                    <Switch>
                        <PrivateRoute path={`${props.match.path}/settings/`} >
                            <Countries />
                        </PrivateRoute>

                        <PrivateRoute path={`${props.match.path}/abtests/create`} >
                            <Create />
                        </PrivateRoute>

                        <PrivateRoute path={`${props.match.path}/abtests`} >
                            <Report />
                        </PrivateRoute>

                        <PrivateRoute path={`${props.match.path}/deeplink`} >
                            <DeeplinkReport />
                        </PrivateRoute>

                        <PrivateRoute path={`${props.match.path}/products`} >
                            <GrowthReport />
                        </PrivateRoute>

                        <PrivateRoute path={`${props.match.path}/home2`} >
                            <Home />
                        </PrivateRoute>

                        <PrivateRoute path={`${props.match.path}/home`} >
                            <FilterForm />
                        </PrivateRoute>

                        {/*<PrivateRoute path={`${props.match.path}/home3`} >*/}
                        {/*    */}
                        {/*</PrivateRoute>*/}

                        <PrivateRoute path={`${props.match.path}/daily/new`} >
                            <ScrollTable />
                        </PrivateRoute>

                        <PrivateRoute path={`${props.match.path}/daily`} >
                            <NewReport />
                        </PrivateRoute>

                        <PrivateRoute path={`${props.match.path}/weekly`} >
                            <WeeklyPage />
                        </PrivateRoute>

                        <PrivateRoute path={`${props.match.path}/monthly`} >
                            <MonthlyPage />
                        </PrivateRoute>

                        <PrivateRoute path={`${props.match.path}/calendar_dash`} >
                            <CalendarDashPage />
                        </PrivateRoute>
                        <PrivateRoute path={`${props.match.path}/cohorts_dash`} >
                            <CohortsDashPage />
                        </PrivateRoute>
                        <PrivateRoute path={`${props.match.path}/subscriptions_analysis`} >
                            <SubscriptionsAnalysisPage />
                        </PrivateRoute>

                        <PrivateRoute path={`${props.match.path}/recurring_revenue`} >
                            <RecurringRevenuePage />
                        </PrivateRoute>
                        {/*<PrivateRoute exact path={`${props.match.path}/weekly`}>*/}
                        {/*    <WeeklyPage />*/}
                        {/*</PrivateRoute>*/}

                        <PrivateRoute path={`${props.match.path}/versions`} >
                            <VersionsList />
                        </PrivateRoute>

                        <PrivateRoute path={`${props.match.path}/renewalReport`} >
                            <ReportRenewals />
                        </PrivateRoute>

                        <PrivateRoute path={`${props.match.path}/renewals`} >
                            <Renewals />
                        </PrivateRoute>

                        <PrivateRoute exact path={`${props.match.path}/detailrenewals`} >
                            <DetailsRenewals />
                        </PrivateRoute>

                        <PrivateRoute exact path={`${props.match.path}/cancelled`} >
                            <Cancelled />
                        </PrivateRoute>

                        <PrivateRoute exact path={`${props.match.path}/conversions`}>
                            <TrialReport />
                        </PrivateRoute>



                        <PrivateRoute exact path={`${props.match.path}/`} >
                            <HomePage />
                        </PrivateRoute>



                        <Route path={`/start/login/`} component={SignInComponent}/>

                        <Route path={'*'}>
                            <Box m={'auto'}>Page Not found 2</Box>
                        </Route>

                    </Switch>
                </Container>
            </main>
        </Box>
    );

};

const mapState = state => ({
    profile:       state.profile,
    calculator:    state.calculator,
    projects:      state.projects,
    project:       state.project,
    products:      state.products,
    abTestsReport: state.abTestsReport,
    abTestsShort:  state.abTestsShort,
    projectTests:  state.projectTests,
    settings:      state.settings,
    reports:       state.reports,
    createAbTest:  state.createAbTest,
    versions:      state.versions,
    versionLog:    state.versionLog,
    renewals:      state.renewals,
    calculatorhome:state.calculatorhome,

    renewalcaluator:     state.renewalcaluator,
    calculatorpredictive:state.calculatorpredictive,
});

const mapDispatch = ( {
                         profile,
                         calculator,
                         projects,
                         products,
                         abTestsReport,
                         abTestsShort,
                         projectTests,
                         settings,
                         reports,
                         createAbTest,
                         versions,
                         versionLog,
                         renewals,
                         renewalcaluator,
                         calculatorpredictive,
                         calculatorhome,

                     }) => ({
    profileUpdateError:       (...data) => profile.updateError(...data),
    calculatorUpdateError:    (...data) => calculator.updateError(...data),
    projectsUpdateError:      (...data) => projects.updateError(...data),
    productsUpdateError:      (...data) => products.updateProducts(...data),
    abTestsReportUpdateError: (...data) => abTestsReport.updateAbTests(...data),
    abTestsShortUpdateError:  (...data) => abTestsShort.updateAbTestsShort(...data),
    projectTestsUpdateError:  (...data) => projectTests.updateAbTests(...data),
    settingsUpdateError:      (...data) => settings.updateSettings(...data),
    reportsUpdateError:       (...data) => reports.updateReports(...data),
    updateCreateAbTest:       (...data) => createAbTest.updateCreateAbTest(...data),
    updateVersions:           (...data) => versions.updateVersions(...data),
    updateVersionLog:         (...data) => versionLog.updateVersionLog(...data),
    updateRenewals:           (...data) => renewals.updateRenewals(...data),
    updateRenewalsCalculator: (...data) => renewalcaluator.updateRenewals(...data),
    homeUpdateCalculator:     (...data) => calculatorhome.updateCalculator(...data),
    calcPredictiveUpdate:     (...data) => calculatorpredictive.update(...data),
});

export default withRouter(connect(mapState, mapDispatch)(DashboardLayout));