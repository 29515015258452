import {reduce, get, compact, uniq, filter, isArray, isEmpty} from 'lodash'
import {CellFormatRender} from "../../../views/home2/utils";
import camelcase from "camelcase";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import React from "react";
import {
    getRetentionEvents,
    getRetentionEventRows,
    getRetentionChildrenRows,
    getCreatedEvents,
    getCreatedEventRows,
    getCreatedChildrenRows,
    getViewedEvents,
    getViewedEventRows,
    getViewedChildrenRows,
    getAccViewedEvents, getAccCreatedEvents
} from "./utils";
import Box from "@mui/material/Box";

export const rowsMapper = ({retentionEventRows = []}) => [
    {
        name: 'LTV Факт ($)',
        key: 'ltv',
        renderValue: CellFormatRender.LTV
    },
    {
        name: 'CAC Факт ($)',
        key: 'cac',
        renderValue: CellFormatRender.CAC
    },
    {
        name: 'Revenue Факт ($)',
        key: 'revenue',
        renderValue: CellFormatRender.money
    },
    {
        name: 'Конв. в подписку (%)',
        key: 'conversions.toUniquePayed',
        renderValue: CellFormatRender.percent
    },
    {
        name: 'Конв. в Ин-апп (%)',
        key: 'conversions.toPurchase',
        renderValue: CellFormatRender.percent
    },
    {
        name: 'Конв. в уникальн. платящего (%)',
        key: 'conversions.toUniquePayed',
        renderValue: CellFormatRender.percent
    },
    {
        name: 'Pred. ROMI 360',
        key: 'predictive.romi360',
        renderValue: CellFormatRender.percent
    },
    {
        name: 'Pred. ROMI 720+',
        key: 'predictive.romi720+',
        renderValue: CellFormatRender.percent
    },
    {
        name: 'Pred. LTV 360',
        key: 'predictive.ltv360',
        renderValue: CellFormatRender.money
    },
    {
        name: 'Pred. LTV 720+',
        key: 'predictive.ltv720+',
        renderValue: CellFormatRender.money
    },

    {
        name: 'Pred. revenue 360',
        key: 'predictive.revenue360',
        renderValue: CellFormatRender.money
    },
    {
        name: 'Pred. revenue 720+',
        key: 'predictive.revenue720+',
        renderValue: CellFormatRender.money
    },

    {
        name: 'Продукт:',
        key: null,
        style: {
            fontWeight: 600,
            fontSize: '14px',
        },
        rowStyle: {
            background: '#00ff02'
        }
    },
    {
        name: 'Retention в целевое',
        key: 'events.retention.value',
        renderValue: CellFormatRender.percent,
        children: 'retentionChildren',
    },
    {
        name: 'Кол-во единиц созданного контента (общий / на платящего юзера)',
        key: ['events.created.current.count', 'events.created.current.index'],
        title: 'events.created.current.users',
        children: 'eventsCreatedList',
    },

    {
        name: <>&#8627;️ Накопительное ☝️</>,
        key: ['events.created.overall.count', 'events.created.overall.index'],
        title: 'events.created.overall.users',
        children: 'eventsAccCreatedList',
    },
    {
        name: 'Кол-во единиц прочит/просмотр. контента (общий / на юзера)',
        key: ['events.viewed.current.count', 'events.viewed.current.index'],
        title: 'events.viewed.current.users',
        children: 'eventsViewedList',
    },
    {
        name: <>&#8627;️ Накопительное ☝️</>,
        key: ['events.viewed.overall.count', 'events.viewed.overall.index'],
        title: 'events.viewed.overall.users',
        children: 'eventsAccViewedList',
    },
    ...[
        {
            name: 'Подписки',
            key: null,
            style: {
                fontWeight: 600,
                fontSize: '14px',
            },
            rowStyle: {
                background: '#01ebeb'
            }
        },
        {
            name: <>Total</>,
            key: 'subscriptions.total',
            rowStyle: {
                background: '#01ffff'
            }
        },
        {
            name: <>Active</>,
            key: 'subscriptions.active',
            rowStyle: {
                background: '#01ffff'
            },
            renderValue: (v) => v
        },
        {
            name: <>Renewable Afo</>,
            key: 'subscriptions.renewableAfo',
            rowStyle: {
                background: '#01ffff'
            },
            renderValue: (v) => v
        },
        {
            name: <>Canceled Afo</>,
            key: 'subscriptions.canceledAfo',
            rowStyle: {
                background: '#01ffff'
            },
            renderValue: (v) => v
        },
        {
            name: <>Refunded</>,
            key: 'subscriptions.refunded',
            rowStyle: {
                background: '#01ffff'
            },
            renderValue: (v) => v
        },
        {
            name: <>Renewable Ass</>,
            key: 'subscriptions.renewableAss',
            rowStyle: {
                background: '#01ffff'
            },
            renderValue: (v) => v
        },
        {
            name: <>Canceled Ass</>,
            key: 'subscriptions.canceledAss',
            rowStyle: {
                background: '#01ffff'
            },
            renderValue: (v) => v
        },

        {
            name: <>payed</>,
            key: 'subscriptions.payed',
            rowStyle: {
                background: '#01ffff'
            },
            renderValue: (v) => v
        },

        {
            name: 'Подписки годовые',
            key: null,
            // children: 'subscriptions.year',
            style: {
                fontWeight: 600,
                fontSize: '14px',
            },
            rowStyle: {
                background: '#d0bac3'
            }
        },
        {
            name: <>Total</>,
            key: 'subscriptions.year.total',
            rowStyle: {
                background: '#ead1dc'
            },
            renderValue: (v) => v
        },
        {
            name: <>Active</>,
            key: 'subscriptions.year.active',
            rowStyle: {
                background: '#ead1dc'
            },
            renderValue: (v) => v
        },
        {
            name: <>Renewable Afo</>,
            key: 'subscriptions.year.renewableAfo',
            rowStyle: {
                background: '#ead1dc'
            },
            renderValue: (v) => v
        },
        {
            name: <>Canceled Afo</>,
            key: 'subscriptions.year.canceledAfo',
            rowStyle: {
                background: '#ead1dc'
            },
            renderValue: (v) => v
        },
        {
            name: <>Refunded</>,
            key: 'subscriptions.year.refunded',
            rowStyle: {
                background: '#ead1dc'
            },
            renderValue: (v) => v
        },
        {
            name: <>Renewable Ass</>,
            key: 'subscriptions.year.renewableAss',
            rowStyle: {
                background: '#ead1dc'
            },
            renderValue: (v) => v
        },
        {
            name: <>Canceled Ass</>,
            key: 'subscriptions.year.canceledAss',
            rowStyle: {
                background: '#ead1dc'
            },
            renderValue: (v) => v
        },
    ],

    {
        // Годовые (Product IDs)
        name: '',
        key: null,
        children: 'subscriptions.year',
        style: {
            fontWeight: 600,
            fontSize: '14px',
        },
        rowStyle: {
            background: '#ead1dc'
        }
    },

    {
        name: 'Подписки месячные',
        key: null,
        style: {
            fontWeight: 600,
            fontSize: '14px',
        },
        rowStyle: {
            background: '#acbabc'
        }
    },
    {
        name: <>Total</>,
        key: 'subscriptions.month.total',
        rowStyle: {
            background: '#d0e0e3'
        },
        renderValue: (v) => v
    },
    {
        name: <>Active</>,
        key: 'subscriptions.month.active',
        rowStyle: {
            background: '#d0e0e3'
        },
        renderValue: (v) => v
    },
    {
        name: <>Renewable Afo</>,
        key: 'subscriptions.month.renewableAfo',
        rowStyle: {
            background: '#d0e0e3'
        },
        renderValue: (v) => v
    },
    {
        name: <>Canceled Afo</>,
        key: 'subscriptions.month.canceledAfo',
        rowStyle: {
            background: '#d0e0e3'
        },
        renderValue: (v) => v
    },
    {
        name: <>Refunded</>,
        key: 'subscriptions.month.refunded',
        rowStyle: {
            background: '#d0e0e3'
        },
        renderValue: (v) => v
    },
    {
        name: <>Renewable Ass</>,
        key: 'subscriptions.month.renewableAss',
        rowStyle: {
            background: '#d0e0e3'
        },
        renderValue: (v) => v
    },
    {
        name: <>Canceled Ass</>,
        key: 'subscriptions.month.canceledAss',
        rowStyle: {
            background: '#d0e0e3'
        },
        renderValue: (v) => v
    },



    {
        // Подписки месячные IDs
        name: '',
        key: null,
        children: 'subscriptions.month',
        style: {
            fontWeight: 600,
            fontSize: '13px',
        },
        rowStyle: {
            background: '#aab8ba'
        }
    },
    {
        // Подписки 3-ые месячные IDs
        name: '',
        key: null,
        children: 'subscriptions.3months',
        style: {
            fontWeight: 600,
            fontSize: '13px',
        },
        rowStyle: {
            background: '#adbbbd'
        }
    },
    {
        // Подписки 6-ые месячные IDs
        name: '',
        key: null,
        children: 'subscriptions.6months',
        style: {
            fontWeight: 600,
            fontSize: '13px',
        },
        rowStyle: {
            background: '#b5c2c5'
        }
    },


    {
        name: 'Подписки недельные',
        key: null,
        style: {
            fontWeight: 600,
            fontSize: '13px',
        },
        rowStyle: {
            background: '#a8b7c5'
        }
    },
    {
        name: <>Total</>,
        key: 'subscriptions.week.total',
        rowStyle: {
            background: '#cfe2f3'
        },
        renderValue: (v) => v
    },
    {
        name: <>Active</>,
        key: 'subscriptions.week.active',
        rowStyle: {
            background: '#cfe2f3'
        },
        renderValue: (v) => v
    },
    {
        name: <>Renewable Afo</>,
        key: 'subscriptions.week.renewableAfo',
        rowStyle: {
            background: '#cfe2f3'
        },
        renderValue: (v) => v
    },
    {
        name: <>Canceled Afo</>,
        key: 'subscriptions.week.canceledAfo',
        rowStyle: {
            background: '#cfe2f3'
        },
        renderValue: (v) => v
    },
    {
        name: <>Refunded</>,
        key: 'subscriptions.week.refunded',
        rowStyle: {
            background: '#cfe2f3'
        },
        renderValue: (v) => v
    },
    {
        name: <>Renewable Ass</>,
        key: 'subscriptions.week.renewableAss',
        rowStyle: {
            background: '#cfe2f3'
        },
        renderValue: (v) => v
    },
    {
        name: <>Canceled Ass</>,
        key: 'subscriptions.week.canceledAss',
        rowStyle: {
            background: '#cfe2f3'
        },
        renderValue: (v) => v
    },
    // {
    //     name: <>payed</>,
    //     key: 'subscriptions.week.payed',
    //     rowStyle: {
    //         background: '#cfe2f3'
    //     }
    // },

    {
        // Недельные (Product IDs)
        name: '',
        key: null,
        children: 'subscriptions.week',
        style: {
            fontWeight: 600,
            fontSize: '13px',
        },
        rowStyle: {
            background: '#a4b3c0'
        }
    },

    {
        name: 'In-App',
        key: null,
        style: {
            fontWeight: 600,
            fontSize: '14px',
        },
        rowStyle: {
            background: '#d7ccac'
        }
    },
    {
        name: 'total',
        key: 'purchases.total',
        rowStyle: {
            background: '#fff2cc'
        },
        renderValue: (v) => v
    },
    {
        name: 'payed',
        key: 'purchases.payed',
        rowStyle: {
            background: '#fff2cc'
        },
        renderValue: (v) => v
    },
    {
        name: 'refunded',
        key: 'purchases.refunded',
        rowStyle: {
            background: '#fff2cc'
        },
        renderValue: (v) => v
    },
    {
        // In-App by Ids
        name: '',
        key: null,
        children: 'purchases',
        style: {
            fontWeight: 600,
            fontSize: '13px',
        },
        rowStyle: {
            background: '#dbd0af'
        }
    }
];


const subscriptionPeriodStyles = {
    week: {
        rowStyle: {
            background: '#cfe2f3'
        }
    },
    month: {
        rowStyle: {
            background: '#d0e0e3'
        }
    },
    '3months': {
        rowStyle: {
            background: '#d0e0e3'
        }
    },
    '6months': {
        rowStyle: {
            background: '#d0e0e3'
        }
    },
    year: {
        rowStyle: {
            background: '#ead1dc'
        }
    }
};

/**
 *
 * @param {Array<Object>} weeklyData
 * @param {Object} weeksObject
 * @param {Array<String>} columnKeys
 */
const getSubscriptionsProductInfo = ({weeklyData, weeksObject, columnKeys}) => {
    const subscriptionsFields = ['total', 'active', 'renewableAfo',  'canceledAfo', 'refunded', 'renewableAss',  'canceledAss',];
    const subscriptionsPeriods = ['week', 'month', '3months', '6months', 'year', 'unknown'];

    const subscriptionProductsByPeriod = subscriptionsPeriods.reduce((acc, item) => ({
        ...acc,
        [item]: uniq([
            ...get(acc, item, []),
            ...weeklyData.reduce((accWeek, {subscriptions}) => ([
                ...accWeek,
                ...Object.keys(get(subscriptions, `${item}.products`, {}))
            ]), [])
        ])
    }), {});


    const subscriptionsRows = period =>
        subscriptionProductsByPeriod
            ?.[period]
            ?.reduce((acc, productId) => ([
                ...acc,
                {
                    // ...get(subscriptionPeriodStyles, period, {}),
                    rowName: productId,
                    rowKey: productId,
                },
                ...subscriptionsFields.reduce((acc, rowName) => ([
                    ...acc,
                    {
                        ...get(subscriptionPeriodStyles, period, {}),
                        rowName: rowName,
                        rowKey: rowName,
                        ...columnKeys.reduce((acc, colKey) => {
                            const value = [get(weeksObject, compact([colKey, `subscriptions.${period}.products`, camelcase(productId), rowName]).join('.'), null)];
                            const total = get(weeksObject, compact([colKey, `subscriptions.${period}.products`, camelcase(productId), 'total']).join('.'), null)
                            if (rowName !== 'total' && rowName !== 'payed') {
                                value.push(
                                    <Box component='span' sx={{ fontSize: '10px'}}>&nbsp;({Math.ceil(value/total * 10000)/100}%)</Box>
                                )
                            }

                            return {
                                ...acc,
                                [colKey]: {
                                    value: value.map(item => item)
                                }
                            }
                        }, {}),
                    }

                ]), [])


            ]), []);

    return subscriptionsPeriods.reduce((acc, period) => ({
        ...acc,
        ...(subscriptionProductsByPeriod?.[period].length > 0 && {
            [`subscriptions.${period}`]: subscriptionsRows(period)
        })
    }), {})
};

const listData = (data) => {
    if (isEmpty(data)) return null;

    const {users} = Object.values(data)?.[0];

    return (
        <Table size="small">
            <TableHead>
                <TableCell padding='none' sx={{px: "4px", py: "1px"}}>Event</TableCell>
                <TableCell padding='none' sx={{px: "4px", py: "1px"}}>Count</TableCell>
                {users && <TableCell padding='none' sx={{px: "4px", py: "1px"}}>Users</TableCell>}
            </TableHead>
            <TableBody>
                {reduce(data, (acc, value, key) => ([
                    ...acc,
                    <TableRow key={key}>
                        <TableCell padding='none' sx={{px: "4px", py: "1px"}}>{key}</TableCell>
                        <TableCell padding='none' sx={{px: "4px", py: "1px"}}>{value?.count || value}</TableCell>
                        {users && <TableCell padding='none' sx={{px: "4px", py: "1px"}}>{value?.users}</TableCell>}
                    </TableRow>
                ]), [])}
            </TableBody>
        </Table>
    )
};

/**
 *
 * @param {Array.<Object>} weeklyData
 * @param {Array.<{type, productId}>} products
 * @returns {*[]}
 */
const prepareRows = ({weeklyData: oldWeeklyData, products}) => {
    const weeklyData =
        oldWeeklyData.reduce((acc, item) => ([
            ...acc,
            {
                ...item,
                subscriptions: {
                    ...item.subscriptions,
                    active: <>{item.subscriptions.active}&nbsp;<Box component='span' sx={{ fontSize: '10px'}}>({Math.ceil(item.subscriptions.active / item.subscriptions.total * 10000) / 100}%)</Box></>,
                    refunded: <>{item.subscriptions.refunded}&nbsp;<Box component='span' sx={{ fontSize: '10px'}}>({Math.ceil(item.subscriptions.refunded / item.subscriptions.total * 10000) / 100}%)</Box></>,

                    canceledAfo: <>{item.subscriptions.canceledAfo}&nbsp;<Box component='span' sx={{ fontSize: '10px'}}>({Math.ceil(item.subscriptions.canceledAfo / item.subscriptions.total * 10000) / 100}%)</Box></>,
                    renewableAfo: <>{item.subscriptions.renewableAfo}&nbsp;<Box component='span' sx={{ fontSize: '10px'}}>({Math.ceil(item.subscriptions.renewableAfo / item.subscriptions.total * 10000) / 100}%)</Box></>,

                    canceledAss: <>{item.subscriptions.canceledAss}&nbsp;<Box component='span' sx={{ fontSize: '10px'}}>({Math.ceil(item.subscriptions.canceledAss / item.subscriptions.total * 10000) / 100}%)</Box></>,
                    renewableAss: <>{item.subscriptions.renewableAss}&nbsp;<Box component='span' sx={{ fontSize: '10px'}}>({Math.ceil(item.subscriptions.renewableAss / item.subscriptions.total * 10000) / 100}%)</Box></>,

                    total: item.subscriptions.total,
                    ...reduce(['year', 'week', 'month', '3Months', '6Months'], (acc, period) => ({
                        ...acc,
                        [period]: {
                            ...item.subscriptions[period],
                            active: <>{item.subscriptions[period].active}&nbsp;<Box component='span' sx={{ fontSize: '10px'}}>({Math.ceil(item.subscriptions[period].active / item.subscriptions[period].total * 10000) / 100}%)</Box></>,
                            refunded: <>{item.subscriptions[period].refunded}&nbsp;<Box component='span' sx={{ fontSize: '10px'}}>({Math.ceil(item.subscriptions[period].refunded / item.subscriptions[period].total * 10000) / 100}%)</Box></>,

                            canceledAfo: <>{item.subscriptions[period].canceledAfo}&nbsp;<Box component='span' sx={{ fontSize: '10px'}}>({Math.ceil(item.subscriptions[period].canceledAfo / item.subscriptions[period].total * 10000) / 100}%)</Box></>,
                            renewableAfo: <>{item.subscriptions[period].renewableAfo}&nbsp;<Box component='span' sx={{ fontSize: '10px'}}>({Math.ceil(item.subscriptions[period].renewableAfo / item.subscriptions[period].total * 10000) / 100}%)</Box></>,

                            canceledAss: <>{item.subscriptions[period].canceledAss}&nbsp;<Box component='span' sx={{ fontSize: '10px'}}>({Math.ceil(item.subscriptions[period].canceledAss / item.subscriptions[period].total * 10000) / 100}%)</Box></>,
                            renewableAss: <>{item.subscriptions[period].renewableAss}&nbsp;<Box component='span' sx={{ fontSize: '10px'}}>({Math.ceil(item.subscriptions[period].renewableAss / item.subscriptions[period].total * 10000) / 100}%)</Box></>,
                        },
                    }), {})
                },
                purchases: {
                    ...item.purchases,
                    payed: <>{item.purchases.payed}&nbsp;<Box component='span' sx={{ fontSize: '10px'}}>({Math.ceil(item.purchases.payed / item.purchases.total * 10000) / 100}%)</Box></>,
                    refunded: <>{item.purchases.refunded}&nbsp;<Box component='span' sx={{ fontSize: '10px'}}>({Math.ceil(item.purchases.refunded / item.purchases.total * 10000) / 100}%)</Box></>
                }
            }

        ]), []);

    const weeksObject = weeklyData.reduce((acc, weekData, index) => ({
        ...acc,
        [`week${index + 1}`]: weekData
    }), {});
    // ключи от колонок
    const columnKeys = Object.keys(weeksObject);


    const retentionEvents = getRetentionEvents({weeklyData});
    const retentionEventRows = getRetentionEventRows({events: retentionEvents});
    const retentionEventChildrenRows = getRetentionChildrenRows({weeksObject, rows: retentionEventRows, columnKeys});

    const createdEvents = getCreatedEvents({weeklyData});
    const createdEventsRows = getCreatedEventRows({events: createdEvents});
    const eventsCreatedList = getCreatedChildrenRows({weeksObject, rows: createdEventsRows, columnKeys});

    const viewedEvents = getViewedEvents({weeklyData});
    const viewedEventsRows = getViewedEventRows({events: viewedEvents});
    const eventsViewedList = getViewedChildrenRows({weeksObject, rows: viewedEventsRows, columnKeys});

    const createdAccEvents = getAccCreatedEvents({weeklyData});
    const createdAccEventsRows = getCreatedEventRows({events: createdAccEvents, listKey: 'list+'});
    const eventsAccCreatedList = getCreatedChildrenRows({weeksObject, rows: createdAccEventsRows, columnKeys});

    const viewedAccEvents = getAccViewedEvents({weeklyData});
    const viewedAccEventsRows = getViewedEventRows({events: viewedAccEvents, listKey: 'list+'});
    const eventsAccViewedList = getViewedChildrenRows({weeksObject, rows: viewedAccEventsRows, columnKeys});


    const purchaseFields = ['total', 'refunded', 'payed'];
    const purchasesRows = filter(products, {type: 'purchases'}).reduce((acc, {productId}) => ([
        ...acc,
        {
            rowName: productId,
            rowKey: productId,
            rowStyle: {
                background: '#d9ceae'
            }
        },
        ...purchaseFields.reduce((acc, rowName) => ([
            ...acc,
            {
                rowStyle: {
                    background: '#d9ceae'
                },
                rowName: rowName,
                rowKey: rowName,
                ...columnKeys.reduce((acc, colKey) => {
                    const value = [get(weeksObject, compact([colKey, 'purchases.products', camelcase(productId), rowName]).join('.'), 0)];
                    const total = get(weeksObject, compact([colKey, 'purchases.products', camelcase(productId), 'total']).join('.'), 0);
                    if (colKey !== 'total')
                        value.push(
                            <Box component='span' sx={{ fontSize: '10px'}}>&nbsp;({Math.ceil(value/total * 10000)/100}%)</Box>
                        );
                    return {
                    ...acc,
                    [colKey]: {
                        value: value.map(v => v)
                    }
                }}, {}),
            }
        ]), [])
    ]), []);

    const childrens = {
        purchases: purchasesRows,
        ...getSubscriptionsProductInfo({weeklyData, weeksObject, columnKeys}),
        retentionChildren: retentionEventChildrenRows,
        eventsCreatedList,
        eventsViewedList,
        eventsAccCreatedList,
        eventsAccViewedList,
    };

    console.log('childrens', {createdAccEvents, childrens});

    return rowsMapper({retentionEventRows}).reduce((acc, {
        name,
        key,
        children = null,
        renderValue = CellFormatRender.count,
        style = {},
        rowStyle = {},
        title,
        popover = null
    }) => {
        return [
            ...acc,
            {
                style,
                rowStyle,
                rowName: name,
                rowKey: isArray(key) ? key.join('_') : key || children,
                // // expandeted data
                ...(children && {children: childrens?.[children]}),
                ...reduce(columnKeys, (acc, columnKey) => ({
                    ...acc,
                    [columnKey]: {
                        popover: popover
                            ? listData(
                                get(weeksObject, compact([columnKey, popover]).join('.'), null)
                            ) : null,
                        title: get(weeksObject, compact([columnKey, title]).join('.'), null),
                        value: isArray(key)
                            ? reduce(key, (acc, item) =>
                                    ([
                                        ...acc,
                                        renderValue(get(weeksObject, compact([columnKey, item]).join('.'), null))]),

                                []).join(' / ')
                            : renderValue(get(weeksObject, compact([columnKey, key]).join('.'), null))
                    }
                }), {})
            }]
    }, [])
};

export default prepareRows;