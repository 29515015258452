import React from "react";
import { versionCompare } from "./../../../../services/helpers";
import Box from "@mui/material/Box";
import {Popover, Stack, Typography } from "@mui/material";
import { reduce, take, orderBy } from 'lodash';

const VersionItems = ({ versionKeys, versions }) => {
    return reduce(versionKeys, (acc, version) => ([
        ...acc,
        <Box px={1} key={version} display="flex" justifyContent="space-between">
            <Box mx={0.25}>{version || 'unknown'}</Box>
            <Box mx={0.25}>({versions?.[version]})</Box>
        </Box>
    ]), [])
};


const AppVersions = ({ versions, period }) => {
    const versionKeys = orderBy(Object.keys(versions), versionCompare);
    const lastVersions = take(versionKeys, 2);
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? `version-${period}-popper` : undefined;

    return (
        <>
            <Typography variant="body1" onClick={handleClick} sx={{ cursor: 'pointer' }}>
                <VersionItems versionKeys={lastVersions} versions={versions} />
            </Typography>
            <Popover
                id={id} open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <Box sx={{ bgcolor: 'background.paper' }}>
                    <Stack spacing={.5} py={1} sx={{ '&>div:hover': {
                        backgroundColor: 'rgba(95,95,95,0.44)',
                            cursor: 'pointer'
                        }}}>
                        <VersionItems versionKeys={versionKeys} versions={versions} />
                    </Stack>
                </Box>
            </Popover>
        </>
    )

};

export default AppVersions;