import React, {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Select from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";
import {compact} from "lodash";
import MenuItem from "@mui/material/MenuItem";
import CountryFlag from "../../components/CountryFlag";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import {useDispatch, useSelector} from "react-redux";
import {useHistory, useWindowSize} from "../../hooks";
import {useQuery} from "../../services/helpers";
import moment from "../../core/moment";
import theme from "../../theme";
import Table, {Column} from "react-base-table";
import component from "../../views/calculator/TrialReportCompoment";
import columns from "./utils/columns";
import {AutoSizer} from "react-virtualized";
import BasicDateRangePicker from "../../components/BasicDateRangePicker";
import CircularProgress from "@mui/material/CircularProgress";


const TRIAL_TYPES = [
  {
    value: 'no_trial',
    // value: 'Без триала',
    name: 'Без триала'
  },
  {
    value: 'trial',
    // value: 'Триал',
    name: 'Триал'
  },
  {
    value: 'payed_trial',
    // value: 'Платный триал',
    name: 'Платный триал'
  },

];

const GROUP_BY = [
  {
    value: 'day',
    name: 'Day',
  },
  {
    value: 'week',
    name: 'Week',
  },
  {
    value: 'month',
    name: 'Month',
  },
  {
    value: 'line',
    name: 'Line',
  }
];

const TRIAL_DAYS = [
  {
    name: '3 Дня',
    value: '3days'
  },
  {
    name: '7 Дней',
    value: '7days'
  },
  {
    name: '1 Месяц',
    value: '1month'
  }
];

const SubscriptionsAnalysisPage = () => {
  const dispatch = useDispatch();
  const [, height] = useWindowSize();
  const query = useQuery();
  const history = useHistory();

  const [isOpenCountries, setIsOpenCountries] = useState(false);

  const {pending, data} = useSelector(state => state.subscriptionsAnalysis);
  const calculator = useSelector(state => state.calculator);
  const {data: calculatorData} = calculator;

  const projectId = useSelector(state => state.project?.active?.id);
  const { active } = useSelector(state => state.project);

  const [filter, setFilter] = useState({
    dateAfter: query.get('date_after') ? moment(query.get('date_after')).toDate() : null,
    dateBefore: query.get('date_before') ? moment(query.get('date_before')).toDate() : null,
    groupBy: query.get('group_by') || 'month',
    trialType: compact(query.get('trial_type[]')?.split(',') || []),
    trialDays: compact(query.get('trial_days[]')?.split(',') || []),
    country: compact(query.get('country[]')?.split(',') || [])
  });

  const handleChangeData = ({startDate, endDate}) => {
    setFilter(prev => ({
      ...prev,
      dateAfter: startDate,
      dateBefore: endDate,
    }));
  };

  const updateLocation = () => {
    history.push({
      search: '?' + compact([
        filter.groupBy && `groupBy=${filter.groupBy}`,
        filter.dateAfter && `date_after=${moment(filter.dateAfter)?.format('YYYY-MM-DD')}`,
        filter.dateBefore && `date_before=${moment(filter.dateBefore)?.format('YYYY-MM-DD')}`,
        filter.trialType && `trial_type[]=${filter.trialType?.join(',')}`,
        filter.trialDays && `trial_days[]=${filter.trialDays?.join(',')}`,
        filter.country && `country[]=${filter.country?.join(',')}`,
      ]).join('&')
    });
  };

  const handleChange = event => {
    const {
      target: {value},
    } = event;
    setFilter(prev => ({
      ...prev,
      country: typeof value === 'string' ? value.split(',') : value,
    }))
  };

  const handleChangeTrialType = event => {
    const {
      target: {value},
    } = event;
    setFilter(prev => ({
      ...prev,
      trialType: typeof value === 'string' ? value.split(',') : value,
    }))
  };

  const handleChangeTrialDays = event => {
    const {
      target: {value},
    } = event;
    setFilter(prev => ({
      ...prev,
      trialDays: typeof value === 'string' ? value.split(',') : value,
    }))
  };

  const handleChangeGroupBy = event => {
    const {
      target: {value},
    } = event;
    setFilter(prev => ({
      ...prev,
      groupBy: value,
    }))
  };

  useEffect(() => {
    if (filter.dateAfter && filter.dateBefore && projectId && !isOpenCountries) {
      const {country, trialType, trialDays, groupBy} = filter;
      dispatch({
        type: 'subscriptionsAnalysis/getData',
        payload: {
          projectId,
          dateAfter: moment(filter.dateAfter)?.format('YYYY-MM-DD'),
          dateBefore: moment(filter.dateBefore)?.format('YYYY-MM-DD'),
          ...(compact(country)?.length > 0 && {country: compact(country)}),
          ...(compact(trialType)?.length > 0 && {trialType: compact(trialType)}),
          ...(compact(trialDays)?.length > 0 && {trialDays: compact(trialDays)}),
          ...(groupBy && {groupBy})
        }
      });
    }
    // eslint-disable-next-line`
  }, [active, filter, projectId, isOpenCountries, dispatch]);

  // eslint-disable-next-line
  useEffect(updateLocation, [filter]);

  return (
    <Box>
      <Stack direction="row" component={Box} mb={2} spacing={1} alignItems="center">
        <Stack>
          <Typography>Period</Typography>
          <BasicDateRangePicker
            dateStart={filter.dateAfter}
            dateEnd={filter.dateBefore}
            onChange={handleChangeData}
          />
        </Stack>
        <Stack>
          <Typography>Trial Type</Typography>

          <Select
            displayEmpty
            multiple
            size="small"
            value={filter.trialType}
            input={<OutlinedInput size="small"/>}
            onChange={handleChangeTrialType}
            renderValue={(selected) =>
              compact(selected).length > 0
                ? `Selected ${compact(selected).length}`
                : 'Not selected'
            }
            onOpen={() => setIsOpenCountries(prev => !prev)}
            onClose={() => setIsOpenCountries(prev => !prev)}
          >
            {TRIAL_TYPES.map(({name, value}) =>
              <MenuItem key={value} value={value}>
                <Checkbox size="small" checked={filter.trialType?.indexOf(value) > -1}/>
                <ListItemText primary={name}/>
              </MenuItem>
            )}
          </Select>
        </Stack>

        <Stack>
          <Typography>Trial days</Typography>

          <Select
            displayEmpty
            multiple
            size="small"
            value={filter.trialDays}
            input={<OutlinedInput size="small"/>}
            onChange={handleChangeTrialDays}
            renderValue={(selected) =>
              compact(selected).length > 0
                ? `Selected ${compact(selected).length}`
                : 'Not selected'
            }
            onOpen={() => setIsOpenCountries(prev => !prev)}
            onClose={() => setIsOpenCountries(prev => !prev)}
          >
            {TRIAL_DAYS.map(({name, value}) =>
              <MenuItem key={value} value={value}>
                <Checkbox size="small" checked={filter.trialDays?.indexOf(value) > -1}/>
                <ListItemText primary={name}/>
              </MenuItem>
            )}
          </Select>
        </Stack>

        <Stack>
          <Typography>Group by</Typography>

          <Select
            displayEmpty
            size="small"
            value={filter.groupBy}
            input={<OutlinedInput size="small"/>}
            onChange={handleChangeGroupBy}
          >
            {GROUP_BY.map(({name, value}) =>
              <MenuItem key={value} value={value}>
                {name}
              </MenuItem>
            )}
          </Select>
        </Stack>

        <Stack>
          <Typography>Country</Typography>
          <Select
            displayEmpty
            size="small"
            multiple
            value={filter.country}
            onChange={handleChange}
            input={<OutlinedInput size="small"/>}
            renderValue={(selected) =>
              compact(selected).length > 0
                ? `Selected ${compact(selected).length}`
                : 'All'
            }
            onOpen={() => setIsOpenCountries(prev => !prev)}
            onClose={() => setIsOpenCountries(prev => !prev)}
          >
            {calculatorData
              ?.countries?.map(({value, name}) => (
                <MenuItem key={name} value={value}>
                  <CountryFlag countryCode={value} style={{fontSize: '2em', paddingRight: 8}}/>
                  <Checkbox checked={filter.country?.indexOf(value) > -1}/>
                  <ListItemText primary={name}/>
                </MenuItem>
              ))}
          </Select>
        </Stack>

        {pending && <Box pt={3}>Loading...</Box>}
      </Stack>

      <AutoSizer style={{padding: theme.spacing(3, 0)}}>
        {({width}) => (
          <Table fixed
                 style={{
                   position: 'absolute',
                 }}
                 ignoreFunctionInColumnCompare={true}
                 disabled={pending}
                 overlayRenderer={
                   pending && (
                     <Box
                       width='100%'
                       height='100%'
                       display='flex'
                       justifyContent='center'
                       alignItems='center'
                       sx={{
                         bgcolor: 'rgba(0,0,0,0.44)'
                       }}
                     >
                       <CircularProgress color="inherit"/>
                     </Box>
                   )
                 }
                 expandColumnKey='date'

                 width={width}
                 height={height - 200}
                 rowHeight={35}

                 data={data}
                 components={component}
          >
            {columns.map((column, index) => (
              <Column
                key={`${column.dataKey}-${index}`}
                {...column}
                filter={filter}
              />
            ))}
          </Table>
        )}
      </AutoSizer>

    </Box>
  );
};


export default SubscriptionsAnalysisPage;