import ApiService from "../services/api";
import {checkResponseError} from './helpers'


export default {
    state: {
        pending: false,
        error: null,
        errorCode: null,

        settings: null,
        data: null,
        order: 'alpha',  // alpha or users
        controller: null
    },
    reducers: {
        authStart(state, data) {
            return {
                ...state,
                ...data,
                pending: true
            }
        },
        updateError(state, data) {
            return {
                ...state,
                ...data,
                pending: false
            }
        },
        updateSettings(state, data){
            return {
                ...state,
                ...data,
                pending: false
            }
        }
    },

    effects: (dispatch) => ({

        // async countriesList(data){
        //     try{
        //         const responseData = await ApiService.countriesList(data);
        //
        //         checkResponseError(dispatch.settings, responseData);
        //
        //         dispatch.settings.updateProjects({settings: responseData?.settings});
        //
        //         return responseData;
        //     }
        //     catch (e) {
        //         console.log('Catch projects', e);
        //         dispatch.settings.updateSettings({error: e.message})
        //     }
        // },

        async getCountries(data, rootState){
            try{

                if (rootState.settings.controller !== null)
                    rootState.settings.controller.abort();

                const controller = new AbortController();
                dispatch.settings.authStart({ controller });

                const responseData = await ApiService.getCountries(data);

                checkResponseError(dispatch.settings, responseData);

                dispatch.settings.updateSettings({data: responseData, order: data.order || 'alpha', controller: null });

                return responseData;
            }
            catch (e) {
                console.log('Catch projects', e);
                dispatch.settings.updateSettings({error: e.message})
            }
        },

        async putCountry(data, state){
            try{
                dispatch.settings.authStart();

                const responseData = await ApiService.putCountry(data);

                checkResponseError(dispatch.settings, responseData);

                if (responseData?.success) {
                    const {countries, count} = state.settings.data;

                    for (let index in countries) {
                        if (countries[index].code === data?.country) {
                            countries[index].is_active = true;
                            count.checked++;
                            break; //Stop this loop, we found it!
                        }
                    }

                    dispatch.settings.updateSettings({
                        data:{
                            count,
                            countries,
                        }
                    });
                }

                return responseData;
            }
            catch (e) {
                console.log('Catch projects', e);
                dispatch.settings.updateSettings({error: e.message})
            }
        },

        async delCountry(data, state){
            try{
                dispatch.settings.authStart();

                const responseData = await ApiService.delCountry(data);

                checkResponseError(dispatch.settings, responseData);

                if (responseData?.success) {
                    const {countries, count} = state.settings.data;

                    for (let index in countries) {
                        if (countries[index].code === data?.country) {
                            countries[index].is_active = false;
                            count.checked--;
                            break; //Stop this loop, we found it!
                        }
                    }

                    dispatch.settings.updateSettings({
                        data:{
                            count,
                            countries,
                        }
                    });
                }

                return responseData;
            }
            catch (e) {
                console.log('Catch projects', e);
                dispatch.settings.updateSettings({error: e.message})
            }
        },
    })
};