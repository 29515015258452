import ApiService from "../services/api";
import {checkResponseError} from "./helpers";


export default {
    state: {
        pending: false,
        error: null,
        errorCode: null,

        data:null,
    },
    reducers: {
        authStart(state) {
            return {
                ...state,
                pending: true
            }
        },
        updateError(state, data) {
            return {
                ...state,
                ...data,
                pending: false
            }
        },
        updateConversions(state, data){
            return {
                ...state,
                ...data,
                pending: false
            }
        }
    },

    effects: (dispatch) => ({

        async getConversions(data){
            try{
                dispatch.conversions.authStart();

                const responseData = await ApiService.getConversions(data);

                checkResponseError(dispatch.createAbTest, responseData);

                const worker = new Worker('/WorkerData.js');

                worker.onmessage = (event) => {
                    dispatch.conversions.updateConversions({data: event.data});
                };

                worker.postMessage({
                    method: 'conversions',
                    data: responseData.conversions
                });

            }
            catch (e) {
                console.log('Catch Conversions', e);
                dispatch.conversions.updateConversions({error: e.message})
            }
        },
    })
};