import ApiService from "../services/api";
import {checkResponseError, round, saveDivide} from './helpers'
import {nanoid} from 'nanoid'
import ReportService from "../services/ReportService";
import {reduce, sortBy, uniqBy, isBoolean} from 'lodash';


export default {
  state: {
    pending: false,
    error: null,
    errorCode: null,

    data: null,
    data2() {
      return this.data;
    },

    summary: null,

    revertData: true,

    uniquePayments: [],
    uniqueSubscriptions: [],
    controller: null
  },
  reducers: {
    authStart(state, data) {
      return {
        ...state,
        ...data,
        pending: true,
        error: null,
        errorCode: null,
      }
    },
    updateError(state, data) {
      return {
        ...state,
        ...data,
        ...(isBoolean(data?.pending) && {
          pending: data.pending
        })
      }
    },
    updateReports(state, data) {
      return {
        ...state,
        ...data,
        pending: false
      }
    }
  },

  effects: (dispatch) => ({

    async getReports(data, prevState) {
      try {
        if (prevState.reports.controller !== null)
          prevState.reports.controller.abort();

        const controller = new AbortController();
        dispatch.reports.authStart({controller});

        const {replace, ...otherProps} = data;

        const responseData = await ApiService.getProjectReports({ ...otherProps, signal: controller.signal});

        checkResponseError(dispatch.reports, responseData);

        responseData.reduce((p, c) => {


          c.id = nanoid(10);

          p.push(c);

          return p;
        }, []);


        responseData.reduce((p, c) => {
          p.id = nanoid();
          p.push(c);
          return p;
        }, []);


        let {uniqueSubscriptions, uniquePayments,} = prevState.reports;

        if (uniquePayments.length === 0 || uniqueSubscriptions.length === 0) {
          [uniqueSubscriptions, uniquePayments] = getUniqueProductIds(responseData, uniqueSubscriptions, uniquePayments);
        }

        let _responseData = [];

        const prevData = data?.offset === 0 ? [] : (prevState.reports.data || []);

        if (prevState.reports.revertData === true) {
          process.env.NODE_ENV !== 'production' && console.time('getReportsReverse');
          responseData.reverse().reduce((prevData, currentItem) => {
            // ToDo need refactoring !!!
            for (const [key, value] of Object.entries(currentItem)) {
              if (Array.isArray(value) === true) {
                currentItem[key] = value.reverse();
              } else if (typeof currentItem === 'object') {
                for (const [key1, value1] of Object.entries(value)) {
                  if (Array.isArray(value1) === true) {
                    currentItem[key][key1] = value1.reverse();
                  } else if (typeof value1 === 'object') {
                    for (const [key2, value2] of Object.entries(value1)) {
                      if (Array.isArray(value2) === true) {
                        currentItem[key][key1][key2] = value2.reverse();
                      }
                    }
                  }
                }
              }
            }

            prevData.push(currentItem);

            return prevData;

          }, []);
          process.env.NODE_ENV !== 'production' && console.timeEnd('getReportsReverse');

          _responseData = ReportService.calculateTotalForWeek(responseData, uniqueSubscriptions, uniquePayments);

          if (replace !== true)
            _responseData = (_responseData).concat(prevData);
        } else {
          if (replace !== true)
            _responseData = (prevData).concat(_responseData);
        }

        dispatch.reports.updateReports({
          controller,
          data: _responseData,
          uniquePayments,
          uniqueSubscriptions,
        });

        return responseData;
      } catch (e) {
        process.env.NODE_ENV === 'development' && console.log('Catch getReports', e);
        dispatch.reports.updateReports({error: e.message})
      }
    },

    async postProjectReportsRevenue({projectId, date, value, weekDayIndex, rowIndex}, prevState) {
      try {
        // dispatch.reports.authStart();

        ApiService.postProjectReportsRevenue({projectId, date, value}).then(responseData => {

          checkResponseError(dispatch.reports, responseData);

          if (prevState.reports.data && responseData?.success === true) {
            const prevRevenueValueCursive = parseFloat(prevState.reports.data[rowIndex].revenue.value_cursive[weekDayIndex]);
            const totalWeekIndex = prevState.reports.data[rowIndex].revenue.value_cursive.length - 1;

            prevState.reports.data[rowIndex].profit.value_cursive[weekDayIndex] -= prevRevenueValueCursive;
            prevState.reports.data[rowIndex].profit.value_cursive[weekDayIndex] += parseFloat(value);

            prevState.reports.data[rowIndex].profit.value_cursive[totalWeekIndex] -= prevRevenueValueCursive;
            prevState.reports.data[rowIndex].profit.value_cursive[totalWeekIndex] += parseFloat(value);

            prevState.reports.data[rowIndex].revenue.value_cursive[weekDayIndex] -= prevRevenueValueCursive;
            prevState.reports.data[rowIndex].revenue.value_cursive[weekDayIndex] += parseFloat(value);

            prevState.reports.data[rowIndex].revenue.value_cursive[totalWeekIndex] -= prevRevenueValueCursive;
            prevState.reports.data[rowIndex].revenue.value_cursive[totalWeekIndex] += parseFloat(value);

            prevState.reports.data[rowIndex].payments.cost[weekDayIndex] = round(
              saveDivide(prevState.reports.data[rowIndex].spends['sum'][weekDayIndex], prevState.reports.data[rowIndex].payments.count[weekDayIndex]),
              2
            );
            prevState.reports.data[rowIndex].payments.cost[totalWeekIndex] = round(
              saveDivide(prevState.reports.data[rowIndex].spends['sum'][totalWeekIndex], prevState.reports.data[rowIndex].payments.count[totalWeekIndex]),
              2
            );

            prevState.reports.data[rowIndex].profit.value_cursive_roi[weekDayIndex] = round(
              saveDivide(prevState.reports.data[rowIndex].profit.value_cursive[weekDayIndex], prevState.reports.data[rowIndex].spends['sum'][weekDayIndex]) * 100,
              2
            );
            prevState.reports.data[rowIndex].profit.value_cursive_roi[totalWeekIndex] = round(
              saveDivide(prevState.reports.data[rowIndex].profit.value_cursive[totalWeekIndex], prevState.reports.data[rowIndex].spends['sum'][totalWeekIndex]) * 100,
              2
            );

            dispatch.reports.updateReports({data: (prevState.reports.data)});
          }
        });

      } catch (e) {
        process.env.NODE_ENV === 'development' && console.log('Catch postProjectReportsRevenue', e);
        dispatch.reports.updateReports({error: e.message})
      }
    },

    async postProjectReportsSpend({projectId, date, source, value, weekDayIndex, rowIndex}, prevState) {
      try {
        // dispatch.reports.authStart();

        ApiService.postProjectReportsSpend({projectId, source, date, value}).then(responseData => {

          checkResponseError(dispatch.reports, responseData);

          let {data, summary} = prevState.reports;

          if (data && responseData?.success === true) {

            const prevSourceValueCursive = parseFloat(data[rowIndex].spends[source][weekDayIndex]);
            const totalWeekIndex = data[rowIndex].spends[source].length - 1;

            data[rowIndex].spends[source][weekDayIndex] -= prevSourceValueCursive;
            data[rowIndex].spends[source][weekDayIndex] += parseFloat(value);

            data[rowIndex].spends[source][totalWeekIndex] -= prevSourceValueCursive;
            data[rowIndex].spends[source][totalWeekIndex] += parseFloat(value);

            data[rowIndex].spends['sum'][weekDayIndex] -= prevSourceValueCursive;
            data[rowIndex].spends['sum'][weekDayIndex] += parseFloat(value);

            data[rowIndex].spends['sum'][totalWeekIndex] -= prevSourceValueCursive;
            data[rowIndex].spends['sum'][totalWeekIndex] += parseFloat(value);

            data[rowIndex].profit.value[weekDayIndex] += prevSourceValueCursive;
            data[rowIndex].profit.value[weekDayIndex] -= parseFloat(value);

            data[rowIndex].profit.value[totalWeekIndex] += prevSourceValueCursive;
            data[rowIndex].profit.value[totalWeekIndex] -= parseFloat(value);

            data[rowIndex].profit.value_cursive[weekDayIndex] += prevSourceValueCursive;
            data[rowIndex].profit.value_cursive[weekDayIndex] -= parseFloat(value);

            data[rowIndex].profit.value_cursive[totalWeekIndex] += prevSourceValueCursive;
            data[rowIndex].profit.value_cursive[totalWeekIndex] -= parseFloat(value);

            data[rowIndex].profit.value_roi[weekDayIndex] = round(saveDivide(data[rowIndex].profit.value[weekDayIndex], data[rowIndex].spends['sum'][weekDayIndex]) * 100, 2);
            data[rowIndex].profit.value_roi[totalWeekIndex] = round(saveDivide(data[rowIndex].profit.value[totalWeekIndex], data[rowIndex].spends['sum'][totalWeekIndex]) * 100, 2);


            data[rowIndex].profit.value_manual[weekDayIndex] += prevSourceValueCursive;
            data[rowIndex].profit.value_manual[weekDayIndex] -= parseFloat(value);

            data[rowIndex].profit.value_manual[totalWeekIndex] += prevSourceValueCursive;
            data[rowIndex].profit.value_manual[totalWeekIndex] -= parseFloat(value);

            data[rowIndex].profit.value_manual_roi[weekDayIndex] = round(
              saveDivide(data[rowIndex].profit.value_manual[weekDayIndex], data[rowIndex].spends['sum'][weekDayIndex]) * 100,
              2
            );
            data[rowIndex].profit.value_manual_roi[totalWeekIndex] = round(
              saveDivide(data[rowIndex].profit.value_manual[totalWeekIndex], data[rowIndex].spends['sum'][totalWeekIndex]) * 100,
              2
            );

            data[rowIndex].payments.cost[weekDayIndex] = round(
              saveDivide(prevState.reports.data[rowIndex].spends['sum'][weekDayIndex], data[rowIndex].payments.count[weekDayIndex]),
              2
            );
            data[rowIndex].payments.cost[totalWeekIndex] = round(
              saveDivide(data[rowIndex].spends['sum'][totalWeekIndex], data[rowIndex].payments.count[totalWeekIndex]),
              2
            );

            data[rowIndex].subscriptions.cost[weekDayIndex] = round(
              saveDivide(prevState.reports.data[rowIndex].spends['sum'][weekDayIndex], data[rowIndex].subscriptions.count_trials[weekDayIndex]),
              2
            );
            data[rowIndex].subscriptions.cost[totalWeekIndex] = round(
              saveDivide(data[rowIndex].spends['sum'][totalWeekIndex], data[rowIndex].subscriptions.count_trials[totalWeekIndex]),
              2
            );

            data[rowIndex].profit.value_cursive_roi[weekDayIndex] = round(
              saveDivide(data[rowIndex].profit.value_cursive[weekDayIndex], data[rowIndex].spends['sum'][weekDayIndex]) * 100,
              2
            );

            data[rowIndex].profit.value_cursive_roi[totalWeekIndex] = round(
              saveDivide(data[rowIndex].profit.value_cursive[totalWeekIndex], data[rowIndex].spends['sum'][totalWeekIndex]) * 100,
              2
            );


            summary.sources[source] += (parseFloat(value) - prevSourceValueCursive);
            summary.sources['sum'] = sumOfSources(summary.sources);

            dispatch.reports.updateReports({data});
          }

        });

      } catch (e) {
        process.env.NODE_ENV === 'development' && console.log('Catch postProjectReportsSpend', e);
        dispatch.reports.updateReports({error: e.message})
      }
    },

    async getProjectReportSummary(data, prevState) {
      try {
        dispatch.reports.authStart();
        if (prevState.reports.controller !== null)
          prevState.reports.controller.abort();

        const controller = new AbortController();
        dispatch.reports.authStart({controller});

        let responseData = await ApiService.getProjectSummary(data);

        checkResponseError(dispatch.reports, responseData);

        const {sources} = responseData;

        responseData.sources.sum = sumOfSources(sources);

        dispatch.reports.updateReports({summary: responseData});

        return responseData;

      } catch (e) {
        process.env.NODE_ENV === 'development' && console.log('Catch getProjectSummary', e);
        dispatch.reports.updateReports({error: e.message})
      }
    },


    async uploadSpendsFromFile(data) {
      try {
        return await ApiService.uploadSpendsFromFile(data)
      } catch (e) {
        dispatch.reports.updateReports({error: e.message})
      }
    },

    async uploadRevenueFromFile(data) {
      try {
        return await ApiService.uploadRevenueFromFile(data)
      } catch (e) {
        dispatch.reports.updateReports({error: e.message})
      }
    }
  })
};


const sumOfSources = sources => reduce(sources, (acc, value, key) => (key === 'sum' ? acc : acc + value), 0);


const getUniqueProductIds = (data, prevUniqSubscription, prevUniqPayments) => {
  process.env.NODE_ENV !== 'production' && console.time('getUniqueProductIds');
  console.log('getUniqueProductIds data', {data, prevUniqSubscription, prevUniqPayments});

  const paymentsInfo = reduce(data, (acc, {payments}) => ([
    ...acc,
    ...reduce(payments?.info, (accInfo, infoItem) => ([
      ...accInfo,
      ...reduce(infoItem, (accInfoItem, item) => ([
        ...accInfoItem,
        {
          product_id: item.product_id,
          type: 'payments',
          count: item.count,
          value: item.count,
          columnCount: 1
        }]), [])
    ]), [])
  ]), []);

  const subscriptionsInfo = reduce(data, (acc, {subscriptions}) => ([
    ...acc,
    ...reduce(subscriptions?.info, (accInfo, infoItem) => ([
      ...accInfo,
      ...reduce(infoItem, (accInfoItem, item) => ([
        ...accInfoItem,
        {
          product_id: item.product_id,
          type: 'subscriptions',
          count: item.count,
          value: item.count,
          columnCount: 1
        }]), [])
    ]), [])
  ]), []);

  const payments = sortBy(
    uniqBy([...prevUniqPayments, ...paymentsInfo], 'product_id'),
    'columnCount'
  );
  const subscription = sortBy(
    uniqBy([...prevUniqSubscription, ...subscriptionsInfo], 'product_id'),
    'columnCount'
  );

  console.log('getUniqueProductIds data out', {payments, subscription});
  process.env.NODE_ENV !== 'production' && console.timeEnd('getUniqueProductIds');
  return [subscription, payments];
};