import axios from 'axios'
import snakeCaseKeys from "snakecase-keys";
import buildQueryString from "./helpers";

// export const HOST = (
//     process.env.NODE_ENV === 'development' ?
//         `https://localhost:3000/api` :
//         `${process.env.REACT_APP_DEV_API_PROTOCOL || "http"}://${process.env.REACT_APP_DEBUG === '1' ? 'dev.' :''}${process.env.REACT_APP_BI_TABLES_API_HOST}`
// ).replace(/\/$/, '');

class ApiRequestError extends Error {
    constructor(message, status, body) {
        super();

        this.name = `ApiRequestError[${status}]`;
        this.message = message;
        this.status = status;
        this.body = body;

        try {
            const json = JSON.parse(body);
            if (json?.error) {
                this.message = json?.error
            }
        } catch(e){

        }

        if (typeof Error.captureStackTrace === 'function') {
            Error.captureStackTrace(this, this.constructor);
        } else {
            this.stack = (new Error(message)).stack;
        }
    }
}

/*eslint-disable no-unused-vars*/

const OUT_FORMAT_TEXT = 'text';
const OUT_FORMAT_BLOB = 'blob';
const OUT_FORMAT_JSON = 'json';

async function get(url, params = {}, outFormat = OUT_FORMAT_JSON) {
    const { signal, ...otherParams } = params;
    const queryParams = buildQueryString(otherParams); // JSON.stringify(params) === JSON.stringify({}) ? '' : `?${queryString.stringify(params)}`;

    let response = await fetch(`/api${url}` + queryParams, {
        method: 'GET',
        headers: {
            'api-token': localStorage.getItem('access_token'),
            'debug':process.env.REACT_APP_DEBUG || 0,
        },
        signal
    });

    if (response.ok){

        if (outFormat === OUT_FORMAT_BLOB)
            return await response.blob();

        if (outFormat === OUT_FORMAT_TEXT)
            return await response.text();

        return await response.json()

    }else {
        throw new ApiRequestError(response.statusText, response.status, await response.text())
    }
}

async function post(url, data) {

    let response = await fetch(`/api${url}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json;charset=utf-8',
            'api-token': localStorage.getItem('access_token'),
            'debug':process.env.REACT_APP_DEBUG || 0,
        },
        body: JSON.stringify(snakeCaseKeys(data))
    });

    if (response.ok) {
        return await response.json()
    }
    else {
        const text = await response.text();
        let jsonResponse = null;

        try {
            jsonResponse = JSON.parse(text);

        }catch (e) {
            throw new ApiRequestError(response.statusText, response.status, text)
        }

        if (jsonResponse.hasOwnProperty('error')) {
            throw new ApiRequestError(jsonResponse.error, response.status, response.statusText)
        }
        throw new ApiRequestError(response.statusText, response.status, text)
    }
}

async function upload(url, data, onUploadProgress, cancelFileUploadRef) {

    const options = {
        onUploadProgress,
        cancelToken: (new axios.CancelToken(
            cancel => (cancelFileUploadRef.current = cancel)
        )),
        headers: {
            'api-token': localStorage.getItem('access_token'),
            'debug':     process.env.REACT_APP_DEBUG || 0,
        },
    };

    return await axios.post(`/api${url}`, data, options);
}

async function put(url, data) {

    let response = await fetch(`/api${url}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json;charset=utf-8',
            'api-token': localStorage.getItem('access_token'),
            'debug':process.env.REACT_APP_DEBUG || 0,
        },
        body: JSON.stringify(snakeCaseKeys(data))
    });

    if (response.ok) {
        return await response.json()
    } else {
        throw new ApiRequestError(response.statusText, response.status, await response.text())
    }

}

async function del(url, data) {

    let response = await fetch(`/api${url}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json;charset=utf-8',
            'api-token': localStorage.getItem('access_token'),
            'debug':process.env.REACT_APP_DEBUG || 0,
        },
        body: JSON.stringify(snakeCaseKeys(data))
    });

    if (response.ok) {
        return await response.json()
    }
    else {
        const text = await response.text();
        let jsonResponse = null;

        try {
            jsonResponse = JSON.parse(text);

        }catch (e) {
            throw new ApiRequestError(response.statusText, response.status, text)
        }

        if (jsonResponse.hasOwnProperty('error')) {
            throw new ApiRequestError(jsonResponse.error, response.status, response.statusText)
        }
        throw new ApiRequestError(response.statusText, response.status, text)
    }
}

/*eslint-enable no-unused-vars*/


const ApiService = {

    async login({email, password}) {
        return await post(
            `/login/`,
            {
                email,
                password
            }
        )
    },

    // change password
    async renewPassword({email, password}) {
        return await post(
            `/login/password`,
            {
                email,
                password,
            }
        )
    },

    async getProjects() {
        return await get(`/projects`)
    },

    async getCalculatorHome(data) {
        return await get(`/project/calculator/home`, data)
    },

    async getCalculatorPredictive(data) {
        return await get(`/project/calculator/predictive`, data)
    },

    async countriesList(data) {
        return await get(`/project/countries/list`, data)
    },

    async getCountries(data) {
        return await get(`/project/countries`, data)
    },

    async putCountry(data) {
        return await put(`/project/countries`, data)
    },

    async delCountry(data) {
        return await del(`/project/countries`, data)
    },

    async getCountriesList(data) {
        return await get(`/project/countries/list`, data)
    },

    async getABProjectVersions(data) {
        return await get(`/project/versions`, data)
    },

    async getABProjectVersion(data) {
        return await get(`/project/version`, data)
    },

    async postProjectOption(data) {
        return await get(`/project/version`, data)
    },

    async getProjectProducts(data) {
        return await get(`/project/products`, data)
    },

    async postProjectProduct(data) {
        return await post(`/project/product`, data)
    },

    async getProjectReports(data) {
        return await get(`/project/reports`, data)
    },

    async getProjectSummary(data) {
        return await get(`/project/report/summary`, data);
    },

    async postProjectReportsRevenue(data) {
        return await post(`/project/report/revenue`, data)
    },

    async postProjectReportsSpend(data) {
        return await post(`/project/report/spend`, data)
    },

    async getProductConversions(data) {
        return await get(`/project/product/conversion`, data)
    },

    async downloadProductsReports(data) {
        return await get(`/project/download/csv/ltv_by_country`, data, OUT_FORMAT_BLOB)
    },

    async uploadSpendsFromFile(data) {

        const {onUploadProgress, cancelFileUploadRef, formData, type, source} = data;

        return await upload(`/project/upload/${type}/spend/${source}`, formData, onUploadProgress, cancelFileUploadRef)
    },

    async uploadRevenueFromFile(data) {

        const {onUploadProgress, cancelFileUploadRef, formData} = data;

        return await upload(`/project/upload/csv/revenue`, formData, onUploadProgress, cancelFileUploadRef)
    },


    async getDeeplinks(data) {
        return await get(`/project/deeplinks`, data)
    },

    async getDeeplinkDetails(data) {
        return await get(`/project/deeplink`, data)
    },

    async getABTests(data) {
        return await get(`/project/ab_tests`, data)
    },

    async getABTest(data) {
        return await get(`/project/ab_test`, data)
    },

    async postABTest(data) {
        return await post(`/project/ab_test`, data)
    },

    async getAbTestNew(data) {
        return await get(`/project/ab_test/new`, data)
    },

    async postAbTestNew(data) {
        return await post(`/project/ab_test/new`, data)
    },

    async postPushToken(data) {
        return await post(`/push_token`, data)
    },

    async getVersions(data) {
        return await get(`/project/versions`, data)
    },

    async postVersionNew(data) {
        return await post(`/project/version/new`, data)
    },

    async postVersionFront(data) {
        return await post(`/project/version/front`, data)
    },

    async deleteVersionFront(data) {
        return await del(`/project/version/front`, data)
    },

    async postVersionFrontApply(data) {
        return await post(`/project/version/front/apply`, data)
    },

    async getVersionLog(data) {
        return await get(`/project/version`, data)
    },

    async getProductRenewals(data) {
        return await get(`/project/product/renewals`, data)
    },

    async getReportRenewals(data) {
        return await get(`/project/report/renewals`, data)
    },

    async getCalculatorRenewals(data) {
        return await get(`/project/calculator/renewals`, data)
    },

    async getCancelled(data) {
        return await get(`/project/calculator/cancelled`, data)
    },

    async getConversions(data){
        return await get(`/project/calculator/conversions`, data)
    },

    async getVeryGoodReportData(data){
        return await get(`/project/report/very_good`, data)
    },
    async getPredictiveDebug(data) {
        return await get(`/project/report/predictive_debug`, data, OUT_FORMAT_TEXT)
    },
    async getProjectReportHome(data) {
        return await get(`/project/report/home`, data)
    },
    async getWeeklyData(data) {
        return await get(`/project/report/weekly`, data)
    },
    async getMonthlyData(data) {
        return await get(`/project/report/monthly`, data)
    },
    getRecurringRevenue: async data => await get(`/project/report/recurring_revenue`, data),
    getCalendarDash: async data => await get(`/project/report/calendar_dash`, data),
    getCohortsDash: async data => await get(`/project/report/cohorts_dash`, data),
    getCalculator: async data => await get(`/project/calculator`, data),
    getSubscriptionsAnalysis: async data => await get(`/project/report/subscriptions_analysis`, data),
    postProject: async data => await post(`/project`, data),
};

export default ApiService;
