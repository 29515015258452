import palette from "../palette";


export default {
    root: {
        'border-bottom': 'unset',
        '&>sup': {
            '&.positive': {
                color: palette.primary.main
            },
            '&.negative': {
                color: palette.error.main
            },
        },
    },
    head: {
        color: palette.darkGrey,
        fontWeight: 600,
    },
    body:{
        textAlign: 'end'
    },
    sizeSmall: {
        padding: '6px 16px 6px 16px'
    }
}