import React from "react";
import IconButton from "@mui/material/IconButton";
import Popover from "@mui/material/Popover";
import SettingsIcon from '@mui/icons-material/Settings';
import Box from "@material-ui/core/Box";
import IOSSwitch from "../../components/IOSSwitch";
import Stack from "@mui/material/Stack";
import {useDispatch, useSelector} from "react-redux";

const CONVERSIONS = {
  30: 0.7,
  15: 0.85
};

const PriceConversionSettings = () => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const { active } = useSelector(state => state.project);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleChangeConversion = (event) => {
      dispatch({
        type: 'project/postProject',
        payload: {
          projectId: active.id,
          option: 'price_conversion',
          value: event.target.checked ? CONVERSIONS[30] : CONVERSIONS[15]
        }
      });
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  if (!active?.id) return null;

  return (
    <>
      <IconButton aria-describedby={id} variant="contained" onClick={handleClick}>
        <SettingsIcon />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Stack direction="row" component={Box} spacing={1} p={2} >
          <Box
            {...(CONVERSIONS[15] !== active.options.price_conversion && {
              color: "text.secondary"
            })}
          >
            15%
          </Box>
          <IOSSwitch
            checked={CONVERSIONS[30] === active.options.price_conversion}
            onChange={handleChangeConversion}
            name="priceConversion"/>
          <Box
            {...(CONVERSIONS[30] !== active.options.price_conversion && {
              color: "text.secondary"
            })}
          >
            30%
          </Box>
        </Stack>
      </Popover>
    </>
  )
};


export default PriceConversionSettings;