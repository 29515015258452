import Alert from "@material-ui/lab/Alert";
import {Snackbar} from "@material-ui/core";
import React from "react";


function SuccessSnackbar({open = false, closeTimeout= 6000, text, severity='success', cb }) {

    const [open1, setOpen] = React.useState(false);

    const handleClose = () => {
        setOpen(false);
        cb()
    };

    return (
        <Snackbar open={open || open1} autoHideDuration={closeTimeout} onClose={handleClose}>
            <Alert onClose={handleClose} severity={severity}>
                {text}
            </Alert>
        </Snackbar>
    )
}


export default SuccessSnackbar