import React from "react";
import 'react-base-table/styles.css';
import {reduce, upperFirst, isEmpty, round, has, get, values, replace} from 'lodash'
import Box from "@material-ui/core/Box";
import {CellFormatRender} from "./utils";
import Typography from "@material-ui/core/Typography";
import BaseGridTable from "../../../components/BaseGridTable";
import {GridToolbarContainer} from "@mui/x-data-grid";

const PERIOD_ROWS = [
    //'users_payed',
    'payments', 'profit', 'revenue',  'LTV', 'ROMI', 'ARPU'
];

const prepareColumns = (data) => {
    return [
        {
            field:      'period',
            headerName: 'Period',
            minWidth:   90,
            headerAlign: 'left',
            align: 'left',
            sortable: false,
            flex: 1,
            renderCell: ({ value }) => replace(upperFirst(value), '_', ' ')
        },
        ...reduce(data, (acc, item) => {
            const key = item.period;
            return [
                ...acc,
                {
                    minWidth:   130,
                    field: key,
                    headerName: item.name || item.period,
                    headerAlign: 'right',
                    align: 'right',
                    sortable: false,
                    flex: 1,
                    renderCell: ({ value, id, field, row, api }) => {
                        let percent = null;
                        if (api.getColumnIndex(field) > 1 && id === 'payments') {
                            const firstColumnDataKey = api.getAllColumns()[1].field;
                            const firstColumnValue = get(row, firstColumnDataKey);
                            percent =
                                CellFormatRender.percent((value - firstColumnValue)/firstColumnValue * 100);
                        }

                        const renderValue = has(CellFormatRender, id) ?
                            CellFormatRender[id](value) :
                            round(value, 2)?.toLocaleString('en-US');

                        return (
                            <Box display="flex" flexDirection="column">
                                <Box>{renderValue}</Box>
                                {!isEmpty(percent) && (
                                    <Box component={Typography} variant="overline">
                                        {percent}
                                    </Box>
                                )}
                            </Box>
                        );
                    }
                }
            ]
        }, [])
    ];
};

const prepareTableRows = ({ data, columns }) => {
    return reduce(PERIOD_ROWS, (acc, field) => ([
        ...acc,
        {
            ...reduce(data, (acc2, item) => ({
                ...acc2,
                [item.period]: item[field],
            }), {
                'period': field,
                'id': field
            })
        }
    ]), [])
};

const PredictiveTable = ({ data, loading, spend, firstOpen, usersPayed }) => {
    const columns = prepareColumns(data);
    const rows = prepareTableRows({ data, columns});

    return (
        <BaseGridTable
            components={{
                Toolbar: () => (
                    <GridToolbarContainer style={{ borderBottom: '1px solid rgba(224,224,224,1)' }}>
                        <Box p={1} display="flex" alignItems="center">
                            <Typography p={1} component={Box} variant={'h4'} color={'inherit'}>Predictive Table</Typography>
                            <Box px={1}>Spend: $ {spend?.toLocaleString('en-US')}</Box>
                            {firstOpen !== 0 && (
                                <Box px={1}>
                                    CPI: $ {round(spend / firstOpen, 3)?.toLocaleString('en-US')}
                                </Box>)
                            }
                            {usersPayed !== 0 && (
                                <Box px={1}>
                                    Payed Unique User: $ {round(spend / usersPayed, 3)?.toLocaleString('en-US')}
                                </Box>)
                            }
                        </Box>
                    </GridToolbarContainer>
                )
            }}
            loading={loading}
            rowHeight={36}
            rows={isEmpty(data) ? [] : values(rows)}
            columns={columns}
        />
    )
};

export default PredictiveTable;
