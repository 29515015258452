import { reduce, sortBy } from 'lodash'
import { nanoid } from "nanoid";
import moment from "moment";


export const preparePaymentsData = data => sortBy(
    reduce(data, (acc, item) => ([
        ...acc,
        ...[{
            key: nanoid(),
            ...item,
            ...{
                timestamp: moment(item.time, 'DD.MM.YYYY HH:mm:ss').unix(),
                paymentsCount: reduce(item.data.users_payed, (acc, a) => acc + a, 0)
            }
        }]
    ]), []), [ 'timestamp' ]);