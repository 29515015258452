import React from "react";
import useOnClickOutside from "../hooks/useOnClickOutside";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import Badge from "@material-ui/core/Badge";
import InfoIcon from "@material-ui/icons/Info";
import Popover from "@material-ui/core/Popover";
import { isEmpty } from 'lodash';
import Warnings from "./Warnings";

const WarningPopper = ({ data }) =>  {
    const [anchorEl, setAnchorEl] = React.useState(null);

    useOnClickOutside(anchorEl, () => {
        setAnchorEl(null);
    });

    const handleClick = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };
    const handlePopoverClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    const id = open ? 'warnings-popper' : undefined;

    if (isEmpty(data)) return null;

    return (
        <Box>
            Warnings:
            <IconButton onClick={handleClick}>
                <Badge badgeContent={data?.length || ''} color="error">
                    <InfoIcon/>
                </Badge>
            </IconButton>

            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
            >
                <Warnings data={data} />
            </Popover>
        </Box>
    );
};

export default WarningPopper;
