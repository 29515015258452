import React, {useEffect} from "react";
import {connect, useSelector} from "react-redux";
import Box from "@material-ui/core/Box";
import {Column, Table} from "./../../components/table";
import {useQuery} from "../../services/helpers";
import ReportService from "../../services/ReportService";
import { CSVLink } from "react-csv";
import {format} from 'date-fns'
import {LinearProgress} from "@material-ui/core";
import RenewalForm from "./RenewalForm";
import {isNil} from "lodash";
import {CONVERSION_MODEL} from "../../constants/Constants";

const addClassNameRow = selectedRow => ({ rowData }) => {
    const { id } = rowData;
    const hasEqualRow = selectedRow === id;
    return hasEqualRow && "active";
};
const handleOnClickRow = setSelectedRow => ({ rowKey }) => {
    setSelectedRow(rowKey);
};
const columns = (products) => ({ dataKey, ...restProps }) => {
    return <Column key={dataKey} dataKey={dataKey} {...restProps} rowData={products} />
};

export const expandColumnKey = 'date';

function Renewals(props){
    const query    = useQuery();
    const [loading]                     = React.useState(false);
    const [selectedRow, setSelectedRow] = React.useState(null);
    const [legend, setLegend]           = React.useState(null);

    const [filterData, setFilterData] = React.useState({
        products:         query.getAll('products[]') || [],
        dateAfter:        query.get('date_after'), //  ? format(Date.parse(query.get('date_after')), strFormat)  : null,
        dateBefore:       query.get('date_before'), // ? format(Date.parse(query.get('date_before')), strFormat) : null,
        groupCountPeriod: query.get('group_count_period') || 2,
        conversionModel: query.get('conversionModel') || CONVERSION_MODEL.MANUAL
    });
    const { active } = useSelector(state => state.project);

    useEffect(()=>{
        if (props.report.pending === false && props.report.renewals === null &&
            props.project?.active?.id && props.project.pending === false) {
            const {dateAfter, dateBefore, conversionModel} = filterData;
            let data = {projectId:  props.project.active.id, conversionModel};
            if (dateAfter !== null && dateBefore !== null)
                data = {...data, ...{dateAfter, dateBefore,}};

            props.getReportRenewals(data);
        }
    }, [props, filterData, active]);

    useEffect(() => {
        if (props.report.legend !== legend) {
            setLegend(props.report.legend)
        }

    }, [props, legend]);

    const handleLoad = () => {
        const { dateAfter, dateBefore, conversionModel } = filterData;
        let data = {projectId:  props.project.active.id, conversionModel};
        if (dateAfter !== null && dateBefore !== null)
            data = {...data, ...{dateAfter, dateBefore,}};

        props.getReportRenewals(data);
    };

    const columnDefinition = (props.report.columns||[]).map((column, columnIndex) => {
        let frozen;
        if (columnIndex < 2) frozen = Column.FrozenDirection.LEFT;
        if (columnIndex > (props.report.columns||[]).length-2) frozen = Column.FrozenDirection.RIGHT;
        return { ...column, frozen }
    });


    let data = [];

    let exportData = [];

    if (isNil(filterData.dateAfter) && isNil(filterData.dateBefore)) {
        data = ReportService.filterRenewals((props.report.renewals || []), filterData.dateAfter, filterData.dateBefore);
    }
    else {
        data = ReportService.reportRenewals(props.report.renewals || [], filterData.dateAfter, filterData.dateBefore, filterData.groupCountPeriod).reverse();
        exportData = ReportService.prepareExportData(data, filterData.products, props.report?.exportColumnHeader );
    }

    return (
        <Box p={3}>
            <RenewalForm legend={legend}
                         pending={props.report.pending}
                         filterData={filterData}
                         onChange={(data) => {
                             setFilterData(data)
                         }}
                         handleLoad={handleLoad}/>

            {(exportData.length > 0 && filterData.dateAfter !== null && filterData.dateBefore !== null) &&
            <Box>
                <CSVLink data={exportData} separator={","} filename={props.match.params?.projectPath +  '_report_renewal_export_'+format(Date.now(), 'yyyy_MM_dd') + '.csv'}>
                    Export to CSV
                </CSVLink>
            </Box>
            }

            {props.report.pending && <LinearProgress  />}

            <Table
                rowKey="date"
                data={data}
                loading={loading || props.report.pending}
                expandColumnKey={expandColumnKey}

                height={(45 + ((data?.length || 1) * 30)) }
                width={500}

                headerHeight={45}
                rowHeight={30}

                onClickRow={handleOnClickRow(setSelectedRow)}
                rowClassName={addClassNameRow(selectedRow)}
            >
                {columnDefinition.map(columns(filterData.products || []))}
            </Table>


        </Box>
    )
}


const mapState = state => ({
    report: state.report,
    project: state.project,
});

const mapDispatch = ({
                         report:{
                             getReportRenewals,
                         },
                     }) => ({
    getReportRenewals:  (data) => getReportRenewals(data),
});

export default connect(mapState, mapDispatch)(Renewals)