import React, {useCallback, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import {connect} from "react-redux";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Info from "./Info";
import Button from "@material-ui/core/Button";
import useWindowSize from "../../hooks/useWindowSize";
import AddIcon from '@material-ui/icons/Add';
import {useHistory} from "react-router-dom";
import buildQueryString, {useQuery, versionCompare} from "../../services/helpers";


const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
}));


function VersionsList(props) {

    let history = useHistory();
    let query = useQuery();

    const version = query.get('version') || null;

    const classes = useStyles();
    const [, _height] = useWindowSize();
    const [state, setState] = React.useState({});

    const handleChange = (name) => {

        const _isOpen = isOpen(name);
        console.log('handleChange', name, _isOpen);

        setState({ ...state, [name]: _isOpen });
    };

    const isOpen = (name) => {

        try {
            if (version !== null) {
                const versionNumbers = version.split('.');
                const versionNameNumbers = (name || '').split('.');
                if (versionNumbers.length > versionNameNumbers.length && name?.length > 0) {
                    versionNumbers.pop();

                    const tmpVersion = versionNumbers.join('.');

                    if (versionCompare(tmpVersion, name) === 0) return true;
                }

            }
        } catch (e) {
            console.log('isOpen', e);
        }

        return !(state?.[name] ?? true)
    };

    const [info, setInfo] = React.useState(null);

    const handleShowInfo = (info) => {
        const {version} = info;
        
        setInfo(info);

        props.getVersionLog({
            projectId: props.project.active.id,
            version: info?.version
        });

        history.push(window.location.pathname + buildQueryString({version}));
    };

    const setActiveVersion = useCallback((version) => {

        // let _info = null;
        // eslint-disable-next-line
        props.versions.versions?.forEach(item =>{
            // eslint-disable-next-line
            item.list?.forEach(itemList =>{
                if (versionCompare(itemList.version, version) === 0){
                    // return itemList;
                    // _info = itemList;
                    setInfo(itemList);
                    // return true
                }else{
                    // eslint-disable-next-line
                    itemList.subs?.forEach(subItem =>{
                        if (versionCompare(subItem.version, version) === 0){
                            // return subItem;
                            // _info = subItem;
                            setInfo(subItem);
                            // return true;
                        }
                    })
                }
            })
        });

    }, [props]);

    useEffect(()=>{
        if (props.versions.versions === null && props.versions.pending === false && props.project?.active?.id && props.project.pending === false) {
            props.getVersions({projectId: props.project.active.id});
        }

        if (version &&
            props.versions.versions !== null && props.versions.pending === false &&
            props.project?.active?.id && props.project.pending === false){
            setActiveVersion(version)
        }

    },[props, version, info, setActiveVersion]);

    useEffect(()=>{
        if (info?.version && props.versionLog.actionLog === null && props.versionLog.pending === false && props.project?.active?.id && props.project.pending === false) {
            props.getVersionLog({
                projectId: props.project.active.id,
                version: info?.version
            });
        }
    },[props, info]);


    const handleVersionNew = (newVersion) => {
        props.postVersionNew({newVersion})
    };

    return (
        <Grid container spacing={3}>
            <Grid item xs={4}>
                <List style={{maxHeight: _height-124, overflowY:'auto'}}
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                    subheader={
                        <ListSubheader component="div" id="nested-list-subheader">
                            Versions LIst
                        </ListSubheader>
                    }
                    className={classes.root}
                >
                    {props.versions.versions?.map((versionItem) =>
                        <React.Fragment key={`${versionItem.number}`}>
                            <ListItem button
                                      // selected={info?.version === versionItem.next}
                                      onClick={handleChange.bind(this, versionItem.number)}
                            >
                                <ListItemIcon>
                                    Prod
                                </ListItemIcon>
                                <ListItemText primary={`Version: ${versionItem.number}`} />
                                {(isOpen(versionItem.number) || version!==null) ? <ExpandLess /> : <ExpandMore />}
                            </ListItem>

                            <Collapse in={(isOpen(versionItem.number) || version!==null)} timeout="auto" unmountOnExit>

                                <List component="div" disablePadding>

                                    {versionItem?.list?.map( listItem => {

                                        return (
                                            <React.Fragment  key={`${listItem.version}`}>
                                                <ListItem className={classes.nested}
                                                          role={undefined}
                                                          dense
                                                          button
                                                          selected={info?.version === listItem.version}
                                                          onClick={handleChange.bind(this, listItem.version)}>
                                                    <ListItemIcon>
                                                        {isOpen(listItem.version) ? <ExpandLess /> : <ExpandMore />}
                                                    </ListItemIcon>

                                                    <ListItemText primary={listItem.version}
                                                                  secondary={`Users: ${listItem.users}`} />

                                                    <ListItemSecondaryAction>
                                                        <Button onClick={ handleShowInfo.bind(this, listItem)} color="primary">Info</Button>
                                                    </ListItemSecondaryAction>
                                                </ListItem>
                                                <Collapse in={isOpen(listItem.version)}
                                                          timeout="auto"
                                                          unmountOnExit
                                                          component={Box} pl={2}
                                                >
                                                        <List disablePadding
                                                              component="nav">
                                                        {listItem?.subs?.map( subItem => {

                                                            return (
                                                                <React.Fragment key={`${subItem.version}`}>
                                                                    <ListItem key={subItem.version}
                                                                              className={classes.nested}
                                                                              selected={info?.version === subItem.version}
                                                                              role={undefined} dense button>
                                                                        <ListItemIcon>

                                                                        </ListItemIcon>
                                                                        <ListItemText primary={subItem.version}
                                                                                      secondary={`Users: ${subItem.users}`}/>

                                                                          <ListItemSecondaryAction>
                                                                              <Button onClick={ handleShowInfo.bind(this, subItem)}
                                                                                      color="primary">Info</Button>
                                                                        </ListItemSecondaryAction>
                                                                    </ListItem>
                                                                </React.Fragment>
                                                            )
                                                        })}

                                                            <ListItem className={classes.nested}>
                                                                <Button variant={"outlined"}
                                                                        color={"primary"}
                                                                        startIcon={<AddIcon color={"primary"} />}
                                                                        onClick={handleVersionNew.bind(this, listItem.next)}
                                                                >
                                                                    {listItem.next}
                                                                </Button>
                                                            </ListItem>
                                                    </List>
                                                </Collapse>
                                            </React.Fragment>
                                        )}
                                    )}
                                    <ListItem className={classes.nested}>
                                        <Button variant={"outlined"}
                                                color={"primary"}
                                                startIcon={<AddIcon color={"primary"} />}

                                                onClick={handleVersionNew.bind(this, versionItem.next)}
                                        >
                                            {versionItem.next}
                                        </Button>
                                    </ListItem>
                                </List>
                            </Collapse>
                        </React.Fragment>
                    )}
                </List>
            </Grid>
            <Grid item xs={8}>

                {!!info && <Info {...info} {...props} />}

            </Grid>
        </Grid>
    );
}



const mapState = state => ({
    versions: state.versions,
    versionLog: state.versionLog,
    project: state.project,
});

const mapDispatch = ({
                         versions:{
                             getVersions,
                             postVersionNew,
                             postVersionFront,
                             deleteVersionFront,
                             postVersionFrontApply,
                         },
                         versionLog:{
                             getVersionLog
                         }
                     }) => ({
    getVersions,
    postVersionNew,
    postVersionFront,
    deleteVersionFront,
    postVersionFrontApply,
    getVersionLog,
});



export default connect(mapState, mapDispatch)(VersionsList)