import ApiService from "../services/api";
import {checkResponseError} from './helpers'


export default {
    state: {
        pending: false,
        error: null,
        errorCode: null,

        data: null,
    },
    reducers: {
        authStart(state) {
            return {
                ...state,
                error: null,
                errorCode: null,
                pending: true
            }
        },
        updateError(state, data) {
            return {
                ...state,
                ...data,
                pending: false
            }
        },
        update(state, data){
            return {
                ...state,
                ...data,
                pending: false
            }
        }
    },

    effects: (dispatch) => ({

        async getCalculatorPredictive(data){
            try{
                dispatch.calculatorpredictive.authStart();

                if (data.hasOwnProperty('dateAfter')) {
                    data.registeredAfter = data.dateAfter;
                }
                if (data.hasOwnProperty('dateBefore')) {
                    data.registeredBefore = data.dateBefore;
                }

                const responseData = await ApiService.getCalculatorPredictive(data);

                checkResponseError(dispatch.calculatorpredictive, responseData);

                dispatch.calculatorpredictive.update({data: responseData});

                return responseData;
            }
            catch (e) {
                console.log('Catch calculator', e);
                dispatch.calculatorpredictive.update({error: e.message})
            }
        },

    })
};