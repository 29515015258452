import React from "react";
import Box from "@material-ui/core/Box";
import Filters from "./components/Filters";
import {sumBy, values} from "lodash";
import WarningPopper from "../../components/WarningPopper";
import {useSelector} from "react-redux";
import ActualTable from "./components/ActualTable";
import PredictiveTable from "./components/PredictiveTable";
import ProductInfo from "./components/ProductInfo";
import useStyles from './components/styles'
import StatisticsTable from "./components/StatisticsTable";
import Card from "@material-ui/core/Card";
import Actual2Table from "./components/Actual2Table";


const paymentsTotalFirst = ({ subscriptions = {}, trials = {} }) => {
    const subscriptionsSum = sumBy(values(subscriptions), 'payments');
    const trialsSum = sumBy(values(trials), 'payments');
    return {
        payments: subscriptionsSum + trialsSum,
    }
};
const paymentsSubFirst = ({ subscriptions = {}, trials = {} }) => {
    const subscriptionsSum = sumBy(values(subscriptions), 'count');
    const convertedSum = sumBy(values(trials), 'converted');
    return {
        payments: subscriptionsSum + convertedSum,
        // converted: convertedSum,
        // count: subscriptionsSum
    }
};
const paymentsInApp = ({ purchases = {} }) => {
    const purchasesPaymentsSum = sumBy(values(purchases), 'payments');
    return {
        payments: purchasesPaymentsSum,
    }
};
const totalInfo = ({ details = {}, first_open }) => [
    {
        product: 'First Open',
        payments: first_open
    },
    {
        product: "Payments in app",
        ...paymentsInApp(details)
    },
    {
        product: "Payments sub first",
        ...paymentsSubFirst(details)
    },
    {
        product: "Payments sub total",
        ...paymentsTotalFirst(details)
    },
];

const Home = () => {
    const classes = useStyles();
    const { warnings, first_open, spend,  actual, predictive, actual2, statistics, pending, details, users_payed = 0 } =
        useSelector(state => state.home);

    return (
        <Box>
            <WarningPopper data={values(warnings)} />

            <Filters />

            <Card mt={2} component={Box}>
                <ActualTable
                    data={actual}
                    loading={pending}
                    spend={spend}
                    firstOpen={first_open}
                />
            </Card>

            <Card mt={2} component={Box}>
                <PredictiveTable
                    data={predictive}
                    loading={pending}
                    spend={spend}
                    firstOpen={first_open}
                    usersPayed={users_payed}
                />
            </Card>

            <Card mt={2} component={Box}>
              <Actual2Table
                data={actual2}
                loading={pending}
                spend={spend}
                firstOpen={first_open}
                usersPayed={users_payed}
              />
            </Card>


            <Box
                pt={2}
                display={{xs: 'block', sm: 'block', md: 'flex', lg: 'flex'}}
                flexDirection="row"
                className={classes.total}
            >
                <ProductInfo name="Total" data={pending ? []: totalInfo({ details, first_open })} />
                <ProductInfo name="Subscriptions" data={values(details?.subscriptions)} />
                <ProductInfo name="Trials" data={values(details?.trials)} />
                <ProductInfo name="Purchases" data={values(details?.purchases)} />
            </Box>

            <Box component={Card} mt={2}>
                <StatisticsTable data={statistics} loading={pending} />
            </Box>

        </Box>
    );
};


export default Home;
