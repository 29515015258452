import ApiService from "../services/api";
import {checkResponseError} from "./helpers";

export default {
    state: {
        pending: false,
        error: null,
        errorCode: null,

        renewals: null,
        max_num_payments: 0,
        dist_countries:[]
    },
    reducers: {
        authStart(state) {
            return {
                ...state,
                pending: true,
                error: null,
                errorCode: null,
            }
        },
        updateError(state, data) {
            return {
                ...state,
                ...data,
                pending: false
            }
        },
        updateRenewals(state, data) {
            return {
                ...state,
                ...data,
                pending: false
            }
        },
    },

    effects: (dispatch) => ({

        async getCalculatorRenewals(data) {
            try {
                dispatch.renewalcaluator.authStart();

                const responseData = await ApiService.getCalculatorRenewals(data);
                checkResponseError(dispatch.renewalcaluator, responseData);
                const {renewals, meta} = responseData;
                dispatch.renewalcaluator.updateRenewals({
                    renewals,
                    max_num_payments: meta?.max_num_payments || 0
                });
            } catch (e) {
                console.log('Catch renewal caluator', e);
                dispatch.renewalcaluator.updateRenewals({error: e.message})
            }
        },
    })
}