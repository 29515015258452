import ApiService from "../services/api";
import {checkResponseError} from "./helpers";
import {reduce, last, takeRight} from "lodash";


export default {
    state: {
        pending: false,
        error: null,
        errorCode: null,

        periods: null,
    },
    reducers: {
        authStart(state) {
            return {
                ...state,
                pending: true
            }
        },
        updateError(state, data) {
            return {
                ...state,
                ...data,
                pending: false
            }
        },
        updateRenewals(state, data){
            return {
                ...state,
                ...data,
                pending: false
            }
        },
    },

    effects: (dispatch) => ({

        async getProductRenewals(data) {

            try {
                dispatch.renewals.authStart();

                const responseData = await ApiService.getProductRenewals(data);

                checkResponseError(dispatch.renewals, responseData);

                const {periods} = responseData;


                const newPeriods = reduce(periods, (acc, item, index ) => {
                    const list = reduce(item.list, (accList, listItem) => {
                        const renewals = reduce(listItem.renewals, (accRen, renewalItem, indexRenewal) => {

                            const factualCalc =
                                reduce(takeRight(accRen, indexRenewal), (a, {factual}) => a * factual, 1)
                                    * renewalItem.factual
                                    + last(accRen)?.factualCalc || 0;

                            const manualCalc =
                                  reduce(takeRight(accRen, indexRenewal), (a, {manual}) => a * manual, 1)
                                      * renewalItem.manual
                                      + last(accRen)?.manualCalc || 0;

                            accRen.push({
                                ...renewalItem,
                                factualCalc,
                                manualCalc
                            });
                            return accRen;
                        }, []);

                        accList.push({
                            ...listItem,
                            renewals
                        });
                        return accList;
                    }, []);

                    acc.push({
                        ...item,
                        list
                    });
                    return acc;
                }, []);

                dispatch.renewals.updateRenewals({periods: newPeriods});

            } catch (e) {
                console.log('Catch Product Renewals', e);
                dispatch.renewals.updateProducts({error: e.message})
            }
        },

        async postProductRenewals(data, prevState) {
            try {
                const {id, period, ...other} = data;

                dispatch.renewals.authStart();

                const responseData = await ApiService.postProjectProduct(other);

                checkResponseError(dispatch.renewals, responseData);

                if (responseData?.success === true) {

                    if (other?.field === 'conversion_renewal') {

                        const {product_id, value} = other;

                        let {periods} = prevState.renewals;

                        const periodIndex = periods.findIndex((item) => item.period === period);

                        if (periodIndex !== -1) {

                            let periodList = periods[periodIndex].list;

                            let listIndex = periodList.findIndex((item) => item.product_id === product_id);

                            if (listIndex !== -1) {

                                let renewalIndex = periods[periodIndex].list[listIndex].renewals.findIndex(item => item.id === id);

                                if (renewalIndex !== -1) {

                                    periods[periodIndex].list[listIndex].renewals[renewalIndex]['manual'] = value;

                                    dispatch.renewals.updateRenewals({periods});
                                }
                            }
                        }
                    }
                }
            } catch (e) {
                console.log('Catch postProductRenewals', e);
                dispatch.renewals.updateProducts({error: e.message})
            }
        }
    })
};