import React, {Component,} from "react"
import {Provider} from 'react-redux'
import {BrowserRouter as Router, Route, Switch,} from "react-router-dom"
import {ThemeProvider} from '@material-ui/styles'
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import theme from './theme';
import store from './models';
import {DashboardLayout} from "./layouts";
import ruLocale from "date-fns/locale/ru";


/**
 * переключатель для цветов
 *
 * @see https://material-ui.com/customization/components/#theme-nesting
 **/

export default class App extends Component {

    componentDidMount() {
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
    }

    render() {
        return (
            <Provider store={store}>
                 <ThemeProvider theme={theme}>
                     <StyledThemeProvider  theme={theme}>
                         <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ruLocale}>
                                <Router>
                                    <Switch>
                                        <Route path={`/app/:projectPath?/:page?`} component={DashboardLayout}/>
                                        {/*<Route path={`/app/:projectPath?`} component={DashboardLayout}/>*/}
                                        <Route path={`/`} component={DashboardLayout}/>
                                    </Switch>
                                </Router>
                         </MuiPickersUtilsProvider>
                     </StyledThemeProvider>
                 </ThemeProvider>
             </Provider>
        );
    }
}
