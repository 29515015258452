import ApiService from "../services/api";
import {checkResponseError} from './helpers'


export default {
    state: {
        pending: false,
        error: null,
        errorCode: null,

        versions:null,
    },
    reducers: {
        authStart(state) {
            return {
                ...state,
                pending: true
            }
        },
        updateError(state, data) {
            return {
                ...state,
                ...data,
                pending: false
            }
        },
        updateCreateAbTest(state, data){
            return {
                ...state,
                ...data,
                pending: false
            }
        }
    },

    effects: (dispatch) => ({

        async getAbTestNew(data){
            try{
                dispatch.createAbTest.authStart();
                const responseData = await ApiService.getAbTestNew(data);

                checkResponseError(dispatch.createAbTest, responseData);

                dispatch.createAbTest.updateCreateAbTest({versions: responseData.versions});

                return responseData;
            }
            catch (e) {
                console.log('Catch createAbTest', e);
                dispatch.createAbTest.updateCreateAbTest({error: e.message})
            }
        },

        async postAbTestNew(data){
            try{
                dispatch.createAbTest.authStart();

                const responseData = await ApiService.postAbTestNew(data);

                checkResponseError(dispatch.createAbTest, responseData);

                dispatch.createAbTest.updateCreateAbTest();

                return responseData;
            }
            catch (e) {
                console.log('Catch createAbTest', e);
                dispatch.createAbTest.updateCreateAbTest({error: e.message})
            }
        },
    })
};