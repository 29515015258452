import React from "react";
import {connect} from "react-redux";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Popper from "@material-ui/core/Popper";
import Grow from "@material-ui/core/Grow";
import MenuList from "@material-ui/core/MenuList";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";


const ITEM_HEIGHT = 48;

function SelectProductsCheckboxes(props) {

    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);

    const handleClick = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };



    return (
        <div>
            <Button
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={handleClick}
                style={{height: 49, width:'100%'}}
                variant={"outlined"}
                color={'primary'}
                endIcon={<PlaylistAddCheckIcon />}
                ref={anchorRef}
            >
                {props.name}
            </Button>

            <Popper open={open}
                    anchorEl={anchorRef.current}
                    role={undefined}
                    transition
                    disablePortal
                    style={{
                        zIndex:1
                    }}
            >
                {({ TransitionProps }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin: 'right top',
                            anchorOrigin: 'right bottom',
                        }}
                    >
                        <Paper style={{}}>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList

                                    id="long-menu"
                                    onClose={handleClose}
                                        style={{
                                            overflow: "auto",
                                            maxHeight: ITEM_HEIGHT * 4.5,
                                        }}
                                >

                                    {props?.items?.map((product, i) =>
                                         <MenuItem key={i}
                                                   onClick={handleClose}
                                         >
                                             <FormControlLabel onClick={e => e.stopPropagation()}
                                                               control={
                                                                   <Checkbox checked={props.listChecked?.[product.product_id] || false}
                                                                             onChange={props.onChange}
                                                                             name={product.product_id} />
                                                               }
                                                               label={product.product_id}/>
                                         </MenuItem>
                                    )}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </div>
    )
}

// const mapState = state => ({
//     project: state.project,
//     products: state.products,
// });

// const mapDispatch = ({
//                          products: {
//                              getProducts,
//                          },
//                      }) => ({
//     getProducts: getProducts,
// });

export default connect(null, null)(SelectProductsCheckboxes);