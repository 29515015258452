import {round} from "lodash";
import React from "react";
import Tooltip from "@mui/material/Tooltip";
import moment from "moment";
import {get, reduce} from "lodash";
import Stack from "@mui/material/Stack";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Box from "@mui/material/Box";
import {Column} from "../../../components/table";
import CountryFlag from "../../../components/CountryFlag";
import CustomPopper from "../../../components/CustomPopover";

export default [
  {
    align: Column.Alignment.LEFT,
    frozen: Column.FrozenDirection.LEFT,
    dataKey: 'date',
    key: 'date',
    title: 'Date',
    width: 170,
    cellRenderer: ({rowData, column: {filter}}) => {
      if (rowData?.countryCode)
        return (
          <>
            {rowData?.countryCode && <CountryFlag countryCode={rowData?.countryCode} style={{ fontSize:'2em',paddingRight:8 }} />}
            {rowData?.countryCode?.toUpperCase()}
          </>
        );

      const startDate = moment(get(rowData, 'period.start'), 'DD.MM.YYYY HH:mm:ss');
      const endDate = moment(get(rowData, 'period.finish'), 'DD.MM.YYYY HH:mm:ss');
      return (
        <Tooltip
          title={
            filter?.groupBy === 'day'
              ? startDate.format('DD-MM-YYYY')
              : [startDate.format('DD-MM-YYYY'), endDate.format('DD-MM-YYYY')].join(' to ')
          }
          placement="top"
        >
          <span>
            {filter?.groupBy === 'month' && startDate.format('MMMM YYYY')}
            {filter?.groupBy === 'week' && `Week ${startDate.format('W YYYY')}`}
            {filter?.groupBy === 'day' && startDate.format('DD MMMM YYYY')}
          </span>
        </Tooltip>
      )
    }
  },
  {
    dataKey: 'installs',
    key: 'installs',
    title: (
      <Stack direction="row" spacing={1} alignItems="center">
        <Box component="span">Install</Box>
        <Tooltip
          arrow
          title="Кол-во first open за выбранный период."
          placement="top"
        >
          <InfoOutlinedIcon fontSize="small" sx={{'&:hover': {cursor: 'pointer'}}}/>
        </Tooltip>
      </Stack>
    ),
    width: 120,
  },
  {
    dataKey: 'subscribers.new',
    key: 'subscribersNew',
    width: 120,
    title: (
      <Stack direction="row" spacing={1} alignItems="center">
        <Box component="span">Subscribers</Box>
        <Tooltip
          arrow
          title="Уникальное кол-во юзеров, которые иницировали подписку за выбранный период, и пришлю неважно когда, тут берем от старта подписки."
          placement="top"
        >
          <InfoOutlinedIcon fontSize="small" sx={{'&:hover': {cursor: 'pointer'}}}/>
        </Tooltip>
      </Stack>
    ),
    cellRenderer: ({ cellData, rowData}) => {
      return (
        <Stack direction="row" spacing={1} alignItems="center" component={Box} pr={2}>
          {cellData}
          <CustomPopper tooltipText="Show subscriptions products count">
            <Box p={1} component='table'>
              <tbody>
              {reduce(rowData['subscriptions']['new+'], (acc, val, subs) => ([
                ...acc,
                <tr key={subs}>
                  <td>{subs}</td>
                  <td>{val}</td>
                </tr>
              ]), [])}
              </tbody>
            </Box>
          </CustomPopper>
        </Stack>
      )
    }
  },
  {
    dataKey: 'subscribers.active',
    key: 'subscribersActive',
    width: 120,
    title: (
      <Stack direction="row" spacing={1} alignItems="center">
        <Box component="span">Active Subscribers</Box>
        <Tooltip
          arrow
          title="Уникальное кол-во юзеров, которые имеют подписку в активном автопродлении на конец периода (в зависимости от грануляции, день, неделя, месяц и так для каждого)"
          placement="top"
        >
          <InfoOutlinedIcon fontSize="small" sx={{'&:hover': {cursor: 'pointer'}}}/>
        </Tooltip>
      </Stack>
    ),
    cellRenderer: ({cellData}) => cellData.toLocaleString('en-En')
  },
  {
    dataKey: 'spend',
    key: 'spend',
    width: 120,
    title: (
      <Stack direction="row" spacing={1} alignItems="center">
        <Box component="span">Cost</Box>
        <Tooltip
          arrow
          title={
            'Спенд за период'
          }
          placement="top"
        >
          <InfoOutlinedIcon fontSize="small" sx={{'&:hover': {cursor: 'pointer'}}}/>
        </Tooltip>
      </Stack>
    ),
    cellRenderer: ({cellData}) => `$ ${cellData.toLocaleString('en-En')}`
  },
  {
    align: Column.Alignment.RIGHT,
    dataKey: 'revenue',
    key: 'revenue',
    width: 120,
    title: (
      <Stack direction="row" spacing={1} alignItems="center">
        <Box component="span">Revenue</Box>
        <Tooltip
          arrow
          title="Фактические деньги уже за вычетом комиссии Apple которые пришли за этот период (и первые платежи и продления старых)"
          placement="top"
        >
          <InfoOutlinedIcon fontSize="small" sx={{'&:hover': {cursor: 'pointer'}}}/>
        </Tooltip>
      </Stack>
    ),
    cellRenderer: ({cellData, rowData}) => {
      return (
        <Stack direction="row" spacing={1} alignItems="center" component={Box} pr={2}>
          {`$ ${cellData.toLocaleString('en-En')}`}
          <CustomPopper tooltipText="Show revenue products count">
            <Box p={1} component='table'>
              <tbody>
              {reduce(rowData['revenue+'], (acc, val, subs) => ([
                ...acc,
                <tr key={subs}>
                  <td>{subs}</td>
                  <td>{val}</td>
                </tr>
              ]), [])}
              </tbody>
            </Box>
          </CustomPopper>
        </Stack>
      )
    }
  },
  {
    dataKey: 'profit',
    key: 'profit',
    width: 120,
    title: (
      <Stack direction="row" spacing={1} alignItems="center">
        <Box component="span">Profit</Box>
        <Tooltip
          arrow
          title="Revenue - Cost"
          placement="top"
        >
          <InfoOutlinedIcon fontSize="small" sx={{'&:hover': {cursor: 'pointer'}}}/>
        </Tooltip>
      </Stack>
    ),
    cellRenderer: ({cellData, rowData}) =>
      `$ ${round(rowData.revenue - rowData.spend, 2)?.toLocaleString('en-En')}`
  },
  {
    dataKey: 'cpa',
    key: 'cpa',
    width: 120,
    title: (
      <Stack direction="row" spacing={1} alignItems="center">
        <Box component="span">CPA</Box>
        <Tooltip
          arrow
          title={
            'Cost / Subscribers'
          }
          placement="top"
        >
          <InfoOutlinedIcon fontSize="small" sx={{'&:hover': {cursor: 'pointer'}}}/>
        </Tooltip>
      </Stack>
    ),
    cellRenderer: ({cellData}) => `$ ${cellData.toLocaleString('en-En')}`
  },
  {
    dataKey: 'ltv',
    key: 'ltv',
    width: 120,
    title: (
      <Stack direction="row" spacing={1} alignItems="center">
        <Box component="span">LTV</Box>
        <Tooltip
          arrow
          title="От first open берем и их фактические данные по деньгам и делим на кол-во уникальных"
          placement="top"
        >
          <InfoOutlinedIcon fontSize="small" sx={{'&:hover': {cursor: 'pointer'}}}/>
        </Tooltip>
      </Stack>
    ),
    cellRenderer: ({cellData}) => `$ ${cellData.toLocaleString('en-En')}`
  },
  {
    dataKey: 'roi',
    key: 'roi',
    width: 120,
    title: (
      <Stack direction="row" spacing={1} alignItems="center">
        <Box component="span">ROI</Box>
        <Tooltip
          arrow
          title="Берем все деньги за период и делим на спенд за период"
          placement="top"
        >
          <InfoOutlinedIcon fontSize="small" sx={{'&:hover': {cursor: 'pointer'}}}/>
        </Tooltip>
      </Stack>
    ),
    cellRenderer: ({cellData}) => `${cellData.toLocaleString('en-En')} %`
  },
]