import React from "react";
import {Paper, Box} from "@material-ui/core";
import {NavLink} from "react-router-dom";
import { useSelector } from "react-redux";


function HomePage(){
    const projects = useSelector(state => state.projects);

    return(
        <React.Fragment>
            <Box display="flex" flexWrap="wrap"  >
                {(projects.projects||[]).map(({id, name, path}) =>
                    <NavLink to={`/app/${path}/daily/`} key={id}>
                        <Box flexGrow={1}
                             p={3}
                             m={1}
                             width={180}
                             component={Paper} >
                            {name}
                        </Box>
                    </NavLink>
                )}
            </Box>
        </React.Fragment>
    )
}

export default HomePage;