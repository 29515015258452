import React, {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import {useDispatch, useSelector} from "react-redux";
import moment from "../../core/moment";
import tableColumns from './utils/columns'
import BasicDateRangePicker from "../../components/BasicDateRangePicker";
import Stack from "@mui/material/Stack";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import {useHistory, useWindowSize} from "../../hooks";
import FilterColumns from "./components/FilterColumns";
import {compact, reduce, uniq, get, round} from "lodash";
import {useQuery} from "../../services/helpers";
import Typography from "@mui/material/Typography";
import component from "../../views/calculator/TrialReportCompoment";
import Table, {Column} from "react-base-table";
import theme from "../../theme";
import {AutoSizer} from "react-virtualized";
import CountryFlag from "../../components/CountryFlag";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Chip from "@mui/material/Chip";
import Popper from "@mui/material/Popper";
import Tooltip from "@mui/material/Tooltip";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";

const COHORTS_VISIBLE_COLUMNS = 'cohorts_hidden_columns';

const DROPDOWN_VALUES = [
  {
    value: '6m1d',
    name: '180'
  },
  {
    value: '1y1d',
    name: '365'
  },
  {
    value: 'all',
    name: 'All'
  }
];

const styles = (theme) => ({
  popper: {
    maxWidth: "fit-content"
  }
});

const PopperMy = function (props) {
  return <Popper {...props} style={styles.popper} placement="bottom-start"/>;
};

const CohortsDashPage = () => {
  const dispatch = useDispatch();
  const [, height] = useWindowSize();
  const query = useQuery();
  const history = useHistory();
  const {active} = useSelector(state => state.project);

  const [isOpenCountries, setIsOpenCountries] = useState(false);
  const [, setCountries] = useState([]);
  const [sortState, setSortState] = useState({});

  const savedHiddenColumns = query.get('columns')
    ? query.get('columns').split(',')
    : JSON.parse(localStorage.getItem(COHORTS_VISIBLE_COLUMNS)) || [];
  const [hiddenColumns, setHiddenColumns] = useState(savedHiddenColumns);

  const calculator = useSelector(state => state.calculator);
  const {data: calculatorData, pending: countriesPending} = calculator;

  const projectId = useSelector(state => state.project?.active?.id);
  const {pending, data} = useSelector(state => state.cohortsDash);


  const customSort = fields => {
    return (a, b) => {
      return Object.keys(fields)
        .map(field => {
          const valueA = get(a, field);
          const valueB = get(b, field);

          if (field === 'date' && Date.parse(valueB) && Date.parse(valueA)) {
            if (fields[field] === 'desc')
              return Date.parse(valueB) - Date.parse(valueA);
            if (fields[field] === 'asc')
              return Date.parse(valueA) - Date.parse(valueB);
          } else if (field === 'date') {
            if (fields[field] === 'asc')
              return valueA?.localeCompare(valueB);
            if (fields[field] === 'desc')
              return valueB?.localeCompare(valueA);
          } else if (fields[field] === 'desc') {
            return valueB - valueA;
          } else if (fields[field] === 'asc') {
            return valueA - valueB;
          }
          return 0;
        })
        .reduce((p, n) => p ? p : n, 0);
    }
  };

  const [dropdown, setDropdown] = useState(query.get('dropdown') || '1y1d');

  const dateBefore = moment().toDate();
  const dateAfter = moment().subtract(2, 'weeks').toDate();
  const country = uniq(compact(query.get('country[]')?.split(',') || []));

  const [filter, setFilter] = useState({
    dateAfter: query.get('date_after') ? moment(query.get('date_after')).toDate() : dateAfter,
    dateBefore: query.get('date_before') ? moment(query.get('date_before')).toDate() : dateBefore,
    groupBy: query.get('group_by') || 'day',
    dropdown: query.get('dropdown') || '1y1d',
    country
  });

  const tableData = reduce(data, (acc, item) => {
    const predictiveRevenue = get(item, `predictiveRevenue.${dropdown}`);
    return ([
      ...acc,
      {
        ...item,
        id: item.date,
        predictiveRevenue,
        netProfitFact: round(get(item, 'revenue', 0) - get(item, 'spend', 0)),
        netProfitPredict: round(get(item, `predictiveRevenue.${dropdown}`, 0) - get(item, 'spend', 0)),
        predictiveRoi: round(get(item, `predictiveRoi.${dropdown}`) * 100, 2),
        ltv: get(item, `predictiveLtv.${dropdown}`),
        ltvTrial:
          get(item, 'trials.total') !== 0
            ? round(predictiveRevenue / get(item, 'trials.total'), 2)
            : null,
        children: reduce(item.countries, (accCountry, country, countryCode) => {
          const predictiveRevenue = get(country, `predictiveRevenue.${dropdown}`);
          return ([
            ...accCountry,
            {
              ...country,
              countryCode,
              parentId: item.date,
              id: `${item.date}-${countryCode}`,
              date: countryCode,
              predictiveRevenue,
              netProfitFact: round(get(country, 'revenue', 0) - get(country, 'spend', 0)),
              netProfitPredict: round(get(country, `predictiveRevenue.${dropdown}`, 0) - get(country, 'spend', 0)),
              predictiveRoi: round(get(country, `predictiveRoi.${dropdown}`) * 100, 2),
              ltv: get(country, `predictiveLtv.${dropdown}`),
              ltvTrial:
                get(country, 'trials.total') !== 0
                  ? round(predictiveRevenue / get(country, 'trials.total'), 2)
                  : null,
            }
          ])
        }, [])
          .sort(customSort(sortState))
      }
    ])
  }, [])
    .sort(customSort(sortState));

  console.log('tableData', { tableData });

  const handleChange = (event, value) => {
    setFilter(prev => ({
      ...prev,
      country: reduce(uniq(value), (acc, item) => ([...acc, item.value]), [])
    }))
  };


  const updateLocation = () => {
    history.push({
      search: '?' + compact([
        filter.groupBy && `groupBy=${filter.groupBy}`,
        filter.dateAfter && `date_after=${moment(filter.dateAfter)?.format('YYYY-MM-DD')}`,
        filter.dateBefore && `date_before=${moment(filter.dateBefore)?.format('YYYY-MM-DD')}`,
        dropdown && `dropdown=${dropdown}`,
        filter.country && `country[]=${filter.country.join(',')}`,
        hiddenColumns.length > 0 && `columns=${hiddenColumns.join(',')}`
      ]).join('&')
    });
  };

  const handleChangeData = ({startDate, endDate}) => {
    setFilter(prev => ({
      ...prev,
      dateAfter: startDate,
      dateBefore: endDate,
    }))
  };

  const handleGroupBy = event => {
    setFilter(prev => ({
      ...prev,
      groupBy: event.target.value,
    }))
  };

  const handleDropdown = event => {
    setDropdown(event.target.value);
  };

  const onColumnSort = ({key, order}) => {
    // setSortState(prev => ({
    //   // clear the sort state if the previous order is desc
    //   ...prev,
    //   [key]: prev[key] === SortOrder.DESC ? null : order,
    // }))

    setSortState(prev => ({
      [key]: order
    }))
  };

  useEffect(() => {
    if (filter.dateAfter && filter.dateBefore && projectId && !isOpenCountries) {
      const {country, ...otherFilterProps} = filter;
      dispatch({
        type: 'cohortsDash/getData',
        payload: {
          projectId,
          ...otherFilterProps,
          dateAfter: moment(filter.dateAfter)?.format('YYYY-MM-DD'),
          dateBefore: moment(filter.dateBefore)?.format('YYYY-MM-DD'),
          ...(compact(country)?.length > 0 && {country: compact(country)})
        }
      });
    }
    // eslint-disable-next-line
  }, [active, filter, projectId, isOpenCountries]);


  useEffect(() => {
    const country = compact(query.get('country[]')?.split(',') || []);
    const _countries = reduce(uniq(country), (acc, value) => ([
      ...acc,
      {
        value
      }
    ]), []);

    setCountries(_countries)
    // eslint-disable-next-line
  }, []);

  // eslint-disable-next-line
  useEffect(updateLocation, [filter, dropdown, hiddenColumns]);

  useEffect(() => {
    localStorage.setItem(COHORTS_VISIBLE_COLUMNS, JSON.stringify(hiddenColumns));
  }, [hiddenColumns]);

  const filteredColumns = tableColumns.filter(column => !hiddenColumns.includes(column.dataKey));

  return (
    <Box>
      <Stack direction="row" justifyContent="space-between" mb={2}>
        <Stack direction="row" component={Box} spacing={1} alignItems="flex-end">
          <Stack>
            <Typography>Period</Typography>
            <BasicDateRangePicker
              dateStart={filter.dateAfter}
              dateEnd={filter.dateBefore}
              onChange={handleChangeData}
            />
          </Stack>
          <Stack>
            <Typography>Group by</Typography>
            <Select size="small" defaultValue={filter.groupBy} onChange={handleGroupBy}>
              <MenuItem value="day">Day</MenuItem>
              <MenuItem value="week">Week</MenuItem>
              <MenuItem value="month">Month</MenuItem>
              <MenuItem value="line">Line</MenuItem>
            </Select>
          </Stack>

          <Stack>
            <Typography>Pred.LTV(days)</Typography>
            <Select
              size="small"
              value={dropdown}
              onChange={handleDropdown}
            >
              {DROPDOWN_VALUES.map(({value, name}) =>
                <MenuItem key={name} value={value}>{name}</MenuItem>
              )}
            </Select>
          </Stack>

          <Stack>
            <Typography>Country</Typography>
            <Autocomplete
              size="small"
              disableClearable
              disableCloseOnSelect
              multiple
              fullWidth
              disabled={countriesPending}
              onChange={handleChange}
              filterSelectedOptions
              getOptionSelected={(option, value) => option.value === value}
              getOptionValue={(option) => option.value}
              value={reduce(country, (acc, item) => ([...acc, {value: item}]), [])}
              onOpen={() => setIsOpenCountries(prev => !prev)}
              onClose={() => setIsOpenCountries(prev => !prev)}
              options={
                (calculatorData?.countries || [])
                  .sort((a, b) => {
                    if (b.value === '??' || b.value === '*') return -1;
                    if (a.value === '??' || a.value === '*') return 1;
                    return -b?.name?.localeCompare(a?.name)
                  })
              }
              sx={{width: 200}}
              PopperComponent={PopperMy}
              renderOption={(props, option, {selected}) => {
                return <Stack direction='row' spacing={0.5} {...props} component={Box} px={1} py={0.5}>
                  <CountryFlag countryCode={option.value} style={{fontSize: '1em'}}/>
                  <Typography>{option.name}</Typography>
                </Stack>
              }}
              getOptionLabel={(option) => option?.name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  fullWidth
                  InputProps={{
                    ...params.InputProps
                  }}
                  sx={{
                    overflow: 'hidden',
                    flexFlow: 'nowrap',
                    textOverflow: 'ellipsis'
                  }}
                  {...(countriesPending && {
                    placeholder: 'Loading...'
                  })}
                />
              )}
              limitTags={1}
              getLimitTagsText={more => `+${more}`}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Tooltip title={option.name}>
                    <Chip
                      size="small"
                      variant="outlined"
                      sx={{
                        alignItems: 'center',
                        display: 'flex'
                      }}
                      label={

                        <CountryFlag countryCode={option.value} style={{fontSize: '1em'}}/>
                      }
                      {...getTagProps({index})} />
                  </Tooltip>
                ))
              }
            />
          </Stack>

          <Button
            sx={{padding: 1}}
            onClick={() => setSortState({})}>
            Clear Column Order
          </Button>

          {pending && <Box py={1}>Loading...</Box>}
        </Stack>

        <Box
          display="flex"
          alignItems="flex-end"
        >
          <FilterColumns
            tableColumns={tableColumns}
            columns={hiddenColumns}
            setColumns={setHiddenColumns}
          />
        </Box>
      </Stack>

      <AutoSizer style={{padding: theme.spacing(3, 0)}}>
        {({width}) => (
          <Table fixed
                 style={{
                   position: 'absolute',
                 }}
                 disabled={pending}
                 overlayRenderer={
                   pending && (
                     <Box
                       width='100%'
                       height='100%'
                       display='flex'
                       justifyContent='center'
                       alignItems='center'
                       sx={{
                         bgcolor: 'rgba(0,0,0,0.44)'
                       }}
                     >
                       <CircularProgress color="inherit"/>
                     </Box>
                   )
                 }
                 ignoreFunctionInColumnCompare={true}
                 expandColumnKey='date'
                 width={width}
                 height={height - 200}
                 rowHeight={35}
                 data={tableData}
                 components={component}
                 sortState={sortState}
                 onColumnSort={onColumnSort}
          >
            {filteredColumns.map((column, index) => (
              <Column
                key={`${column.dataKey}-${index}`}
                {...column}
                filter={{...filter, dropdown}}
                sortable
              />
            ))}
          </Table>
        )}
      </AutoSizer>
    </Box>
  )
};

export default CohortsDashPage;