import {round, calcFields, saveDivide} from './../helpers'
import ApiService from "../../services/api";
import {checkResponseError} from "../helpers";
import {nanoid} from "nanoid";


export default {
    state: {
        pending: false,
        error: null,
        errorCode: null,

        list: null,
        segments: null,
        letters: null,
    },

    reducers:{
        authStart(state) {
            return {
                ...state,
                pending: true
            }
        },
        updateError(state, data) {
            return {
                ...state,
                ...data,
                pending: false
            }
        },
        updateAbTests(state, data){
            return {
                ...state,
                ...data,
                pending: data?.pending || false
            }
        }
    },

    effects: (dispatch) => ({

        async setSegments(letters) {

            const segments = Object.keys(letters);


            dispatch.abTestsReport.updateAbTests({
                segments,
                list: null,
                letters
            })
        },

        async getReportForSegments(_, state){
            try{
                let segments = state.abTestsReport?.segments || [];
                let letters = state.abTestsReport?.letters || [];
                let list = state.abTestsReport?.list || [];

                if (segments.length === 0) {
                    dispatch.abTestsReport.updateAbTests({
                        segments: null,
                        list: null,
                        letters: null
                    })
                } else
                    segments.forEach((letter) => {

                    const versionId = letters[letter]?.id;

                    ApiService.getABProjectVersion({versionId})
                        .then((responseData) => {
                            checkResponseError(dispatch.abTestsReport, responseData);

                            const letterIndex = segments.findIndex(item => item === letter);

                            if (letterIndex !== -1){
                                segments.splice(letterIndex, 1);
                            }

                            let {count} = responseData;

                            let total = 0;


                            let totalDetails = {
                                lifetime: "-",
                                price: 0,
                                product: {id: "", period: "", trials: 0},
                                product_id: "-",
                                purchases: "-",
                                trials: "-",
                                type: "-",
                                userLTV: "-",
                                c1:'-',
                                c2:'-',
                                paidCVR:'-',

                                sumPriceToPaid:0,
                                sumTrials:0,
                                sumPurchases:0,
                            };

                            let details = responseData.details.reduce((prevDetailValue, currentDetail) => {
                                const {product_id, price, trials, purchases, lifetime, c1, c2, paidCVR, userLTV} = calcFields(count.users, currentDetail);

                                total += userLTV;

                                totalDetails.sumTrials      += trials ?? 0;
                                totalDetails.sumPurchases   += purchases ?? 0;
                                totalDetails.sumPriceToPaid += (price * purchases);

                                prevDetailValue.push({
                                    ...currentDetail, ...{
                                        product_id,
                                        price,
                                        trials,
                                        purchases,
                                        lifetime,
                                        c1,
                                        c2,
                                        paidCVR,
                                        userLTV
                                    }
                                });
                                return prevDetailValue;
                            }, []);

                            if (details.length === 0){
                                details.push({
                                    lifetime: "-",
                                    price: 0,
                                    product: {id: "", period: "", trials: 0},
                                    product_id: "-",
                                    purchases: "-",
                                    trials: "-",
                                    type: "-",
                                    userLTV: "-",
                                    c1:'-',
                                    c2:'-',
                                    paidCVR:'-',
                                })
                            }else {
                                totalDetails.c1    = round(saveDivide(totalDetails.sumTrials, count.users) * 100, 2);
                                totalDetails.c2    = round(saveDivide(totalDetails.sumPurchases, totalDetails.sumTrials) * 100, 2);
                                totalDetails.price = round(saveDivide(totalDetails.sumPriceToPaid, totalDetails.sumPurchases), 2);

                                totalDetails.trials    = round(totalDetails.sumTrials, 2);
                                totalDetails.purchases = round(totalDetails.sumPurchases, 2);
                                totalDetails.paidCVR   = round(saveDivide(totalDetails.sumPurchases, count.users) * 100, 2);
                                totalDetails.userLTV   = round(total, 2);

                                // add total row
                                details.push(totalDetails);
                            }

                            responseData.details = details;

                            responseData['segment'] = letter;
                            responseData['users'] = count.users;

                            responseData['total'] = round(total, 2);
                            responseData['uid']   = nanoid();

                            list.push(responseData);

                            if (segments.length === 0)
                                segments = null;

                            dispatch.abTestsReport.updateAbTests({
                                segments,
                                list,
                                pending: segments?.length !== 0,
                            });
                        })
                });
            }
            catch (e) {
                console.log('Catch projects', e);
                dispatch.abTestsReport.updateAbTests({error: e.message})
            }
        }
    })
}