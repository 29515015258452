import React, {useCallback, useEffect, useRef, useState} from "react";
import {Box} from "@mui/material";
import useWindowSize from "../../hooks/useWindowSize";
import {connect, useSelector} from "react-redux";
import {makeStyles} from "@material-ui/styles";
import UploadSpendFromFile from "./UploadSpendFromFile"
import {round, saveDivide} from "../../models/helpers";
import clsx from "clsx";
import {ScrollSync, ScrollSyncPane} from "react-scroll-sync";
import Header from "./ScrollTable/Header";
import InlineEdit from "../../components/InlineEdit";
import BaseTable, {AutoResizer} from 'react-base-table';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import 'react-base-table/styles.css';
import Popover from "@material-ui/core/Popover";
import Loader from "../../components/table/loader";
import {sum} from "lodash";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import Button from "@mui/material/Button";


export const HEADER_HEIGHT = 100;
export const LEFT_COLUMN_WIDTH = 100;
export const COLUMN_WIDTH = 90;
export const ROW_HEIGHT = 20;
export const HEADER_ROW_HEIGHT = 30;
export const ROWS_SEPARATE_HEIGHT = 21;
export const OVERSCAN_ROW_COUNT = 2;
export const OVERSCAN_COLUMN_COUNT = 10;
export const PROFIT_COLUMNS_COUNT = 20;
export const PROFIT_TRIAL_SECTION_COLUMNS_COUNT = 8;
export const PROFIT_SECTION_COLUMNS_COUNT = 11;

export const MIN_TABLE_CONTAINER_HEIGHT = 500;
export const BORDER_SIZE = 1;


export const DEFAULT_SPEND_GROUP = ['sum'];
export const ALL_SPEND_GROUP = [
  'facebook',
  'ad_networks',
  'unity',
  'google',
  'snap_chat',
  'search_ads',
  'twitter',
  'tik_tok',
  'other',
  'sum'
];

const columns = [{
  "key": "column-0",
  "dataKey": "column-0",
  "title": "Column 0",
  "width": 150,
  "frozen": "left"
}];


export const CURRENT_PROFIT_COLUMNS = [
  'First Open',
  'Count Renewable',
  <>
    <div title="
            Sub=trials.converted + subscriptions.count_primary;
            Pur=payments.count"
    >
      Sub/ Pur/ Total
    </div>
  </>,
  <div title="
        Trial = trials.count;
        Conv. = trials.converted;
        % = subscriptions.conversions_rate
    ">Trial/ Conv./ %</div>,

  // 'Sub+Pur (Unique)',
  <div title="payed_2">CAC count</div>,
  <div title="payed_1">P.Users count</div>,
  <div title="trials.in_trial">Tr.in Trial</div>,
  <div title="trials.in_billing">Tr.in Billing</div>,
  <div title="sum / payed_2">CAC</div>,
  <div title="sum / payed_1">P.Users</div>,
  <div title="subscriptions.cost">Tr.cost</div>,
  <div title="подписки без триала,
        подписки с триалом,
        которые конвертнулись уже
        + инаппы
        (все это уникальное кол-во юзеров, выражается в проценте от всех юзеров)
    ">
    Subrate %
  </div>,
  <div title="подписки с триалом (все это уникальное кол-во юзеров, выражается в проценте от всех юзеров)">
    in Trial %
  </div>,
  <div title="revenue.value">Revenue</div>,
  <div title="profit.value">Profit</div>,
  'ROMI'
];
export const PREDICTIVE_6M_PROFIT_COLUMNS = ['Revenue', 'Profit', 'ROMI', 'CAC / LTV', 'CPI / ARPU'];
export const PREDICTIVE_1Y_PROFIT_COLUMNS = ['Revenue', 'Profit', 'ROMI', 'CAC / LTV', 'CPI / ARPU'];
export const PREDICTIVE_2Y_PROFIT_COLUMNS = ['Revenue', 'Profit', 'ROMI', 'CAC / LTV', 'CPI / ARPU'];
export const PREDICTIVE_2Y_PLUS_PROFIT_COLUMNS = ['Revenue', 'Profit', 'ROMI', 'CAC / LTV', 'CPI / ARPU'];
export const PREDICTIVE_MAX_PROFIT_COLUMNS = ['Revenue', 'Profit', 'ROMI', 'CAC / LTV', 'CPI / ARPU'];
export const ITUNES_COLUMNS = ['Revenue', 'Profit', 'Profit %'];
export const COST_PER_PURCHASE_COLUMNS = ['Cost per purchase', 'Purchases count', 'Subscribes count'];
export const TRIAL_SECTION_COLUMNS = [
  'Cost trial',
  // 'Trial count',
  // 'Conversion count',
  'Conversion trial/purchase',
  'Converted', 'Count', 'In Billing', 'In Trial'
];


export const PROFIT_HEADER_COLUMNS = [
  'First Open',
  'Subrate',
  'Revenue',
  'Profit',
  'ROI',


  'CAC',
  'Predictive 6m',
  'ROMI 6m',
  'LTV_6m',
  'Predictive 2y+',
  'ROMI 2y+',
  'LTV_2y_plus',


  'Predictive revenue',
  'Predictive profit',
  'Predictive ROI',
  'Revenue (iTunes)',
  'Profit (iTunes)',
  'Profit (iTunes) %',
  'Cost per purchase',
  'Purchases count',
  'Subscribes count',
  'Cost trial',
  'Trial count',
  'Conversion count',
  'Conversion trial/purchase'
];

export const COLUMN_TIPS = {
  'Revenue': 'Сумма денег со всех покупок, подписок на текущий момент, без учета будущих продлений и будущих покупок (будущие покупки мы тоже будем предиктить, но попозже).',
  'Profit': 'Сумма всех расходов за сутки (отрицательное значение) плюс ревенью.',
  'ROI': '',
  'Predictive revenue': 'Сумма всех покупок и подписок с учетом продления',
  'Predictive profit': 'Сумма всех расходов за сутки (отрицательное значение) плюс предиктив ревенью.',
  'Predictive ROI': '',
  'Revenue (iTunes)': '',
  'Profit (iTunes)': 'Total spend + Revenue iTunes',
  'Profit (iTunes) % ': '',
  'Cost per purchase': 'Total spend / (Purchase count + Subscriptions cont)',
  'Purchases count': 'Сумма всех инапов по когорте за этот день',
  'Subscribes count': 'Сумма всех подписок за этот день',
  'Cost trial': 'Цена триалки. Тотал спенд / триал каунт',
  'Trial count': 'Сумма всех триалок, которые пришли по этой когорте',
  'Conversion count': 'Количество конверсий из триала, которые пришли по этой когорте',
  'Conversion trial/purchase': 'конвершн каунт / триал каунт',
};

export const SPEND_ALIASES = {
  facebook: 'Facebook',
  ad_networks: 'AdNetworks',
  unity: 'Unity',
  google: 'Google',
  snap_chat: 'SnapChat',
  search_ads: 'Search ads',
  total: 'Total',
  sum: 'Sum',
  other: 'Other',
  twitter: 'Twitter',
  tik_tok: 'Tik Tok',
};

export const WEEK_ROWS_STYLE = {
  display: 'grid',
  textAlign: ' end',
  alignItems: 'center',
  paddingRight: 8,
  borderBottom: `${BORDER_SIZE}px solid #c0c0c063`,
  marginBottom: -BORDER_SIZE,
};

const styles = makeStyles(theme => ({
  root: {
    fontSize: 10,
    borderRadius: 7,
    boxShadow: 'rgba(0, 0, 0, 0.1) 3px 3px 12px',
  },
  header: {
    borderBottom: `${BORDER_SIZE}px solid #c0c0c063`,
  }
}));

const tableHeaderSpend = [
  {name: 'Total spend:', field: 'total_spend'},
  {name: 'Total rev:', field: 'total_revenue'},
  {name: 'Total profit:', field: 'total_profit'},
  {name: 'Total predictive rev:', field: 'total_predictive_manual'}
];

const getLocalValue = value => value?.toLocaleString('en-US');
const currencyBgColor = value => {
  if (value < 0) return 'negative-bg';
  // if (value < 700) return 'neutral-bg';
  return 'positive-bg';
};

const percentBgColor = value => {
  if (value < 0) return 'negative-bg';
  if (value < 30) return 'neutral-bg';
  return 'positive-bg';
};

const Report = props => {
  const classes = styles();

  const reports = useSelector(state => state.reports);
  const project = useSelector(state => state.project);
  const {active} = useSelector(state => state.project);

  const boxContainer = useRef(null);
  const bodyRef = useRef(null);
  const tmpRef = useRef(null);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const wrapperRef = useRef(null);
  const project_id = useSelector(state => state.project?.active?.id);
  const {text, pending} = useSelector(state => state.predictiveDebug);

  const [_width, _height] = useWindowSize();
  const maxHeight = (_height < MIN_TABLE_CONTAINER_HEIGHT ? MIN_TABLE_CONTAINER_HEIGHT : _height) - (boxContainer?.current?.getBoundingClientRect()?.top || 100) - 20;
  let sumPaddingRightLeft = LEFT_COLUMN_WIDTH;
  if (boxContainer?.current?.getBoundingClientRect()) {
    const {left, right, width} = boxContainer?.current?.getBoundingClientRect();
    const paddingRight = (right - width) || 0;
    sumPaddingRightLeft = LEFT_COLUMN_WIDTH + ((left + paddingRight) || 0);
  }

  const [spends, setSpends] = useState(DEFAULT_SPEND_GROUP);
  const handleShowHideSpend = useCallback(() => {
    if (ALL_SPEND_GROUP.length !== spends.length) {
      setSpends(ALL_SPEND_GROUP);
    } else {
      setSpends(DEFAULT_SPEND_GROUP);
    }
  }, [spends]);

  const countSubscriptionColumns = reports.uniqueSubscriptions.reduce((p, c) => p + c.columnCount, 0);
  const countPaymentColumns = reports.uniquePayments.length;

  useEffect(() => {
    if (reports.summary !== null && reports.data === null && reports.pending === false && project?.active?.id && project.pending === false && reports.error === null) {
      props.getReports({projectId: project.active.id, limit: 5});
    }
    // eslint-disable-next-line
  }, [project, reports, active]);

  useEffect(() => {
    if (reports.summary === null && reports.pending === false && project?.active?.id && project.pending === false && reports.error === null) {
      props.getProjectReportSummary({projectId: project.active.id})
    }
    // eslint-disable-next-line
  }, [project, reports]);

  useEffect(() => {
    if (tmpRef?.current === null && bodyRef !== null && reports.data !== null && reports.revertData === true) {
      tmpRef.current = 'ddd';
      // eslint-disable-next-line
      bodyRef?.current?.querySelector(':scope > div:last-child')?.scrollIntoView();
    }
    // eslint-disable-next-line
  }, [reports]);

// const onScroll = (e) => {
//     const {bottom} = bodyRef?.current?.getBoundingClientRect() || {bottom: null};
//     const {top: topFirst} =
//     bodyRef?.current?.querySelector(':scope > div:first-child')?.getBoundingClientRect() || {top: null};
//     const {bottom: bottomLast} =
//     bodyRef?.current?.querySelector(':scope > div:last-child')?.getBoundingClientRect() || {bottom: null};
//
//     if (topFirst !== null && reports.revertData === true && reports.pending === false && topFirst > 0) {
//         props.getReports({projectId: project.active.id, offset: reports.data.length});
//     } else if (bottom !== null && reports.revertData === false && reports.pending === false && (bottomLast - bottom) < 200) {
//         props.getReports({projectId: project.active.id, offset: reports.data.length});
//     }
// };

  const handleLoadMore = e => {
    props.getReports({
      projectId: project.active.id,
      offset: reports.data.length,
      limit: 5
    });
  };

  const onSync = (el) => {
    // console.log('getBoundingClientRect', el.querySelector(':scope > div:last-child'));
  };

  const _handleUpdateSpendValue = useCallback((date, source, value, weekDayIndex, rowIndex) => {
    props.postProjectReportsSpend({
      projectId: active.id,
      date,
      source,
      value,
      weekDayIndex,
      rowIndex
    })
  }, [props, active]);

  const _handleUpdateRevenueValue = useCallback((date, value, weekDayIndex, rowIndex) => {
    props.postProjectReportsRevenue({
      projectId: active.id,
      date,
      value,
      weekDayIndex,
      rowIndex
    })
  }, [props, active]);

  const handleClick = ({first_open_date, project_id, period}) => ({currentTarget}) => {
    // console.log('handleClick', { first_open_date, project_id, currentTarget });
    props.getPredictiveDebug({first_open_date, project_id, period});

    setAnchorEl(anchorEl ? null : currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'warnings-popper' : undefined;

  return (
    <>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        {pending && <Loader loading={true}/>}
        <Box p={3}>
          <pre>{text}</pre>
        </Box>
      </Popover>

      <Box
        ref={boxContainer}
        height={'100%'}
        width={'100%'}
        className={classes.root}
      >
        <Box
          style={{height: 50, background: '#F0F3F9', borderRadius: '7px 7px 0px 0px'}}
          display="flex"
          px={3}
          justifyContent="space-between"
          mb={1}
        >
          <Box width="100%" display="flex" flexWrap="wrap">
            {tableHeaderSpend.map(({name, field}) =>
              <Box my={'auto'} pr={3} alignContent="flex-start" key={field}>
                <Box variant={'body2'} color={'#7D889A'}>{name}</Box>
                <Box>$&nbsp;{getLocalValue(reports?.summary?.[field]) || 'Loading...'}</Box>
              </Box>
            )}
          </Box>
          <Box flexShrink={0} display={'flex'} py={1}>
            <UploadSpendFromFile/>
          </Box>
        </Box>

        <ScrollSync onSync={onSync}>
          <React.Fragment>

            <Backdrop
              sx={{
                color: '#fff',
                zIndex: 1
              }}
              open={reports.pending}
            >
              <CircularProgress color="inherit"/>
            </Backdrop>

            <Box display="flex" height={HEADER_HEIGHT} className={classes.header}>
              <Box flexShrink={0} width={LEFT_COLUMN_WIDTH} display={'flex'} justifyContent="center"
                   alignItems="center">Date</Box>
              <ScrollSyncPane group="horizontal">
                <Box width={(bodyRef.current?.width || _width) - sumPaddingRightLeft + 55}
                     style={{overflow: 'hidden', marginRight: 15}} display={'flex'}>
                  <Header {...props} spends={spends} handleShowHideSpend={handleShowHideSpend}/>
                </Box>
              </ScrollSyncPane>
            </Box>

            <Box display="flex" height={maxHeight - HEADER_HEIGHT - 50}>
              {/* Render Left Column  */}
              <ScrollSyncPane group="vertical">
                <Box
                  flexShrink={0}
                  width={LEFT_COLUMN_WIDTH}
                  sx={{
                    display: 'block',
                    overflow: 'auto',
                    overflowY: 'hidden',
                    height: 'calc(100% - 16px)',
                    // '&>:first-of-type': {
                    //     marginTop: '48px'
                    // },
                    // '&>:last-of-type': {
                    //     marginBottom: '100vh'
                    // }
                  }}
                  // onScroll={onScroll}
                >
                  <Box height="30px"/>
                  {reports?.data?.map(({dates, id}) =>
                    <div key={`week-${id}`}
                         style={{width: '100%', paddingBottom: `${ROWS_SEPARATE_HEIGHT}px`,}}>
                      <table className={'daily-table'}>
                        <tbody>
                        {dates.map((date) =>
                          <tr key={`date-${date}`} className={'border-bottom'}
                              style={{height: ROW_HEIGHT}}>
                            <td>
                              {date}
                            </td>
                          </tr>
                        )}
                        </tbody>
                      </table>
                    </div>
                  )}
                </Box>
              </ScrollSyncPane>

              {/* Render Body Cells  */}
              <ScrollSyncPane group={["horizontal", "vertical"]}>
                <Box ref={bodyRef}
                     width={_width - sumPaddingRightLeft + 55}
                     sx={{
                       overflow: "scroll"
                     }}
                >
                  <Button
                    size="small"
                    sx={{
                      left: "50%",
                      transform: "translate(-50%, 0px)",
                      position: "sticky"
                    }}
                    onClick={handleLoadMore}
                  >
                    Load previous week
                  </Button>
                  {reports?.data?.map(({
                                         dates,
                                         id,
                                         spends: weekSpends,
                                         revenue,
                                         profit,
                                         payments,
                                         subscriptions,
                                         trials,
                                         CAC,
                                         LTV_2y_plus,
                                         LTV_6m,
                                         LTV_1y,
                                         LTV_2y,
                                         LTV_max,
                                         subrate,
                                         in_trial,
                                         // users,
                                         first_open,
                                         CPI,
                                         current_romi,
                                         payed,
                                         payed_1,
                                         payed_2
                                       }, weekIndex) =>
                    <Box id={id}
                         key={id}
                         sx={{
                           paddingBottom: `${ROWS_SEPARATE_HEIGHT}px`,
                           width: (spends.length + PROFIT_HEADER_COLUMNS.length + countSubscriptionColumns + countPaymentColumns) * COLUMN_WIDTH,
                           borderBottom: '1px solid #c0c0c063',
                         }}
                    >
                      <table className={'daily-table'}>
                        <tbody>
                        {dates.map((date, dayWeekIndex) =>
                          <React.Fragment key={date}>
                            <tr className={clsx('border-bottom', date === 'week' ? 'week-total' : '')}>
                              {spends?.map(spend =>
                                <td key={`${id}-${date}-${spend}`}
                                    className={clsx(spend === 'sum' ? 'border-right negative-bg' : 'spend')}
                                >
                                  {spend !== 'sum' && date !== 'week' ?
                                    <InlineEdit type={'number'}
                                                currency
                                                text={weekSpends?.[spend][dayWeekIndex]}
                                                onSetText={newValue => _handleUpdateSpendValue(date, spend, parseFloat(newValue), dayWeekIndex, weekIndex)}
                                    />
                                    :
                                    `$ ${getLocalValue(weekSpends?.[spend][dayWeekIndex])}`
                                  }
                                </td>
                              )}

                              {/* Cost trial */}
                              {/*<td className={'revenue-bg'}>{getLocalValue(subscriptions.count_trials[dayWeekIndex])}</td>*/}
                              {/*<td className={'revenue-bg'}>{getLocalValue(subscriptions.count_conversions[dayWeekIndex])}</td>*/}

                              {/*<td className={'revenue-bg'}>$ {getLocalValue(subscriptions.cost[dayWeekIndex])}</td>*/}
                              {/*<td className={clsx('revenue-bg', 'border-right')}>{getLocalValue(subscriptions.conversions_rate[dayWeekIndex] * 100)} %</td>*/}
                              {/*<td className={'revenue-bg'}>{getLocalValue(trials.converted[dayWeekIndex])}</td>*/}
                              {/*<td className={'revenue-bg'}>{getLocalValue(trials.count[dayWeekIndex])}</td>*/}
                              {/*<td className={'revenue-bg'}>{getLocalValue(trials.in_billing[dayWeekIndex])}</td>*/}
                              {/*<td className={clsx('revenue-bg', 'border-right')}>{getLocalValue(trials.in_trial[dayWeekIndex])}</td>*/}


                              <td>{getLocalValue(first_open[dayWeekIndex])}</td>
                              <td>{subscriptions.count_renewable[dayWeekIndex]}</td>
                              <td style={{paddingLeft: 8}}>
                                {sum([
                                  (trials?.converted[dayWeekIndex] || 0),
                                  (subscriptions?.count_primary[dayWeekIndex] || 0)
                                ])}
                                &nbsp;/&nbsp;
                                {payments.count[dayWeekIndex]}
                                &nbsp;/&nbsp;
                                {sum([
                                  (trials?.converted?.[dayWeekIndex] || 0),
                                  (subscriptions?.count_primary?.[dayWeekIndex] || 0),
                                  (payments.count?.[dayWeekIndex])
                                ])}
                              </td>

                              <td className={'revenue-bg'}>
                                {`${getLocalValue(trials.count[dayWeekIndex])} / ${getLocalValue(trials.converted[dayWeekIndex])} / ${getLocalValue(subscriptions.conversions_rate[dayWeekIndex] * 100)} %`}
                              </td>
                              {/* Sub+Pur (Unique) */}
                              {/* <td>{(subscriptions.unique?.[dayWeekIndex] || 0) + (payments?.unique?.[dayWeekIndex] || 0)}</td> */}
                              <td>{(payed_2?.[dayWeekIndex] || 0)}</td>
                              <td>
                                {(payed_1?.[dayWeekIndex] || 0)}
                                {/*{*/}
                                {/*sum([*/}
                                {/*    (trials?.count[dayWeekIndex] || 0),*/}
                                {/*    (subscriptions?.count_primary[dayWeekIndex] || 0),*/}
                                {/*    (payments?.count[dayWeekIndex] || 0)*/}
                                {/*])}*/}
                              </td>
                              <td className={'revenue-bg'}>{getLocalValue(trials.in_trial[dayWeekIndex])}</td>
                              <td className={'revenue-bg'}>{getLocalValue(trials.in_billing[dayWeekIndex])}</td>
                              <td className={'revenue-bg'}>$ {getLocalValue(CAC[dayWeekIndex])}</td>
                              <td className={'revenue-bg'}>
                                $ {getLocalValue(
                                round(
                                  saveDivide(weekSpends.sum[dayWeekIndex],
                                    (payed_1?.[dayWeekIndex] || 0)
                                    // sum([
                                    //     (trials?.count[dayWeekIndex] || 0),
                                    //     (subscriptions?.count_primary[dayWeekIndex] || 0),
                                    //     (payments?.count[dayWeekIndex] || 0)
                                    // ])
                                  ),
                                  2)
                              )}
                              </td>
                              <td className={'revenue-bg'}>$ {getLocalValue(subscriptions.cost[dayWeekIndex])}</td>


                              <td>{getLocalValue(subrate[dayWeekIndex])}&nbsp;%</td>
                              <td>{getLocalValue(in_trial[dayWeekIndex])}&nbsp;%</td>
                              {/* Revenue */}
                              <td>
                                $ {getLocalValue(revenue.value[dayWeekIndex])}
                              </td>
                              <td className={currencyBgColor(profit.value[dayWeekIndex])}>
                                $ {getLocalValue(profit.value[dayWeekIndex])}
                              </td>
                              <td className={clsx('border-right', percentBgColor(profit.value_roi[dayWeekIndex]))}>
                                {getLocalValue(current_romi[dayWeekIndex])} %
                              </td>

                              {/* Predictive 1y */}
                              <td>
                                $ {getLocalValue(revenue.predictive_1y[dayWeekIndex])}
                                {date !== 'week' && <MoreVertIcon
                                  onClick={handleClick({
                                    first_open_date: date,
                                    project_id,
                                    period: '1y1d'

                                  })}
                                  ref={wrapperRef}
                                  style={{fontSize: "0.71rem"}}/>
                                }
                              </td>
                              <td>{getLocalValue(round(revenue.predictive_1y[dayWeekIndex] - weekSpends.sum[dayWeekIndex], 2))}</td>
                              <td>{getLocalValue(revenue.ROMI_1y[dayWeekIndex])} %</td>
                              <td>{getLocalValue(CAC[dayWeekIndex])}&nbsp;/&nbsp;{getLocalValue(LTV_1y[dayWeekIndex])}</td>
                              <td className="border-right">
                                {getLocalValue(CPI[dayWeekIndex])}
                                /
                                {getLocalValue(saveDivide(revenue.predictive_1y[dayWeekIndex], first_open[dayWeekIndex]))}
                              </td>

                              {/* Predictive max */}
                              {/*<td>$ {getLocalValue(revenue.predictive_max[dayWeekIndex])}</td>*/}
                              <td>
                                $ {getLocalValue(revenue.predictive_max[dayWeekIndex])}
                                {date !== 'week' && <MoreVertIcon
                                  onClick={handleClick({
                                    first_open_date: date,
                                    project_id,
                                    period: 'all'

                                  })}
                                  ref={wrapperRef}
                                  style={{fontSize: "0.71rem"}}/>
                                }
                              </td>
                              <td>{getLocalValue(round(revenue.predictive_max[dayWeekIndex] - weekSpends.sum[dayWeekIndex], 2))}</td>
                              <td>{getLocalValue(revenue.ROMI_max[dayWeekIndex])} %</td>
                              <td>{getLocalValue(CAC[dayWeekIndex])}&nbsp;/&nbsp;{getLocalValue(LTV_max[dayWeekIndex])}</td>
                              <td className="border-right">
                                {getLocalValue(CPI[dayWeekIndex])}
                                /
                                {getLocalValue(saveDivide(revenue.predictive_max[dayWeekIndex], first_open[dayWeekIndex]))}
                              </td>

                              {/* Predictive 6m */}
                              <td>
                                $ {getLocalValue(revenue.predictive_6m[dayWeekIndex])}
                                {date !== 'week' && <MoreVertIcon
                                  onClick={handleClick({
                                    first_open_date: date,
                                    project_id,
                                    period: '6m1d'

                                  })}
                                  ref={wrapperRef}
                                  style={{fontSize: "0.71rem"}}/>
                                }
                              </td>
                              <td>{getLocalValue(round(revenue.predictive_6m[dayWeekIndex] - weekSpends.sum[dayWeekIndex], 2))}</td>
                              <td>{getLocalValue(revenue.ROMI_6m[dayWeekIndex])} %</td>
                              <td>{getLocalValue(CAC[dayWeekIndex])}&nbsp;/&nbsp;{getLocalValue(LTV_6m[dayWeekIndex])}</td>
                              <td className="border-right">
                                {getLocalValue(CPI[dayWeekIndex])}
                                /
                                {getLocalValue(saveDivide(revenue.predictive_6m[dayWeekIndex], first_open[dayWeekIndex]))}
                              </td>

                              {/* Predictive 2y */}
                              <td>
                                $ {getLocalValue(revenue.predictive_2y[dayWeekIndex])}
                                {date !== 'week' && <MoreVertIcon
                                  onClick={handleClick({
                                    first_open_date: date,
                                    project_id,
                                    period: '2y1d'

                                  })}
                                  ref={wrapperRef}
                                  style={{fontSize: "0.71rem"}}/>
                                }
                              </td>
                              <td>{getLocalValue(round(revenue.predictive_2y[dayWeekIndex] - weekSpends.sum[dayWeekIndex], 2))}</td>
                              <td>{getLocalValue(revenue.ROMI_2y[dayWeekIndex])} %</td>
                              <td>{getLocalValue(CAC[dayWeekIndex])}&nbsp;/&nbsp;{getLocalValue(LTV_2y[dayWeekIndex])}</td>
                              <td className="border-right">
                                {getLocalValue(CPI[dayWeekIndex])}
                                /
                                {getLocalValue(saveDivide(revenue.predictive_2y[dayWeekIndex], first_open[dayWeekIndex]))}
                              </td>

                              {/* Predictive 2y+ */}
                              {/*<td>$ {getLocalValue(revenue.predictive_2y_plus[dayWeekIndex])}</td>*/}
                              {/*<td>*/}
                              {/*    $ {getLocalValue(revenue.predictive_max[dayWeekIndex])}*/}
                              {/*    {date !== 'week' && <MoreVertIcon*/}
                              {/*        onClick={handleClick({*/}
                              {/*            first_open_date: date,*/}
                              {/*            project_id,*/}
                              {/*            period: '2y1d'*/}

                              {/*        })}*/}
                              {/*        ref={wrapperRef}*/}
                              {/*        style={{fontSize: "0.71rem"}}/>*/}
                              {/*    }*/}
                              {/*</td>*/}
                              {/*<td>{getLocalValue(round(revenue.predictive_2y_plus[dayWeekIndex] - weekSpends.sum[dayWeekIndex], 2))}</td>*/}
                              {/*<td>{getLocalValue(revenue.ROMI_2y_plus[dayWeekIndex])} %</td>*/}
                              {/*<td>{getLocalValue(CAC[dayWeekIndex])}&nbsp;/&nbsp;{getLocalValue(LTV_2y_plus[dayWeekIndex])}</td>*/}
                              {/*<td className="border-right">*/}
                              {/*    {getLocalValue(CPI[dayWeekIndex])}&nbsp;/&nbsp;{getLocalValue(saveDivide(revenue.predictive_2y_plus[dayWeekIndex], first_open[dayWeekIndex]))}*/}
                              {/*</td>*/}


                              {/*<td>{getLocalValue(revenue.ROMI_6m[dayWeekIndex])} %</td>*/}
                              {/*<td>$ {getLocalValue(revenue.predictive_2y_plus[dayWeekIndex])}</td>*/}
                              {/*<td>{getLocalValue(revenue.ROMI_2y_plus[dayWeekIndex])} %</td>*/}
                              {/*<td>{getLocalValue(LTV_2y_plus[dayWeekIndex])}</td>*/}


                              {/* Predictive*/}
                              {/*<td>$ {revenue.value_manual[dayWeekIndex]}</td>*/}
                              {/*<td className={currencyBgColor(round(revenue.value_manual[dayWeekIndex] - weekSpends.sum[dayWeekIndex],2 ))} >*/}
                              {/*    $ {getLocalValue(round(revenue.value_manual[dayWeekIndex] - weekSpends.sum[dayWeekIndex],2 ))}*/}
                              {/*</td>*/}
                              {/*<td className={clsx('border-right', percentBgColor(profit.value_manual_roi[dayWeekIndex]))}>*/}
                              {/*    {getLocalValue(profit.value_manual_roi[dayWeekIndex])} %*/}
                              {/*</td>*/}

                              {/* Revenue iTunes*/}
                              <td>
                                {date !== 'week' ?
                                  <InlineEdit type={'number'}
                                              currency
                                              text={revenue.value_cursive[dayWeekIndex]}
                                              onSetText={newValue => _handleUpdateRevenueValue(date, parseFloat(newValue), dayWeekIndex, weekIndex)}
                                  />
                                  :
                                  `$ ${revenue.value_cursive[dayWeekIndex]}`
                                }
                                {/*$ {revenue.value_cursive[dayWeekIndex]}*/}
                              </td>
                              {/* Profit iTunes*/}
                              <td className={clsx('border-right', currencyBgColor(profit.value_cursive[dayWeekIndex]))}>
                                $ {getLocalValue(profit.value_cursive[dayWeekIndex])}
                              </td>
                              <td
                                className={clsx(profit.value_cursive_roi[dayWeekIndex] > 0 ? 'positiveColor' : 'negativeColor')}>
                                {getLocalValue(profit.value_cursive_roi[dayWeekIndex])} %
                              </td>

                              {/* Cost per purchase */}
                              <td className={'revenue-bg'}>$ {getLocalValue(payments.cost[dayWeekIndex])}</td>
                              <td className={'revenue-bg'}>{getLocalValue(payments.count[dayWeekIndex])}</td>
                              <td
                                className={clsx('revenue-bg', 'border-right')}>{getLocalValue(subscriptions.count[dayWeekIndex])}</td>


                              {/*{conversionsCells(props.reports.uniqueSubscriptions, subscriptions, dayWeekIndex, date, 'subscriptions')}*/}

                              {/*{conversionsCells(props.reports.uniquePayments, payments, dayWeekIndex, date, 'payments')}*/}

                              {/* conversions section. Count subscriptions for product  */}
                              {reports.uniqueSubscriptions.map(({product_id: productId, columnCount}) => {
                                const subscription = subscriptions.info[dayWeekIndex]?.find(({product_id}) => productId === product_id);
                                if (subscription === null) return null;
                                return columnCount === 2 ?
                                  <React.Fragment key={`${productId}-${date}`}>
                                    <td>{getLocalValue(subscription?.count || 0)}</td>
                                    <td className={clsx('border-right')}>{getLocalValue(subscription?.trials || 0)}</td>
                                  </React.Fragment>
                                  :
                                  <td key={`${productId}-${date}`}
                                      className={clsx('border-right')}>{getLocalValue(subscription?.count || 0)}</td>
                              })}

                              {/* conversions section. Count payments for product  */}
                              {reports.uniquePayments.map(({product_id: productId, columnCount}) => {

                                const payment = payments.info[dayWeekIndex]?.find(({product_id}) => productId === product_id);

                                if (!payment) return null;

                                return columnCount === 2 ?
                                  <React.Fragment key={`${productId}-${date}`}>
                                    <td>{getLocalValue(payment?.count || 0)}</td>
                                    <td className={clsx('border-right')}>{getLocalValue(payment?.trials || 0)}</td>
                                  </React.Fragment>
                                  :
                                  <td key={`${productId}-${date}`}
                                      className={clsx('border-right')}>{getLocalValue(payment?.count || 0)}</td>
                              })}

                            </tr>
                          </React.Fragment>
                        )}
                        </tbody>
                      </table>
                    </Box>
                  )}
                </Box>
              </ScrollSyncPane>
            </Box>
          </React.Fragment>
        </ScrollSync>
      </Box>

      <Box>
        <AutoResizer>
          {({width, height}) => (
            <BaseTable
              width={width}
              height={height}
              fixed
              columns={columns}
              data={[]}
            />
          )}
        </AutoResizer>
      </Box>
    </>
  )
};

const mapState = state => ({
  reports: state.reports,
  profile: state.profile,
  project: state.project,
});

const mapDispatch = ({
                       reports: {
                         getReports,
                         getProjectReportSummary,
                         postProjectReportsSpend,
                         postProjectReportsRevenue,
                         updateReports,
                       },
                       predictiveDebug: {
                         getPredictiveDebug
                       }
                     }) => ({
  getReports: (data) => getReports(data),
  getProjectReportSummary: (data) => getProjectReportSummary(data),
  postProjectReportsSpend,
  postProjectReportsRevenue: (data, data2) => postProjectReportsRevenue(data, data2),
  updateReports: (data) => updateReports(data),
  getPredictiveDebug
});

export default connect(mapState, mapDispatch)(Report);
