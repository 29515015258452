import React, {useState} from "react";
import {connect} from "react-redux";
import Box from "@material-ui/core/Box";
// import {AutoSizer} from "react-virtualized";
// import theme from "../../theme";
import Table from "react-base-table";
import {useWindowSize} from "../../hooks";
import columns from './TrialReportColumns'
import component from './TrialReportCompoment'
import Popover from "@material-ui/core/Popover";
import {Table as ReactTable, TableRow, TableHead, TableCell, TableBody, TableContainer} from '@material-ui/core'
import {makeStyles} from "@material-ui/core/styles";
import FromReport from "../report/DetailsRenewals/FormReport";
import CountryFlag from "../../components/CountryFlag";


const useStyles = makeStyles((theme) => ({
    cell: {
        backgroundColor: theme.palette.secondary.light,
    },
}));


const TableForCountries = React.memo(({data}) => {

    const classes = useStyles();

    return (
        <ReactTable size="small" style={{fontSize: 10,}} padding={'normal'} >
            <TableHead>
                <TableRow className={classes.cell}>
                    <TableCell/>
                    <TableCell>Три</TableCell>
                    <TableCell>Кон</TableCell>
                    <TableCell>%</TableCell>
                </TableRow>
            </TableHead>

            <TableBody>
            {data.map(({isoCountry, conversions, trials }) =>
                <TableRow key={isoCountry}>
                    <TableCell className={classes.cell} style={{display: 'flex'}}>
                        <span><CountryFlag countryCode={isoCountry} style={{fontSize:'2em'}} /></span>
                        <span>{isoCountry}</span>
                    </TableCell>
                    <TableCell>{trials}</TableCell>
                    <TableCell>{conversions}</TableCell>
                    <TableCell>{Math.ceil(conversions/ trials * 10000)/100}%</TableCell>
                </TableRow>
            )}
            </TableBody>
        </ReactTable>
    )
});




const TrialReport = (props) => {

    const [ width, height] = useWindowSize();

    const [selectedProducts, setSelectedProducts] = React.useState([]);

    const handleFormChange = (_filerData) => {

        // console.log('_filerData', {projectId: props.project.active.id, ..._filerData});

        const {products} = _filerData;

        if (props.conversions.pending === false && props.project?.active?.id &&
            props.project.pending === false && _filerData?.products?.length > 0) {

            setSelectedProducts(products);

        props.getConversions({projectId: props.project.active.id, ..._filerData});
        }
    };

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [trialsByCountries, setTrialsByCountries] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setTrialsByCountries(null);
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const cellProps = ({column, columnIndex, rowData: {product_id, trials, alias, conversions, dist_countries}, ...other}) => {

        let outProps = {};

        if (column.key === 'trials') {
            outProps = {...outProps, ...{
                onClick: e => {
                    e.preventDefault();
                    e.stopPropagation();

                    if (trials > 0) {
                        handleClick(e);
                        setTrialsByCountries({
                            product: product_id,
                            trials,
                            conversions,
                            alias,
                            dist_countries
                        });
                    }
                },
            }};
        }

        return outProps

    };



    const productData = (props.conversions.data||[]).reduce((pV, cV) => {

        const {product_id} = cV;

        if (pV.hasOwnProperty(product_id) === false) {
            pV[product_id] = [];

        }

        let {dist_countries} = cV;

        let children = [];
        dist_countries.forEach((distCountriesArray) => {
            distCountriesArray.forEach(item => {
                children.push({
                    ...item,
                    ...{
                        id: `${cV.id}-${item.isoCountry}`,
                        parentId: cV.period,
                        period: <React.Fragment>
                            <CountryFlag countryCode={item.isoCountry} style={{fontSize: '2em', paddingRight: 8}}/>
                            &nbsp;({item.isoCountry})
                        </React.Fragment>,
                    }})
            })
        });

        pV[product_id].push({
            ...cV,
            children: children,
        });

        return pV;
    }, {});

    return (
        <Box>

            <FromReport onChange={handleFormChange.bind(this)} showTrials={true} />

            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical:   'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical:   'bottom',
                    horizontal: 'left',
                }}
            >
                <Box py={1} style={{position: 'sticky', top: 0, backgroundColor: 'white'}}>

                    <Box px={1}><strong>Продукт:</strong> {trialsByCountries?.product} ({trialsByCountries?.alias})</Box>
                    <Box px={1}><strong>Триалки:</strong> {trialsByCountries?.trials}</Box>
                    <Box px={1}><strong>Конверт:</strong> {trialsByCountries?.conversions} ({Math.ceil(trialsByCountries?.conversions/trialsByCountries?.trials*10000 )/100}%)</Box>
                    <Box px={1}><strong>Смена ТП*:</strong> {trialsByCountries?.change_product} ({Math.ceil(trialsByCountries?.change_product/trialsByCountries?.trials*10000 )/100}%)</Box>

                </Box>

                <TableContainer component={Box} display={'flex'} p={1} style={{width: 'inherit',}}>
                    {trialsByCountries?.dist_countries?.map((item, i) =>
                        <TableForCountries key={i} data={item} />
                    )}
                </TableContainer>

            </Popover>


            {selectedProducts.map(product =>
                <Box key={product} my={1}>

                    <Box mb={2} mt={3}>{product}</Box>

                     {/*<AutoSizer key={product}*/}
                     {/*           // disableHeight={false}*/}
                     {/*           style={{*/}
                     {/*               padding: theme.spacing(3, 0),*/}
                     {/*               position: 'absolute',*/}
                     {/*           }}>*/}
                     {/*    {({width}) => (*/}

                            <Table fixed
                                   style={{
                                       position: 'absolute',
                                   }}
                                   overscanRowCount={5}
                                   ignoreFunctionInColumnCompare={true}

                                   expandColumnKey={'period'}

                                   width={width - 120}
                                   // height={Math.min(height - 200, 70 + 35 * (productData?.[product]?.length || 1))}
                                   height={height - 200}
                                   rowHeight={35}

                                   columns={columns}
                                   data={productData?.[product]||[]}
                                   components={component}

                                   cellProps={cellProps}

                            />
                     {/*    )}*/}
                     {/*</AutoSizer>*/}

                </Box>
            )}


            {/*<AutoSizer style={{ padding: theme.spacing(3, 0)}}>*/}
            {/*    {({width}) => (*/}

            {/*        <Table fixed*/}
            {/*               overscanRowCount={5}*/}
            {/*               ignoreFunctionInColumnCompare={true}*/}
            {/*               width={width}*/}
            {/*               height={height - 200}*/}
            {/*               rowHeight={35}*/}

            {/*               columns={columns}*/}
            {/*               data={props.conversions.data||[]}*/}
            {/*               components={component}*/}

            {/*               cellProps={cellProps}*/}

            {/*               />*/}
            {/*        )}*/}
            {/*</AutoSizer>*/}

        </Box>
    )
};


const mapState = state => ({
    conversions:     state.conversions,
    project:         state.project,
});

const mapDispatch = ({
                         conversions: {
                             getConversions,
                         },
                     }) => ({
    getConversions,
});


export default connect(mapState, mapDispatch)(React.memo(TrialReport))