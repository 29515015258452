import palette from "../palette";

export default {
    '@global': {
        html: {
            WebkitFontSmoothing: 'auto',
            backgroundColor: palette.secondary.light,
            color: palette.black,
        },
        body: {
            backgroundColor: palette.secondary.light,
            color: palette.black,
        },
    },
}