import ApiService from "../services/api";
import {checkResponseError} from "./helpers";
import camelcaseKeysDeep from "camelcase-keys-deep";
import {reduce, head} from "lodash";


export default {
  state: {
    pending: false,
    error: null,
    errorCode: null,

    data: {},
    controller: null,
    subscriptions: null
  },
  reducers: {
    authStart(state, data) {
      return {
        ...state,
        ...data,
        pending: true,
        error: null,
        errorCode: null,
      }
    },
    updateError(state, data) {
      return {
        ...state,
        ...data,
        pending: false
      }
    },
    update(state, data) {
      return {
        ...state,
        ...data,
        pending: false
      }
    },
  },

  effects: (dispatch) => ({
    async getData(data, rootState) {

      if (rootState.subscriptionsAnalysis.controller !== null)
        rootState.subscriptionsAnalysis.controller.abort();

      const controller = new AbortController();
      dispatch.subscriptionsAnalysis.authStart({controller});

      ApiService.getSubscriptionsAnalysis({...data, signal: controller.signal})
        .then(responseData => {
          checkResponseError(dispatch.home, responseData);

          const transformData =
            camelcaseKeysDeep(reduce(responseData, (acc, value, key) => ([
              ...acc,
              {
                ...value,
                id: key,
                date: key
              }
            ]), []));

          const newData = reduce(responseData, (acc, value, date) => {
            acc.push({
              ...value,
              date,
              id: date,
              children: reduce(value._countries, (accCountry, country, countryCode) => ([
                ...accCountry,
                {
                  ...country,
                  countryCode,
                  parentId: date,
                  id: `${date}-${countryCode}`,
                  date: countryCode
                }
              ]), [])
                .sort((a, b) => b.installs?.count - a.installs?.count)
            });

            const subscriptions = reduce(value.subscriptions, (accSub, sub, subKey) => ([
              ...accSub,
              {
                ...sub,
                date,
                id: `${date}-${sub.subscription.id}`,
                period: value.period,
                children: reduce(sub._countries, (accCountry, country, countryCode) => ([
                  ...accCountry,
                  {
                    ...country,
                    countryCode,
                    parentId: `${date}-${sub.subscription.id}`,
                    id: `${date}-${sub.subscription.id}-${countryCode}`,
                    date
                  }
                ]), [])
                  .sort((a, b) => b.installs?.count - a.installs?.count)
              }
            ]), []);

            acc.push(...subscriptions);

            return acc;

          }, []);

          dispatch.subscriptionsAnalysis.update({
            // data: transformData,
            data: camelcaseKeysDeep(newData),
            subscriptions: Object.keys(head(transformData)?.subscriptions || {}),
            controller: null
          });
        })
        .catch(e => {
          console.log('getData error ', {e});
          dispatch.subscriptionsAnalysis.updateError({error: e.message})
        })
    }
  })
}