import React from "react";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";


const GroupingSelect = ({ grouping, setGrouping }) => {
    const handleChange = (event) => {
        setGrouping(event.target.value);
    };

    return (
        <Select
            size="small"
            value={grouping}
            onChange={handleChange}
        >
            {['day', 'week', 'month'].map(item => (
                <MenuItem key={item} value={item}>{item}</MenuItem>
            ))}
        </Select>
    );
};

export default GroupingSelect;