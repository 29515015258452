import {TableBody, TableCell, TableContainer, TableHead, TableRow} from "@material-ui/core";
import {StyledTable} from "../styled";
import React from "react";


const LTVDistribution = ({ data }) =>
    <TableContainer>
        <StyledTable size={'small'} style={{ backgroundColor: '#fff' }}>
            <TableHead>
                <TableRow>
                    <TableCell>Count</TableCell>
                    <TableCell>Price</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {data.map(({ price, count }, i) =>
                    <TableRow key={i}>
                        <TableCell>{count}</TableCell>
                        <TableCell>$&nbsp;{price}</TableCell>
                    </TableRow>
                )}
            </TableBody>
        </StyledTable>
    </TableContainer>;

export default LTVDistribution;