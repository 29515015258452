import React, { Fragment, useCallback } from "react";
import {connect} from "react-redux";
import Box from "@material-ui/core/Box";
import FromReport from "./FormReport"
import styled from 'styled-components'
import ReportService from "../../../services/ReportService";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import {makeStyles, withStyles} from '@material-ui/core/styles';
import TableRow from "@material-ui/core/TableRow";
import CustomTableHead from "./Componets/CustomTableHead";
import {StyledTableCell} from "./Componets/StyledTableCell";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import { percent} from "../../../models/helpers";
import clsx from "clsx";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import CountryFlag from "../../../components/CountryFlag";
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import IconButton from "@material-ui/core/IconButton";

const SmallDiv = styled.div`
    font-size:10px;
    z-index: 1;
`;

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor:  'rgba(0, 0, 0, 0.02)',
    },
  },
}))(TableRow);

const hideZeroWithLocal = (value, local = 'en-US') => {
  value *= 1;
  if ( typeof value === 'number' && value > 0) return value?.toLocaleString(local);
  return '';
};

const styles = makeStyles((theme) => ({
  bgGray: {
    backgroundColor: 'rgba(237,240,247,0.75)',
  },
  borderRight:{
    borderRight: '1px solid #E1E5EF'
  },
  borderRowBottom:{
    borderBottom:'1px solid rgba(225, 229, 239, 0.3)'
  },
  borderRowBottomLast:{
    borderBottom:'1px solid #CDD3E1'
  },
  backgroundTableHeader: {
    background: 'rgb(237 240 247 / 40%)',
  },
  bgDarkGreen:{
    backgroundColor: '#B3E4D2',
  },
  bgLightGreen:{
    backgroundColor: 'rgba(179, 228, 210, 0.6)',
  },
  bgLightGreen90:{
    backgroundColor: 'rgba(179, 228, 210, 0.4)',
  },
  // hover:{
  //     '&:hover':{
  //         backgroundColor: 'rgba(0, 0, 0, 0.04)',
  //         // '& > td': {
  //         //     backgroundColor: 'rgba(0, 0, 0, 0.04)',
  //         // },
  //         '& > td':{
  //             '& $bgLightGreen':{
  //                 backgroundColor: 'rgba(179, 228, 210, 0.4)',
  //             },
  //             '& $bgGray':{
  //                 backgroundColor: '#EDF0F76B !important',
  //             }
  //         }
  //     }
  // }
}));

const RenewableCellValue = styled(SmallDiv)`
    color: #10B16E;
    position: absolute;
    padding: 0 8px;
    top: 0;
    right: 0;
    font-weight: 600;
    &:hover{
        z-index: 2;
        background-color: inherit;
    }
`;

function Report({ project, renewalcaluator, getCalculatorRenewals }) {
  const [showDetailsRows, setShowDetailsRows] = React.useState([]);

  const handleHideShow = indexRow => {
    if (!showDetailsRows.includes(indexRow))
      setShowDetailsRows(prev => [...prev, indexRow]);
    else
      setShowDetailsRows(prev => [...prev.filter(item => item !== indexRow)])

  };

  const classes = styles();
  const [filerData, setFilerData] = React.useState({});

  const handleFormChange = useCallback((_filerData) => {
    if (renewalcaluator.pending === false &&
      project?.active?.id && project.pending === false && _filerData?.products?.length > 0) {
      setFilerData(_filerData);
      getCalculatorRenewals({projectId: project.active.id, ..._filerData});
    }
  }, [getCalculatorRenewals, renewalcaluator, project]);

  const products = Array.from(filerData?.products?.values() || []);

  const data = ReportService.renewalsCalculatorData(renewalcaluator.renewals, products);

  const isShow = (is_show = true) => {
    return renewalcaluator.pending === false && renewalcaluator.error === null && is_show
  };

  // const columnDefinition = (report.columns||[]).map((column, columnIndex) => {
  //     let frozen;
  //     if (columnIndex < 5) frozen = Column.FrozenDirection.LEFT;
  //     if (columnIndex > (report.columns||[]).length-2) frozen = Column.FrozenDirection.RIGHT;
  //     return { ...column, frozen }
  // });

  return (
    <Box>
      <Box>
        <FromReport onChange={handleFormChange.bind(this)} />
      </Box>

      {isShow() && products.map( item =>
        <TableContainer component={Paper} key={item} style={{marginTop: 24}}>
          <Box py={2} px={3}>{item}</Box>

          <Table size={'small'} >
            <CustomTableHead />
            <TableBody>
              {data?.[item]?.map((row) => (
                <Fragment>
                  <StyledTableRow hover key={row.date} className={classes.borderRowBottom}>
                    <StyledTableCell
                      component="th"
                      scope="row"
                      style={{
                        minWidth: 75,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between"
                      }}
                    >
                      <IconButton color="primary" onClick={handleHideShow.bind(this, row.date)}>
                        {showDetailsRows.includes(row.date)
                          ? <ArrowDropDownIcon/>
                          : <ArrowRightIcon/>
                        }
                      </IconButton>
                      {row.date}
                    </StyledTableCell>
                    <StyledTableCell align="right" className={clsx(classes.bgGray)} >
                      {hideZeroWithLocal(row.renewable_wfp)}
                    </StyledTableCell>

                    <StyledTableCell align="right" className={clsx(classes.bgLightGreen)}>
                      {hideZeroWithLocal(row.top_index)}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {hideZeroWithLocal(row.summary?.same)}
                    </StyledTableCell>
                    <StyledTableCell align="right" className={classes.borderRight} >
                      {hideZeroWithLocal(row.summary?.change)}
                    </StyledTableCell>

                    {(
                      new Array(renewalcaluator.max_num_payments)).fill(0).map((_, i) =>
                      <StyledTableCell key={i} align="right" className={ clsx((i%2) ? classes.bgGray: '', classes.borderRight)}>
                        {row?.payments?.[i]?.same > 0 &&
                        <React.Fragment>
                          <RenewableCellValue>
                            {hideZeroWithLocal(row?.payments?.[i]?.renewable)}
                          </RenewableCellValue>
                          <div>{hideZeroWithLocal(row?.payments?.[i]?.same)}</div>
                          <SmallDiv>
                            {
                              (
                                (row?.payments?.[i-1]?.same || 0) > 0 &&
                                (row?.payments?.[i]?.same || 0) > 0
                              )
                                ?
                                percent(
                                  (row?.payments?.[i]?.same || 0), (row?.payments?.[i-1]?.same || 0)
                                )
                                : null
                            }
                          </SmallDiv>
                        </React.Fragment>
                        }
                      </StyledTableCell>
                    )}

                  </StyledTableRow>

                  {showDetailsRows.includes(row.date) && row?.dist_countries2.map(({ isoCode }) => (
                      <StyledTableRow>
                        <TableCell>
                          <CountryFlag countryCode={isoCode} style={{ fontSize:'2em' }} />
                          &nbsp;
                          {isoCode}</TableCell>
                        <TableCell align="right" className={clsx(classes.bgGray)} >{hideZeroWithLocal(row?.dist_countries[isoCode].renewable_wfp)}</TableCell>
                        <TableCell align="right" className={clsx(classes.bgLightGreen90)}>{hideZeroWithLocal(row?.dist_countries[isoCode].top_index)}</TableCell>
                        <TableCell>{hideZeroWithLocal(row?.summary?.dist_countries[isoCode].same)}</TableCell>
                        <TableCell className={classes.borderRight}>{hideZeroWithLocal(row?.summary?.dist_countries[isoCode].change)}</TableCell>
                        {(
                          new Array(renewalcaluator.max_num_payments)).fill(0).map((_, i) =>
                          <StyledTableCell key={i} align="right" className={ clsx((i%2) ? classes.bgGray: '', classes.borderRight)}>
                            {row?.payments?.[i]?.dist_countries?.[isoCode]?.same > 0 &&
                            <React.Fragment>
                              <RenewableCellValue>
                                {hideZeroWithLocal(row?.payments?.[i]?.dist_countries?.[isoCode]?.renewable)}
                              </RenewableCellValue>
                              <div>{hideZeroWithLocal(row?.payments?.[i]?.dist_countries?.[isoCode]?.same)}</div>
                              <SmallDiv>
                                {
                                  (
                                    (row?.payments?.[i-1]?.dist_countries?.[isoCode]?.same || 0) > 0 &&
                                    (row?.payments?.[i]?.dist_countries?.[isoCode]?.same || 0) > 0
                                  )
                                    ?
                                    percent(
                                      (row?.payments?.[i]?.dist_countries?.[isoCode]?.same || 0), (row?.payments?.[i-1]?.dist_countries?.[isoCode]?.same || 0)
                                    )
                                    : null
                                }
                              </SmallDiv>
                            </React.Fragment>
                            }
                          </StyledTableCell>
                        )}
                      </StyledTableRow>
                    )
                  )}

                </Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      { isShow(products?.length>=2) &&
      <TableContainer component={Paper} style={{marginTop: 24}}>
        <Box py={2} px={3}  className={classes.borderRowBottom}>Compare products</Box>
        <Table size={'small'} >
          <TableHead className={ clsx(classes.borderRowBottom, classes.backgroundTableHeader)}>
            <TableRow>
              <StyledTableCell style={{minWidth: 75}} rowSpan={2}>Data</StyledTableCell>
              <StyledTableCell align="right" rowSpan={2}>Product</StyledTableCell>
              <StyledTableCell align="right" className={clsx(classes.bgGray, classes.borderRight)} rowSpan={2}>Active</StyledTableCell>
              <StyledTableCell align="right" className={clsx(classes.bgGray, classes.borderRight)} rowSpan={2}>Renewable</StyledTableCell>
              <StyledTableCell align="center" colSpan={3} className={clsx(classes.bgDarkGreen, classes.borderRight)}>SUM</StyledTableCell>
              {(new Array(renewalcaluator.max_num_payments)).fill(0).map((_, i) =>
                <StyledTableCell key={i}  colSpan={2} align="center" className={clsx(classes.bgGray, classes.borderRight)}>{i+1}&nbsp;PL</StyledTableCell>
              )}

            </TableRow>
            <TableRow >
              <StyledTableCell align="center" className={ clsx(classes.bgLightGreen)}>Коэф</StyledTableCell>
              <StyledTableCell align="center"  >ОТП</StyledTableCell>
              <StyledTableCell align="center"  className={clsx(classes.borderRight)} >СТП</StyledTableCell>
              {(new Array(renewalcaluator.max_num_payments)).fill(0).map((_, i) =>
                <React.Fragment key={i}>
                  <StyledTableCell align="center" >ОТП</StyledTableCell>
                  <StyledTableCell align="center" className={classes.borderRight} >СТП</StyledTableCell>
                </React.Fragment>
              )}
            </TableRow>
          </TableHead>

          <TableBody>
            {Object.keys(renewalcaluator?.renewals||[]).map((key, i) => {
              return (renewalcaluator.renewals?.[key]?.data || []).map((row, j) =>
                <React.Fragment key={`${key}-${row.alias}`}>
                  <TableRow hover className={ clsx((renewalcaluator.renewals?.[key]?.data?.length || 0) -1 === j ? classes.borderRowBottomLast : classes.borderRowBottom )}>
                    {j === 0 &&
                    <StyledTableCell style={{minWidth: 75}}
                                     rowSpan={renewalcaluator.renewals?.[key]?.data?.length || 0}
                                     className={classes.borderRowBottomLast}
                    >
                      {key}
                    </StyledTableCell>
                    }
                    <StyledTableCell style={{minWidth:150}}>{row.alias}</StyledTableCell>
                    <StyledTableCell align="right" className={clsx(classes.bgGray, classes.borderRight)}>{hideZeroWithLocal(row.active)}</StyledTableCell>
                    <StyledTableCell align="right" className={clsx(classes.bgGray, classes.borderRight)}>{hideZeroWithLocal(row.renewable)}</StyledTableCell>
                    <StyledTableCell align="center" className={clsx(classes.borderRight, classes.bgLightGreen)} >{hideZeroWithLocal(row.top_index)}</StyledTableCell>
                    <StyledTableCell align="center" >{hideZeroWithLocal(row.summary?.same)}</StyledTableCell>
                    <StyledTableCell align="center" className={clsx(classes.borderRight)}>{hideZeroWithLocal(row.summary?.change)}</StyledTableCell>

                    {(
                      new Array(renewalcaluator.max_num_payments)).fill(0).map((_, i) =>
                      <React.Fragment key={i} >
                        <StyledTableCell align="right" className={ clsx((i%2) ? classes.bgGray: '', classes.borderRight)} >
                          <Fragment>
                            <RenewableCellValue>
                              {hideZeroWithLocal(row?.payments?.[i]?.renewable)}
                            </RenewableCellValue>
                            <div>{hideZeroWithLocal(row?.payments?.[i]?.same)}</div>
                            <SmallDiv>
                              {
                                (
                                  (row?.payments?.[i-1]?.same || 0) > 0 &&
                                  (row?.payments?.[i]?.same || 0) > 0
                                ) ?
                                  percent(
                                    (row?.payments?.[i]?.same || 0), (row?.payments?.[i-1]?.same || 0)
                                  )
                                  : null
                              }
                            </SmallDiv>
                          </Fragment>

                        </StyledTableCell>

                        <StyledTableCell align="right" className={clsx((i%2) ? classes.bgGray: '', classes.borderRight)} >
                          {hideZeroWithLocal(row?.payments?.[i]?.change)}
                        </StyledTableCell>
                      </React.Fragment>
                    )}




                  </TableRow>

                </React.Fragment>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
      }
    </Box>
  )
}


const mapState = ({project, renewalcaluator}) => ({ project, renewalcaluator });

const mapDispatch = ({
                       renewalcaluator: {
                         getCalculatorRenewals
                       }
                     }) => ({
  getCalculatorRenewals,
});

export default connect(mapState, mapDispatch)(Report)