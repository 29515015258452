import React from "react";
import {Box, Typography} from "@material-ui/core";
import { isNil, isNumber } from "lodash";
import useStyles from './styles'
import TextWithIcon from "../../../components/TextWithIcon";
import Tooltip from "@material-ui/core/Tooltip";
import { CellFormatRender } from "./utils";

const ProductInfo = ({ loading = false, name, data = [] }) => {
    const classes = useStyles();

    if (loading) return null;

    return (
        <Box>
            <TextWithIcon iconPosition={name === 'Subscriptions' && 'right'}>
                <Typography component={'h4'} variant={'h4'} color={'inherit'}>{name}</Typography>
            </TextWithIcon>

            {data?.sort((a, b) => {
                    if (a.count < b.count) return 1;
                    if (a.count > b.count) return -1;
                    return 0;
                })
                ?.map(({
                   product,
                   count,
                   payments,
                   converted = null,
                   amount,
                   price,
                   in_trial = null,
                   in_billing = null,
                   payments_refunded = null,
                   amount_refunded = null,
                   cancelled = null,
                   cancelled_in_trial = null,

                }) =>
                    <section key={product} className={classes.section}>
                        <Typography component={'div'} variant={'body2'}>{product}</Typography>
                        <span className={classes.span}/>
                        <Tooltip arrow  placement="top" title={
                            <Box p={1}>
                                {isNumber(count) && (
                                    <Box display="flex" justifyContent="space-between">
                                        <Box>Count:</Box><Box pl={2}>{count?.toLocaleString('en-US')}</Box>
                                    </Box>
                                )}
                                {isNumber(in_trial) && (
                                    <Box display="flex" justifyContent="space-between">
                                        <Box>In trial:</Box><Box pl={2}>{in_trial?.toLocaleString('en-US')}</Box>
                                    </Box>
                                )}
                                {isNumber(in_billing) && (
                                    <Box display="flex" justifyContent="space-between">
                                        <Box>In billing:</Box><Box pl={2}>{in_billing?.toLocaleString('en-US')}</Box>
                                    </Box>
                                )}
                                {isNumber(payments) && (
                                    <Box display="flex" justifyContent="space-between">
                                        <Box>Payments:</Box><Box pl={2}>{payments?.toLocaleString('en-US')}</Box>
                                    </Box>
                                )}
                                {isNumber(converted) && (
                                    <Box display="flex" justifyContent="space-between">
                                        <Box>Converted:</Box><Box pl={2}>{converted?.toLocaleString('en-US')}</Box>
                                    </Box>
                                )}
                                {isNumber(amount) && (
                                    <Box display="flex" justifyContent="space-between">
                                        <Box>Amount:</Box><Box pl={2}>{CellFormatRender.money(amount)}</Box>
                                    </Box>
                                )}
                                {isNumber(price) && (
                                    <Box display="flex" justifyContent="space-between">
                                        <Box>Price:</Box><Box pl={2}>{CellFormatRender.money(price)}</Box>
                                    </Box>
                                )}
                                {isNumber(payments_refunded) && (
                                    <Box display="flex" justifyContent="space-between">
                                        <Box>Payments refunded:</Box><Box pl={2}>{payments_refunded?.toLocaleString('en-US')}</Box>
                                    </Box>
                                )}
                                {isNumber(amount_refunded) && (
                                    <Box display="flex" justifyContent="space-between">
                                        <Box>Amount refunded:</Box><Box pl={2}>{CellFormatRender.money(amount_refunded)}</Box>
                                    </Box>
                                )}
                                {isNumber(cancelled) && (
                                    <Box display="flex" justifyContent="space-between">
                                        <Box>Cancelled:</Box><Box pl={2}>{cancelled?.toLocaleString('en-US')}</Box>
                                    </Box>
                                )}
                                {isNumber(cancelled_in_trial) && (
                                    <Box display="flex" justifyContent="space-between">
                                        <Box>Cancelled in trial :</Box><Box pl={2}>{cancelled_in_trial?.toLocaleString('en-US')}</Box>
                                    </Box>
                                )}
                            </Box>
                        }>
                            <h4>{
                                isNil(converted)
                                    ? (count || payments)?.toLocaleString('en-US')
                                    : `${count}/${converted * 1}`
                            }</h4>
                        </Tooltip>
                    </section>
                )}
        </Box>
    )
};

export default ProductInfo;