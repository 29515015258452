import createBreakpoints from '@material-ui/core/styles/createBreakpoints'
import { createTheme } from '@material-ui/core/styles'

const breakpoints = createBreakpoints({});
const theme = createTheme();


export default {
     gutters:{
        paddingRight:theme.spacing(2),
        paddingLeft:theme.spacing(2),

        [breakpoints.up('lg')]: {
            paddingRight: theme.spacing(3),
            paddingLeft: theme.spacing(3),
         }
     }
}