import ApiService from "../services/api";
import {checkResponseError} from './helpers'
import { saveAs } from 'file-saver';


export default {
    state: {
        pending: false,
        error: null,
        errorCode: null,

        products: null,
        helpers: null,
        conversions:null,

        startDownload: false
    },
    reducers: {
        authStart(state) {
            return {
                ...state,
                pending: true
            }
        },
        updateError(state, data) {
            return {
                ...state,
                ...data,
                pending: false
            }
        },
        updateProducts(state, data){
            return {
                ...state,
                ...data,
                pending: false
            }
        },
    },

    effects: (dispatch) => ({

        async getProducts(data){
            try{

                dispatch.products.authStart();

                const responseData = await ApiService.getProjectProducts(data);

                checkResponseError(dispatch.products, responseData);

                let {products, helpers} = responseData;

                // helpers.periods = helpers.periods.reduce((previousValue, currentItem) => {
                //
                //     if (currentItem !== 'unknown') {
                //         previousValue.push(currentItem);
                //     }
                //     return previousValue;
                // }, []);

                dispatch.products.updateProducts({products, helpers});

                return responseData;
            }
            catch (e) {
                console.log('Catch products', e);
                dispatch.products.updateProducts({error: e.message})
            }
        },

        async postProduct(data, prevState){
            try{
                dispatch.products.authStart();

                const responseData = await ApiService.postProjectProduct(data);

                checkResponseError(dispatch.products, responseData);

                if (responseData?.success === true) {
                    if (data?.country && data?.field === 'conversion_country') {
                        const id = Object.keys(prevState.products.conversions)[0];

                        const index = prevState.products.conversions[id].findIndex(({country}) => country.code === data?.country);

                        if (index !== -1) {
                            let {conversions} = prevState.products;
                            conversions[id][index]['value_manual'] = data.value;

                            dispatch.products.updateProducts({conversions});
                        }
                    }
                }

                return responseData;
            }
            catch (e) {
                console.log('Catch products', e);
                dispatch.products.updateProducts({error: e.message})
            }
        },

        async getProductConversions(data, prevState){

            try{
                dispatch.products.authStart();

                const responseData = await ApiService.getProductConversions(data);

                checkResponseError(dispatch.products, responseData);

                if (data?.type === 'countries') {
                    let {conversions} = prevState.products;

                    conversions = {
                        ...conversions,
                        ...{
                            [data.productId]:  responseData.conversions.sort((x, y) => y.country.is_top - x.country.is_top),
                        }
                    };

                    dispatch.products.updateProducts({conversions});
                }
                else if (data?.type === 'period') {

                    const {conversion} = responseData;
                    let productIndex = prevState.products.products.findIndex(product => product.product_id === data.productId);

                    if (productIndex !== -1){
                        let {products} = prevState.products;
                        products[productIndex]['odds']['conversion_factual'] = conversion;

                        dispatch.products.updateProducts({products});
                    }
                }

                return responseData;
            }
            catch (e) {
                console.log('Catch conversions', e);
                dispatch.products.updateProducts({error: e.message})
            }
        },

        async download(data){

            dispatch.products.updateProducts({startDownload: true});

            ApiService.downloadProductsReports(data)
                .then(response => {
                    const blob = new Blob([response], {
                        type: "text/csv;charset=utf-8"
                    });

                    dispatch.products.updateProducts({startDownload: false});
                    saveAs(blob, "report.csv");
                });

        }
    })
};