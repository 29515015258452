import LanguageOutlinedIcon from "@material-ui/icons/LanguageOutlined";
import FlagOutlinedIcon from "@material-ui/icons/FlagOutlined";
import ReactCountryFlag from "react-country-flag";
import React from "react";


const CountryFlag = (props) =>{

    const {countryCode} = props;

    if (countryCode === '*') return <LanguageOutlinedIcon style={props.style} color={'primary'}/>;

    if (countryCode === '??') return <FlagOutlinedIcon style={props.style} color={'primary'}/>;

    return <ReactCountryFlag {...props}/>;
};

export default CountryFlag;