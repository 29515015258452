import React, {memo} from "react";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import Box from "@material-ui/core/Box";
import FromReport from "../report/DetailsRenewals/FormReport";
import ColumnDefinition from './ColumnsDefinition'
import Table from 'react-base-table'
import {AutoSizer} from "react-virtualized";
import {useWindowSize} from "../../hooks";
import theme from './../../theme'

const Cancelled = () => {
    const [, height] = useWindowSize();
    const dispatch = useDispatch();

    const project = useSelector(state => state.project, shallowEqual);
    const cancelled = useSelector(state => state.cancelled, shallowEqual);

    const handleFormChange = (_filerData) => {

        if (cancelled.pending === false && project?.active?.id &&
            project.pending === false && _filerData?.products?.length > 0) {

            dispatch.cancelled.getCancelled({projectId: project.active.id, ..._filerData});
        }
    };

    return (
        <Box mb={3}>
            <FromReport onChange={handleFormChange.bind(this)} />

            <AutoSizer style={{ padding: theme.spacing(3, 0)}}>
                    {({width}) => (
                        <Table fixed
                               overscanRowCount={5}
                               ignoreFunctionInColumnCompare={true}
                               width={width}
                               height={height - 200}
                               rowHeight={35}
                               columns={ColumnDefinition}
                               data={cancelled?.data||[]}
                        />
                    )}
                </AutoSizer>

        </Box>
    )
};

export default memo(Cancelled)