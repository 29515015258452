import ApiService from "../services/api";
import {checkResponseError} from "./helpers";
import camelcaseKeysDeep from "camelcase-keys-deep";
import { keys, reduce } from "lodash";


// const responseData = {
//     "products": {
//         "com.elteam.grids.1month9.99": 684,
//         "com.elteam.grids.1year9.99": 683,
//         "com.kamela.smmhub.year59.99trial3d": 137,
//         "com.elteam.grids.1week4.99trial3d": 91,
//         "com.elteam.grids.1year29.99trial3d": 161,
//         "com.elteam.grids.6months": 25,
//         "com.elteam.grids.1year19.99": 445,
//         "com.elteam.grids.1year29.99": 1624,
//         "com.elteam.grids.1month9.99trial3d": 18,
//         "com.elteam.1week3.99": 5,
//         "com.elteam.grids.1year39.99": 172,
//         "com.elteam.grids.1year14.99": 626,
//         "com.elteam.grids.1year49.99trial1month0.99": 4,
//         "com.kamela.smmhub.1year59.99trial3d": 42,
//         "com.kamela.smmhub.3months29.99trial3d": 42,
//         "com.elteam.grids.offer.1year9.99trial30days": 3
//     },
//     "data": {
//         "2022-10-01": {
//             "in_trial": {
//                 "amount": 0,
//                 "products": []
//             },
//             "canceled": {
//                 "amount": 144.23,
//                 "products": {
//                     "com.elteam.grids.1year9.99": 5.38,
//                     "com.elteam.grids.1year29.99trial3d": 6.83,
//                     "com.elteam.grids.1month9.99": 54.37,
//                     "com.elteam.grids.1year19.99": 4.59,
//                     "com.elteam.grids.1year29.99": 37.04,
//                     "com.elteam.grids.6months": 0.46,
//                     "com.elteam.grids.1week4.99trial3d": 6.49,
//                     "com.elteam.grids.1month9.99trial3d": 0.9,
//                     "com.elteam.1week3.99": 0.65,
//                     "com.elteam.grids.1year39.99": 6.9,
//                     "com.elteam.grids.1year14.99": 5.18,
//                     "com.kamela.smmhub.year59.99trial3d": 6.88,
//                     "com.kamela.smmhub.3months29.99trial3d": 6.38,
//                     "com.kamela.smmhub.1year59.99trial3d": 2.17
//                 }
//             },
//             "renewable": {
//                 "amount": 197.66,
//                 "products": {
//                     "com.elteam.grids.1month9.99": 67.25,
//                     "com.elteam.grids.1year9.99": 7.78,
//                     "com.kamela.smmhub.year59.99trial3d": 6.01,
//                     "com.elteam.grids.1week4.99trial3d": 27.67,
//                     "com.elteam.grids.6months": 1.3,
//                     "com.elteam.grids.1year29.99": 45.69,
//                     "com.elteam.grids.1year19.99": 12.52,
//                     "com.elteam.grids.1month9.99trial3d": 3,
//                     "com.elteam.grids.1year29.99trial3d": 2.5,
//                     "com.elteam.1week3.99": 0.8,
//                     "com.elteam.grids.1year39.99": 6.29,
//                     "com.elteam.grids.1year14.99": 11.55,
//                     "com.elteam.grids.1year49.99trial1month0.99": 0.53,
//                     "com.kamela.smmhub.1year59.99trial3d": 2.6,
//                     "com.elteam.grids.offer.1year9.99trial30days": 0.01,
//                     "com.kamela.smmhub.3months29.99trial3d": 2.17
//                 }
//             }
//         },
//         "2022-10-02": {
//             "in_trial": {
//                 "amount": 0,
//                 "products": []
//             },
//             "canceled": {
//                 "amount": 143.51,
//                 "products": {
//                     "com.elteam.grids.1year9.99": 5.34,
//                     "com.elteam.grids.1year29.99trial3d": 6.83,
//                     "com.elteam.grids.1month9.99": 53.63,
//                     "com.elteam.grids.1year19.99": 4.59,
//                     "com.elteam.grids.1year29.99": 37.5,
//                     "com.elteam.grids.6months": 0.46,
//                     "com.elteam.grids.1week4.99trial3d": 6,
//                     "com.elteam.grids.1month9.99trial3d": 0.9,
//                     "com.elteam.1week3.99": 0.65,
//                     "com.elteam.grids.1year39.99": 6.9,
//                     "com.elteam.grids.1year14.99": 5.27,
//                     "com.kamela.smmhub.year59.99trial3d": 6.88,
//                     "com.kamela.smmhub.3months29.99trial3d": 6.38,
//                     "com.kamela.smmhub.1year59.99trial3d": 2.17
//                 }
//             },
//             "renewable": {
//                 "amount": 199.4,
//                 "products": {
//                     "com.elteam.grids.1month9.99": 68.17,
//                     "com.elteam.grids.1year9.99": 7.8,
//                     "com.kamela.smmhub.year59.99trial3d": 6.01,
//                     "com.elteam.grids.1week4.99trial3d": 27.67,
//                     "com.elteam.grids.6months": 1.3,
//                     "com.elteam.grids.1year29.99": 46.38,
//                     "com.elteam.grids.1year19.99": 12.52,
//                     "com.elteam.grids.1month9.99trial3d": 3,
//                     "com.elteam.grids.1year29.99trial3d": 2.5,
//                     "com.elteam.1week3.99": 0.8,
//                     "com.elteam.grids.1year39.99": 6.29,
//                     "com.elteam.grids.1year14.99": 11.66,
//                     "com.elteam.grids.1year49.99trial1month0.99": 0.53,
//                     "com.kamela.smmhub.1year59.99trial3d": 2.6,
//                     "com.elteam.grids.offer.1year9.99trial30days": 0.01,
//                     "com.kamela.smmhub.3months29.99trial3d": 2.17
//                 }
//             }
//         },
//         "2022-10-03": {
//             "in_trial": {
//                 "amount": 0,
//                 "products": []
//             },
//             "canceled": {
//                 "amount": 143.42,
//                 "products": {
//                     "com.elteam.grids.1year9.99": 5.32,
//                     "com.elteam.grids.1year29.99trial3d": 6.83,
//                     "com.elteam.grids.1month9.99": 53.6,
//                     "com.elteam.grids.1year19.99": 4.55,
//                     "com.elteam.grids.1year29.99": 37.56,
//                     "com.elteam.grids.6months": 0.38,
//                     "com.elteam.grids.1week4.99trial3d": 6,
//                     "com.elteam.grids.1month9.99trial3d": 0.9,
//                     "com.elteam.1week3.99": 0.65,
//                     "com.elteam.grids.1year39.99": 6.9,
//                     "com.elteam.grids.1year14.99": 5.3,
//                     "com.kamela.smmhub.year59.99trial3d": 6.88,
//                     "com.kamela.smmhub.3months29.99trial3d": 6.38,
//                     "com.kamela.smmhub.1year59.99trial3d": 2.17
//                 }
//             },
//             "renewable": {
//                 "amount": 203.18,
//                 "products": {
//                     "com.elteam.grids.1month9.99": 70.69,
//                     "com.elteam.grids.1year9.99": 7.8,
//                     "com.kamela.smmhub.year59.99trial3d": 6.01,
//                     "com.elteam.grids.1week4.99trial3d": 28.13,
//                     "com.elteam.grids.6months": 1.3,
//                     "com.elteam.grids.1year29.99": 46.96,
//                     "com.elteam.grids.1year19.99": 12.52,
//                     "com.elteam.grids.1month9.99trial3d": 3,
//                     "com.elteam.grids.1year29.99trial3d": 2.5,
//                     "com.elteam.1week3.99": 0.8,
//                     "com.elteam.grids.1year39.99": 6.29,
//                     "com.elteam.grids.1year14.99": 11.66,
//                     "com.elteam.grids.1year49.99trial1month0.99": 0.53,
//                     "com.kamela.smmhub.1year59.99trial3d": 2.6,
//                     "com.elteam.grids.offer.1year9.99trial30days": 0.01,
//                     "com.kamela.smmhub.3months29.99trial3d": 2.4
//                 }
//             }
//         },
//         "2022-10-04": {
//             "in_trial": {
//                 "amount": 0,
//                 "products": []
//             },
//             "canceled": {
//                 "amount": 142.78,
//                 "products": {
//                     "com.elteam.grids.1year9.99": 5.3,
//                     "com.elteam.grids.1year29.99trial3d": 6.83,
//                     "com.elteam.grids.1month9.99": 52.62,
//                     "com.elteam.grids.1year19.99": 4.55,
//                     "com.elteam.grids.1year29.99": 37.9,
//                     "com.elteam.grids.6months": 0.38,
//                     "com.elteam.grids.1week4.99trial3d": 6,
//                     "com.elteam.grids.1month9.99trial3d": 0.9,
//                     "com.elteam.1week3.99": 0.65,
//                     "com.elteam.grids.1year39.99": 6.9,
//                     "com.elteam.grids.1year14.99": 5.3,
//                     "com.kamela.smmhub.year59.99trial3d": 6.88,
//                     "com.kamela.smmhub.3months29.99trial3d": 6.38,
//                     "com.kamela.smmhub.1year59.99trial3d": 2.17
//                 }
//             },
//             "renewable": {
//                 "amount": 204.92,
//                 "products": {
//                     "com.elteam.grids.1month9.99": 71.59,
//                     "com.elteam.grids.1year9.99": 7.8,
//                     "com.kamela.smmhub.year59.99trial3d": 6.01,
//                     "com.elteam.grids.1week4.99trial3d": 28.13,
//                     "com.elteam.grids.6months": 1.3,
//                     "com.elteam.grids.1year29.99": 47.59,
//                     "com.elteam.grids.1year19.99": 12.52,
//                     "com.elteam.grids.1month9.99trial3d": 3,
//                     "com.elteam.grids.1year29.99trial3d": 2.5,
//                     "com.elteam.1week3.99": 0.8,
//                     "com.elteam.grids.1year39.99": 6.29,
//                     "com.elteam.grids.1year14.99": 11.86,
//                     "com.elteam.grids.1year49.99trial1month0.99": 0.53,
//                     "com.kamela.smmhub.1year59.99trial3d": 2.6,
//                     "com.elteam.grids.offer.1year9.99trial30days": 0.01,
//                     "com.kamela.smmhub.3months29.99trial3d": 2.4
//                 }
//             }
//         },
//         "2022-10-05": {
//             "in_trial": {
//                 "amount": 0,
//                 "products": []
//             },
//             "canceled": {
//                 "amount": 142.16,
//                 "products": {
//                     "com.elteam.grids.1year9.99": 5.3,
//                     "com.elteam.grids.1year29.99trial3d": 6.83,
//                     "com.elteam.grids.1month9.99": 51.65,
//                     "com.elteam.grids.1year19.99": 4.55,
//                     "com.elteam.grids.1year29.99": 38.02,
//                     "com.elteam.grids.6months": 0.38,
//                     "com.elteam.grids.1week4.99trial3d": 6.41,
//                     "com.elteam.grids.1month9.99trial3d": 0.9,
//                     "com.elteam.1week3.99": 0.65,
//                     "com.elteam.grids.1year39.99": 6.9,
//                     "com.elteam.grids.1year14.99": 5.36,
//                     "com.kamela.smmhub.year59.99trial3d": 6.88,
//                     "com.kamela.smmhub.3months29.99trial3d": 6.16,
//                     "com.kamela.smmhub.1year59.99trial3d": 2.17
//                 }
//             },
//             "renewable": {
//                 "amount": 207.99,
//                 "products": {
//                     "com.elteam.grids.1month9.99": 72.98,
//                     "com.elteam.grids.1year9.99": 7.8,
//                     "com.kamela.smmhub.year59.99trial3d": 6.01,
//                     "com.elteam.grids.1week4.99trial3d": 28.72,
//                     "com.elteam.grids.6months": 1.3,
//                     "com.elteam.grids.1year29.99": 48.57,
//                     "com.elteam.grids.1year19.99": 12.52,
//                     "com.elteam.grids.1month9.99trial3d": 3,
//                     "com.elteam.grids.1year29.99trial3d": 2.5,
//                     "com.elteam.1week3.99": 0.8,
//                     "com.elteam.grids.1year39.99": 6.29,
//                     "com.elteam.grids.1year14.99": 11.98,
//                     "com.elteam.grids.1year49.99trial1month0.99": 0.53,
//                     "com.kamela.smmhub.1year59.99trial3d": 2.6,
//                     "com.elteam.grids.offer.1year9.99trial30days": 0.01,
//                     "com.kamela.smmhub.3months29.99trial3d": 2.4
//                 }
//             }
//         },
//         "2022-10-06": {
//             "in_trial": {
//                 "amount": 0,
//                 "products": []
//             },
//             "canceled": {
//                 "amount": 141.39,
//                 "products": {
//                     "com.elteam.grids.1year9.99": 5.3,
//                     "com.elteam.grids.1year29.99trial3d": 6.83,
//                     "com.elteam.grids.1month9.99": 50.7,
//                     "com.elteam.grids.1year19.99": 4.55,
//                     "com.elteam.grids.1year29.99": 38.13,
//                     "com.elteam.grids.6months": 0.38,
//                     "com.elteam.grids.1week4.99trial3d": 6.41,
//                     "com.elteam.grids.1month9.99trial3d": 0.9,
//                     "com.elteam.1week3.99": 0.65,
//                     "com.elteam.grids.1year39.99": 6.9,
//                     "com.elteam.grids.1year14.99": 5.41,
//                     "com.kamela.smmhub.year59.99trial3d": 6.88,
//                     "com.kamela.smmhub.3months29.99trial3d": 6.16,
//                     "com.kamela.smmhub.1year59.99trial3d": 2.17
//                 }
//             },
//             "renewable": {
//                 "amount": 208.79,
//                 "products": {
//                     "com.elteam.grids.1month9.99": 73.23,
//                     "com.elteam.grids.1year9.99": 7.8,
//                     "com.kamela.smmhub.year59.99trial3d": 6.01,
//                     "com.elteam.grids.1week4.99trial3d": 28.72,
//                     "com.elteam.grids.6months": 1.3,
//                     "com.elteam.grids.1year29.99": 49.03,
//                     "com.elteam.grids.1year19.99": 12.52,
//                     "com.elteam.grids.1month9.99trial3d": 3,
//                     "com.elteam.grids.1year29.99trial3d": 2.5,
//                     "com.elteam.1week3.99": 0.8,
//                     "com.elteam.grids.1year39.99": 6.29,
//                     "com.elteam.grids.1year14.99": 12.06,
//                     "com.elteam.grids.1year49.99trial1month0.99": 0.53,
//                     "com.kamela.smmhub.1year59.99trial3d": 2.6,
//                     "com.elteam.grids.offer.1year9.99trial30days": 0.01,
//                     "com.kamela.smmhub.3months29.99trial3d": 2.4
//                 }
//             }
//         },
//         "2022-10-07": {
//             "in_trial": {
//                 "amount": 0,
//                 "products": []
//             },
//             "canceled": {
//                 "amount": 141.31,
//                 "products": {
//                     "com.elteam.grids.1year9.99": 5.3,
//                     "com.elteam.grids.1year29.99trial3d": 6.83,
//                     "com.elteam.grids.1month9.99": 50.2,
//                     "com.elteam.grids.1year19.99": 4.51,
//                     "com.elteam.grids.1year29.99": 38.36,
//                     "com.elteam.grids.6months": 0.38,
//                     "com.elteam.grids.1week4.99trial3d": 6.65,
//                     "com.elteam.grids.1month9.99trial3d": 0.9,
//                     "com.elteam.1week3.99": 0.65,
//                     "com.elteam.grids.1year39.99": 6.9,
//                     "com.elteam.grids.1year14.99": 5.41,
//                     "com.kamela.smmhub.year59.99trial3d": 6.88,
//                     "com.kamela.smmhub.3months29.99trial3d": 6.16,
//                     "com.kamela.smmhub.1year59.99trial3d": 2.17
//                 }
//             },
//             "renewable": {
//                 "amount": 210.4,
//                 "products": {
//                     "com.elteam.grids.1month9.99": 73.67,
//                     "com.elteam.grids.1year9.99": 7.8,
//                     "com.kamela.smmhub.year59.99trial3d": 6.01,
//                     "com.elteam.grids.1week4.99trial3d": 29.17,
//                     "com.elteam.grids.6months": 1.3,
//                     "com.elteam.grids.1year29.99": 49.72,
//                     "com.elteam.grids.1year19.99": 12.52,
//                     "com.elteam.grids.1month9.99trial3d": 3,
//                     "com.elteam.grids.1year29.99trial3d": 2.5,
//                     "com.elteam.1week3.99": 0.8,
//                     "com.elteam.grids.1year39.99": 6.29,
//                     "com.elteam.grids.1year14.99": 12.09,
//                     "com.elteam.grids.1year49.99trial1month0.99": 0.53,
//                     "com.kamela.smmhub.1year59.99trial3d": 2.6,
//                     "com.elteam.grids.offer.1year9.99trial30days": 0.01,
//                     "com.kamela.smmhub.3months29.99trial3d": 2.4
//                 }
//             }
//         },
//         "2022-10-08": {
//             "in_trial": {
//                 "amount": 0,
//                 "products": []
//             },
//             "canceled": {
//                 "amount": 141.88,
//                 "products": {
//                     "com.elteam.grids.1year9.99": 5.26,
//                     "com.elteam.grids.1year29.99trial3d": 6.83,
//                     "com.elteam.grids.1month9.99": 50.4,
//                     "com.elteam.grids.1year19.99": 4.51,
//                     "com.elteam.grids.1year29.99": 38.88,
//                     "com.elteam.grids.6months": 0.38,
//                     "com.elteam.grids.1week4.99trial3d": 6.5,
//                     "com.elteam.grids.1month9.99trial3d": 0.9,
//                     "com.elteam.1week3.99": 0.65,
//                     "com.elteam.grids.1year39.99": 6.9,
//                     "com.elteam.grids.1year14.99": 5.44,
//                     "com.kamela.smmhub.year59.99trial3d": 6.88,
//                     "com.kamela.smmhub.3months29.99trial3d": 6.16,
//                     "com.kamela.smmhub.1year59.99trial3d": 2.17
//                 }
//             },
//             "renewable": {
//                 "amount": 211.27,
//                 "products": {
//                     "com.elteam.grids.1month9.99": 73.92,
//                     "com.elteam.grids.1year9.99": 7.8,
//                     "com.kamela.smmhub.year59.99trial3d": 6.01,
//                     "com.elteam.grids.1week4.99trial3d": 29.21,
//                     "com.elteam.grids.6months": 1.3,
//                     "com.elteam.grids.1year29.99": 50.18,
//                     "com.elteam.grids.1year19.99": 12.52,
//                     "com.elteam.grids.1month9.99trial3d": 3,
//                     "com.elteam.grids.1year29.99trial3d": 2.5,
//                     "com.elteam.1week3.99": 0.8,
//                     "com.elteam.grids.1year39.99": 6.29,
//                     "com.elteam.grids.1year14.99": 12.21,
//                     "com.elteam.grids.1year49.99trial1month0.99": 0.53,
//                     "com.kamela.smmhub.1year59.99trial3d": 2.6,
//                     "com.elteam.grids.offer.1year9.99trial30days": 0.01,
//                     "com.kamela.smmhub.3months29.99trial3d": 2.4
//                 }
//             }
//         },
//         "2022-10-09": {
//             "in_trial": {
//                 "amount": 0,
//                 "products": []
//             },
//             "canceled": {
//                 "amount": 141.87,
//                 "products": {
//                     "com.elteam.grids.1year9.99": 5.23,
//                     "com.elteam.grids.1year29.99trial3d": 6.83,
//                     "com.elteam.grids.1month9.99": 50.59,
//                     "com.elteam.grids.1year19.99": 4.51,
//                     "com.elteam.grids.1year29.99": 39.05,
//                     "com.elteam.grids.6months": 0.38,
//                     "com.elteam.grids.1week4.99trial3d": 6.17,
//                     "com.elteam.grids.1month9.99trial3d": 0.9,
//                     "com.elteam.grids.1year39.99": 6.9,
//                     "com.elteam.grids.1year14.99": 5.44,
//                     "com.kamela.smmhub.year59.99trial3d": 6.88,
//                     "com.elteam.1week3.99": 0.65,
//                     "com.kamela.smmhub.3months29.99trial3d": 6.16,
//                     "com.kamela.smmhub.1year59.99trial3d": 2.17
//                 }
//             },
//             "renewable": {
//                 "amount": 213.27,
//                 "products": {
//                     "com.elteam.grids.1month9.99": 75.5,
//                     "com.elteam.grids.1year9.99": 7.78,
//                     "com.kamela.smmhub.year59.99trial3d": 6.01,
//                     "com.elteam.grids.1week4.99trial3d": 28.82,
//                     "com.elteam.grids.6months": 1.3,
//                     "com.elteam.grids.1year29.99": 50.98,
//                     "com.elteam.grids.1year19.99": 12.52,
//                     "com.elteam.grids.1month9.99trial3d": 3,
//                     "com.elteam.grids.1year29.99trial3d": 2.5,
//                     "com.elteam.1week3.99": 0.8,
//                     "com.elteam.grids.1year39.99": 6.29,
//                     "com.elteam.grids.1year14.99": 12.23,
//                     "com.elteam.grids.1year49.99trial1month0.99": 0.53,
//                     "com.kamela.smmhub.1year59.99trial3d": 2.6,
//                     "com.elteam.grids.offer.1year9.99trial30days": 0.01,
//                     "com.kamela.smmhub.3months29.99trial3d": 2.4
//                 }
//             }
//         },
//         "2022-10-10": {
//             "in_trial": {
//                 "amount": 0,
//                 "products": []
//             },
//             "canceled": {
//                 "amount": 139.91,
//                 "products": {
//                     "com.elteam.grids.1year9.99": 5.23,
//                     "com.elteam.grids.1year29.99trial3d": 6.83,
//                     "com.elteam.grids.1month9.99": 49.27,
//                     "com.elteam.grids.1year19.99": 4.51,
//                     "com.elteam.grids.1year29.99": 39.34,
//                     "com.elteam.grids.6months": 0.38,
//                     "com.elteam.grids.1week4.99trial3d": 5.47,
//                     "com.elteam.grids.1month9.99trial3d": 0.9,
//                     "com.elteam.grids.1year39.99": 6.9,
//                     "com.elteam.grids.1year14.99": 5.44,
//                     "com.kamela.smmhub.year59.99trial3d": 6.88,
//                     "com.elteam.1week3.99": 0.65,
//                     "com.kamela.smmhub.3months29.99trial3d": 5.94,
//                     "com.kamela.smmhub.1year59.99trial3d": 2.17
//                 }
//             },
//             "renewable": {
//                 "amount": 215.77,
//                 "products": {
//                     "com.elteam.grids.1month9.99": 77.32,
//                     "com.elteam.grids.1year9.99": 7.76,
//                     "com.kamela.smmhub.year59.99trial3d": 6.01,
//                     "com.elteam.grids.1week4.99trial3d": 28.82,
//                     "com.elteam.grids.6months": 1.3,
//                     "com.elteam.grids.1year29.99": 51.56,
//                     "com.elteam.grids.1year19.99": 12.52,
//                     "com.elteam.grids.1month9.99trial3d": 3,
//                     "com.elteam.grids.1year29.99trial3d": 2.5,
//                     "com.elteam.1week3.99": 0.8,
//                     "com.elteam.grids.1year39.99": 6.29,
//                     "com.elteam.grids.1year14.99": 12.35,
//                     "com.elteam.grids.1year49.99trial1month0.99": 0.53,
//                     "com.kamela.smmhub.1year59.99trial3d": 2.6,
//                     "com.elteam.grids.offer.1year9.99trial30days": 0.01,
//                     "com.kamela.smmhub.3months29.99trial3d": 2.4
//                 }
//             }
//         },
//         "2022-10-11": {
//             "in_trial": {
//                 "amount": 0,
//                 "products": []
//             },
//             "canceled": {
//                 "amount": 139.36,
//                 "products": {
//                     "com.elteam.grids.1year9.99": 5.21,
//                     "com.elteam.grids.1year29.99trial3d": 6.83,
//                     "com.elteam.grids.1month9.99": 49.46,
//                     "com.elteam.grids.1year19.99": 4.51,
//                     "com.elteam.grids.1year29.99": 39.34,
//                     "com.elteam.grids.6months": 0.38,
//                     "com.elteam.grids.1week4.99trial3d": 4.94,
//                     "com.elteam.grids.1year39.99": 6.9,
//                     "com.elteam.grids.1month9.99trial3d": 0.67,
//                     "com.elteam.grids.1year14.99": 5.47,
//                     "com.kamela.smmhub.year59.99trial3d": 6.88,
//                     "com.elteam.1week3.99": 0.65,
//                     "com.kamela.smmhub.3months29.99trial3d": 5.94,
//                     "com.kamela.smmhub.1year59.99trial3d": 2.17
//                 }
//             },
//             "renewable": {
//                 "amount": 218.14,
//                 "products": {
//                     "com.elteam.grids.1month9.99": 79.35,
//                     "com.elteam.grids.1year9.99": 7.76,
//                     "com.kamela.smmhub.year59.99trial3d": 6.01,
//                     "com.elteam.grids.1week4.99trial3d": 28.82,
//                     "com.elteam.grids.6months": 1.3,
//                     "com.elteam.grids.1year29.99": 51.79,
//                     "com.elteam.grids.1year19.99": 12.52,
//                     "com.elteam.grids.1month9.99trial3d": 3,
//                     "com.elteam.grids.1year29.99trial3d": 2.5,
//                     "com.elteam.1week3.99": 0.8,
//                     "com.elteam.grids.1year39.99": 6.29,
//                     "com.elteam.grids.1year14.99": 12.46,
//                     "com.elteam.grids.1year49.99trial1month0.99": 0.53,
//                     "com.kamela.smmhub.1year59.99trial3d": 2.6,
//                     "com.elteam.grids.offer.1year9.99trial30days": 0.01,
//                     "com.kamela.smmhub.3months29.99trial3d": 2.4
//                 }
//             }
//         },
//         "2022-10-12": {
//             "in_trial": {
//                 "amount": 0,
//                 "products": []
//             },
//             "canceled": {
//                 "amount": 137.84,
//                 "products": {
//                     "com.elteam.grids.1year9.99": 5.19,
//                     "com.elteam.grids.1year29.99trial3d": 6.83,
//                     "com.elteam.grids.1month9.99": 48.29,
//                     "com.elteam.grids.1year19.99": 4.51,
//                     "com.elteam.grids.1year29.99": 39.45,
//                     "com.elteam.grids.6months": 0.38,
//                     "com.elteam.grids.1week4.99trial3d": 4.5,
//                     "com.elteam.grids.1year39.99": 6.9,
//                     "com.elteam.grids.1month9.99trial3d": 0.67,
//                     "com.elteam.grids.1year14.99": 5.47,
//                     "com.kamela.smmhub.year59.99trial3d": 6.88,
//                     "com.elteam.1week3.99": 0.65,
//                     "com.kamela.smmhub.3months29.99trial3d": 5.94,
//                     "com.kamela.smmhub.1year59.99trial3d": 2.17
//                 }
//             },
//             "renewable": {
//                 "amount": 219.82,
//                 "products": {
//                     "com.elteam.grids.1month9.99": 79.79,
//                     "com.elteam.grids.1year9.99": 7.76,
//                     "com.kamela.smmhub.year59.99trial3d": 6.01,
//                     "com.elteam.grids.1week4.99trial3d": 28.39,
//                     "com.elteam.grids.6months": 1.3,
//                     "com.elteam.grids.1year29.99": 52.25,
//                     "com.elteam.grids.1year19.99": 12.52,
//                     "com.elteam.grids.1month9.99trial3d": 3,
//                     "com.elteam.grids.1year29.99trial3d": 2.5,
//                     "com.elteam.1week3.99": 0.8,
//                     "com.elteam.grids.1year39.99": 6.29,
//                     "com.elteam.grids.1year14.99": 12.55,
//                     "com.elteam.grids.1year49.99trial1month0.99": 1.66,
//                     "com.kamela.smmhub.1year59.99trial3d": 2.6,
//                     "com.elteam.grids.offer.1year9.99trial30days": 0.01,
//                     "com.kamela.smmhub.3months29.99trial3d": 2.4
//                 }
//             }
//         },
//         "2022-10-13": {
//             "in_trial": {
//                 "amount": 0,
//                 "products": []
//             },
//             "canceled": {
//                 "amount": 137.05,
//                 "products": {
//                     "com.elteam.grids.1year9.99": 5.17,
//                     "com.elteam.grids.1year29.99trial3d": 6.83,
//                     "com.elteam.grids.1year19.99": 4.51,
//                     "com.elteam.grids.1year29.99": 39.68,
//                     "com.elteam.grids.6months": 0.38,
//                     "com.elteam.grids.1month9.99": 47.79,
//                     "com.elteam.grids.1week4.99trial3d": 4,
//                     "com.elteam.grids.1year39.99": 6.9,
//                     "com.elteam.grids.1month9.99trial3d": 0.67,
//                     "com.elteam.grids.1year14.99": 5.47,
//                     "com.kamela.smmhub.year59.99trial3d": 6.88,
//                     "com.elteam.1week3.99": 0.65,
//                     "com.kamela.smmhub.3months29.99trial3d": 5.94,
//                     "com.kamela.smmhub.1year59.99trial3d": 2.17
//                 }
//             },
//             "renewable": {
//                 "amount": 221.97,
//                 "products": {
//                     "com.elteam.grids.1month9.99": 81.37,
//                     "com.elteam.grids.1year9.99": 7.76,
//                     "com.kamela.smmhub.year59.99trial3d": 6.01,
//                     "com.elteam.grids.1week4.99trial3d": 28.39,
//                     "com.elteam.grids.6months": 1.3,
//                     "com.elteam.grids.1year29.99": 52.65,
//                     "com.elteam.grids.1year19.99": 12.52,
//                     "com.elteam.grids.1month9.99trial3d": 3,
//                     "com.elteam.grids.1year29.99trial3d": 2.5,
//                     "com.elteam.1week3.99": 0.8,
//                     "com.elteam.grids.1year39.99": 6.29,
//                     "com.elteam.grids.1year14.99": 12.72,
//                     "com.elteam.grids.1year49.99trial1month0.99": 1.66,
//                     "com.kamela.smmhub.1year59.99trial3d": 2.6,
//                     "com.elteam.grids.offer.1year9.99trial30days": 0.01,
//                     "com.kamela.smmhub.3months29.99trial3d": 2.4
//                 }
//             }
//         },
//         "2022-10-14": {
//             "in_trial": {
//                 "amount": 0,
//                 "products": []
//             },
//             "canceled": {
//                 "amount": 135.28,
//                 "products": {
//                     "com.elteam.grids.1year9.99": 5.09,
//                     "com.elteam.grids.1year29.99trial3d": 6.71,
//                     "com.elteam.grids.1year19.99": 4.51,
//                     "com.elteam.grids.1year29.99": 39.86,
//                     "com.elteam.grids.6months": 0.38,
//                     "com.elteam.grids.1month9.99": 47.08,
//                     "com.elteam.grids.1week4.99trial3d": 3.44,
//                     "com.elteam.grids.1year39.99": 6.9,
//                     "com.elteam.grids.1month9.99trial3d": 0.67,
//                     "com.elteam.grids.1year14.99": 5.47,
//                     "com.kamela.smmhub.year59.99trial3d": 6.88,
//                     "com.kamela.smmhub.3months29.99trial3d": 5.72,
//                     "com.kamela.smmhub.1year59.99trial3d": 2.17,
//                     "com.elteam.1week3.99": 0.4
//                 }
//             },
//             "renewable": {
//                 "amount": 222.92,
//                 "products": {
//                     "com.elteam.grids.1month9.99": 81.82,
//                     "com.elteam.grids.1year9.99": 7.76,
//                     "com.kamela.smmhub.year59.99trial3d": 6.01,
//                     "com.elteam.grids.1week4.99trial3d": 28.39,
//                     "com.elteam.grids.6months": 1.3,
//                     "com.elteam.grids.1year29.99": 53.11,
//                     "com.elteam.grids.1year19.99": 12.56,
//                     "com.elteam.grids.1month9.99trial3d": 3,
//                     "com.elteam.grids.1year29.99trial3d": 2.5,
//                     "com.elteam.1week3.99": 0.8,
//                     "com.elteam.grids.1year39.99": 6.29,
//                     "com.elteam.grids.1year14.99": 12.72,
//                     "com.elteam.grids.1year49.99trial1month0.99": 1.66,
//                     "com.kamela.smmhub.1year59.99trial3d": 2.6,
//                     "com.elteam.grids.offer.1year9.99trial30days": 0.01,
//                     "com.kamela.smmhub.3months29.99trial3d": 2.4
//                 }
//             }
//         }
//     }
// };

export default {
    state: {
        pending: false,
        error: null,
        errorCode: null,

        data: {},
        products: [],
        productsRealName: {},
        periods: {},
        controller: null
    },
    reducers: {
        authStart(state) {
            return {
                ...state,
                pending: true,
                error: null,
                errorCode: null,
            }
        },
        updateError(state, data) {
            return {
                ...state,
                ...data,
                pending: false
            }
        },
        update(state, data) {
            return {
                ...state,
                ...data,
                pending: false
            }
        },
    },

    effects: (dispatch) => ({
        async getData(data, rootState) {
            if (rootState.recurringRevenue.controller !== null)
                rootState.recurringRevenue.controller.abort();

            const controller = new AbortController();
            dispatch.recurringRevenue.authStart({ controller });

            const responseData = await ApiService.getRecurringRevenue(data);
            const { data: dates, products, periods } = responseData;
            const camelKeysProducts = camelcaseKeysDeep(products);

            checkResponseError(dispatch.recurringRevenue, responseData);

            dispatch.recurringRevenue.update({
                data: reduce(dates, (acc, value, key) => ({
                    ...acc,
                    [key]: camelcaseKeysDeep(value)
                }), {}),
                periods: reduce(periods, (acc, {title, products}, period) => ({
                    ...acc,
                    [period]: {
                        title,
                        products: keys(camelcaseKeysDeep(reduce(products, (a, v) => ({...a, [v]: v}), {})))
                    }
                }), {}),
                products: keys(camelKeysProducts),
                productsRealName: camelcaseKeysDeep(reduce(products, (acc, val, key) => ({...acc, [key]: key}), {})),
                controller: null
            });

            return responseData;
        }
    })
}