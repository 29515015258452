


export default {
    state: {
        pending: false,
        error: null,
        errorCode: null,

        data: null,
    },

    reducers:{
        authStart(state) {
            return {
                ...state,
                pending: true
            }
        },
        updateError(state, data) {
            return {
                ...state,
                ...data,
                pending: false
            }
        },
        updateAbTestsShort(state, data){
            return {
                ...state,
                ...data,
                pending: false
            }
        }
    },

    effects: (dispatch) => ({
        async getReport(){
            try{

                this.authStart();

                console.log('+getReport');
                let data = [
                    {
                        spend: 55000,
                        revenue: {
                            value: 50000,
                            grows: 10,
                        },
                        days: [10, 20, 30, 45, 70, 100],
                        money: [
                            {
                                value: 1000,
                                grows: 10,
                            },
                            {
                                value: 1000,
                                grows: 10,
                            },
                            {
                                value: 1000,
                                grows: 10,
                            },
                            {
                                value: 1000,
                                grows: 10,
                            },
                            {
                                value: 1000,
                                grows: 10,
                            },
                            {
                                value: 1000,
                                grows: 10,
                            },
                            {
                                value: 1000,
                                grows: 10,
                            },
                        ],
                        roi: [1,2,3,4,5,6, 7],
                    },
                    {
                        spend: 55000,
                        revenue: {
                            value: 50000,
                            grows: 13,
                        },
                        days: [10, 20, 30, 45, 70, 100],
                        money: [
                            {
                                value: 1000,
                                grows: 10,
                            },
                            {
                                value: 1000,
                                grows: 10,
                            },
                            {
                                value: 1000,
                                grows: 10,
                            },
                            {
                                value: 1000,
                                grows: 10,
                            },
                            {
                                value: 1000,
                                grows: 10,
                            },
                            {
                                value: 1000,
                                grows: 10,
                            },
                            {
                                value: 1000,
                                grows: 10,
                            },

                        ],
                        roi: [1,2,3,4,5, 6, 7],
                    },
                ];

                setTimeout(() => {
                    dispatch.abTestsShort.updateAbTestsShort({
                        data,
                    });
                }, 800);


            }catch (e) {
                console.log('Catch short', e);
                dispatch.abTestsShort.updateAbTestsShort({error: e.message});
            }
        }
    })
}