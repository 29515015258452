import clsx from "clsx";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import {Menu as MenuIcon, Notifications as NotificationsIcon} from "@material-ui/icons";
import Typography from "@material-ui/core/Typography";
import AppBar from "@material-ui/core/AppBar";
import React, {memo, useEffect} from "react";
import { makeStyles, alpha } from '@material-ui/core/styles';
import {connect} from "react-redux";
import {
    NavLink,
} from "react-router-dom";
import Box from "@material-ui/core/Box";
import {tabs} from './../layouts/DashboardLayout'
import List from "@material-ui/core/List";
import Drawer from "@material-ui/core/Drawer";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import Divider from "@material-ui/core/Divider";
import ListSubheader from "@material-ui/core/ListSubheader";
import ProjectsListItems from "./ProjectsListItems";
import Badge from "@material-ui/core/Badge";
import {fireBaseInit} from "../init-fcm";
import InfoIcon from '@material-ui/icons/Info';
import Popover from "@material-ui/core/Popover";
import {showNotification, useQuery} from "../services/helpers";
import {LEFT_MENU_WIDTH} from "../constants/Constants";
import {HEADER_HEIGHT} from "../views/daily/Report";
import useWindowSize from "../hooks/useWindowSize";
import {upperFirst} from "lodash";
import PriceConversionSettings from "../layouts/components/PriceConversionSettings";


const drawerWidth = LEFT_MENU_WIDTH;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        paddingLeft: theme.spacing(2), // keep right padding when drawer closed
        paddingRight: theme.spacing(2), // keep right padding when drawer closed
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    appBar: {
        backgroundColor: theme.palette.white,
        boxShadow: `0px 4px 4px ${alpha(theme.palette.black, 0.07)}`,
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        color: theme.palette.black,
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
        paddingRight:theme.spacing(3)
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        minHeight:'100vh',
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: 0,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(7),
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
        backgroundColor: `${theme.palette.secondary.light}`,
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),

    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 240,
    },
    grow: {
        flexGrow: 1,
    },
    hideScrollBar:{
        overflowY: 'scroll',
        msOverflowStyle: 'none',  /* IE and Edge */
        scrollbarWidth: 'none',  /* Firefox */
        '&::-webkit-scrollbar' : {
            display: 'none'
        },
    },

}));

function NavBar(props) {

    let query = useQuery();

    const [, height] = useWindowSize();

    const classes = useStyles();

    const [open, setOpen] = React.useState(false);
    const handleDrawerOpen = () => {
        setOpen(true);
    };
    const handleDrawerClose = () => {
        setOpen(false);
    };

    useEffect(()=> {
        if (props.profile.isAuthUser() && props.project.active !== null && props.project.data === null && props.project.pending === false && props.projects.error === null){
            props.getCountriesList({projectId:props.project.active.id});
        }

    }, [props, query]);


    const [anchorEl, setAnchorEl] = React.useState(null);

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const openInfo = Boolean(anchorEl);


    const projectWithNotice = props.projects?.projects?.reduce((p, c) => {

        if (c.notices?.length > 0) {
            p.push(c);
        }
        return p;
    }, []) || [];



    return (
        <React.Fragment>
            <AppBar position="absolute"
                    className={clsx(classes.appBar, open && classes.appBarShift)}>

                <Toolbar className={classes.toolbar}>
                    <IconButton
                        edge="start"
                        color={'primary'}
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
                    >
                        <MenuIcon color={'primary'}/>
                    </IconButton>

                    <Typography component="h4" variant="h4" noWrap className={classes.title} title={props.project?.active?.name || ''}>
                        {props.project?.active?.name || ''}
                    </Typography>

                    <Box className={classes.hideScrollBar}>
                        {props.match.params.projectPath && tabs.map((path, i) =>
                            <Box key={i}
                                 component={NavLink}
                                 style={{textTransform: 'capitalize'}}
                                 px={1}
                                 color={ `/app/${props.match.params.projectPath}/${path}` === props.location.pathname.replace(new RegExp('/$'), '') ? 'primary.main' :'text.primary'}
                                 to={`/app/${props.match.params.projectPath}/${path}`}>
                                {upperFirst(path)}
                            </Box>
                        )}
                    </Box>

                    <div className={classes.grow} />

                    <PriceConversionSettings />

                    { projectWithNotice.length > 0 &&
                        <React.Fragment>
                            <Box aria-owns={openInfo ? 'mouse-over-popover' : undefined}
                                 aria-haspopup="true"
                                 onMouseEnter={handlePopoverOpen}
                                 // onMouseLeave={handlePopoverClose}
                            >
        
                                <IconButton>
                                    <Badge badgeContent={projectWithNotice.length} color="error">
                                        <InfoIcon/>
                                    </Badge>
                                </IconButton>
                            </Box>

                            <Popover
                                id="mouse-over-popover"
                                className={classes.popover}
                                classes={{
                                    paper: classes.paper,
                                }}
                                open={openInfo}
                                anchorEl={anchorEl}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                onClose={handlePopoverClose}
                                disableRestoreFocus
                            >
                                {projectWithNotice.map((item, i) =>
                                    <React.Fragment key={i}>
                                        <Typography color={'primary'}>{item.name}</Typography>
                                        {item.notices.map(({text}, j) =>
                                            <div key={j}>{text}</div>
                                        )}
                                    </React.Fragment>
                                )}
                            </Popover>
                        </React.Fragment>
                    }

                    {
                        showNotification() &&
                        <IconButton color="primary" onClick={fireBaseInit}>
                            <Badge badgeContent={1} color="error">
                                <NotificationsIcon/>
                            </Badge>
                        </IconButton>
                    }
                </Toolbar>
            </AppBar>

            <Drawer
                variant="permanent"
                classes={{
                    paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
                }}
                open={open}
            >
                <div className={classes.toolbarIcon}>
                    <Typography component="h4" variant="h4" noWrap className={classes.title}>
                        Elteam
                    </Typography>

                    <IconButton onClick={handleDrawerClose}>
                        <ChevronLeftIcon />
                    </IconButton>
                </div>
                <Divider />
                <List style={{
                    overflowX: 'hidden',
                    overflowY: 'scroll',
                    maxHeight: `${height-HEADER_HEIGHT}px`,
                }} className={classes.hideScrollBar}>
                    <ListSubheader inset disableSticky={true}>Projects</ListSubheader>
                    { props.profile.isAuthUser() && <ProjectsListItems onChange={handleDrawerClose} /> }
                </List>
            </Drawer>

        </React.Fragment>
    )
}


const mapState = state => ({
    profile:  state.profile,
    projects: state.projects,
    project:  state.project,
});

const mapDispatch = ({
                         project: {
                             getCountriesList
                         },

                     }) => ({
    getCountriesList: (...data) => getCountriesList(...data),
});

export default connect(mapState, mapDispatch)(memo(NavBar))