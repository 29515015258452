import React from 'react';
import {
    OutlinedInput,
    InputLabel,
    makeStyles,
    Container,
    Typography,
    InputAdornment,
    IconButton,
    Link,
    Box,
    CssBaseline,
    Button,
    alpha
} from "@material-ui/core";
import {Visibility, VisibilityOff} from "@material-ui/icons";
import {connect} from "react-redux";
import {Redirect} from "react-router-dom";


function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://material-ui.com/">
                Your Website
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.primary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        padding: theme.spacing(3) + 4,
        border: `1px solid #E0E4EF`,
        boxShadow: `0px 5px 7px ${alpha(theme.palette.black, 0.03)}, 0px 30px 50px rgba(1, 11, 99, 0.05)`,
        borderRadius: 7,
        backgroundColor:theme.palette.secondary.dark,
    },
    title:{
        textAlign: 'center',
    },
    submit: {
        margin:`${theme.spacing(4)}px auto ${theme.spacing(2)}px`,
        minWidth:192,
        display:'block',
    },
}));


function SignIn(props) {

    const classes = useStyles();
    const {search, pathname} = props.location.state.from;

    const [values, setValues] = React.useState({
        email: props.profile.userInfo.email,
        password: '',
        showPassword: false,
    });

    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleSubmitForm = (event) => {
        event.preventDefault();

        props.profile.errorCode === 'need_renew_password' ?
            props.profileRenewPassword({...values, ...{email: props.profile.userInfo.email }})
            : props.profileLogin(values).then(e => {
            });

        setValues({ ...values, password: '' });
    };

    if (props.profile.isAuthUser()) {

        return <Redirect push={true} to={{
            pathname: pathname || '/app/',
            search,
            state: {from: props.location}
        }}/>;
    } 

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />

            <Typography component={'h4'} mb={3} variant="h4" color={'inherit'} className={classes.title}>
                {props.profile.errorCode === 'need_renew_password' ? 'Renew Password': 'Authorization'}
            </Typography>

            <div className={classes.paper}>
                <form className={classes.form} onSubmit={handleSubmitForm}>

                    <InputLabel htmlFor='email'>
                        User
                    </InputLabel>
                    <OutlinedInput
                        margin="dense"
                        id='email'
                        type='email'
                        name='email'
                        fullWidth={true}
                        required={true}
                        disabled={props.profile.errorCode === 'need_renew_password'}
                        readOnly={props.profile.errorCode === 'need_renew_password'}
                        autoFocus={props.profile.errorCode !== 'need_renew_password'}
                        value={values.email}
                        onChange={handleChange('email')}
                    />

                    <InputLabel htmlFor='password'>
                        Password
                    </InputLabel>
                    <OutlinedInput
                        margin="dense"
                        variant="outlined"
                        required
                        fullWidth
                        name="password"
                        id="password"
                        autoFocus
                        autoComplete="current-password"
                        type={values.showPassword ? 'text' : 'password'}
                        value={values.password}
                        onChange={handleChange('password')}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                >
                                    {values.showPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                        }
                    />

                    <Button type={'submit'} color={'primary'} variant={'contained'} className={classes.submit}>
                        { props.profile.errorCode === 'need_renew_password' ? 'Renew' : 'Sign in'}
                    </Button>

                </form>
            </div>
            <Box mt={8}>
                <Copyright />
            </Box>
        </Container>
    );
}

const mapState = state => ({
    profile: state.profile,
});

const mapDispatch = ({
                         profile: {
                             login,
                             renewPassword
                         }
                     }) => ({
    profileLogin: (...data) => login(...data),
    profileRenewPassword: (...data) => renewPassword(...data),
});

export default connect(mapState, mapDispatch)(SignIn)