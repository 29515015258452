import React from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {filter, uniq, without} from 'lodash';
import {shallowEqual, useSelector} from "react-redux";

const getProductsRealName = state => state.recurringRevenue.productsRealName;

const IndeterminateCheckbox = ({title = '', products = [], selectedProducts, setSelectedProducts}) => {
    const productsRealName = useSelector(getProductsRealName, shallowEqual);
    const [checkedProducts, setCheckedProducts] = React.useState(selectedProducts);

    const handleCheckedAll = ({target: {checked}}) => {

        setCheckedProducts(checked ? products : []);

        setSelectedProducts(prevState => ([
            ...(checked
                    ? uniq([...prevState, ...products])
                    : [...filter(prevState, item => !products.includes(item))]
            )
        ]));
    };

    const handleChangeProduct = ({target: {name, checked}}) => {
        if (checked)
            setCheckedProducts(prevState => ([...prevState, name]));
        else
            setCheckedProducts(prevState => [...filter(prevState, item => item !== name)]);


        setSelectedProducts(prevState => ([
            ...(checked ? uniq([...prevState, name]) : [...filter(prevState, item => item !== name)])
        ]));
    };

    const children = (
        <Box sx={{display: 'flex', flexDirection: 'column', ml: 3}}>
            {products.map(product => (
                <FormControlLabel
                    key={product}
                    label={productsRealName?.[product] || product}
                    control={
                        <Checkbox
                            sx={{py: 2 / 8}}
                            checked={checkedProducts.includes(product)}
                            name={product}
                            onChange={handleChangeProduct}
                        />
                    }
                />
            ))}
        </Box>
    );

    return (
        <Box px={3}>
            <FormControlLabel
                label={<Typography variant="h5">{title}</Typography>}
                control={
                    <Checkbox
                        size="small"
                        checked={checkedProducts.length === products.length && checkedProducts.length > 0}
                        indeterminate={without(products, checkedProducts) && checkedProducts.length > 0}
                        // indeterminate={(checkedProducts.length !== products.length) && checkedProducts.length > 0}
                        onChange={handleCheckedAll}
                    />
                }
            />
            {children}
        </Box>
    );
};

export default IndeterminateCheckbox;