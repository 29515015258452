import React from 'react';
// import Thumbnail from './thumbnail';
// import Types from './types';
import {round, saveDivide} from "../../models/helpers";
import ReportService from "../../services/ReportService";


const Cell = ({ className, cellData, column }) => {

    const { dataKey, rowData } = column;

    if (dataKey === 'date')
        return <div className={className}>{cellData}</div>;
    if (dataKey ==='costs')
        return <div className={className}>{cellData>0 && '$'} {cellData?.toLocaleString('en-US')}</div>;

    let value = cellData;

    if (dataKey === 'sumFact') {
        const value = ReportService.sumForFields(cellData, rowData, ['rsp_f24', 'rsp_a24', 'lifetime']);

        return <div className={className}>{value>0 && '$'} {value?.toLocaleString('en-US')}</div>;
    }
    if (dataKey === 'sumPred') {
        const value = ReportService.sumForFields(cellData, rowData, ['rsp_f24', 'rsp_a24', 'esp_act', 'esp_gra', 'lifetime']);

        return <div className={className}>{value>0 && '$'} {value?.toLocaleString('en-US')}</div>;
    }

    if (dataKey === 'roiFact') {

        const costs = cellData?.costs||0;

        let value = ReportService.sumForFields(cellData, rowData, ['rsp_f24', 'rsp_a24', 'lifetime']);
        value = saveDivide(value, costs);
        if (value !== 0)
            value = round((value - 1) * 100, 2);

        return <div className={className}>{value?.toLocaleString('en-US')} {value!==0 && '%'}</div>;
    }
    if (dataKey === 'roiPred') {
        const costs = cellData?.costs || 0;

        let value = ReportService.sumForFields(cellData, rowData, ['rsp_f24', 'rsp_a24', 'esp_act', 'esp_gra', 'lifetime']);

        value = saveDivide(value, costs);
        if (value !== 0)
            value = round((value - 1) * 100, 2);

        return <div className={className}>{value?.toLocaleString('en-US')} {value!==0 && '%'}</div>;
    }



    if (cellData !== undefined){

        value = 0;
        rowData.forEach(field => {
            value += (cellData?.[field] || 0)
        });

        value = round(value, 2);
    }

    // switch(dataKey){
    //     case 'thumbnail':
    //         return <Thumbnail src={cellData} />;
    //     case 'type':
    //         return <Types types={cellData} />;
    //     default:
    return <div className={className}>{value>0 && '$'} {value?.toLocaleString('en-US')}</div>;
    // }
};

export default {
    TableCell: Cell,
}