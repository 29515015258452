import ApiService from "../services/api";
import {checkResponseError} from './helpers'

export default {
    state: {
        pending:   false,
        error:     null,
        errorCode: null,

        data: {
            users: null,
            spent: null
        },
        payments: []
    },
    reducers: {
        authStart(state) {
            return {
                ...state,
                pending: true
            }
        },
        updateError(state, data) {
            return {
                ...state,
                ...data,
                pending: false
            }
        },
        updateReport(state, data) {
            return {
                ...state,
                ...data,
                pending: false
            }
        }
    },

    effects: (dispatch) => ({
        async getVeryGoodReportData(data) {
            try {
                dispatch.veryGood.authStart();

                const responseData = await ApiService.getVeryGoodReportData(data);

                console.log('getVeryGoodReportData', responseData);

                checkResponseError(dispatch.veryGood, responseData);

                dispatch.veryGood.updateReport(responseData);

            } catch (e) {
                process.env.NODE_ENV === 'development' && console.log('Catch getReport', e);
                dispatch.veryGood.updateReport({error: e.message})
            }
        }

    })
}