import ApiService from "../services/api";
import {checkResponseError} from "./helpers";

export default {
    state: {
        pending: false,
        error: null,
        errorCode: null,
        data: null,

        versions: null,
        controller: null,
    },
    reducers: {
        authStart(state, data) {
            return {
                ...state,
                ...data,
                pending: true
            }
        },
        updateError(state, data) {
            return {
                ...state,
                ...data,
                pending: false
            }
        },
        updateCancelled(state, data) {
            return {
                ...state,
                ...data,
                pending: false
            }
        }
    },

    effects: (dispatch) => ({

        async getCancelled(data, rootState){
            try{
                if (rootState.cancelled.controller !== null)
                    rootState.cancelled.controller.abort();

                const controller = new AbortController();
                dispatch.cancelled.authStart({ controller });

                const responseData = await ApiService.getCancelled(data);

                checkResponseError(dispatch.cancelled, responseData);

                const {cancelled, meta} = responseData;

                const worker = new Worker('/WorkerData.js');

                worker.onmessage = (event) => {

                    dispatch.cancelled.updateCancelled({
                        data: event.data,
                        max_num_payments: meta?.max_num_payments || 0,
                        controller: null
                    });
                };

                worker.postMessage({
                    method: 'cancelled',
                    data: cancelled,
                    query: data
                });

                // const newData = ReportService.cancelled(cancelled);
                //
                // dispatch.cancelled.updateCancelled({
                //     data: newData,
                //     max_num_payments: meta?.max_num_payments || 0
                // });
            }
            catch (e) {

                console.log('Catch cancelled', e);

                dispatch.cancelled.updateCancelled({error: e.message})
            }
        },
    })
}