import { colors } from '@material-ui/core';

const black = '#242F41';
// const grey = '#292929';
export const lightGrey = '#E5E5E5';
export const darkGrey = '#7D87A3';
const white = '#FFFFFF';
const primary = '#FC3768';
const orange = '#F7685B';
const blue = '#006180';

export default {
  black,
  darkGrey,
  white,
  orange,
  blue,
  primary: {
    contrastText: white,
    light: '#70FFAA',
    main: '#10B16E',
    dark: '#2ED47A',
  },
  secondary: {
    contrastText: white,
    light: '#F9FAFC',
    main: '#F0F3F9',
    dark: '#E8EBF3',
  },
  success: {
    contrastText: white,
    dark: colors.green[900],
    main: colors.green[600],
    light: colors.green[400]
  },
  info: {
    contrastText: white,
    dark: colors.blue[900],
    main: colors.blue[600],
    light: colors.blue[400]
  },
  warning: {
    contrastText: white,
    dark: colors.orange[900],
    main: colors.orange[600],
    light: colors.orange[400]
  },
  error: {
    contrastText: white,
    dark: colors.red[900],
    main: colors.red[600],
    light: colors.red[400]
  },
  text: {
    primary: darkGrey,
    secondary: colors.grey[400],
    tertiary: colors.grey[600],
    link: primary,
    black: black
  },
  background: {
    default: lightGrey,
  },
  icon: primary,
  action: {
    main: primary
  },
  divider: colors.grey[200],
};
