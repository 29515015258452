import palette from "../palette";
import { createTheme } from '@material-ui/core/styles'

const theme = createTheme();


export default {
    root: {
        margin: theme.spacing(0.5),
        color: palette.black,
        fontWeight: 500,
        height:49,
        backgroundColor: palette.white,
    },
    multiline:{
        height:'auto',
    }
};
