import ApiService from "../../services/api";
import {checkResponseError} from "../helpers";
import {versionCompare} from "../../services/helpers";
import { nanoid } from 'nanoid'


export default {
    state: {
        pending: false,
        error: null,
        errorCode: null,

        list: null,
    },

    reducers:{
        authStart(state) {
            return {
                ...state,
                pending: true
            }
        },
        updateError(state, data) {
            return {
                ...state,
                ...data,
                pending: false
            }
        },
        updateAbTests(state, data){
            return {
                ...state,
                ...data,
                pending: false
            }
        }
    },

    effects: (dispatch) => ({

        async getAllProjectTests(data){
            try{
                dispatch.projectTests.authStart();

                const responseData = await ApiService.getABProjectVersions(data);

                checkResponseError(dispatch.projectTests, responseData.versions);

                const {versions} = responseData;

                const list = versions.reduce((previousValue, currentItem) => {

                    const {groups, letters: testLetters} = currentItem.tests;

                    if (groups.length === 0) {
                        currentItem.uid = nanoid();
                        previousValue.push(currentItem)
                    }

                    groups.forEach((group) => {
                        const {name, letters} = group;

                        let users = 0;
                        let newLetters = {};
                        letters.forEach(letter => {
                            newLetters[letter] = testLetters[letter];
                            users += testLetters[letter]['count']['users'];
                        });

                        const newVersion = {
                            uid: nanoid(),
                            count:{
                                users:users
                            },
                            tests:{
                                groups: [{name, letters}],
                                letters: newLetters
                            },
                            version: currentItem.version
                        };

                        previousValue.push(newVersion)
                    });

                    return previousValue;

                }, []).sort((a, b) => (-1) * versionCompare(a.version, b.version));

                dispatch.projectTests.updateAbTests({
                    list
                });

                return responseData?.versions;
            }
            catch (e) {
                console.log('Catch projectTests', e);
                dispatch.projectTests.updateError({error: e.message})
            }
        }

    })
}