import ApiService from "../services/api";
import {checkResponseError} from './helpers'
import ReportService from "../services/ReportService";


export default {
    state: {
        pending: false,
        error: null,
        errorCode: null,

        abTests: null,
        abTest: null,
        filterAbTests: null,
        actionLog: null,
        histogram: null,
        countries: null,
        countriesIsoCodes: null,
    },
    reducers: {
        authStart(state) {
            return {
                ...state,
                pending: true
            }
        },
        updateError(state, data) {
            return {
                ...state,
                ...data,
                pending: false
            }
        },
        updateAbTests(state, data){
            return {
                ...state,
                ...data,
                pending: false
            }
        }
    },

    effects: (dispatch) => ({

        async getABTests(data){
            try{

                dispatch.abtests.authStart();

                const responseData = await ApiService.getABTests(data);

                checkResponseError(dispatch.abtests, responseData);

                const abTests = responseData?.ab_tests;
                    // .sort((a, b) => {
                    //     return b.is_active - a.is_active;
                    // });

                dispatch.abtests.updateAbTests({abTests});

                return responseData;
            }
            catch (e) {
                console.log('Catch abTest', e);
                dispatch.abtests.updateAbTests({error: e.message})
            }
        },

        async getABTest(data){
            try{
                dispatch.abtests.authStart();

                const responseData = await ApiService.getABTest(data);

                checkResponseError(dispatch.abtests, responseData);

                const {ab_test, histogram}  = responseData;


                ab_test.variants.reduce((prevVariants, variant) => {

                    variant = ReportService.abTestSummary(variant);

                    variant.B2F_letters = variant.B2F_letters.reduce((prevB2FLetters, currentB2FLetters) => {

                        currentB2FLetters = ReportService.abTestSummary(currentB2FLetters);

                        prevB2FLetters.push(currentB2FLetters);
                        return prevB2FLetters;
                    },[]);

                    prevVariants.push(variant);
                    return prevVariants;
                }, []);

                if (responseData?.histogram)
                    histogram.historyForChart = ReportService.convertHistogramHistoryForChart(histogram.history);

                let _data = {abTest:ab_test};

                if (responseData?.countries)
                    _data = {..._data, ...{
                        countries:responseData?.countries.sort((a, b) => b.users - a.users),
                        countriesIsoCodes: responseData?.countries
                            .sort((a, b) => b.users - a.users)
                            .reduce((p, c) => {
                            p.push(c.value);
                            return p;
                        }, [])
                    }};

                if (responseData?.action_log)
                    _data = {..._data, ...{actionLog:responseData?.action_log}};

                if (responseData?.histogram)
                    _data = {..._data, ...{histogram}};

                if (responseData?.languages)
                    _data = {..._data, ...{languages:responseData?.languages}};


                if (data.hasOwnProperty('countries') || data.hasOwnProperty('attrDays')) {

                    const {abTest, ...other} = _data;

                    dispatch.abtests.updateAbTests({
                        filterAbTests: abTest,
                        ...other,
                    });
                } else {
                    _data = {..._data, ...{filterAbTests: null}};
                    dispatch.abtests.updateAbTests(_data);
                }


                return responseData;
            }
            catch (e) {
                console.log('Catch projects', e);
                dispatch.abtests.updateAbTests({error: e.message})
            }
        },

        async postABTest(data, prevState) {
            try {
                dispatch.abtests.authStart();

                const responseData = await ApiService.postABTest(data);

                checkResponseError(dispatch.abtests, responseData);

                let abTest = prevState.abtests.abTest;
                if (data?.field === 'description') {
                    abTest.description = data?.value;
                } else if (data?.field === 'title'){
                    abTest.title = data?.value;
                }

                const abTests = prevState.abtests.abTests.reduce((p, c) => {

                    if (data?.field === 'description' && data?.abTestId && c.id === data?.abTestId) {
                        c.description = data?.value;
                    }
                    if (data?.field === 'title' && data?.abTestId && c.id === data?.abTestId) {
                        c.title = data?.value;
                    }

                    p.push(c);
                    return p;
                }, []);

                dispatch.abtests.updateAbTests({abTests});

            } catch (e) {
                console.log('Catch abTest', e);
                dispatch.abtests.updateAbTests({error: e.message})
            }
        }

    })
};