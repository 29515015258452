import React, {useEffect} from "react";
import {connect, useSelector} from "react-redux";
import {makeStyles} from "@material-ui/core/styles";
import {Box, Table, TableRow, TableCell, TableContainer, Paper} from "@material-ui/core";
import clsx from "clsx";
import TableBody from "@material-ui/core/TableBody";
import Typography from "@material-ui/core/Typography";


const useStyles = makeStyles((theme) => ({
    root: {
        borderRadius: 7,
        '& > :not(:first-child)': {
            borderRadius: '0 0 7px 7px',
            '& ~ .MuiTableContainer-root': {
                borderRadius: 7,
            }
        },
        '& > .MuiBox-root': {
            '& ~ .MuiTableContainer-root': {
                '&:last-child': {
                    // background: 'yellow',
                    boxShadow: '0px 5px 7px rgba(0, 0, 0, 0.03)',
                }
            },
        },
    },
    boxContainer: {
        boxShadow: `0px 5px 7px rgba(0, 0, 0, 0.03)`,
        // '&:first-child':{
        //     borderTopLeftRadius: 'unset',
        //     borderTopRightRadius: 'unset',
        // },

    },
    header: {
        background: '#F0F3F9',
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
    },
    day: {
        background: 'rgba(240,207,123,0.41)',
    },
    dayValue: {
        background: 'rgba(249,236,202,0.51)'
    },
    money: {
        background: 'rgba(131,159,226,0.4)'
    },
    moneyValue: {
        background: 'rgba(205, 217, 244, 0.6)',
        '& > sup': {
            fontSize: 11,
            paddingLeft: 4,
        }
    },
    roi: {
        background: 'rgba(16, 177, 110, 0.3)',
    },
    roiValue: {
        background: 'rgba(184, 232, 212, 0.5)'
    },
    textEnd: {
        textAlign: 'end'
    },
    textLeft: {
        textAlign: 'left'
    },
    odd: {
        background: '#F9FAFF',
        boxShadow: `0px 5px 7px rgba(0, 0, 0, 0.03)`,
    },
    borderRow: {
        borderBottom: '1px solid #fff',
        borderRight: '1px solid #fff',
    },
    verticalAlign: {
        verticalAlign: 'initial',
        '&>.MuiBox-root:last-child': {
            fontWeight: 'bold',
            fontSize: 21,
            lineHeight: '25px',
        },
        '&>*>sup': {
            fontSize: 11,
            paddingLeft: 4,
        }
    },
    positive: {
        color: theme.palette.primary.main
    },
    negative: {
        color: theme.palette.error.main
    },
}));

const ShortReport = (props) => {
    const classes = useStyles();
    const { getShortReport, getCalculatorPredictive, filters} = props;

    const project = useSelector(state => state.project);
    const abTestsShort = useSelector(state => state.abTestsShort);
    const calculatorpredictive = useSelector(state => state.calculatorpredictive);

    const getColorClass = (value) => value < 0 ? classes.negative : classes.positive;

    useEffect(() => {
        if (abTestsShort.data === null && abTestsShort.pending === false) {
            getShortReport();
        }
    }, [project, abTestsShort, getShortReport]);

    useEffect(() => {
        if (project.active !== null && project.pending === false &&
            calculatorpredictive.pending === false && calculatorpredictive.data === null) {
            getCalculatorPredictive({...filters, ...{projectId: project.active.id}});
        }
    }, [calculatorpredictive, project, getCalculatorPredictive, filters]);

    const shortData = calculatorpredictive.data;

    const revenueGrow = shortData?.revenue && shortData?.spent !== 0
        ?  Math.ceil(shortData?.revenue / shortData.spent * 1000)/10
        : null;

    return (
        <Box className={classes.root} pt={3}>
            <Box p={2} className={classes.header}>
                <Typography component={'div'} variant={'subtitle1'}>
                    Actual
                </Typography>
            </Box>

                <TableContainer component={Paper}
                                className={clsx(classes.boxContainer)}>
                    <Table>
                        <TableBody>
                            <TableRow className={classes.borderRow}>
                                <TableCell colSpan={2}/>
                                <TableCell colSpan={6}
                                           style={{
                                               textAlign: 'center',
                                               background: '#FBFCFF'

                                           }}>
                                    <Box color={'initial'}
                                         component={Typography}
                                         variant={'subtitle1'}
                                    >
                                        Predictive
                                    </Box>
                                </TableCell>
                            </TableRow>
                            <TableRow className={classes.borderRow}>
                                <TableCell rowSpan={4} className={clsx(classes.textLeft, classes.verticalAlign)}>
                                    <Box>Spent</Box>
                                    <Typography component={Box} variant={'body1'}>$&nbsp;{shortData?.spent?.toLocaleString('en-US')}
                                        <sup className={getColorClass(500)}>&nbsp;</sup>
                                    </Typography>
                                </TableCell>
                                <TableCell rowSpan={4} className={clsx(classes.textLeft, classes.verticalAlign)}>
                                    <Box>Revenue</Box>
                                    <Typography component={Box} variant={'body1'}>
                                        $&nbsp;
                                        {shortData?.revenue?.toLocaleString('en-US')}
                                        {
                                            revenueGrow &&
                                            <sup className={getColorClass(revenueGrow)}>
                                                {revenueGrow}%
                                            </sup>
                                        }
                                    </Typography>
                                </TableCell>
                                <TableCell className={clsx(classes.day, classes.textLeft)}>Day</TableCell>
                                {shortData?.predictive?.map(({day}) =>
                                    <TableCell key={day}
                                               className={clsx(classes.dayValue, classes.textEnd)}>
                                        {day}
                                    </TableCell>
                                )}
                            </TableRow>
                            <TableRow className={classes.borderRow}>
                                <TableCell className={clsx(classes.money, classes.textLeft)}>Money</TableCell>
                                {shortData?.predictive?.map(({amount}, j) =>
                                    <TableCell key={j}
                                               className={clsx(classes.moneyValue, classes.textEnd)}>
                                        $&nbsp;{amount.toLocaleString('en-US')}
                                        <sup className={getColorClass(amount?.grows)}>({amount?.grows}%)</sup>
                                    </TableCell>
                                )}
                            </TableRow>
                            <TableRow className={classes.borderRow}>
                                <TableCell className={clsx(classes.roi, classes.textLeft)}>ROI</TableCell>
                                {shortData?.predictive?.map(({roi}, j) =>
                                    <TableCell key={j}
                                               className={clsx(classes.roiValue, classes.textEnd)}>
                                        {roi}%
                                    </TableCell>
                                )}
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>

        </Box>
    )
};

const mapDispatch = ({
                         abTestsShort: {
                             getReport,
                         },
                         calculatorpredictive: {
                             getCalculatorPredictive
                         },
                     }) => ({
    getShortReport: () => getReport(),
    getCalculatorPredictive: (...data) => getCalculatorPredictive(...data),
});

export default connect(null, mapDispatch)(ShortReport);