import {DEBUG} from './../constants/Constants'

import {init} from '@rematch/core'
import { enhancer as withReduxEnhancer } from 'addon-redux'

import count from './_count'
import profile from './profile'
import projects from './projects'
import project from './project'
import calculator from './calculator'
import calculatorhome from './calculatorhome'
import calculatorpredictive from './calculatorpredictive'
import settings from './settings'
import abtests from './abtests'
import {abTestsShort, abTestsReport, abTestTests as projectTests} from './abTest'

import grouth from './growth'
import products from './products'
import reports from './reports'
import deeplink from './deeplink'
import createAbTest from './createabtest'
import versions from './versions'
import versionLog from './versionLog'
import renewals from './renewals'
import report from './report'
import renewalcaluator from './renewalcaluator'
import cancelled from './cancelled'
import conversions from './conversions'
import veryGood from './veryGood'
import predictiveDebug from './predictiveDebug'
import home from './home'
import weekly from './weekly'
import monthly from './monthly'
import recurringRevenue from './recurringRevenue'
import calendarDash from './calendarDash'
import cohortsDash from './cohortsDash'
import subscriptionsAnalysis from './subscriptionsAnalysis'


let config = {
    models: {
        count,
        profile,
        projects,
        calculator,
        calculatorhome,
        calculatorpredictive,
        settings,
        project,
        abtests,

        // abtests: {
        abTestsShort,
        abTestsReport,
        projectTests,
        grouth,
        products,
        reports,
        deeplink,
        createAbTest,
        versions,
        versionLog,
        renewals,
        report,
        renewalcaluator,
        cancelled,
        conversions,
        veryGood,
        predictiveDebug,
        home,
        weekly,
        monthly,
        recurringRevenue,
        calendarDash,
        cohortsDash,
        subscriptionsAnalysis
    }
};

if (DEBUG === true) {
    config = {
        ...config,
        ...{
            redux: {
                enhancers: [withReduxEnhancer]
            }
        }
    }
}

const store = init(config);

export default store;
