import * as React from 'react';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

const StatusCheckboxes = ({ checked, setChecked }) => {
    const handleChange = (event) => {
        setChecked(prev => ({
            ...prev,
            [event.target.name]: event.target.checked
        }));
    };

    const children = (
        <Box sx={{display: 'flex', flexDirection: 'column', pl: 1}}>
            <FormControlLabel
                color="primary"
                label="Renewable"
                sx={{
                    '&>.MuiCheckbox-root': {
                        p: 0,
                    }
                }}
                control={
                    <Checkbox
                        checked={checked.renewable}
                        name="renewable"
                        onChange={handleChange}
                        size="small"
                    />
                }
            />
            <FormControlLabel
                label="Canceled"
                sx={{
                    '&>.MuiCheckbox-root': {
                        p: 0,
                    }
                }}
                control={
                    <Checkbox
                        checked={checked.canceled}
                        name="canceled"
                        onChange={handleChange} size="small"
                    />
                }
            />
        </Box>
    );

    return (
        <>
            {/*<FormControlLabel*/}
            {/*    label="Renewable & Canceled"*/}
            {/*    sx={{*/}
            {/*        '&>.MuiCheckbox-root': {*/}
            {/*            pt: 0,*/}
            {/*            pb: 0,*/}
            {/*        }*/}
            {/*    }}*/}
            {/*    control={*/}
            {/*        <Checkbox*/}
            {/*            size="small"*/}
            {/*            checked={checked.renewable && checked.canceled}*/}
            {/*            indeterminate={checked.renewable !== checked.canceled}*/}
            {/*            onChange={handleChangeAll}*/}
            {/*        />*/}
            {/*    }*/}
            {/*/>*/}
            {children}
        </>
    );
}

export default StatusCheckboxes;