import React, {useEffect} from "react";
import {Box, Paper} from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import buildQueryString, {alphabetLetters, formatDateFromTimestamp} from "../../services/helpers";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import {makeStyles} from "@material-ui/core/styles";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import HistoryIcon from '@material-ui/icons/History';
import ViewListIcon from '@material-ui/icons/ViewList';
import {NavLink} from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import TableHead from "@material-ui/core/TableHead";


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Box
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    {children}
                </Box>
            )}
        </Box>
    );
}

const useStyles = makeStyles((theme) => ({
    error: {
        '& > path':{
            fill:theme.palette.error.light,
        }
    },
}));

function Info(props){

    const classes = useStyles();

    const {can_change_in_use, can_change_variants, is_production, users, variant_in_use, version} = props;
    let {variants} = props;

    const letters = alphabetLetters();

    const [value, setValue] = React.useState('');
    const timer = React.useRef(null);
    const [_variants, setVariants] = React.useState({});

    const cbVariants =  React.useCallback(() => {
        setVariants(variants);
    }, [variants]);

    useEffect(() => {
        cbVariants();
    }, [variants, cbVariants]);

    const handleChange = (event) => {
        setValue(event.target.value);
    };

    const handleProduction = () => {
        const isAllow = window.confirm("Данное действие нельзя будет отменить! Продолжить?");
        if (is_production === false && isAllow) {
            console.log('Ok');
        }
    };

    const [newVariant, setNewVariant] = React.useState({key:null, value:null});

    const handleUpdateNewVariant = (event) => {
        setNewVariant({...newVariant, ...{[event?.target?.name]: event?.target?.value}})
    };

    const handleAddNewVariant = () => {

        setNewVariant({..._variants, ...{[newVariant.key]: newVariant.value}});

        props.postVersionFront({
            projectId: props.project.active.id,
            version: props.version,
            letter: newVariant.key,
            description: newVariant.value,
        });

        setNewVariant({key:null, value:null});
    };

    const handleDeleteVariant = (letter) => {

        const isAllow = window.confirm("Do you want remove this variant? Continue");
        if (isAllow) {
            props.deleteVersionFront({
                projectId: props.project.active.id,
                version: props.version,
                letter,
            }).then(res => {
                if (props.versions.pending === false && props.versions.error === null && _variants.hasOwnProperty(letter)) {
                    delete _variants[letter];
                    setVariants(_variants);
                }
            });
        }
    };

    const handleChangeDescription = (letter, event) => {

        if (timer?.current !== null) clearTimeout(timer.current);

        setVariants({..._variants, ...{[letter]: event?.target?.value}});

        timer.current = setTimeout(() => {
            props.postVersionFront({
                projectId: props.project.active.id,
                version: props.version,
                letter,
                description: event?.target?.value,
            });

            props.getVersionLog({
                projectId: props.project.active.id,
                version: props.version,
            });

            if (timer?.current !== null) clearTimeout(timer.current);
        }, 1000);
    };

    const handleActiveVariant = (letter) => {
        props.postVersionFrontApply({
            projectId: props.project.active.id,
            version: props.version,
            letter,
        });
    };

    const availableLetters = letters.filter( function( el ) {
        return Object.keys(_variants).indexOf( el.toUpperCase() ) < 0;
    } );

    const variantsObjects = Object.keys(_variants).reduce((p, key ) => {
        p.push({
            key,
            value: _variants[key]
        });
        return p;
    }, []);


    const [tabValue, setTabValue] = React.useState(0);
    const handleChangeTab = (event, newValue) => {
        setTabValue(newValue);
    };

    const [cohortTestName, setCohortTestName] = React.useState(0);
    const handleChangeTabCohortTestName = (event, newValue) => {

        setCohortTestName(newValue);
    };


    return (
        <React.Fragment>
            <Box p={2}  display={'block'} component={Paper}>
                <Box display={{xs: 'block', sm: 'block', md: 'flex', lg: 'flex'}} width={'100%'} >
                    <Box width="100%" pt={1}>
                        <Box>Production:
                            <Checkbox checked={is_production}
                                      disabled={is_production}
                                      color={"secondary"}
                                      onChange={handleProduction} />
                        </Box>
                        <Box mb={1}>Version: {version}</Box>
                        <Box>Users: {users}</Box>
                    </Box>
                    <Box width="100%" pt={1}>
                        <Box>Variants</Box>
                        <List component={RadioGroup} aria-label="gender" name="gender1" value={value} onChange={handleChange}>
                            {variantsObjects.map(({key, value}) =>
                                <ListItem key={key} >
                                    <ListItemIcon color={'primary'}>

                                        <FormControlLabel label={''}
                                                          color={'primary'}
                                                          control={
                                                              <Radio checked={key === variant_in_use}
                                                                     disabled={is_production || can_change_in_use===false}
                                                                     color="primary"
                                                                     onChange={handleActiveVariant.bind(this, key)} />
                                                          } />


                                        <Select variant={"outlined"}
                                                disabled={true}
                                                value={key.toUpperCase()}
                                        >
                                            {letters.map( letter =>
                                                <MenuItem key={letter}
                                                          value={letter.toUpperCase()}
                                                          selected={ letter.toUpperCase() === key.toUpperCase()}
                                                          // disabled={ letter.toUpperCase() === key.toUpperCase()}
                                                >
                                                    {letter.toUpperCase()}
                                                </MenuItem>
                                            )}
                                        </Select>
                                    </ListItemIcon>

                                    <TextareaAutosize className={'MuiOutlinedInput-multiline'}
                                                      value={value}
                                               variant={"outlined"}
                                               disabled={is_production}
                                               onChange={handleChangeDescription.bind(this, key)}
                                               // InputProps={{
                                               //     endAdornment:(
                                               //         <InputAdornment position="end">
                                               //             { timer?.current !== null && <SaveIcon />}
                                               //         </InputAdornment>
                                               //     )
                                               // }}
                                    />

                                        <ListItemSecondaryAction>
                                            <IconButton edge="end"
                                                        disabled={is_production || can_change_variants === false}
                                                        aria-label="comments"
                                                        onClick={handleDeleteVariant.bind(this, key)}
                                            >
                                                <DeleteIcon className={(can_change_variants === true) ? classes.error: ''} />
                                            </IconButton>
                                        </ListItemSecondaryAction>
                                </ListItem>
                            )}
                            {(is_production === false  || can_change_variants) &&
                            <ListItem style={{paddingLeft: 64}}>
                                <ListItemIcon>
                                    <Select variant={"outlined"}
                                            name="key"
                                            value={newVariant.key || ''}
                                            onChange={handleUpdateNewVariant}
                                    >
                                        {availableLetters.map(letter =>
                                            <MenuItem key={`addNew-${letter}`}
                                                      value={letter.toUpperCase()}
                                                // selected={ letter.toUpperCase() === key.toUpperCase()}
                                                // disabled={ letter.toUpperCase() === key.toUpperCase()}
                                            >
                                                {letter.toUpperCase()}
                                            </MenuItem>
                                        )}
                                    </Select>
                                </ListItemIcon>

                                <TextareaAutosize variant={"outlined"}
                                                  className={'MuiOutlinedInput-multiline'}
                                           color={'primary'}
                                           name="value"
                                           value={newVariant.value || ''}
                                           disabled={newVariant?.key === null || can_change_variants===false}
                                           onChange={handleUpdateNewVariant}/>

                                <ListItemSecondaryAction>
                                    <IconButton edge="end"
                                                color={"primary"}
                                                aria-label="comments"
                                                onClick={handleAddNewVariant}
                                                disabled={newVariant?.key === null || newVariant?.value === null || can_change_variants===false}
                                    >
                                        <AddIcon color={'primary'}/>
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                            }
                        </List>

                    </Box>

                </Box>
            </Box>

            <Box p={2} mt={1} display={'block'} component={Paper}>
                <Box>
                    <Tabs
                        value={tabValue}
                        onChange={handleChangeTab}
                        indicatorColor="primary"
                        centered
                    >
                        <Tab icon={<HistoryIcon />} />
                        <Tab icon={<ViewListIcon />} />
                    </Tabs>

                    <TabPanel value={tabValue} index={0}>
                        <TableContainer component={Box} pt={1}>
                            <Table size={'small'}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell width={150} align={"left"}>Date</TableCell>
                                        <TableCell align={"center"}>Description</TableCell>
                                        <TableCell align={"right"}>Agent</TableCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                {props.versionLog.actionLog?.map(({text, agent, time}, i) =>
                                    <TableRow key={i}>
                                        <TableCell align={"left"}>{formatDateFromTimestamp(time, "ru-RU", 'H:mm:ss')}</TableCell>
                                        <TableCell align={"left"}>{text}</TableCell>
                                        <TableCell>{agent}</TableCell>
                                    </TableRow>
                                )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </TabPanel>
                    <TabPanel value={tabValue} index={1}>

                        <Box display={'flex'} p={0}>
                            <Tabs
                                orientation="vertical"
                                variant="scrollable"
                                value={cohortTestName}
                                onChange={handleChangeTabCohortTestName}
                                aria-label="Vertical tabs example"

                            >
                                {props.versionLog.cohorts?.map(({name}, i) =>
                                <Tab label={name} value={i} key={i} color={"primary"} />
                                )}

                            </Tabs>

                            {props.versionLog.cohorts?.map(({name, ab_tests}, i) =>

                                <TabPanel value={cohortTestName} index={i} key={i} width={"100%"} py={0} px={1}>

                                    {ab_tests.map(({description, id, title}) =>

                                        <Box component={Paper} px={3} py={2} key={id} >
                                            <Box pb={1} >
                                                {title}
                                            </Box>
                                            <Box pb={1} component={Typography} color={'secondary'}>{description || 'Not set'}</Box>
                                            <Button component={NavLink}
                                                    variant={"outlined"}
                                                    color={"primary"}
                                                    endIcon={<OpenInNewIcon />}
                                                    target={"_blank"}
                                                    to={`/app/${props.project.active?.path}/abtests${buildQueryString({abTestId:id})}`}
                                            >
                                                Show
                                            </Button>
                                        </Box>
                                    )}
                                </TabPanel>
                            )}
                        </Box>
                    </TabPanel>
                </Box>
            </Box>

        </React.Fragment>


    )
}





export default Info