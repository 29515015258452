import ApiService from "../services/api";
import {checkResponseError} from './helpers'

export default {
    state: {
        pending:   false,
        error:     null,
        errorCode: null,
        data: null,
        controller: null
    },
    reducers: {
        authStart(state, data) {
            return {
                ...state,
                ...data,
                pending: true
            }
        },
        updateError(state, data) {
            return {
                ...state,
                ...data,
                pending: false
            }
        },
        updateReport(state, data) {
            return {
                ...state,
                ...data,
                pending: false
            }
        }
    },

    effects: (dispatch) => ({
        async getProjectReportHome(data, rootState) {
            try {
                if (rootState.home.controller !== null)
                    rootState.home.controller.abort();

                const controller = new AbortController();
                dispatch.home.authStart({ controller });

                ApiService.getProjectReportHome({ ...data, signal: controller.signal }).then(responseData => {
                    checkResponseError(dispatch.home, responseData);
                    dispatch.home.updateReport({...responseData, controller: null});
                })
            } catch (e) {
                process.env.NODE_ENV === 'development' && console.log('Catch getProjectReportHome', e);
                dispatch.home.updateReport({error: e.message})
            }
        }

    })
}