import React from "react";
import clsx from "clsx";
import TableRow from "@material-ui/core/TableRow";
import { styles } from './../unils'
import {StyledTableCell} from "./StyledTableCell";
import { useSelector } from "react-redux";
import TableHead from "@material-ui/core/TableHead";

const CustomTableHead = () => {
    const classes = styles();
    const { max_num_payments } = useSelector(state => state.renewalcaluator);

    return (
        <TableHead className={ clsx(classes.borderRowBottom, classes.backgroundTableHeader)}>
            <TableRow>
                <StyledTableCell style={{minWidth: 60}} rowSpan={2}>Data</StyledTableCell>
                <StyledTableCell align="right"
                                 rowSpan={2}
                                 className={clsx(classes.bgGray, classes.borderRight)}
                >
                    Renewable <br/>with first payment
                </StyledTableCell>
                <StyledTableCell align="right" rowSpan={2} className={clsx(classes.bgDarkGreen,classes.borderRight)}>Top</StyledTableCell>
                <StyledTableCell align="center" colSpan={2} className={clsx(classes.bgDarkGreen)}>SUM</StyledTableCell>

                {(new Array(max_num_payments)).fill(0).map((_, i) =>
                    <StyledTableCell key={i}
                                     align="right"
                                     rowSpan={2}
                                     className={clsx(classes.bgGray, classes.borderRight)}
                    >
                        {i+1}&nbsp;PL
                    </StyledTableCell>
                )}
            </TableRow>
            <TableRow>
                <StyledTableCell align="right">ОТП</StyledTableCell>
                <StyledTableCell align="right" className={classes.borderRight}>СТП</StyledTableCell>
            </TableRow>
        </TableHead>
    )
};

export default CustomTableHead;
